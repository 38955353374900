import React from 'react';
import { View } from 'react-native';
import Channels from './Channels';
import Ten4Chat from './Ten4Chat';
import Header from '../../Components/Header';

const TenFour = ({route, navigation}) => {
    const [channel, setChannel] = React.useState({});
    
    React.useEffect(()=> {
        const unsubscribe = navigation.addListener('blur', () => {
            setChannel({});
        })
        
        return unsubscribe;

    },[navigation])

    return(
        <>
        <Header
        title="Ten4"
        />
        <View
        style={{flexDirection:'row'}}
        >
            <View style={{flex:1}}>
                <Channels 
                navigation = {navigation}
                setChannel = {setChannel}
                channel={channel}
                />
            </View>

            <View style={{flex:3}}>
                { channel?.id &&
                <Ten4Chat
                    navigation={navigation}
                    channel={channel}
                />
                }
            </View>

        </View>
        </>
    )

}

export default TenFour;