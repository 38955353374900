/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { useDispatch } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container } from 'native-base';

import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import storeData from '../../../AsyncUtils/StoreData';
import getData from '../../../AsyncUtils/GetData';
import keyword from '../../../Constants/Keywords';
import { setProfile, setToken } from '../../../Redux';

export default function VerifyEmail(props) {
  const [otp, setOtp] = useState('');
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const verifyOtp = async () => {
    if (otp.length > 5) {
      let RequestData = {
        action: 'verify_code',
        userid: props.route.params.userId,
        code: otp,
      };
      let x = { 'Content-Type': 'text/plain' };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: RequestData,
        headers: x,
        redirect: 'follow',
      };
      setLoader(true);
      try {
        let { data } = await RequestMaker(Config);
        setLoader(false);
        if (data.device) {
          await storeData(keyword.Device_id, data.device);
          await loginRequest();
        } else {
          Alert.alert(data.message);
        }
      } catch (err) {
        Alert.alert(err.message);
      }
    } else {
      Alert.alert('Invalid otp length');
    }
  };

  const loginRequest = async () => {
    const Device_id = await getData(keyword.Device_id);
    let RequestData = {
      action: 'login',
      username: props.route.params.username,
      password: props.route.params.password,
      pushKey: props.route.params.pushKey,
    };
    let header = { 'Content-Type': 'text/plain' };
    if (Device_id) {
      header = { 'X-Device': Device_id };
    }
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: RequestData,
      headers: header,
      redirect: 'follow',
    };
    setLoader(true);
    try {
      let result = await RequestMaker(Config);
      setLoader(false);
      if (result.data.success) {
        if (props.route.params.check) {
          storeData(keyword.user_auth, JSON.stringify(RequestData));
        } else {
          storeData(keyword.user_auth, null);
        }
        if (result.headers && result.headers['x-dart-token']) {
          storeData(keyword.Token, result.headers['x-dart-token']);
          dispatch(
            setToken({
              token: result.headers['x-dart-token'],
              authenticated: true,
            })
          );
        }

        if (result.data) {
          dispatch(setProfile(result.data));
        }
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      {loader && <Loader />}
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt='MyRepChat logo.'
              src={require('../../../Assets/Images/logo.png')}
            />
          </View>

          <View style={{ width: '100%' }}>
            <View
              style={{
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 16,
                }}>
                A temporary verification code has been sent your mobile device/email. Enter it here and click Verify to login.
              </Text>
            </View>
            <CustomInput
              onChangeText={(s) => setOtp(s)}
              value={otp}
              title={'Verification Code'}
              placeholder={'Enter Verification code'}
            />
          </View>
          <View style={{ height: 15 }} />
          <CustomButton
            onPress={() => {
              verifyOtp();
            }}
            title={'Next'}
          />
          <View style={{ height: 15 }} />
          <CustomButton
            onPress={() => props.navigation.goBack()}
            title={'Back'}
          />
          <View style={{ marginTop: 10 }} />
        </View>
      </Container>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});
