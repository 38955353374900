import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Switch,
  Keyboard,
  FlatList,
  Linking,
  TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { useToast } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import Alert from 'Alert';
const moment = require('moment');
import Header from '../../../Components/Header';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import TimeBtn from '../../../Components/VoiceAvailability/TimeBtn';
import DateBtn from '../../../Components/VoiceAvailability/DateBtn';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';

const dayList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const VoiceAvailability = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [checked, setChecked] = useState(tenant?.useVoiceAvailable);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStartTime, setSelectedStartTime] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment());
  const [startHour, setStartHour] = useState(moment().valueOf());
  const [endHour, setEndHour] = useState(moment().valueOf());
  const [availability, setAvailability] = useState(false);
  const [voiceAvailability, setVoiceAvailability] = useState(null);
  const [id, setId] = useState(null);

  const Toast = useToast();

  useEffect(() => {
    getVoiceAvailable();
  }, []);

  const handleUpdateAvailability = async () => {
    try {
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let body = {
        action: 'update_use_voice_available',
        useVoiceAvailable: !checked,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setChecked(!checked);
      setLoading(true);
      let { data } = await RequestMaker(Config);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Voice delays updated',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };


  const onChangeStartTime = (startDate) => {
      const dateObj = startDate.toDate();
      const millesecond = dateObj.getTime();
      setStartHour(millesecond);
      setSelectedStartTime(startDate);
  };

  const onChangeEndTime = (endDate) => {
    const dateObj = endDate.toDate();
    const millesecond = dateObj.getTime();
    setEndHour(millesecond);
    setSelectedEndTime(endDate);
  };

  const handleVoiceAvailability = async () => {
    console.log('start:',startHour,'\r\n end:',endHour);
    if (!selectedDate || !startHour || !endHour) {
      Alert.alert('Error', 'Please select start time and end time');
      return;
    }
    if (endHour < startHour) {
      Alert.alert('Error', 'Please select time correctly');
      return;
    }
    try {
      console.log(voiceAvailability);
      let body = {
        action: 'save_voice_availability',
        availability: {
          ...voiceAvailability,
          [selectedDate]: {
            weekday: selectedDate,
            available: availability,
            startHour,
            endHour,
          },
        },
      };
      console.log(body);
      if(id) body.availability[selectedDate].id = id
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Voice availability schedule saved',
          status: 'success',
        });
        console.log('savevoiceAvailability:',voiceAvailability);
        getVoiceAvailable();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const getVoiceAvailable = async () => {
    let body = {
      action: 'get_voice_availability',
    };
    try {
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      console.log('getVoiceAvailable:',data.availablity, '\r\n data:', data);
      setVoiceAvailability(data.availablity);
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleHelp = () => {
    Linking.canOpenURL(
      'https://ionlake.zendesk.com/hc/en-us/articles/360021750852-Voice-Availability'
    ).then((supported) => {
      if (supported) {
        Linking.openURL(
          'https://ionlake.zendesk.com/hc/en-us/articles/360021750852-Voice-Availability'
        );
      } else {
        console.log("Don't know how to open URI: ");
      }
    });
  };

  const handleSelectAvailability = (val) => {
    setSelectedDate(val);
    if (voiceAvailability && voiceAvailability[val]) {
      const temp = voiceAvailability[val];
      console.log('temp:', temp, '\r\n', 'voiceAvailability:', voiceAvailability, '\r\n val:', val );
      setAvailability(temp.available);
      setStartHour(temp.startHour);
      setEndHour(temp.endHour);
      setId(temp.id);
      setSelectedStartTime(moment(temp.startHour));
      setSelectedEndTime(moment(temp.endHour));
    } else {
      if (val !== 'Sat' && val !== 'Sun') {
        setAvailability(true);
      } else {
        setAvailability(false);
      }
      const date = new Date();
      setId(null);
      setStartHour(46800000);
      setEndHour(79200000);
      setSelectedStartTime(moment(new Date(date.setHours(8, 0, 0))));
      setSelectedEndTime(moment(new Date(date.setHours(17, 0, 0))));
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Voice Availability'}
      />
      <View style={styles.content}>

        <View>
          <Text style={styles.comment}>
            Leave "Use Voice Availability" off if you want incoming calls to
            always go through to your voice number.
          </Text>
          <View style={styles.switchWrapper}>
            <Text style={styles.label}>USE VOICE AVAILABILITY </Text>
            <Switch
              trackColor={{ false: 'red', true: 'green' }}
              thumbColor={AppColor.SecondaryColor}
              ios_backgroundColor="red"
              onValueChange={handleUpdateAvailability}
              value={checked}
            />
          </View>
          <View style={styles.forms}>
            <Text style={styles.comment}>
              Turn on "Use Voice Availability" if you want to limit when
              incoming calls go to your voice number.
            </Text>
            <Text style={styles.comment}>
              For voice availability, specify if you are available, and the
              hours you want voice calls to be routed to your voice number. For
              the times that you do not indicate as available, your unavailable
              greeting (see below) will be played and the caller will be able to
              leave a voice mail message after they hear a beep noise.
            </Text>
            <View style={styles.dateListContainer}>
              <View style={styles.dateList}>
                {dayList.map((item) => (
                  <DateBtn
                    item={item}
                    onPress={(val) => handleSelectAvailability(val)}
                    selectedDate={selectedDate}
                    voiceAvailability={voiceAvailability}
                    key={item}
                  />
                ))}
              </View>
            </View>

            {!!selectedDate && (
              <View>
                <View style={styles.switchWrapper}>
                  <Text style={styles.label}>Availability Off</Text>
                  <Switch
                    trackColor={{ false: 'red', true: 'green' }}
                    thumbColor={AppColor.SecondaryColor}
                    ios_backgroundColor="red"
                    onValueChange={(val) => setAvailability(val)}
                    value={availability}
                  />
                  <Text style={styles.label}>On</Text>
                </View>
                {availability && (
                  <>
                    <TimeBtn
                      label="Start Time:"
                      onChange={onChangeStartTime}
                      selectedTime={selectedStartTime}
                      minuteStep={1}
                    />
                    <TimeBtn
                      label="End Time:"
                      onChange={onChangeEndTime}
                      selectedTime={selectedEndTime}
                      minuteStep={1}
                    />
                  </>
                )}
              </View>
            )}
            <View style={styles.bottom}>
              <CustomButton
                title="Save Voice Availability"
                onPress={handleVoiceAvailability}
                style={styles.button}
                disabled={loading}
              />
            </View>
          </View>
        </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

export default VoiceAvailability;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: AppColor.SecondaryColor },
  content: {
    width: '100%',
    padding: 15,
    marginVertical: 30,
    alignSelf: 'center',
  },
  comment: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginVertical: 5,
    lineHeight: 22,
  },
  forms: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#d5e2f5',
    padding: 20,
    marginTop: 20,
  },
  button: {
    marginVertical: 5,
  },
  switchWrapper: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  label: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 18,
    marginHorizontal: 15,
    color: AppColor.PrimaryColor,
  },
  help: { alignItems: 'flex-end' },
  dateListContainer: {
    paddingHorizontal: 30,
  },
  dateList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  bottom: {
    marginTop: 40,
  },
});
