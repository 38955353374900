/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  StatusBar,
  Keyboard,
  Image,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, Content, useToast } from 'native-base';

import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';
import storeData from '../../../AsyncUtils/StoreData';
import keyword from '../../../Constants/Keywords';
import RequestMaker from '../../../Middleware/ApiCaller';
import { setSignUpData } from '../../../Redux/signupdata';

const EmailPassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const signupdata = useSelector((state) => state.signupdata);
  const Toast = useToast();
  const [data, setData] = React.useState({
    contact: '',
    email: '',
    password: '',
    password2: '',
  });
  const [loading, setLoading] = React.useState(false);
  const handleNext = () => {
    if (
      data.contact.length > 0 &&
      data.email.length > 0 &&
      data.password.length > 0
    ) {
      // if (data.password==data.password2) {
      completeSetupForm1();
      // } else {
      // 	Alert.alert("Error", "Passwords do not match. Please fix and try again.");
      // }
    } else {
      Alert.alert(
        'Error',
        'All fields are required. Please compete the form and try again.'
      );
    }
  };

  const signup = async () => {
    Keyboard.dismiss();
    let body = {
      action: 'signup',
      ...signupdata,
      ...data,
      timezone: '',
      //groupCode: groupCode,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    setLoading(true);
    let result = await RequestMaker(Config);
    if (result.data.success) {
      Toast.show({
        duration: 2000,
        title: 'Successfully registered',
        status: 'success',
      });
      storeData(
        keyword.user_auth,
        JSON.stringify({
          username: signupdata.email,
          password: signupdata.password,
        })
      ).catch((err) => {});
      navigation.navigate('Login');
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    setLoading(false);
  };

  const completeSetupForm1 = () => {
    let contact = data.contact.trim();
    if (contact.length == 0) {
      Toast.show({
        duration: 2000,
        title: 'Name is a required field',
        status: 'warning',
      });
      return;
    }
    let email = data.email.trim();
    if (email.length < 6 || email.indexOf('@') == -1) {
      Toast.show({
        duration: 2000,
        title: 'Email must be a valid email address',
        status: 'warning',
      });
      return;
    }
    let password = data.password.trim();
    let confirmPassword = data.password2.trim();
    if (password != confirmPassword) {
      Toast.show({
        duration: 2000,
        title: 'Passwords do not match. Please fix and try again.',
        status: 'warning',
      });
      return;
    }
    if( !/^(?=.*\d).{8,}$/.test(password) ) {
      Toast.show({
        duration: 2000,
        title: 'Passwords must be atlest 8 characters with one number.',
        status: 'warning',
      });
      return;
    }
    checkEmail();
  };

  const checkEmail = async () => {
    let body = {
      action: 'account_check',
      verifyEmail: true,
      email: data.email,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    setLoading(true);
    let result = await RequestMaker(Config);
    if (result.data.success) {
      dispatch(setSignUpData({ data: data }));
      if (result.data.verificationRequired) {
        navigation.navigate('Verification');
      } else {
        signup();
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    setLoading(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt='MyRepChat logo.'
              src={require('../../../Assets/Images/logo.png')}
            />
          </View>
          <View style={{ width: '100%' }}>
            <CustomInput
              value={data.contact}
              onChangeText={(t) =>
                setData((state) => ({ ...state, contact: t }))
              }
              title={'Your Name'}
              placeholder={'Enter Your Name'}
            />
            <View style={{ height: 15 }} />
            <CustomInput
              value={data.email}
              onChangeText={(t) => setData((state) => ({ ...state, email: t }))}
              title={'Email'}
              placeholder={'Enter Email'}
            />
            <View style={{ height: 15 }} />
            <CustomInput
              value={data.password}
              onChangeText={(t) =>
                setData((state) => ({ ...state, password: t }))
              }
              title={'Password'}
              placeholder={'Enter Password'}
              secure={true}
            />
            <View style={{ height: 15 }} />
            <CustomInput
              value={data.password2}
              onChangeText={(t) =>
                setData((state) => ({ ...state, password2: t }))
              }
              title={'Confirm Password'}
              placeholder={'Confirm Password'}
              secure={true}
            />
            <View style={{ height: 40 }} />
          </View>
          <CustomButton onPress={handleNext} title={'Finish'} />
          <View style={{ marginTop: 10 }}>
            <Text
              onPress={() => navigation.goBack()}
              style={{
                textAlign: 'center',
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              }}>
              I have an account.{' '}
              <Text style={{ color: AppColor.PrimaryColor }}>Login Here</Text>
            </Text>
          </View>
        </View>
      </Container>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});

export default EmailPassword;
