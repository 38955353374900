import { useToast } from 'native-base';
import React, { useState } from 'react';
import { Text, View, TextInput, Keyboard, ScrollView } from 'react-native';
import Alert from 'Alert';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Modal from 'modal-react-native-web';
import CountryPicker from 'react-native-country-picker-modal';
import { useSelector, useDispatch } from 'react-redux';

import CustomButton from '../../../Components/Button';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import CustomInput from '../../../Components/CustomInput';
import { loadAccountHealthThunk } from '../../../Redux/account_health';
import Loader from '../../../Components/Loader';
import AddPhoneModal from './AddPhoneModal';
import { formatPhoneNumber } from '../../../Middleware/helpers';

const ForwardingNumber = ({ navigation }) => {
  const [countryCode, setCountryCode] = React.useState('US');
  const [code, setCode] = React.useState('+1');
  const [mobile, setMobile] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [pVisible, setPVisible] = React.useState(false);
  const [withFlag, setWithFlag] = useState(true);
  const account_health = useSelector((state) => state.account_health);
  const [withFilter, setWithFilter] = useState(true);
  const [withCallingCode, setWithCallingCode] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const Toast = useToast();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (account_health?.voice?.value) {
      setMobile(account_health?.voice?.value);
    }
  }, [account_health]);

  const handleSave = async (phone) => {
    Keyboard.dismiss();
    setLoading(true);
    try {
      let body = { action: 'set_forward', countryCode: '1', phone: phone };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Updated Successfully',
          status: 'success',
        });
        if (tenant?.id) {
          dispatch(loadAccountHealthThunk(tenant.id));
        }
        navigation.goBack();
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Forwarding Number'}
      />
      <View style={{ flex: 1 }}>
        <View
          style={{
            width: widthPercentageToDP(90),
            alignSelf: 'center',
            marginBottom: 20,
            marginTop: 5,
            maxWidth: 768,
            padding: 20,
          }}>
          <Text style={{ fontSize: 14, color: AppColor.Dark }}>
            Voice Number
          </Text>
          <Text style={{ fontSize: 14, color: AppColor.Dark }}>
          This number will be used to bridge all incoming and outgoing calls to and from your MyRepChat number.  {'\n'}
          You can change this forwarding number at any time.  {'\n'}
          This number will be the phone that rings when you receive an incoming call to your MyRepChat number.  {'\n'}
          When you place an outgoing call from MyRepchat this will be the number that rings, you answer, and the call will be placed to the contact.
          </Text>

          <View
            style={{
              backgroundColor: '#F6F6F6',
              justifyContent: 'center',
              marginVertical: 20,
            }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ justifyContent: 'center' }}>
                <CountryPicker
                  {...{
                    countryCode,
                    withFilter,
                    withFlag,
                    withCallingCode,
                  }}
                  onSelect={(v) => {
                    setCountryCode(v.cca2);
                    setCode('+' + v.callingCode[0]);
                  }}
                  visible={visible}
                />
              </View>
              <View style={{ justifyContent: 'center' }}>
                <Text onPress={() => setVisible(true)}>{code}</Text>
              </View>
              <TextInput
                textContentType={'username'}
                contextMenuHidden={true}
                style={{ paddingLeft: 10, color: 'Black', width: '100%' }}
                placeholder={'(201) 555 - 0123'}
                onChangeText={(s) => setMobile(s)}
                value={account_health?.voice?.value ? formatPhoneNumber(account_health?.voice?.value) : '' }
                keyboardType={'number-pad'}
              />
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <CustomButton
              onPress={() => setPVisible(true)}
              // onPress={()=>handleSave(mobile)}
              title={'Change'}
            />
          </View>
        </View>
      </View>
      {loading && <Loader />}
      <AddPhoneModal
        visible={pVisible}
        setVisible={setPVisible}
        navigation={navigation}
        handleSave={handleSave}
        mobile={mobile}
      />
    </SafeAreaView>
  );
};

export default ForwardingNumber;
