import { Container, Content, Icon, useToast } from 'native-base';
import React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import Alert from 'Alert';
import { set } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedContact } from '../../Redux/selected_contact';
import AlphabetList from 'react-native-flatlist-alphabet';
import { CheckBox } from 'react-native-elements';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';
const StartConversation = ({ navigation, route }) => {
  const tenant = useSelector((state) => state.User?.profile?.tenant);
  const contactData = useSelector((state) => state.contacts).data;
  const [search, setSearch] = React.useState('');
  const dls = useSelector((state) => state.distributions).data;
  const [allContactData, setAllContactData] = React.useState([]);
  const [dlSelected, setdlSelected] = React.useState(true);
  const [contactSelected, setContactSelected] = React.useState(true);
  const { url } = route.params;
  const dispatch = useDispatch();
  const Toast = useToast();
  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter(
        (x) =>
          x.fname.toLowerCase().includes(search.trim().toLowerCase()) ||
          x.lname.toLowerCase().includes(search.trim().toLowerCase())
      );
      const searchDls = dls.filter((x) =>
        x.text.toLowerCase().includes(search.trim().toLowerCase())
      );
      let arr = [];
      for (let x of searchDls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(searchedData.concat(arr));
    } else {
      setAllContactData(contactData);
    }
  }, [search]);

  React.useEffect(() => {
    if (contactData.length > 0) {
      let arr = [];
      for (let x of contactData) {
        if (x.phoneNumbers.length > 0) {
          for (let phone of x.phoneNumbers) {
            if (phone.messaging) {
              let data = { ...x };
              data['mobile'] = phone.longCode;
              arr.push(data);
            }
          }
        }
      }
      for (let x of dls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(arr);
    }
  }, [contactData, dls]);

  React.useEffect(() => {
    if (dlSelected & contactSelected) {
      let arr = [];
      for (let x of contactData) {
        if (x.phoneNumbers.length > 0) {
          for (let phone of x.phoneNumbers) {
            if (phone.messaging) {
              let data = { ...x };
              data['mobile'] = phone.longCode;
              arr.push(data);
            }
          }
        }
      }
      for (let x of dls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(arr);
    } else {
      if (dlSelected) {
        let arr = [];
        for (let x of dls) {
          arr.push({ id: x.id, name: x.text, dl: true, item: x });
        }
        setAllContactData(arr);
      } else if (contactSelected) {
        let arr = [];
        for (let x of contactData) {
          if (x.phoneNumbers.length > 0) {
            for (let phone of x.phoneNumbers) {
              if (phone.messaging) {
                let data = { ...x };
                data['mobile'] = phone.longCode;
                arr.push(data);
              }
            }
          }
        }
        setAllContactData(arr);
      } else {
        setAllContactData([]);
      }
    }
  }, [contactSelected, dlSelected]);
  const sendMessage = async (text, memberId, dl = null) => {
    try {
      const body = {
        action: 'send_text_message',
        type: 'sms',
        text: text,
      };
      console.log(dl, memberId);
      if (dl == null) {
        body['members'] = [memberId];
      } else {
        body['distributionLists'] = [{ text: dl }];
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status == 400) {
        if (tenant.status == 3) {
          Alert.alert(
            'Server Error',
            'You are not able to send messages while your account is in review state.',
            'Ok',
            null
          );
        } else {
          Alert.alert(
            'Server Error',
            'You are not able to send messages at this time.'
          );
        }
      }
      if (result.data.success) {
        // if (dl != null) {
        Toast.show({
          duration: 2000,
          title: 'Message sent successfully.',
          status: 'success',
        });
        // }
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: ' We were unable to send your message',
          status: 'success',
        });
      }
    } catch (err) {
      console.log(err, 'eeeee');
    }
  };
  const renderListView = ({ item }) => {
    if (item.dl) {
      return <RenderListView1 item={item.item} />;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          Alert.alert(
            'Message',
            `Do you like to send bookmark(${url}) to this contact.`,
            [
              {
                text: 'cancel',
              },
              {
                text: 'ok',
                onPress: () => {
                  sendMessage(url, item.id);
                },
              },
            ]
          );
          // alert("Selected")
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <img
            src={
              item.source == 'wealthbox'
                ? require('../../Assets/Images/wealthbox.png')
                : require('../../Assets/Images/splashlogo.png')
            }
            alt={
              item.source == 'wealthbox'
              ? 'Wealthbox logo.'
              : 'MyRepChat logo.'

            }
            style={{ height: 40, width: 40 }}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 12,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.mobile}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const RenderListView1 = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          // dispatch(setSelectedContact({ data: item, dl: true }))
          // navigation.goBack()
          Alert.alert(
            'Message',
            `Do you like to send bookmark(${url}) to this dl.`,
            [
              {
                text: 'cancel',
              },
              {
                text: 'ok',
                onPress: () => {
                  sendMessage(url, null, item.text);
                },
              },
            ]
          );
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            name="tags"
            type="AntDesign"
            style={{ fontSize: 40, color: AppColor.PrimaryColor }}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {item.text && (
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Select Contact'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <Search
        onChangeText={(t) => setSearch(t)}
        placeholder={'Filter by name'}
      />
      <View style={{ flexDirection: 'row', paddingHorizontal: 30 }}>
        <CheckBox
          containerStyle={{ flex: 1 }}
          title="DLs"
          checked={dlSelected}
          onPress={() => setdlSelected((state) => !state)}
        />
        <CheckBox
          containerStyle={{ flex: 1 }}
          title="Contacts"
          checked={contactSelected}
          onPress={() => setContactSelected((state) => !state)}
        />
      </View>
      <Container style={{ paddingVertical: 15, flex:1 }}>
        <AlphabetList
          data={allContactData.map((x, i) => ({
            value: x.name ?? '',
            item: x,
            key: x.id + '' + i,
          }))}
          style={{flex:1,width:'100%',alignSelf:'center'}}
          renderItem={renderListView}
          renderSectionHeader={(d) => {
            return (
              <View style={{ marginHorizontal: 10, marginVertical: 5 }}>
                <Text
                  style={{
                    backgroundColor: AppColor.PrimaryColor,
                    width: 20,
                    textAlign: 'center',
                    color: 'white',
                  }}>
                  {d.title}
                </Text>
              </View>
            );
          }}
          sectionHeaderHeight={40}
          indexLetterColor={AppColor.PrimaryColor}
          letterItemStyle={{ marginTop: 8 }}
        />
      </Container>
    </SafeAreaView>
  );
};
export default StartConversation;
