import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Dimensions,
  FlatList,
  StyleSheet,
  ScrollView,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import { HStack } from 'native-base';
import { deleteSavedMessageThunk } from '../../Redux/saved_messages';
const Templates = ({ navigation }) => {
  const saved_messages = useSelector((state) => state.saved_messages).data;
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteSavedMessageThunk(id));
  };
  const renderListView = (item) => {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate('AddTemplates', { item: item })}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginTop: 10,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            type={'antdesign'}
            name={'copy1'}
            color={AppColor.PrimaryColor}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 17,
              marginHorizontal: 10,
              color: 'black',
            }}
            numberOfLines={1}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              lineHeight: 20,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}
            numberOfLines={2}>
            {item.message}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => handleDelete(item.id)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
          <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              name={'arrowright'}
              type={'antdesign'}
              color={AppColor.Dark}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        title={'Templates'}
        
      />
      <HStack>
        <View
          style={{ flex: 1, height: window.innerHeight - 70 }}>
            <ScrollView style={{flex:1,marginBottom:20}}>
          <FlatList
            data={saved_messages}
            scrollEnabled={false}
            ListEmptyComponent={
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: 20,
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                Empty templates.
              </Text>
            }
            key={(item) => item.name}
            style={{}}
            renderItem={({ item }) => {
              return renderListView(item);
            }}
          />
          </ScrollView>
          <View style={{ alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => navigation.navigate('AddTemplates', {item: null})}
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}>
              <Icon
                color={AppColor.PrimaryColor}
                name={'add-circle-outline'}
                type={'ionicon'}
              />
              <Text
                style={{
                  fontSize: 24,
                  marginHorizontal: 5,
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.PrimaryColor,
                }}>
                Create Template
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            height: Dimensions.get('window').height * 0.8,
            borderLeftWidth: 1,
            borderColor: AppColor.PrimaryColor,
            paddingVertical: 20,
            paddingHorizontal: 10,
          }}>
          <Text style={styles.notesStyles}>
            Message Templates are a great way to save time when sending a message
            multiple times by allowing you to type the message once and sending
            it as needed. You can easily recall a message template in the
            messages view by clicking the templates button.
          </Text>
          <Text style={[styles.notesStyles, { marginVertical: 20 }]}>
            You can use the following place holders in your message
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.name}'} - combines first and last name of the contact (formerly {'${name}'})
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.firstname}'} - first name of contact (formerly {'${fname}'})
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.lastname}'} - last name of contact (formerly {'${lname}'})
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.phone}'} - the messaging number of the contact
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.address}'} - the address line of the contact
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.city}'} - the city of the contact
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.state}'} - the state of the contact (two letter)
          </Text>
          <Text style={styles.notesStyles}>
            {'${contact.zip}'} - the zip code of the contact
          </Text>
          <Text style={[styles.notesStyles, { marginTop: 20 }]}>
            When the message is sent, these place holders will be replaced with
            the values from the contact record.
          </Text>
        </View>
      </HStack>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  notesStyles: {
    fontSize: 17,
    textAlign: 'center',
  },
});
export default Templates;
