import React from 'react'
import {View, TouchableOpacity} from 'react-native';
import { InputToolbar } from 'react-native-gifted-chat';
import { CheckBox } from 'react-native-elements';
import Entypo from 'react-native-vector-icons/Entypo';
import { Icon } from 'react-native-elements';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const MessageInput = props => {
    const consent = props.consent;

    return(
    <InputToolbar
        {...props.toolBarProps}

        renderAccessory={() => (
          <CheckBox
            checked={props.checked}
            onPress={props.onCheck}
            title="Enter To Send"
            containerStyle={{
              backgroundColor: 'transparent',
              borderWidth: 0,
              padding: 0,
            }}
            titleProps={{
              style: {
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              },
            }}
          />
        )}
        primaryStyle={{
          marginTop: 37,
          paddingHorizontal: 10,
        }}
        accessoryStyle={{
          position: 'absolute',
          top: 0,
        }}
        renderSend={() => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Smile'
              accessibilityHint='Select emoji.'
              disabled={!consent}
              style={{ marginHorizontal: 10 }}
              onPress={props.onPressEmoji}>
              <Icon
                name="smile"
                type='feather'
                size={25}
                color={AppColor.PrimaryColor}
              />
            </TouchableOpacity>

            <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Attachment'
              accessibilityHint='Attach a document.'
              disabled={!consent}
              style={{ marginHorizontal: 10 }}
              onPress={props.onPressAttachment}>
              <Entypo
                name="attachment"
                size={25}
                color={AppColor.PrimaryColor}
              />
            </TouchableOpacity>
            <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            accessibilityLabel='Send'
              onPress={props.onPressSend}
              activeOpacity={1}
              style={{
                width: 40,
                borderRadius: 25,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: AppColor.PrimaryColor,
              }}>
              <Icon color={'white'} type={'font-awesome'} name={'send'} />
            </TouchableOpacity>
          </View>
        )}
        containerStyle={{
          paddingBottom: 12,
          borderTopColor: AppColor.GREY100,
        }}
      />
      )
}

export default MessageInput;