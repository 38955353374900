import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const initialState = {
  stats: [
    {
      date: 0,
      day: null,
      type: 'comm.sms.inbound',
      value: 0,
    },
  ],
  unreadMessages: 0,
  memberCount: 0,
  templateCount: 0,
  workflowCount: 0,
  startDate: null,
  endDate: null,
  success: true,
  errorMessage: '',
};

const accountStatSlice = createSlice({
  name: 'account_stat',
  initialState: initialState,
  reducers: {
    setAccountStat: (state, action) => {
      return action.payload.data;
    },
  },
});

export const { setAccountStat } = accountStatSlice.actions;

const moment = require('moment');

export const loadAccountStatThunk = () => {
  return async (dispatch) => {
    try {
      let startDate = moment();
      let endDate = moment();
      startDate.subtract(1, 'w');
      endDate.subtract(1, 'd');
      let sevenDayBefore = startDate.format('YYYY-MM-DD')
      let todayDate = endDate.format('YYYY-MM-DD');

      const body = {
        action: 'get_account_stats',
        startDate: sevenDayBefore,
        endDate: todayDate,
      };

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setAccountStat({ data: result.data }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default accountStatSlice.reducer;
