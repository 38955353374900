import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../../../Components/Header';
import AppColor from '../../../../Constants/Color';
import { WebView } from 'react-native-webview';
import Loader from '../../../../Components/Loader';
import { useToast } from 'native-base';

const WebViewScreen = ({ navigation, route }) => {
  const [loading, setLoading] = React.useState(false);
  const { title, url } = route.params ?? { title: '', url: '' };
  React.useEffect(() => {
    if (url !== '') {
      window.open(url, '_blank').focus();
      navigation.goBack();
    }
  }, [url]);
  const Toast = useToast();
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header title={title} back={true} onPress={() => navigation.goBack()} />
      <WebView
        // onLoadStart={e => {
        //     setLoading(true)
        // }}
        // onLoadEnd={e => {
        //     setLoading(false)
        // }}
        // newWindow={true}
        source={{ uri: url }}
        onNavigationStateChange={(x) => {
          if (x.url.includes('&token=') || x.url.includes('?token=')) {
            Toast.show({
              duration: 2000,
              title: 'Integration Successful, ' + 'Link established.',
              status: 'success',
            });
            navigation.goBack();
          }
        }}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default WebViewScreen;
