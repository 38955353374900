import React, { useEffect, useMemo, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';

const NotificationStatus = ({ navigation }) => {
  const [disabled, setDisabled] = useState(true);

  const supported = useMemo(() => {
    if (!('Notification' in window)) {
      return 'Unsupported';
    } else {
      if (Notification.permission === 'denied') {
        return 'Permission Denied';
      } else if (Notification.permission === 'granted') {
        setDisabled(false);
        return 'Permission Granted';
      } else if (Notification.permission === 'default') {
        setDisabled(false);
        return 'Permission Default';
      }
    }
  }, [window]);

  const handleTest = () => {
    new Notification('Notification Test Successful');
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Browser Notification Status'}
        back={true}
        onPress={() => navigation.goBack()}
      />

      <View style={{ width: '100%', padding: 30 }}>
        <Text style={styles.text}>
          Check to see if notifications are supported by your browser and if
          this web page has permission to send notifications. Test the
          notification in the browser.
        </Text>
        <View
          style={{
            marginVertical: 10,
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text
            style={[
              styles.text,
              { color: AppColor.PrimaryColor, marginRight: 15 },
            ]}>
            WEB BROWSER NOTIFICATIONS
          </Text>
          <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
            {supported}
          </Text>
        </View>
        <Text style={styles.text}>
          If web browser notifications are not granted, go to your browsers
          notifications settings, find the setting for https://dart.ionlake.com,
          and allow notifications for this web page. Then return to this page
          and re-fresh (F5) the page.
        </Text>
        <CustomButton
          title="browser test"
          style={{ width: '100%', maxWidth: 250, marginTop: 30 }}
          onPress={handleTest}
          disabled={disabled}
        />
      </View>
    </SafeAreaView>
  );
};
export default NotificationStatus;

const styles = StyleSheet.create({
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 24,
    color: AppColor.Black200,
  },
});
