import { createSlice } from '@reduxjs/toolkit';

const receivedMessageSlice = createSlice({
  name: 'received_message',
  initialState: {
    newMessage: null,
    badgeNums: null,
  },
  reducers: {
    setReceivedMessage: (state, action) => {
      const id = action.payload.data.contactId;
      let badgeNums = { ...state.badgeNums };
      badgeNums = {
        ...badgeNums,
        [id]: badgeNums?.[id] ? badgeNums[id] + 1 : 1,
      };
      return {
        newMessage: action.payload,
        badgeNums,
      };
    },
    clearBadgeNum: (state, { payload }) => {
      let badgeNums = { ...state.badgeNums };
      badgeNums = {
        ...badgeNums,
        [payload]: 0,
      };
      return {
        ...state,
        badgeNums,
      };
    },
  },
});

export const { setReceivedMessage, clearBadgeNum } =
  receivedMessageSlice.actions;

export const setReceivedMessageThunk = (newMessage) => {
  return (dispatch) => {
    dispatch(setReceivedMessage(newMessage));
  };
};

export const clearBadgeNumThunk = (id) => {
  return (dispatch) => {
    dispatch(clearBadgeNum(id));
  };
};

export default receivedMessageSlice.reducer;
