import React from 'react';
import { Text, TouchableOpacity, View, Linking, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { loadAllBlockedContactsThunk } from '../../Redux/contacts';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import { loadAllDistributionsThunk } from '../../Redux/distributions';

const Contacts = ({ navigation }) => {
  const contactData = useSelector((state) => state.contacts);
  const dup_contacts = useSelector(
    (state) => state.duplicate_members
  ).messagingPhone;
  const distributions = useSelector((state) => state.distributions).data;
  const tenant = useSelector((state) => state.User.profile)?.tenant;

  const dispatch = useDispatch();

  const loadData = async () => {
    dispatch(loadAllBlockedContactsThunk());
    dispatch(loadDuplicateNumbersThunk(tenant.id));
    dispatch(loadAllDistributionsThunk());
  };
  React.useEffect(() => {
    loadData();
  }, []);

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
    }

  const contactnum = abbreviateNumber(contactData.data.length)

  const data = [
    {
      _id: '1',
      name: 'All Contacts (excluding blocked)',
      iconname: 'contacts',
      icontype: 'antdesign',
      Count: contactnum ,
    },
    {
      _id: '2',
      name: 'Duplicate Contacts',
      iconname: 'people-arrows',
      icontype: 'font-awesome-5',
      Count: Object.entries(dup_contacts).length,
    },
    {
      _id: '3',
      name: 'Blocked Contacts',
      iconname: 'block',
      icontype: 'Entypo',
      Count: contactData.blocked_contacts.length,
    },
    {
      _id: '4',
      name: 'Distribution Lists',
      iconname: 'file-text-o',
      icontype: 'font-awesome',
      Count: distributions.length,
    },
  ];


  return (
    <SafeAreaView style={{backgroundColor:"#f1f1f1",flex: 1 }}>
      <Header
        title={'Contacts'}
        chatIcon={
          <Icon
            onPress={() => {
              if (Platform.OS == 'web') {
                window
                  .open(
                    'https://ionlake.zendesk.com/hc/en-us/sections/360005996891-Contacts-and-Groups-Mobile',
                    '_blank'
                  )
                  .focus();
              } else {
                Linking.openURL(
                  'https://ionlake.zendesk.com/hc/en-us/sections/360005996891-Contacts-and-Groups-Mobile'
                );
              }
            }}
            color={AppColor.SecondaryColor}
            name={'help-outline'}
            type={'material'}
          />
        }
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
      />
      {/* <Search onChangeText={(t)=>setSearch(t)}/> */}
      <View
            style={{
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderRadius: 8,
              justifyContent:'center',
              alignItems:'center',
              height:'80%'
            }}>
      {
        data.map(item => {
          return (
            <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            activeOpacity={0.6}
            onPress={() => {
              // if(item.Count>0){
              switch (item._id) {
                case '1':
                  navigation.navigate('AllContacts', {filter: null, item: null, no_phone: null});
                  break;
                case '2':
                  navigation.navigate('DuplicateContacts');
                  break;
                case '3':
                  navigation.navigate('BlockedContacts');
                  break;
                case '4':
                  navigation.navigate('Distributions');
                  break;
              }
              // }
            }}
            key={item._id}
            style={{
              height: widthPercentageToDP(9),
              backgroundColor: AppColor.SecondaryColor,
              width: widthPercentageToDP(9),
              margin: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 0,
              }}>
              <Icon
                // style={{ color: AppColor.PrimaryColor }}
                color={AppColor.PrimaryColor}
                name={item.iconname}
                type={item.icontype}
              />
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: 10,
                  fontFamily: AppFonts.Roboto_Regular,
                  marginHorizontal: 20,
                  fontSize: heightPercentageToDP(1.6),
                }}>
                {item.name}
              </Text>
            </View>
            <View
              style={{
                borderRadius: 30,
                backgroundColor: AppColor.PrimaryColor,
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
              <Text
                style={{
                  minWidth: 30,
                  minHeight: 30,
                  borderRadius: 15,
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: 30,
                  color: AppColor.SecondaryColor,
                  textAlign: 'center',
                }}>
                {item.Count && item.Count}
              </Text>
            </View>
          </TouchableOpacity>
          )
        })
      }

      </View>

      <TouchableOpacity
      accessible={true}
      accessibilityRole='button'
          onPress={() => navigation.navigate('AddContacts')}
          style={{
            marginBottom: 20,
            flexDirection:'row',
            alignSelf:'center'
          }}>
          <Icon
            color={AppColor.PrimaryColor}
            name={'add-circle-outline'}
            type={'ionicon'}
          />
          <Text
            style={{
              fontSize: 24,
              marginHorizontal: 5,
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.PrimaryColor,
            }}>
            Add Contact or Distribution List
          </Text>
        </TouchableOpacity>

    </SafeAreaView>
  );
};
export default Contacts;


/*
        <TouchableOpacity onPress={() => navigation.navigate('AllContacts')}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
            }}>
            All Contacts (excluding blocked) :{contactData.data.length}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.navigate('DuplicateContacts')}>
          <Text
            style={{
              marginTop: 20,
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
            }}>
            Duplicate Contacts : {Object.entries(dup_contacts).length}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.navigate('BlockedContacts')}>
          <Text
            style={{
              marginTop: 20,
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
            }}>
            Blocked Contacts :{contactData.blocked_contacts.length}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('Distributions')}>
          <Text
            style={{
              marginTop: 20,
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
            }}>
            Distribution Lists :{distributions.length}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ alignItems: 'center' }}>
        <TouchableOpacity
          onPress={() => navigation.navigate('AddContacts')}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <Icon
            color={AppColor.PrimaryColor}
            name={'add-circle-outline'}
            type={'ionicon'}
          />
          <Text
            style={{
              fontSize: 24,
              marginHorizontal: 5,
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.PrimaryColor,
            }}>
            Add Contact or Distribution List
          </Text>
        </TouchableOpacity>


*/