import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  Linking,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'modal-react-native-web';
import { SafeAreaView } from 'react-native-safe-area-context';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { useToast, Tooltip } from 'native-base';
import Alert from 'Alert';
import { CheckBox } from 'react-native-elements';
import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import AlphabetFlatList from './AlphabetList';
import { providers } from '../IntegrationProvider';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { deleteItem, callContact, loadMemberNumbers, loadReconcileMember, reconcileMember } from './helpers';
import { RemovePhoneModal, ReconcileModal  } from './modals';
import { RenderPhoneModal } from '../Chat/RenderPhoneModal';
import storeData from '../../AsyncUtils/StoreData';
import NewAlphabetList from './NewAlphabetList';

const Contacts = ({ navigation, route }) => {
  const Toast = useToast();
  const contactData = useSelector((state) => state.contacts).data;
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const affiliate = useSelector((state) => state.User)?.profile?.affiliate;
  const [hasVoiceAddon, setHasVoiceAddon] = React.useState(tenant?.voice);
  const [search, setSearch] = React.useState('');
  const [allContactData, setAllContactData] = React.useState([]);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState('');
  const [reconcileVisible, setReconcileVisible] = React.useState(false);
  const [reconcileMembers, setReconcileMembers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sortModalVisible, setSortModalVisible]=  React.useState(false);
  const [sortBy, setSortBy] = React.useState('name');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tenant) {
      setHasVoiceAddon(tenant.voice);
    }
  }, [tenant]);

  React.useEffect(()=>{
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(loadAllContactsThunk());
    });

    return unsubscribe;
  },[navigation])

  React.useEffect(()=>{
    const clear = navigation.addListener('blur', () => {
      navigation.setParams({filter: null, item: null, no_phone: null})
    });

    return clear;
  },[navigation])


  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter((x) =>
      (`${x.fname.toLowerCase()} ${x.lname.toLowerCase()}`).includes(search.trim().toLowerCase())
      );
      setAllContactData(searchedData);
    } else {
      setAllContactData(contactData);
    }
  }, [search]);


  React.useEffect(() => {
    // setAllContactData([])
    if (route.params?.no_phone) {
      // setAllContactData([])
      setAllContactData([
        ...contactData.filter((x) => x.phoneNumbers.length == 0),
      ]);
    } else {
      setAllContactData(contactData);
    }
    if(route?.params?.filter){
      filterContacts();
    }
  }, [route, contactData]);

  React.useEffect(()=>{
    getData('sortBy')
    .then(result => {
      if(!result) return;
      if(result === 'lname') setSortBy('lname');
      if(result === 'fname') setSortBy('name');
    })
  },[])


  const handleCall = async (phoneNumber) => {
    callContact(phoneNumber)
    .then(res => {
      if(res?.data?.success){
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to compelete the call',
          status: 'success',
        });
        setTimeout(() => {
          Linking.openURL(`tel:${phoneNumber}`);
        }, 2000);
      } else {
        Alert.alert('Fail to Send Message', res?.data?.failures?.join(','));
      }
    })    
  };

  const handleLoadMemberNumbers = async (id) => {
    setIsLoading(true);
    loadMemberNumbers(id)
    .then(res => {
      if(res?.data?.success) setPhoneNumbers(res?.data?.phonenumbers)
      setIsLoading(false);
    })    
  };

  const handleRemove = async () => {
    setRemoveModal(false);
    setIsLoading(true);
    deleteItem(selectedNumber)
    .then( res => {
      if (res?.data?.success) {
        Toast.show({ duration: 2000, title: res?.data?.message || 'Successfully Deleted!', status: 'success' });
        dispatch(loadAllContactsThunk());
      } else {
        Toast.show({
          duration: 2000,
          title: res?.data?.errorMessage || 'Something went wrong',
          status: 'error',
        });
      }
      setIsLoading(false);
    })
  };

  const handleLoadReconcileMember = async (id) => {
    setIsLoading(true);
    loadReconcileMember(id)
    .then(res => {
      if(res?.data?.success){
        setReconcileMembers(res.data.contacts);
      }
      setIsLoading(false);
    })     
  };


  const handleReconcile = async (contact) => {
    setReconcileVisible(false);
    setIsLoading(true);
    reconcileMember(contact)
    .then(res => {
      setIsLoading(false);
      if(res?.data?.success){ 
        dispatch(loadAllContactsThunk());
        Toast.show({ duration: 2000, title: res?.data?.message, status: 'success' });
      }
      else {
        Toast.show({
          duration: 3000,
          type: 'danger',
          text: res?.data?.errorMessage || 'Something went wrong, please try again later.',
        });
      }
    }) 
  };

  const phoneFormat = (phoneNumber) => {
    let resp = phoneNumber;
    if (!resp) return;
    if (phoneNumber.length === 10) {
      var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        resp = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    } else if (phoneNumber.length > 10) {
      if (phoneNumber.startsWith('+1')) {
        resp =
          '(' +
          phoneNumber.substring(2, 5) +
          ') ' +
          phoneNumber.substring(5, 8) +
          '-' +
          phoneNumber.substring(8);
      }
    }

    return resp;
  };

  const filterContacts = () => {
    let result = [];
     
    allContactData.forEach(e => {
      let hasNoMessagingNumber = false;

      e.phoneNumbers.forEach(ee => {
        if(ee.messaging) hasNoMessagingNumber = true;
      })

      if(!hasNoMessagingNumber) result.push(e);
    })
    
    setAllContactData(result)

  }

  const handleSort = sortBy => {
    storeData('sortBy',sortBy);
    setSortBy(sortBy);
    setSortModalVisible(false);
  }


  const renderListView = ({ item, index }) => {     
    let phone_numnber_message = 'No Messaging Number'
    let phone_numnber_color = AppColor.danger
    if(item.phone1 !== ""){
      phone_numnber_message = '(' + item.phone1.substring(0,3) + ') ' + item.phone1.substring(3,6) + '-' + item.phone1.substring(6,10);
      phone_numnber_color = AppColor.Dark
    }
    
    let status_message = '' 
    let status_color = AppColor.BlackColor
    if(item.spam){
      status_message = 'Spam'
      status_color = AppColor.danger
    }
    if(affiliate?.consentEnabled && item.consented){
      status_color = AppColor.BLUE200
      status_message = 'Consented'
    }
    if(item.stopped){
      status_message = 'Opt-Out'
      status_color = AppColor.danger
    }

    let image = require('../../Assets/Images/splashlogo.png');
    let provider = Object.values(providers).find((i) => i.name == item.source);
    if (provider) {
      image = End_Points.Base_url + provider.smallImageUrlPath;
    }

    const accountId = item?.accountId || null;


      return (
        <>
        <TouchableOpacity
        accessible={true}
        accessibilityRole='button'
        onPress={() => {
            navigation.navigate('ContactEdit', { item: item, phoneData: null, dl: null });
          }}
          key={index}
          style={{
            width: '100%',
            alignSelf: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            minHeight: 60,
            paddingLeft:15,
            marginBottom: 5,
          }}>
          <View
            style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <img src={image} style={{ height: 40, width: 40, borderRadius: 20 }} />
            <View>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.name} 
            </Text>
            {accountId &&
            <View style={{flexDirection:'row',marginHorizontal: 10}}>
            <Icon size={17} name='building-o' type='font-awesome'/>
            <Text style={{fontFamily: AppFonts.Roboto_Regular,fontSize: 17,marginLeft:2,color: AppColor.Dark,}}>{accountId}</Text>
            </View>
}
            </View>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.email_address}
            </Text>
          </View>
          
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  marginHorizontal: 10,
                  color: phone_numnber_color,
                }}>
                {phone_numnber_message}
              </Text>
            </View>

          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: status_color,
              }}>
              {status_message}
            </Text>
          </View>


          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            {
            hasVoiceAddon &&
            <Tooltip
              label="Call"
              openDelay={500}
              placement="top"
              hasArrow={true}>
              <View
                style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                <Icon
                accessibilityLabel='phone'
                accessibilityHint='Call contact.'
                  onPress={() => {
                    setPhoneModalVisible(true);
                    handleLoadMemberNumbers(item.id);
                  }}
                  name={'call-outline'}
                  type={'ionicon'}
                  style={{ fontSize: 27, color: AppColor.Dark }}
                />
              </View>
            </Tooltip>
            }

            <Tooltip
              label="Message"
              openDelay={500}
              placement="top"
              hasArrow={true}>
              <View
                style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                <Icon
                  accessibilityLabel='Message'
                  accessibilityHint='Message contact.'
                  accessibilityRole='button'
                  onPress={() => {
                    if(!item.phoneNumbers.length){
                      Alert.alert('No messaging number','This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
                    } else {
                      navigation.navigate('Chats', { memberData: item, dl: null, template_message: null })
                    }
                  }
                  }
                  style={{ fontSize: 27, color: AppColor.Dark }}
                  name={'message1'}
                  type={'antdesign'}
                />
              </View>
            </Tooltip>
            <Tooltip

              label="Delete"
              openDelay={500}
              placement="top"
              hasArrow={true}>
              <View
                style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                <TouchableOpacity
                accessibilityLabel='Trash'
                accessibilityHint='Delete Contact.'
                accessibilityRole='button'
                
                  onPress={() => {
                    setRemoveModal(true);
                    setSelectedNumber(item.id);
                  }}>
                  <EvilIcons name="trash" size={30} />
                </TouchableOpacity>
              </View>
            </Tooltip>
            <Tooltip
              label="Reconnect to CRM"
              openDelay={500}
              placement="top"
              hasArrow={true}>
              <View
                style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                <Icon
                  accessibilityLabel='Reconcile.'
                  accessibilityHint='Reconcile Contact.'
                  accessibilityRole='button'
                
                  onPress={() => {
                    setReconcileMembers([]);
                    setReconcileVisible(true);
                    handleLoadReconcileMember(item.id);
                  }}
                  style={{ fontSize: 27, color: AppColor.Dark }}
                  name={'arrowsalt'}
                  type={'antdesign'}
                />
              </View>
            </Tooltip>
          </View>
        </TouchableOpacity>
        </>
      );
    }

   const ListHeaderComponent = () => {
      return(<View
      style={{
      flexDirection: 'row',
      justifyContent: 'space-around',
      }}>
      <Text
      style={{
      fontFamily: AppFonts.Roboto_Bold,
      fontSize: 17,
      color: AppColor.Dark,
      }}>
      Name
      </Text>
      <Text
      style={{
      fontFamily: AppFonts.Roboto_Bold,
      fontSize: 17,
      color: AppColor.Dark,
      }}>
      Email
      </Text>
      <Text
      style={{
      fontFamily: AppFonts.Roboto_Bold,
      fontSize: 17,
      color: AppColor.Dark,
      }}>
      Phone Number
      </Text>
      <Text
      style={{
      fontFamily: AppFonts.Roboto_Bold,
      fontSize: 17,
      color: AppColor.Dark,
      }}>
      Status
      </Text>
      <Text
      style={{
      fontFamily: AppFonts.Roboto_Bold,
      fontSize: 17,
      color: AppColor.Dark,
      }}>
      Action
      </Text>
      </View>)
   }
  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
        <Header
          title={'Contacts'}
          back={true}
          chatIcon = {
            <MaterialIcons
            name='add'
            color='white'
            size={22}
            onPress={() => navigation.navigate('AddContacts')}
            />
          }
          onPress={() => navigation.navigate('Contacts')}
        />
        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          <MaterialIcons
          accessible={true}
          accessibilityRole='button'
          accessibilityLabel='Filter.'
          accessibilityHint='Open filter settings.'
          onPress={()=>setSortModalVisible(true)}
          name='sort' size={22} style={{marginRight:10}} />
          <Search
            onChangeText={(t) => setSearch(t)}
            placeholder="Filter by name"
          />
        </View>

          <NewAlphabetList
          sortBy={sortBy} 
          data={allContactData}
          RenderItem={renderListView}
          ListHeaderComponent={ListHeaderComponent}
          />
        {
        phoneModalVisible &&
        <Modal visible={phoneModalVisible} transparent={true}>
          <RenderPhoneModal
            handleCall={handleCall}
            phoneNumbers={phoneNumbers}
            setPhoneModalVisible={setPhoneModalVisible}
          />
        </Modal>
        }
        {
          removeModal && 
        <Modal visible={removeModal} transparent={true}>
          <RemovePhoneModal
            handleRemove={handleRemove}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
        }
        {
          reconcileVisible && 
        <Modal visible={reconcileVisible} transparent={true}>
          <ReconcileModal
            setReconcileVisible={setReconcileVisible}
            reconcileMembers={reconcileMembers}
            handleReconcile={handleReconcile}
            isLoading={isLoading}
          />
        </Modal>
        }
        {
          sortModalVisible && 
        <SortModal
          visible={sortModalVisible}
          handleSort={handleSort}
          sortBy={sortBy}
        />
        }
      </SafeAreaView>
      {isLoading && <Loader />}
    </>
  );
};

const SortModal = ({ visible, handleSort, sortBy }) => {
  const [sort, setSortBy] = React.useState(sortBy);

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Sort By
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>

          <View style={{ marginVertical: 10 }}>
              <CheckBox
                checked={sort === 'fname' || sort === 'name'}
                onPress={() => setSortBy('fname')}
                title="First Name"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={sort === 'lname'}
                onPress={() =>
                  setSortBy('lname')
                }
                title="Last Name"
                containerStyle={{ flex: 1 }}
              />
          </View>
          <CustomButton title="Done" onPress={() => {handleSort(sort)}} />
        </View>
      </View>
    </Modal>
  );
};


export default Contacts;
