/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';

import Alert from 'Alert';
import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import { Container, Content } from 'native-base';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';

const ForgetPassword = ({ navigation }) => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = useState('');
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const Validate = () => {
    if (validateEmail(email)) {
      MakeRequest();
    } else {
      Alert.alert('Invalid Email');
    }
  };

  const MakeRequest = async () => {
    setLoading(true);
    try {
      const body = {
        action: 'reset_password',
        username: email,
      };

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Alert.alert('An email has been sent with your new password.');
        navigation.goBack();
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
            }}
              
              
              alt='MyRepChat logo.'
              src={require('../../../Assets/Images/logo.png')}
            />
          </View>
          <View style={{ width: '100%' }}>
            <View style={{ marginVertical: 10 }}>
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                }}>
                Enter your email address into the field to leave a new password
                emailed to you.
              </Text>
            </View>
            <CustomInput
              onChangeText={(e) => setEmail(e)}
              value={email}
              title={'Email'}
              placeholder={'Enter Email'}
            />
          </View>
          <View style={{ height: 40 }} />
          <CustomButton
            onPress={() => {
              Validate();
            }}
            title={'Reset Password'}
          />
          <CustomButton
            style={{ marginTop: 20 }}
            onPress={() => {
              navigation.goBack();
            }}
            title={'Cancel'}
          />
        </View>
      </Container>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});

export default ForgetPassword;
