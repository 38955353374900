const End_Points = {
  //Base_url:"localhost",
  //Base_url:"https://dart.ionlake.com/",
  //Base_url: 'https://test.ionlake.com/',
  Base_url: 'https://dev.ionlake.com/',
  //Base_url:"  https://8c2c-2600-6c4a-437f-e2e1-6148-2942-9799-6093.ngrok.io",
  End: 'dart/core',
  IMGSERVER: 'dart/upload',
};

export default End_Points;
