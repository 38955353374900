import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React, { useEffect } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Image,
  FlatList,
} from 'react-native';
import Alert from 'Alert';

import { SafeAreaView } from 'react-native-safe-area-context';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadScheduledMessageThunk,
  deleteScheduledMessageThunk,
} from '../../Redux/scheduled_messages';
import { clearSelectedContact } from '../../Redux/selected_contact';
import { useFocusEffect } from '@react-navigation/core';

import { Calendar } from 'react-native-calendars';

var months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const moment = require('moment');

const ScheduledMessage = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const current_date = moment().format('YYYY-MM-DD');
  const [selected_date, setSelectedDate] = React.useState(current_date);
  const scheduled_messages = useSelector((state) => state.scheduled_messages);
  const [messages, setMessages] = React.useState([]);
  const group = useSelector((state) => state.User)?.profile?.group;
  const [currentMonth, setCurrentMonth] = React.useState(
    Number(moment().format('MM'))
  );
  const [currentYear, setCurrentYear] = React.useState(
    Number(moment().format('YYYY'))
  );
  const [markedDates, setMarkDates] = React.useState({
    [`${selected_date}`]: {
      selected: true,
      selectedColor: 'AppColor.PrimaryColor',
    },
  });

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let array = [];

    let selectedDate = moment(selected_date);
    
    scheduled_messages.data.forEach(e => {
      let deliveryDate = moment(e.deliveryDate);

      if(deliveryDate.isSame(selectedDate, 'day') ){
        array.push(e);
      }
      else if(e.frequency === 1 && deliveryDate.isSameOrBefore(selectedDate)){
        array.push(e)
      }
      else if(e.frequency === 2){
        let forward = moment(e.deliveryDate);
        let back = moment(e.deliveryDate);
        for(let i = 0; i < 3; i++){
          forward.add(1, 'week');
          back.subtract(1, 'week');
          if ((forward.isSame(selectedDate, 'day') || back.isSame(selectedDate, 'day')) && !deliveryDate.isSameOrAfter(selectedDate)){
            
            array.push(e);
          }
        }
      }

    })
    
    setMessages(array)
  }, [selected_date, scheduled_messages]);
  
  React.useEffect(() => {
    let mdates = {};
    if (scheduled_messages.data.length > 0) {
      for (let x of scheduled_messages.data) {
        let deliveryDate = moment(x.deliveryDate);
        
        if(deliveryDate.isAfter(moment())){
          deliveryDate = deliveryDate.format('YYYY-MM-DD');
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'orange' };
        }else{
          deliveryDate = deliveryDate.format('YYYY-MM-DD');
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'blue' };
        }

        let frequency = x.frequency;

        switch(frequency){
          case 1:
            let startDate = moment(x.deliveryDate);
            for (let i = 0; i < 31; i++){
              startDate.add(1, 'd');
              let formattedDate = startDate.format('YYYY-MM-DD')
              mdates[`${formattedDate}`] = { marked: true, dotColor:'orange' };
            }
            break;
          case 2:
            startDate = moment(x.deliveryDate);
            for (let i = 0; i < 3; i++){
              startDate.add(1, 'w');
              let formattedDate = startDate.format('YYYY-MM-DD')
              mdates[`${formattedDate}`] = { marked: true, dotColor:'orange' };
            }
            break;
        }


      }
    }

    mdates[`${selected_date}`] = {
      selected: true,
      selectedColor: AppColor.PrimaryColor,
    };

    setMarkDates(mdates);
  }, [scheduled_messages, selected_date]);

  const loadSMessage = () => {
    var date = new Date();
    var firstDay = new Date(currentYear, currentMonth - 1, 1);
    var lastDay = new Date(currentYear, currentMonth, 1);
    dispatch(loadScheduledMessageThunk(firstDay.getTime(), lastDay.getTime()));
  };

  useFocusEffect(
    React.useCallback(() => {
      loadSMessage();
      console.log(scheduled_messages);
    }, [])
  );

  const handleMonthChange = (month) => {
    // setCurrentMonth(month)
    var date = new Date();
    var firstDay = new Date(currentYear, month - 1, 1);
    var lastDay = new Date(currentYear, month, 1);
    dispatch(loadScheduledMessageThunk(firstDay.getTime(), lastDay.getTime()));
  };
  React.useEffect(() => {
    handleMonthChange(currentMonth);
  }, [currentMonth, currentYear]);
  const renderListView = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate('AddScheduledMessage', {
            item,
            loadMonthData: () => handleMonthChange(currentMonth),
          })
        }
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginTop: 10,
        }}>
        <View style={{ justifyContent: 'center' }}>
          {
          (item?.consented === false && group?.consentEnabled === true) ? 
          <Icon
          type={'material'}
          name={'warning'}
          color={AppColor.danger}
        />
          :
          <Icon
            type={'material'}
            name={'timer'}
            color={AppColor.PrimaryColor}
          />
          }
        </View>
        <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
          {item.delivered && (
            <Icon name="check" type="antdesign" color={AppColor.PrimaryColor} />
          )}
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 17,
                marginHorizontal: 10,
                color: 'black',
              }}
              numberOfLines={1}>
              {item.fname} {item.lname}
            </Text>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
                lineHeight: 20,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}
              numberOfLines={1}>
              {item.message}
            </Text>
          </View>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <Text>{moment(item.deliveryDate).format('hh:mm a')}</Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => {
              setLoading(true);
              dispatch(deleteScheduledMessageThunk(item.id)).then((x) => {
                handleMonthChange(currentMonth);
                setLoading(false);
              });
            }}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
          <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              name={'arrowright'}
              type={'antdesign'}
              color={AppColor.Dark}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  const renderFullListView = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate('AddScheduledMessage', {
            item,
            loadMonthData: () => {return;},
          })
        }
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginTop: 10,
        }}>
        <View style={{ justifyContent: 'center' }}>
        {
          (item?.consented === false && group?.consentEnabled === true) ?     
          <Icon
          type={'material'}
          name={'warning'}
          color={AppColor.danger}
          />
          :           
          item.delivered ?
          <Icon name="check" type="antdesign" color={AppColor.PrimaryColor} />
          :
          <Icon
            type={'material'}
            name={ item.frequency > 0 ? 'repeat' : 'timer'}
            color={AppColor.PrimaryColor}
          />
        }
        </View>
        <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
          
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 17,
                marginHorizontal: 10,
                color: 'black',
              }}
              numberOfLines={1}>
              {item.fname} {item.lname}
            </Text>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
                lineHeight: 20,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}
              numberOfLines={1}>
              {item.message}
            </Text>
          </View>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <Text>{moment(item.deliveryDate).format('MMM D YYYY')}</Text>
          <Text>{moment(item.deliveryDate).format('hh:mm a')}</Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => {
              setLoading(true);
              dispatch(deleteScheduledMessageThunk(item.id)).then((x) => {
                handleMonthChange(currentMonth);
                setLoading(false);
              });
            }}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
          <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              name={'arrowright'}
              type={'antdesign'}
              color={AppColor.Dark}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Scheduled'}
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        chatIcon={
          <Icon
            onPress={() => {
              window
                .open(
                  'https://ionlake.zendesk.com/hc/en-us/articles/360031086011-How-do-I-create-a-scheduled-message-Mobile-',
                  '_blank'
                )
                .focus();
              // Linking.openURL("https://ionlake.zendesk.com/hc/en-us/articles/360031086011-How-do-I-create-a-scheduled-message-Mobile-")
            }}
            color={AppColor.SecondaryColor}
            name={'help-outline'}
            type={'material'}
          />
        }
      />
      <View style={{ flex: 1,flexDirection:'row' }}>
      <ScrollView 
      style={{flex:1}}
      >
        {<FlatList
          data={scheduled_messages.data}
          renderItem={renderFullListView}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                }}>
                No Messages Scheduled
              </Text>
            </View>
          }
        />}
      </ScrollView>

      <View style={{ flex: 2 }}>
        <Calendar
          current={current_date}
          markedDates={markedDates}
          onDayPress={(day) => {
            // alert("changes")
            if (day.month < 10) {
              day.month = '0' + day.month;
            }
            if (day.day < 10) {
              day.day = '0' + day.day;
            }
            setSelectedDate(`${day.year}-${day.month}-${day.day}`);
          }}
          onDayLongPress={(day) => {
            // alert("changes")
          }}
          monthFormat={'yyyy MM'}
          onMonthChange={(month) => {
            // handleMonthChange(month.month);
            setCurrentMonth(month.month);
            setCurrentYear(month.year);
          }}
          hideExtraDays={true}
          disableMonthChange={true}
          showWeekNumbers={true}
          renderArrow={(direction) =>
            direction == 'left' ? (
              <MaterialIcons
                name="chevron-left"
                size={30}
                color={AppColor.PrimaryColor}
              />
            ) : (
              <MaterialIcons
                name="chevron-right"
                size={30}
                color={AppColor.PrimaryColor}
              />
            )
          }
          onPressArrowLeft={(subtractMonth) => subtractMonth()}
          onPressArrowRight={(addMonth) => addMonth()}
          disableAllTouchEventsForDisabledDays={true}
          renderHeader={(date) => {
            return (
              <Text
                style={{
                  color: AppColor.PrimaryColor,
                  textTransform: 'uppercase',
                  fontFamily: AppFonts.Roboto_Bold,
                  fontSize: 24,
                }}>
                {months[date.getMonth()]} {date.getFullYear()}
              </Text>
            );
          }}
          enableSwipeMonths={true}
        />
        <View style={{ alignItems: 'center', marginTop: 50, }}>
          <TouchableOpacity
            accessibilityRole='button'
            accessibilityLabel='Schedule new message.'
            onPress={() => {
              dispatch(clearSelectedContact());
              navigation.navigate('AddScheduledMessage', {
                item: null,
                date: moment(selected_date),
                template_message: null,
                loadMonthData: () => handleMonthChange(currentMonth),
              });
            }}
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'add-circle-outline'}
              type={'ionicon'}
            />
            <Text
              style={{
                fontSize: 24,
                marginHorizontal: 5,
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.PrimaryColor,
              }}>
              Schedule New Message
            </Text>
          </TouchableOpacity>
        </View>
        <FlatList
          data={messages}
          renderItem={renderListView}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                }}>
                No Messages Scheduled
              </Text>
            </View>
          }
        />
      </View>
      </View>

      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default ScheduledMessage;
