import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Linking } from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import CustomButton from '../../../../Components/Button';
import Loader from '../../../../Components/Loader';
import AppColor from '../../../../Constants/Color';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';
import AppFonts from '../../../../Constants/Fonts';

const Riskalyze = ({ provider, integration }) => {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const Toast = useToast();

  const handleConnect = async () => {
    const body = {
      action: 'get_oauth_connect',
      provider: provider.name,
    };
    try {
      setLoading(true);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
    	Linking.canOpenURL(result.data.url).then((supported) => {
      		if (supported) {
				console.log("Opening link",result.data.url)
        		Linking.openURL(result.data.url);
      		} else {
        		console.log("Don't know how to open URI: ");
      		}
    	});
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <View style={styles.topComment}>
            <Text style={styles.text}>
              Connect MyRepChat to Riskalyze by clicking the Connect button
              below. You will be asked to log into your Riskalyze account. Once
              logged in, accept the MyRepChat app to complete the Riskalyze
              integration.
            </Text>
            <Text style={styles.text}>
              Note: You will need to log out and log back into mobile app to see
              this setting change.
            </Text>
          </View>
          <View style={styles.buttonWrapper}>
            <CustomButton
              title="connect"
              onPress={handleConnect}
              style={{ width: '40%' }}
            />
          </View>
        </View>
      </SafeAreaView>
      {loading && <Loader backgroundColor="transparent" />}
    </>
  );
};

export default Riskalyze;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 30,
  },
  topComment: {},
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
});
