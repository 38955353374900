import React from 'react';
import { Text, ScrollView, View, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';

const ContactUS = ({ navigation }) => {
  const contact = useSelector((state) => state.User)?.profile?.user?.mobile;
  const group = useSelector((state) => state.User)?.profile?.group;
  const tenant = useSelector((state) => state.User)?.profile?.tenant;

  const data = [
    `Available ${ group.supportHours ? group.supportHours : '8:00 AM - 5:00 PM Mon-Fri CST (Except Holidays)' } - You can email us at ${group.supportEmail ? group.supportEmail : 'support@ionlake.com'}`,
    'For a prompter response, please provide as much of the following information as possible when sending an email to support:',
    '+ MyRepChat phone number',
    '+ Your name and the business name',
    '+ If your question is about MyRepChat web or mobile app',
    '+ If on the mobile app, what version of mobile app you are using',
    '+ If you are getting an error, what the error message is',
    '+ What activity you’re trying to accomplish',
    '+ Please include screenshots when applicable',
    `Available ${ group.supportHours ? group.supportHours : '8:00 AM - 5:00 PM Mon-Fri CST (Except Holidays)' } - Phone support is available at ${group.supportPhone ? group.supportPhone : '(844) 402-2401'}`,
  ];

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Contact Us'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View style={{ flex: 1, padding: 30 }}>
        <ScrollView>
          <View style={{ width: '100%', alignSelf: 'center' }}>
            {data.map((x) => {
              return (
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 16,
                    lineHeight: 24,
                    color: AppColor.Black200,
                  }}>
                  {x}
                </Text>
              );
            })}
          </View>
        </ScrollView>
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 10,
          }}>
          <CustomButton
            title="Call"
            style={{ width: '40%' }}
            onPress={() => {
              Linking.openURL('tel:+18444022401');
            }}
          />
          <CustomButton
            title="Email"
            style={{ width: '40%' }}
            onPress={() => {
              Linking.openURL(
                `mailto:support@ionlake.com?subject=MyRepChat Question&body=I am a MyRepChat customer and my number is ${tenant?.tw_number}`);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};
export default ContactUS;
