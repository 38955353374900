import React, { useState, useEffect, useMemo } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Switch,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { useToast } from 'native-base';
import { SafeAreaView } from 'react-native-safe-area-context';
import Entypo from 'react-native-vector-icons/Entypo';
import moment from 'moment';

import Alert from 'Alert';
import Header from '../../../Components/Header';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';

const ArchiveAddOn = ({ navigation, route }) => {
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [archives, setArchives] = useState([]);
  const add_ons = useSelector((state) => state.add_ons);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const Toast = useToast();

  useEffect(()=>{
    handleGetArchive();
  },[tenant?.name])

  const budget = useMemo(() => {
    const item = add_ons.biling_details.find((v) => v.addon === 'Archive');
    if (item) {
      return Number(item.amount).toFixed(2);
    } else {
      return '5.00';
    }
  }, [add_ons]);

  const handleGetArchive = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'get_archives',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        setArchives(result.data.archives);
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }


    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      if(tenant?.archive){
        setValue(tenant.archive);
      } else {
        setValue(false)
      }
  
    }
  };

  const handleArchive = async (val) => {
    if(route.params.mutable){
    if (!add_ons.hasPaymentMethod && route.params?.item === null) {
      Alert.alert(
        'Error',
        'You must set up a payment method in the payment manager to enable add-ons'
      );
      setValue(false);
      return;
    }

    try {
      setValue(val);
      setLoading(true);
      const body = {
        action: 'set_archive',
        archive: val
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 3000,
          title: 'Updated successfully',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 3000,
          status: 'error',
          title: result.data.errorMessage,
        });
        setValue(!val);
      }
    } catch (err) {
      console.log(err);
      setValue(!val);
    } finally {
      setLoading(false);
    }
  } else {
    Alert.alert('Error', 'Your group has disabled your ability to modify this setting.')
  }
  };

  const handleRestoreArchive = async (id) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_archive',
        archive: id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 3000,
          status: 'success',
          title:
            'Your archive is being restored. You will receive an email with a link to the archive when it is ready.',
        });
      } else {
        Toast.show({
          duration: 3000,
          status: 'error',
          title: result.data.errorMessage,
        });
      }
    } catch (err) {
      console.log(err);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Archive Add-on'}
      />
      {loading && <Loader />}
      <View
        style={{
          flex: 1,
          width: '100%',
          padding: 15,
          maxWidth: 767,
          alignSelf: 'center',
        }}>
        <View style={{ alignSelf: 'center', marginVertical: 10 }}>
          <Text
            style={{
              fontSize: 14,
              lineHeight: '1.2em',
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            By default all MyRepChat messages are purged after 90 days. If you
            don’t have a third party solution for archiving MyRepChat messages
            and you want ionlake to archive your messages, enable Archive
            Messages.
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 10,
            alignSelf: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              lineHeight: 20,
              marginRight: 15,
            }}>
            Archive Messages:
          </Text>
          <Switch
            value={value}
            onValueChange={() => handleArchive(!value)}
            trackColor={{ false: 'red', true: 'green' }}
          />
          <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginLeft: 5 }}>
            {value ? 'ON' : 'OFF'}
          </Text>
        </View>
        <Text
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          {`($${budget}/Month)`}
        </Text>
        {value && (
          <FlatList
            data={archives}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => {
                  console.log(item);
                  handleRestoreArchive(item.id);
                }}
                style={styles.row}>
                <Text style={styles.content}>
                  Archive ID:{item.id} [
                  {moment(new Date(item.created)).format('YYYY/MM/DD')}]
                </Text>
                <Entypo
                  name="download"
                  size={25}
                  color={AppColor.PrimaryColor}
                />
              </TouchableOpacity>
            )}
            contentContainerStyle={styles.flatContent}
            style={styles.flatContainer}
            ListEmptyComponent={
              <View>
                <Text
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 15,
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 14,
                  }}>
                  You have no archives.
                </Text>
              </View>
            }
          />
        )}
      </View>
    </SafeAreaView>
  );
};
export default ArchiveAddOn;

const styles = StyleSheet.create({
  flatContainer: {
    marginTop: 24,
    paddingHorizontal: 15,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 5,
  },
  title: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 20,
  },
  content: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
  },
});
