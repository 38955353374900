import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Linking,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import AppFonts from '../../../Constants/Fonts';
import { setCompletedStep } from '../../../Redux/singupwizard';

const Voip = ({
  verifiedData,
  handleCancel,
  areaCode,
  prefix,
  lastFourNum,
  setLoading,
  navigation,
}) => {
  const [notes, setNotes] = useState('');
  const [confirmed, setConfirmed] = useState('');
  const { profile } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const Toast = useToast();
  console.log(11, profile);

  const handleContinue = async () => {
    try {
      setLoading(true);
      let body = {
        action: 'create_zenticket',
        userNumber: areaCode + prefix + lastFourNum,
        userName: profile.user.name,
        userEmail: profile.user.email,
        supportEmail: 'support@ionlake.com',
        userNotes: notes,
        toast: false,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setCompletedStep({ phone: true }));
        navigation.navigate('BusinessCard');
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({ duration: 2000, title: err.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // if (!verifiedData) return null;

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{flex:1}}>
        <View style={styles.top}>
          <Text style={styles.text}>
            There are a few Steps to determine if we can use your Voice over
            Internet Protocol. For more information about using your VOIP
            number, please click:
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  'https://ionlake.zendesk.com/hc/en-us/articles/360021982292-Can-I-use-my-VOIP-number-'
                )
              }>
              <Text style={[styles.text, styles.link]}>HERE</Text>
            </TouchableOpacity>
          </Text>
        </View>
        <View style={styles.item}>
          <CustomInput
            titleStyle={styles.titleStyle}
            width={400}
            title="Name1"
            value={profile?.user?.name}
            disabled
          />
        </View>
        <View style={styles.item}>
          <CustomInput
            titleStyle={styles.titleStyle}
            width={400}
            title="Email"
            value={profile?.user?.email}
            disabled
          />
        </View>
        <View style={styles.item}>
          <CustomInput
            titleStyle={styles.titleStyle}
            width={400}
            title="Number"
            value={profile?.user?.mobile}
            disabled
          />
        </View>
        <View style={{ marginTop: 20 }}>
          <CustomInput
            titleStyle={styles.titleStyle}
            title="Additional Notes:"
            value={notes}
            onChangeText={(val) => setNotes(val)}
          />
        </View>

        <Text style={[styles.text, styles.comment]}>
          Clicking "Continue Setup" will create a support ticket. We will
          determine your carrier,
          <Text style={[styles.text, styles.bold]}>
            confirm the necessary steps based on your carrier and respond to you
            with what is needed.
          </Text>
        </Text>
        <View style={styles.noteWrapper}>
          <Text style={styles.text}>
            Type the word <Text style={[styles.text, styles.bold]}>"Yes"</Text>{' '}
            in the space provided below to continue
          </Text>
          <CustomInput
            title=""
            value={confirmed}
            onChangeText={(val) => setConfirmed(val)}
          />
        </View>
        <View style={styles.buttonWrapper}>
          <CustomButton
            title="continue setup"
            width={200}
            onPress={handleContinue}
            disabled={confirmed?.toLowerCase() !== 'yes'}
          />
          <CustomButton title="cancel" onPress={handleCancel} width={100} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Voip;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 30,
    width: '100%',
    maxWidth: 767,
    textAlign: 'center',
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    color: AppColor.Black200,
    fontSize: 20,
  },
  bold: {
    fontFamily: AppFonts.Roboto_Bold,
  },
  link: {
    textDecorationLine: 'underline',
    textDecorationColor: AppColor.PrimaryColor,
    color: AppColor.PrimaryColor,
  },
  label: {
    fontSize: 16,
    marginRight: 50,
    minWidth: 80,
  },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 50,
  },
  noteWrapper: {
    marginTop: 70,
  },
  continueBtn: {
    backgroundColor: AppColor.GREY100,
    marginRight: 50,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  comment: {
    marginTop: 30,
  },
  top: {
    marginBottom: 30,
  },
  titleStyle: {
    marginRight: 30,
    minWidth: 100,
  },
});
