/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { CheckBox } from 'react-native-elements';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, ScrollView, Content } from 'native-base';
import firebase from '../../../../firebase_config';
import Alert from 'Alert';
import CustomInput from '../../../Components/CustomInput';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import AppFonts from '../../../Constants/Fonts';
import AppColor from '../../../Constants/Color';
import End_Points  from '../../../Constants/Api';
import keyword from '../../../Constants/Keywords';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import storeData from '../../../AsyncUtils/StoreData';
import { setProfile, setToken, logOutThunk } from '../../../Redux';
import axios from 'axios';
// import DeviceInfo from 'react-native-device-info';

const TOKEN_QUERY_PARAM = 'token=';
const LOGOUT_QUERY = 'sso.html?token=';
const EXPIRATION = 1000 * 60 * 60 * 24 * 7; //7 days

export default function Login({ navigation }) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isFMGBannerVisible, setIsFMGBannerVisible] = useState(true);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const [check, setCheck] = React.useState(false);
  const dispatch = useDispatch();
  const [ssoCheck, SetSSOCheck] = React.useState(false);
  const { Token, authenticated } = useSelector((state) => state.User);

  const Validate = () => {
    if (validateEmail(email)) {
      if (password.length < 5) {
        Alert.alert('Password length must be greater than 5');
      } else {
        MakeRequest();
      }
    } else {
      Alert.alert('Invalid Email');
    }
  };

  const loadUserAuthData = async () => {
    try {
      let auth_data = JSON.parse(await getData(keyword.user_auth));
      if (auth_data.username) {
        setEmail(auth_data.username.trim());
        setPassword(auth_data.password);
        setCheck(true);
      }
    } catch (err) {}
  };

  useFocusEffect(
    React.useCallback(() => {
      loadUserAuthData();
      authorize();
    }, [])
  );

  const switchAccount = async (id, token) => {
    setLoader(true);
    try {
      const fcmToken = await getData('fcmToken');
      const body = {
        action: 'authorize',
        newToken: true,
        account: id,
        pushKey: fcmToken,
        expiration: EXPIRATION,
      };
      const headers = { 'x-dart-token': token };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        dispatch(setProfile(result.data));

        if (result.headers && result.headers['x-dart-token']) {
          storeData(
            keyword.user_auth,
            JSON.stringify({ username: email, password: '' })
          );
          storeData(keyword.Token, result.headers['x-dart-token']);
          dispatch(
            setToken({
              token: result.headers['x-dart-token'],
              authenticated: true,
            })
          );
        }
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const apiAuthorize = async token => {
    const fcmToken = await getData('fcmToken');
    const body = {
      action: 'authorize',
      newToken: true,
      pushKey: fcmToken,
      expiration: EXPIRATION,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    return axios(Config);

  }

  const authorize = async () => {
    setLoader(true);
    apiAuthorize()
    .then(result => {
      if (!result.data.success) {
        localStorage.clear();
        return;
      }
      if (result.status == 200) {
        if (result.data) {
          let tenant = result.data.tenant;
          let user = result.data.user;
          let accounts = user.accounts;
          if (
            tenant == null ||
            !user.permissions.map((x) => x.id).includes(13)
          ) {
            for (let a of accounts) {
              if (
                a.id != 0 &&
                a.permissions.map((x) => x.id).includes(13) &&
                currentAccount == null
              ) {
                currentAccount = a;
              }
            }
            if (currentAccount == null) {
              Alert.alert(
                'Login Error',
                'You cannot login to mobile with a user that doesnot have MyRepchat User Permission'
              );
            } else {
              switchAccount(currentAccount.id, result.headers['x-dart-token']);
            }
          } else {
            dispatch(setProfile(result.data));

            if (result.headers && result.headers['x-dart-token']) {
              storeData(
                keyword.user_auth,
                JSON.stringify({ username: email, password: '' })
              );
              storeData(keyword.Token, result.headers['x-dart-token']);
              dispatch(
                setToken({
                  token: result.headers['x-dart-token'],
                  authenticated: true,
                })
              );
            }
          }
        }
      } else if (result.status == 401) {
        localStorage.clear();
        setLoader(false);
        return;
      } else {
        setLoader(false);
        Alert.alert(result.data.errorMessage);
      }

    })
    .catch(err => {
      console.log(err);
    })
    setLoader(false)    
  };

  // sso check
  const checkSSOAsync = async () => {
    let RequestData = {
      action: 'lookup_user',
      username: email,
      toast: false,
    };
    let headers = { 'Content-Type': 'text/plain' };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: RequestData,
      headers: headers,
    };
    setLoader(true);
    let result = await RequestMaker(Config);
    setLoader(false);
    if (result.data.success) {
      if (result.data?.accounttype && result.data.accounttype == 'sso') {
        navigation.navigate('SSOScreen', {
          url: result.data.redirect ?? null,
          email: email,
        });
      } else {
        SetSSOCheck(true);
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
  };

  const MakeRequest = async () => {
    const Device_id = await getData(keyword.Device_id);
    const fcmToken = await getData('fcmToken');

    let RequestData = {
      action: 'login',
      username: email,
      password: password,
      pushKey: fcmToken,
    };
    let x = { 'Content-Type': 'application/json' };
    if (Device_id) {
      x = { 'X-Device': Device_id };
    }
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: RequestData,
      headers: x,
    };
    setLoader(true);
    try {
      let result = await RequestMaker(Config);
      setLoader(false);
      if (result.data.success) {
        if (check) {
          storeData(keyword.user_auth, JSON.stringify(RequestData));
        } else {
          storeData(keyword.user_auth, null);
        }
        if (result.headers && result.headers['x-dart-token']) {
          storeData(keyword.Token, result.headers['x-dart-token']);
          dispatch(
            setToken({
              token: result.headers['x-dart-token'],
              authenticated: true,
            })
          );
        } else if (Object.keys(result.data).includes('mfa')) {
          navigation.navigate('VerifyEmail', {
            userId: result.data.mfa,
            username: email,
            password: password,
            pushKey: fcmToken,
            check: check,
          });
        } else if (Object.keys(result.data).includes('mobile')) {
          navigation.navigate('MobileAuth', {
            userId: result.data.mobile,
            username: email,
            password: password,
            pushKey: fcmToken,
            check: check,
          });          
        }
        if (result.data) {

          dispatch(setProfile(result.data));
        }
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hideFMGBanner = () => {
    setIsFMGBannerVisible(false);
  }

  return (
    <SafeAreaView style={styles.container}>
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
              marginBottom: 50,
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt={'MyRepChat logo.'}
              src={require('../../../Assets/Images/logo.png')}
            />
            <Text
              style={{
                textAlign: 'right',
                marginRight: 20,
                marginTop: -40,
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              }}>
              Version : 5.6.8
            </Text>
          </View>
          <View style={{ width: '100%' }}>
            <CustomInput
              onEnterPress={() => {
                if (!ssoCheck) {
                  checkSSOAsync();
                }
              }}
              onChangeText={(e) => setEmail(e.trim())}
              value={email}
              title={'Email'}
              placeholder={'Enter Email'}
            />
            <View style={{ height: 15 }} />
            {ssoCheck && (
              <CustomInput
                onEnterPress={() => {
                  if (ssoCheck) {
                    Validate();
                  }
                }}
                onChangeText={(e) => setPassword(e)}
                value={password}
                title={'Password'}
                placeholder={'Enter Password'}
                secure={true}
              />
            )}
            <View
              style={{
                marginTop: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <CheckBox
                checked={check}
                onPress={() => setCheck(!check)}
                title="Remember me"
                containerStyle={{
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  margin: 0,
                  padding: 0,
                }}
                titleProps={{
                  style: {
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.Dark,
                  },
                }}
              />
              <Text
                accessible={true}
                accessibilityLabel='Forgot password?'
                accessibilityRole='button'
                onPress={() => navigation.navigate('ForgetPassword')}
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                }}>
                Forgot Password?
              </Text>
            </View>
          </View>
          <View style={{ height: 40 }} />
          {ssoCheck ? (
            <CustomButton
              onPress={() => {
                Validate();
              }}
              title={'Login'}
            />
          ) : (
            <CustomButton
              onPress={() => {
                checkSSOAsync();
              }}
              title={'Next'}
            />
          )}
          <View focusable={true} accessibilityRole='button' accessibilityLabel="I don\'t have an account" style={{ marginTop: 10 }}>
            <Text
              onPress={() => {
                navigation.navigate('GroupSetup');
              }}
              style={{
                textAlign: 'center',
                fontSize:18,
                textDecorationLine: 'underline',
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              }}>
              I don't have an account.
            </Text>
          </View>
          
          {!firebase.messaging.isSupported() &&
          <Text>Some features, such as real-time notifications, may not be functional with your current browser.  You should switch to use Google Chrome if you have any issues.</Text>
          }

        </View>
      </Container>
      {loader && <Loader />}
      {isFMGBannerVisible && <FMGBanner hideFMGBanner={hideFMGBanner} />}
    </SafeAreaView>
  );
}

const FMGBanner = ({hideFMGBanner}) => {

  const handleLearnMore= () => {

    hideFMGBanner();
    window.open('https://fmgsuite.com/company/in-the-news/fmg-acquires-myrepchat', '_blank');

  }
  return(
    <div style={{position:'absolute',display:'flex',alignItems:'center',flexDirection:'column',top:0,width:window.innerWidth,  backgroundImage: 'linear-gradient(black, transparent)',zIndex:99}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <img height={50} src={require('../../../Assets/Images/fmg.png')} />
        <p style={{color:'white',marginTop:0, marginBottom:0,marginLeft:5,marginRight:5}}>+</p>

        <img height={50} src={require('../../../Assets/Images/logo-white.png')} />
      </div>
      <h3 style={{color:'white',fontFamily:AppFonts.Roboto_Bold,marginTop:5,marginBottom:5}}>FMG Acquires MyRepChat</h3>
      <p style={{fontFamily:AppFonts.Roboto_Regular,color:'white',marginTop:5}}>Learn more about the exciting news.</p>
      <CustomButton
      onPress={handleLearnMore}
      style={{maxWidth:300,padding:10}}
      title="Yes, I Want To Learn More!"
      />
      <p onClick={hideFMGBanner} style={{cursor:'pointer',color:'rgba(0,0,0,.85)',fontFamily:AppFonts.Roboto_Regular}}>Not at this moment.</p>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});
