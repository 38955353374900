import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';
import { Toast } from 'native-base';
const initialState = {
  data: [],
  loading: false,
};

const bookmarkSlice = createSlice({
  name: 'bookmarks',
  initialState: initialState,
  reducers: {
    loadBookMarks: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    },
  },
});

export const { loadBookMarks } = bookmarkSlice.actions;

export const loadAllBookMarksThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_bookmarks',
      };

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadBookMarks({ data: result.data.bookmarks }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteBookmarkThunk = (id) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'delete_bookmark',
        id: id,
      };

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadAllBookMarksThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default bookmarkSlice.reducer;
