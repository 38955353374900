/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Image, ScrollView, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import End_Points from '../../../Constants/Api';

import CrmView from './CrmView';
import ArchiveView from './ArchiveView';

const Integrations = ({ navigation, route }) => {

  const { provider, type, from } = route.params;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={provider?.label}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <ScrollView style={{flex:1}}>
      {provider?.fullImageUrlPath !== '' && 
        <img
          style={{ height: 50, margin: '10px 0px', objectFit:'contain' }}
          src={End_Points.Base_url + provider?.fullImageUrlPath}
          alt={`${provider?.name} logo.`}
        />
      }

      {type === 'crm' ?
        <CrmView provider={provider} groupIntegration={null} from={from} />
      : 
        <ArchiveView provider={provider} groupIntegration={null} />
      }
      </ScrollView>

    </SafeAreaView>
  );
};

export default Integrations;
