import React, { useMemo, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { useToast } from 'native-base';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api/index';
import Loader from '../../Components/Loader';

const MobileNotifications = ({ navigation }) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user_id, pushKey } = useSelector(
    (state) => state.User?.profile?.user
  );

  const Toast = useToast();

  const supprted = useMemo(() => {
    if (pushKey) {
      setDisabled(false);
      if (pushKey.indexOf('-ios-') >= 0) {
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialIcons
              name="check"
              size={30}
              color={AppColor.SuccessColor}
            />
            <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
              Set for iPhone
            </Text>
          </View>
        );
      } else if (pushKey.indexOf('-fcm-') >= 0) {
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialIcons
              name="check"
              size={30}
              color={AppColor.SuccessColor}
            />
            <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
              Set for Android
            </Text>
          </View>
        );
      } else if (pushKey.indexOf('-gcm-') >= 0) {
        //this is an old notification push key that will no longer work. This can be removed eventually
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialIcons
              name="check"
              size={30}
              color={AppColor.SuccessColor}
            />
            <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
              Set for Android, Needs 4.17 update.
            </Text>
          </View>
        );
      } else {
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialIcons
              name="check"
              size={30}
              color={AppColor.SuccessColor}
            />
            <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
              Set for Unknown device type
            </Text>
          </View>
        );
      }
    } else {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <MaterialIcons name="remove" size={30} color={AppColor.danger} />
          <Text
            style={[
              styles.text,
              { fontFamily: AppFonts.Roboto_Bold, color: AppColor.danger },
            ]}>
            Missing
          </Text>
        </View>
      );
    }
  }, [pushKey]);

  const handleTest = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'send_test_push_message',
        id: user_id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let { data } = await RequestMaker(Config);
      console.log(data);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Notification Sent',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Mobile Notifications'}
        back={true}
        onPress={() => navigation.goBack()}
      />

      <View style={{ width: '100%', padding: 30 }}>
        <Text style={styles.text}>
          Pushing notifications to your mobile phone is a very complex process.
          It is important to understand that we do not have any sort of direct
          connection to your mobile phone. We can only control sending a
          notification, and you can only control what your phone does when it
          receives the notification, but everything in between is handled by
          third parties.
        </Text>
        <Text style={[styles.text, { marginVertical: 15 }]}>
          In order for us to send a notification to your phone, you have to:
        </Text>
        <Text style={[styles.text, { paddingLeft: 30, marginVertical: 15 }]}>
          1. Install the MyRepChat app on your phone.
          <br />
          2. Run the app and select "Allow" when asked if you want to allow the
          app to send notifications to your phone. <br />
          3.Login to your account using the app on your phone.
        </Text>
        <Text style={styles.text}>
          After you have completed these three steps, a unique key is generated
          and sent back to our server. We use this key to send notifications to
          your phone.
        </Text>
        <View
          style={{
            marginVertical: 15,
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text
            style={[
              styles.text,
              { color: AppColor.PrimaryColor, marginRight: 15 },
            ]}>
            UNIQUE KEY STATUS
          </Text>
          {supprted}
        </View>
        <CustomButton
          title="mobile push test"
          style={{ width: '100%', maxWidth: 250, marginVertical: 15 }}
          onPress={handleTest}
          disabled={disabled}
        />
        <Text style={styles.text}>
          If you did not receive a notification on your Android phone, use this
          <TouchableOpacity
            onPress={() =>
              window
                .open(
                  'https://ionlake.zendesk.com/hc/en-us/articles/360020286151-How-do-I-enable-notifications-on-my-android-',
                  '_blank'
                )
                .focus()
            }>
            <Text style={{ color: AppColor.PrimaryColor }}>
              {' '}
              knowledge base document
            </Text>
          </TouchableOpacity>{' '}
          as a guide to set up your notifications.
        </Text>
        <Text style={styles.text}>
          If you did not receive a notification on your iPhone, use this
          <TouchableOpacity
            onPress={() =>
              window
                .open(
                  'https://ionlake.zendesk.com/hc/en-us/articles/360022143871-How-do-I-enable-or-change-the-notification-on-my-iOS-device-',
                  '_blank'
                )
                .focus()
            }>
            <Text style={{ color: AppColor.PrimaryColor }}>
              {' '}
              knowledge base document
            </Text>
          </TouchableOpacity>{' '}
          as a guide to set up your notifications.
        </Text>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default MobileNotifications;

const styles = StyleSheet.create({
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 24,
    color: AppColor.Black200,
  },
});
