import React, { useState } from 'react';
import {
  FlatList,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  Picker,
  ScrollView,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { useToast } from 'native-base';
import { Icon } from 'react-native-elements';

import Header from '../../Components/Header';
import Search from '../../Components/Search';
import CustomButton from '../../Components/Button';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import QuickCreateModal from './QuickCreateModal';
import { loadAllContactsThunk } from '../../Redux/contacts';

const Messages = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const Toast = useToast();
  const [selectedText, setSlectedText] = useState('');
  const [search, setSearch] = useState('');
  const [messages, setMessages] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (search.trim()?.length < 2) {
      Toast.show({
        duration: 2000,
        title: 'You must have at least 2 characters in your search.',
        status: 'warning',
      });
      return;
    }
    try {
      setLoading(true);
      const body = {
        action: 'search_messages',
        query: search
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        setMessages(data.messages);
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const navigateToMessages = async (item) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_member',
        phoneNumber: item?.direction === 'inbound' ? item?.from_number : item?.to_number
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      const { data } = await RequestMaker(Config);
      if (data.success) {
        delete body.phoneNumber;
        body.id = data.id
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
        };
        const result = await RequestMaker(Config);

        if(result.data.success){
          navigation.navigate('Messages', {memberData: result.data})
        } else {
          Toast.show({
            duration: 2000,
            title: data.errorMessage,
            status: 'error',
          });
        }


      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#f6f6f6' }}>
      <Header
        back
        title="Search Messages"
        onPress={() => {
          Keyboard.dismiss();
          navigation.goBack();
        }}
      />
      <View style={styles.searchContainer}>
        <Search
          onChangeText={(t) => setSearch(t)}
          placeholder="Search recent messages"
          onSubmitEditing={handleSearch}
        />
        
      </View>
        <CustomButton
          title="Begin Search"
          style={{ marginVertical: 10, width: '30%' }}
          onPress={handleSearch}
        />
              <ScrollView style={{flex:1,padding:10,paddingTop:15}}>

      {(messages.length > 0) && (
          <FlatList
            data={messages}
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={({ item }) => {
              return(
              <View style={{}}>
                <View style={{flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
                 <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Text style={{color:AppColor.PrimaryColor,marginBottom:5,fontWeight:'600'}}>
                      {item?.senders[0]?.name}
                   </Text>

                    <Icon color={AppColor.PrimaryColor} style={{marginHorizontal:10}} size={16} name={item?.direction === 'outbound' ? 'long-arrow-right' : 'long-arrow-left'} type='font-awesome' />

                    <Text style={{color:AppColor.PrimaryColor,marginBottom:5,fontWeight:'600'}}>

                      { item?.direction === 'inbound' ? item.from_number : item.to_number}
                    </Text>
                </View>

              <Text style={{color:AppColor.PrimaryColor}}>
                {
                  new Date(item.date).toLocaleDateString()
                }
              </Text>

              </View>

              {item.messages.map(e => {
                return(
                  <TouchableOpacity
                  onPress={() => navigateToMessages(item)}
                  style={{backgroundColor:'white',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,

                  elevation: 5,
                  padding:20,marginBottom:20,borderRadius:5}}>
                    <Text>
                    {e?.sms}
                    </Text>  
                  </TouchableOpacity>
                )
              })}

              </View>
              )
            }} 
              
                         
          />

      )}
          </ScrollView>

      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default Messages;

const styles = StyleSheet.create({
  searchContainer: {
    width: widthPercentageToDP(90),
    maxWidth: 1024,
    alignItems: 'center',
    padding: 15,
    marginVertical: 30,
    alignSelf: 'center',
  },
  searchTitle: {
    borderColor: AppColor.SecondaryColor,
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#f0f8ff',
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    color: AppColor.Dark,
  },
  btnText: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 18,
    textDecorationLine: 'underline',
    textAlign: 'center',
    color: AppColor.PrimaryColor,
  },
  picker: {
    height: 40,
    maxWidth: 300,
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
    marginHorizontal: 5,
  },
  item: {
    width: '100%',
    alignItems: 'center',
    maxWidth: 300,
  },
  flatContainer: {
    width: widthPercentageToDP(90),
    maxWidth: 1024,
    padding: 15,
    marginVertical: 30,
    backgroundColor: '#91a1b8',
    borderRadius: 5,
    borderWidth: 1,
    alignSelf: 'center',
  },
  label: {
    fontFamily: AppFonts.Roboto_Regular,
    color: '#0b40bb',
    fontSize: 14,
  },
  value: {
    fontFamily: AppFonts.Roboto_Bold,
    color: '#0b40bb',
    fontSize: 14,
  },
  row: {
    flex: 1,
    marginVertical: 10,
    padding: 10,
    backgroundColor: '#f0f8ff',
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
