import React, { useState, createRef, useEffect, useMemo } from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  Text,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Modal from 'modal-react-native-web';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import Dropzone from 'react-dropzone';
import Alert from 'Alert';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const CSVImportModal = ({ visible, setVisible, handleImportMembers }) => {
  const [headers, setHeaders] = useState([]);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState('');
  const [selectedSource, setSelectedSource] = useState(null);
  const [linesRejected, setLinesRejected] = useState([]);
  const [importedObjects, setImportedObjects] = useState([]);
  const [totalLines, setTotalLines] = useState([]);
  const [map, setMap] = useState({});
  const [mappedItem, setMappedItem] = useState(null);
  const [fields, setFields] = useState([]);
  const [visibleSummaryModal, setVisibleSummaryModal] = useState(false);
  const dropzoneRef = createRef();

  useEffect(() => {
    if (!visible) {
      setHeaders([]);
      setAcceptedFile(null);
      setSelectedTarget('');
      setSelectedSource(null);
      setLinesRejected([]);
      setImportedObjects([]);
      setTotalLines([]);
      setMap({});
      setMappedItem(null);
      setFields([]);
    }
  }, [visible]);

  useEffect(() => {
    if (!acceptedFile) {
      return;
    }
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };
    reader.readAsText(acceptedFile[0]);
  }, [acceptedFile]);

  const removeQuotes = (str) => {
    if (str.length > 0 && str[0] === '"') {
      return str.substring(1, str.length - 1);
    }
    return str.trim();
  };

  const processCSV = (result, delim = ',') => {
    let lineSep = result.indexOf('\r\n')
      ? '\r\n'
      : result.indexOf('\n') > -1
      ? '\n'
      : '\r';
    let lines = result.split(lineSep),
      keys = [];

    let _importedObjects = [];
    let _linesRejected = [];
    let values = null;
    setTotalLines(lines);
    lines.forEach((line, i) => {
      if (line.length > 0) {
        if (i === 0) {
          keys = line.split(delim);
          const _keys = keys
            .filter((v) => !!v.replace('"\n"', '')?.trim())
            .map((v) => v.split(' ').join('_'));
          setHeaders(_keys);
        } else {
          let lineValues = removeQuotes(line).split(delim);
          if (values === null) {
            values = lineValues;
          } else {
            values = values.concat(lineValues);
          }
          if (values.length > keys.length) {
            _linesRejected.push(i);
            values = null;
            return;
          }
          if (values.length === keys.length) {
            let obj = {};
            keys.forEach((key, j) => {
              key = key.split(' ').join('_');
              let value = removeQuotes(values[j].trim());
              obj[key.trim()] = value;
            });
            _importedObjects.push(obj);
            values = null;
          } else {
            console.log('reading next row');
          }
        }
      }
    });
    setImportedObjects(_importedObjects);
    setLinesRejected(_linesRejected);
    setVisibleSummaryModal(true);
  };

  const handleMap = () => {
    if (selectedSource && selectedTarget) {
      setMap({
        ...map,
        [selectedTarget]: selectedSource.label,
      });
      setSelectedSource(null);
      setSelectedTarget('');
    }
  };

  useEffect(() => {
    const keys = Object.keys(map);
    const keyIndex = keys.indexOf(selectedTarget);

    if (!selectedTarget) {
      return;
    }

    if (keyIndex > -1) {
      setMappedItem({
        [selectedTarget]: map[selectedTarget],
      });
      setSelectedSource(null);
    } else {
      setMappedItem(null);
    }
  }, [selectedTarget]);

  useEffect(() => {
    const keys = Object.keys(map);
    const values = Object.values(map);
    if (!selectedSource) {
      return;
    }

    const labelIndex = values.indexOf(selectedSource.label);
    if (labelIndex > -1) {
      setMappedItem({
        [keys[labelIndex]]: selectedSource.label,
      });
      setSelectedTarget('');
    } else {
      setMappedItem(null);
    }
  }, [selectedSource]);

  const renderTargetItem = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedTarget(item.id);
        }}
        style={[
          {
            marginTop: 5,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: AppColor.GREY100,
          },
          !map?.[selectedTarget] &&
            selectedTarget === item.id &&
            styles.selectedItem,
          mappedItem?.[item.id] && styles.exitedItem,
        ]}>
        <Text style={{ textAlign: 'center' }}>{item.label}</Text>
      </TouchableOpacity>
    );
  };

  const renderSourceItem = ({ item, index }) => {
    const linkedTo = Object.keys(map).find(e => map[e] === item)
    const field = fields.find(e => e.id === linkedTo)?.label;
    console.log(fields);
    return (
      <>
      <TouchableOpacity
        onPress={() => {
          setSelectedSource({
            value: `${item}${index}`,
            label: item,
          });
        }}
        style={[
          {
            marginTop: 5,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: AppColor.GREY100,
          },
          Object.values(map).indexOf(item) === -1 &&
            selectedSource?.value === `${item}${index}` &&
            styles.selectedItem,
          mappedItem &&
            Object.values(mappedItem).indexOf(item) > -1 &&
            styles.exitedItem,
        ]}>
        <Text style={{ textAlign: 'center' }}>{item}</Text>
      </TouchableOpacity>
      {!!field &&
      <View style={{flexDirection:'row',alignItems:'center'}}>
      <MaterialCommunityIcons
      name='subdirectory-arrow-left'
      />
      <Text style={{fontFamily:AppFonts.Roboto_Regular}}>Linked To: {field}</Text>
      </View>
      }
      </>
    );
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const disabled = useMemo(() => {
    console.log('selectedSource', selectedSource);
    console.log('selectedTarget', selectedTarget);
    console.log('map', map);
    console.log('mappedItem====>', mappedItem);
    if (!selectedTarget || !selectedSource) {
      return true;
    }
    if (map[selectedTarget]) {
      return true;
    }
    if (
      selectedSource &&
      Object.values(map).indexOf(selectedSource.label) > -1
    ) {
      return true;
    }
    if (mappedItem) {
      return true;
    }
    return false;
  }, [map, mappedItem, selectedTarget, selectedSource]);

  return (
    <>
      <Modal
        animationIn={'fadeIn'}
        useNativeDriver={true}
        animationOut={'fadeOut'}
        backdropOpacity={0.9}
        visible={visible}
        transparent={true}
        onDismiss={() => {
          setVisible(false);
        }}
        ariaHideApp={false}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          border: 'none',
        }}
        onBackdropPress={() => {
          setVisible(false);
        }}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#0007',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View style={styles.modal}>
            <View style={styles.header}>
              <Text style={styles.title}>CSV Import</Text>
              <TouchableOpacity onPress={() => setVisible(false)}>
                <EvilIcons name="close" size={24} />
              </TouchableOpacity>
            </View>
            <ScrollView style={{ flex: 1, padding: 30 }}>
              <Text style={styles.description}>
                Drag and drop a CSV file into the area below to begin. CSV file
                must have a header row.
              </Text>
              <View style={{margin:20,border:'1px dashed black'}}>
                <Dropzone
                  ref={dropzoneRef}
                  noKeyboard
                  noClick
                  onDrop={acceptedFile => setAcceptedFile(acceptedFile)}
                >
                  {({ getRootProps, getInputProps }) => {
                    return(
                    <div {...getRootProps()} style={{alignSelf:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
                      <input   {...getInputProps()} />
                      <button style={{width:200,height:30,fontSize:18,marginTop:10,fontFamily:AppFonts.Roboto_Regular,backgroundColor:'rgb(37, 105, 180)',border:'none',color:'white',borderRadius:5}} onClick={openDialog}>CHOOSE FILE </button>
                      <p style={{textAlign:'center'}}>
                        Drag 'n' drop some files here, or click the button above to select files.
                      </p>
                    </div>
                  )}}
                </Dropzone>
                </View>

              <Text style={styles.description}>
                Pick a target field on the left and a CSV source field on the
                right, then select the Link button to pair them for the import.
                Non paired fields will be set to generated values and can be
                changed later. CSV fields that are not paired will be ignored.
              </Text>
              <View style={styles.row}>
                <FlatList
                  data={fields}
                  renderItem={renderTargetItem}
                  keyExtractor={(item) => item.id}
                  style={styles.flatlist}
                  ListHeaderComponent={
                    <Text style={styles.headerTxt}>Target Fields </Text>
                  }
                />
                <View style={styles.button}>
                  <CustomButton
                    title="Link"
                    disabled={disabled}
                    width={100}
                    onPress={handleMap}
                  />
                </View>
                <FlatList
                  data={headers}
                  renderItem={renderSourceItem}
                  keyExtractor={(item, index) => `item${item}${index}`}
                  style={styles.flatlist}
                  ListHeaderComponent={
                    <Text style={styles.headerTxt}>CSV Source Fields</Text>
                  }
                />
              </View>
            </ScrollView>
            <View style={styles.row}>
              <View style={styles.button}>
                <CustomButton
                  title="Import"
                  onPress={() => {
                    if(Object.keys(map).length < headers.length){
                      Alert.alert('Confirm', 'You have not linked all of the CSV source fields to their respective target fields.  You may be creating contacts with missing information.  Are you sure you\'d like to continue?', [{style: 'cancel', text:'Cancel', onPress: () => {return}},{style: 'default', text:'Continue', onPress: () => {
                        handleImportMembers({
                          map: map,
                          objects: importedObjects,
                        });
                      }}])
                    } else {
                      handleImportMembers({
                        map: map,
                        objects: importedObjects,
                      });
                    }                    
                  }}
                  width={100}
                />
              </View>
              <View style={styles.button}>
                <CustomButton
                  title="Close"
                  onPress={() => {
                    setVisible(false);
                  }}
                  width={100}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationIn={'fadeIn'}
        useNativeDriver={true}
        animationOut={'fadeOut'}
        backdropOpacity={0.9}
        visible={visibleSummaryModal}
        transparent={true}
        onDismiss={() => {
          setVisibleSummaryModal(false);
        }}
        ariaHideApp={false}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          border: 'none',
        }}
        onBackdropPress={() => {
          setVisibleSummaryModal(false);
        }}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#0007',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View
            style={{
              backgroundColor: '#ffffff',
              borderRadius: 5,
              borderWidth: 1,
              maxWidth: 525,
              width: '100%',
              maxHeight: 700,
            }}>
            <View style={styles.header}>
              <Text style={styles.title}>CSV Summary</Text>
              <TouchableOpacity onPress={() => setVisible(false)}>
                <EvilIcons name="close" size={24} />
              </TouchableOpacity>
            </View>
            <View style={{ padding: 15 }}>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <Text
                  style={[
                    styles.label,
                    {
                      color: AppColor.PrimaryColor,
                      textTransform: 'capitalize',
                      textDecorationLine: 'underline',
                    },
                  ]}>
                  Total Lines:{' '}
                </Text>
                <Text style={styles.label}>{totalLines.length}</Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <Text
                  style={[
                    styles.label,
                    {
                      color: AppColor.PrimaryColor,
                      textTransform: 'capitalize',
                      textDecorationLine: 'underline',
                    },
                  ]}>
                  Columns found:{' '}
                </Text>
                <Text style={styles.label}>{headers.length}</Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <Text
                  style={[
                    styles.label,
                    {
                      color: AppColor.PrimaryColor,
                      textTransform: 'capitalize',
                      textDecorationLine: 'underline',
                    },
                  ]}>
                  Total Data Lines Found:{' '}
                </Text>
                <Text style={styles.label}>{importedObjects.length}</Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <Text
                  style={[
                    styles.label,
                    {
                      color: AppColor.PrimaryColor,
                      textTransform: 'capitalize',
                      textDecorationLine: 'underline',
                    },
                  ]}>
                  Rejected Lines:{' '}
                </Text>
                <Text style={styles.label}>{linesRejected.length}</Text>
              </View>
              <Text style={[styles.label, { marginVertical: 15 }]}>
                If you have rejected lines they are listed below. This can be
                caused by additional commas in the CSV file or finding more data
                then columns.
              </Text>
              <Text style={[styles.label, { marginVertical: 15 }]}>
                Close this dialog to continue and link Your CSV columns (right)
                to MyRepChat target columns (left) and import, or fix issues and
                re-load your CSV file.
              </Text>
            </View>

            <View style={styles.button}>
              <CustomButton
                title="Close"
                onPress={() => {
                  setFields([
                    {
                      id: 'id',
                      label: 'Id',
                      defaults: ['all'],
                      hint: 'number',
                    },
                    {
                      id: 'fname',
                      label: 'First Name',
                      defaults: ['all'],
                      hint: '64 max',
                    },
                    {
                      id: 'mname',
                      label: 'Middle Name',
                      defaults: ['all'],
                      hint: '64 max',
                    },
                    {
                      id: 'lname',
                      label: 'Last Name',
                      defaults: ['all'],
                      hint: '64 max',
                    },
                    {
                      id: 'address',
                      label: 'Address',
                      defaults: ['all'],
                      hint: '128 max',
                    },
                    {
                      id: 'city',
                      label: 'City',
                      defaults: ['all'],
                      hint: '64 max',
                    },
                    {
                      id: 'state',
                      label: 'State',
                      defaults: ['all'],
                      hint: '32 max',
                    },
                    {
                      id: 'zip',
                      label: 'Zip Code',
                      defaults: ['all'],
                      hint: '16 max',
                    },
                    {
                      id: 'country',
                      label: 'Country',
                      defaults: ['all'],
                      hint: '32 max',
                    },
                    {
                      id: 'mobile',
                      label: 'Mobile',
                      defaults: ['all'],
                      hint: '24 max',
                    },
                    {
                      id: 'phone',
                      label: 'Phone',
                      defaults: ['all'],
                      hint: '24 max',
                    },
                    {
                      id: 'email_address',
                      label: 'Email',
                      defaults: ['all'],
                      hint: '128 max',
                    },
                    //{id: "tags", label: "Tags", defaults: ["complete"], hint: "tag|tag"},
                    {
                      id: 'distributionLists',
                      label: 'Distribution List',
                      defaults: ['complete'],
                      hint: 'DL|DL',
                    },
                    {
                      id: 'source',
                      label: 'Source',
                      defaults: ['complete'],
                      hint: 'redtail',
                    },
                    {
                      id: 'sourceId',
                      label: 'Source Id',
                      defaults: ['complete'],
                      hint: 'Redtail Contact Id',
                    },
                  ]);
                  setVisibleSummaryModal(false);
                }}
                width={100}
              />
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default CSVImportModal;

const styles = StyleSheet.create({
  modal: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    borderWidth: 1,
    maxWidth: 767,
    width: '100%',
    maxHeight: 700,
  },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
    marginVertical: 5,
  },
  flatlist: {
    height: 300,
    marginVertical: 30,
    borderRadius: 4,
    borderColor: AppColor.GREY100,
    borderWidth: 1,
    width: '30%',
    paddingHorizontal: 5,
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
    color: AppColor.BlackColor,
  },
  dropWrapper: {
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: AppColor.GREY100,
    borderRadius: 4,
  },
  header: {
    backgroundColor: AppColor.PrimaryColor,
    height: 40,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  title: {
    color: '#ffffff',
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: 10,
  },
  headerTxt: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 10,
  },
  selectedItem: {
    backgroundColor: AppColor.danger,
  },
  exitedItem: {
    backgroundColor: AppColor.SuccessColor,
  },
  label: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 18,
    color: AppColor.BlackColor,
  },
});
