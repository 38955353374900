import React from 'react';
import { Text, View, Switch } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';

import RequestMaker from '../../../Middleware/ApiCaller';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import Loader from '../../../Components/Loader';
import getData from '../../../AsyncUtils/GetData';
import { setProfile } from '../../../Redux';
import Alert from 'Alert';

const VoiceAddOn = ({ navigation, route }) => {
  const { item } = route.params;
  const add_ons = useSelector((state) => state.add_ons);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [amount_detail, setAmountDetail] = React.useState({
    addon: 'Voice',
    amount: 0,
  });
  const Toast = useToast();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.User)?.profile?.group;

  React.useEffect(()=>{
    console.log(`\r\n\r\n GROUP:`, group,`\r\n\r\n`);
  },[])

  React.useEffect(() => {
    if (tenant?.voice) {
      setChecked(tenant.voice);
    }
  }, [tenant]);

  React.useEffect(() => {
    if (item != null) {
      setDisabled(!item.mutable);
    }
  }, [item]);

  React.useEffect(() => {
    if (add_ons?.biling_details) {
      let voice = add_ons?.biling_details.find((x) => x.addon == 'Voice');
      if (voice) {
        setAmountDetail(voice);
      }
    }
  }, [add_ons]);

  const handleToggle = async (value) => {
    if(route.params.mutable){
    if (add_ons.hasPaymentMethod || (item != null && item.centralized)) {
      try {
        setLoading(true);
        setChecked(value);
        const body = {
          action: 'update_voice',
          voice: value,
        };
        let token = await getData('token');
        let device = await getData('@device_id');
        const headers = { 'x-dart-token': token, 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('addons', result.data);
        if (result.data.success) {
          Toast.show({
            duration: 3000,
            status: 'success',
            title: value ? 'The voice add-on is now enabled. You now need to configure the voice add-on.' : 'The voice add-on is now disabled.'
          });
          _getBillingAmount();
          dispatch(
            setProfile({
              tenant: {
                ...tenant,
                voice: value,
              },
            })
          );
        } else {
          Toast.show({
            duration: 3000,
            status: 'error',
            title: result.data.errorMessage,
          });
          setChecked(!value);
        }
      } catch (err) {
        console.log(err);
        setChecked(!value);
      } finally {
        setLoading(false);
      }
    } else {
      setChecked(!value);
      Alert.alert(
        'Error',
        'You must first setup a payment method before you can enable add-ons.'
      );
    }
  } else {
    Alert.alert('Error', 'Your group has disabled your ability to modify this setting.')
  }
  };

  const _getBillingAmount = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'get_billing_amount',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      await RequestMaker(Config);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Voice Add-on'}
      />
      {loading && <Loader />}
      <View
        style={{
          flex: 1,
          width: '100%',
          padding: 15,
          maxWidth: 767,
          alignSelf: 'center',
        }}>
        <View style={{ alignSelf: 'center', marginVertical: 10 }}>
          <Text
            style={{
              fontSize: 14,
              lineHeight: '1.2em',
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            By default, all phone calls to your MyRepChat number are delivered
            to your Forwarding Number. If you enable voice, you can make calls
            from the MyRepChat app with your MyRepChat number as the caller ID.
            When you receive calls to your MyRepChat number and you are not available, your MyRepChat voicemail (if set up) will pick up.  If MyRepChat voicemail is not set up then your forwarding number’s voicemail will pick up.
          </Text>
        </View>

        <View>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 16,
            }}>
            NOTE: Call recording and transcription can only work with numbers
            that have voice enabled
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 10,
            alignSelf: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              marginRight: 15,
            }}>
            Use MyRepChat Number for voice
          </Text>
          <Switch
            value={checked}
            onValueChange={() => {
              handleToggle(!checked);
            }}
            trackColor={{ false: 'red', true: 'green' }}
          />
          <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginLeft: 5 }}>
            {checked ? 'ON' : 'OFF'}
          </Text>
        </View>
        <Text
          style={{
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          (${amount_detail.amount}.00/month)
        </Text>
      </View>

      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default VoiceAddOn;
