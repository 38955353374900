/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const CustomButton = (props) => {
  return (
    <>
      <TouchableOpacity
        disabled={props.disabled}
        onPress={props.onPress}
        accessible={true}
        accessibilityRole='button'
        style={{
          height: 40,
          opacity: props.disabled ? 0.5 : 1,
          width: props.width ? props.width : '80%',
          alignSelf: 'center',
          backgroundColor: AppColor.PrimaryColor,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 4,
          flexDirection: 'row',
          alignItems: 'center',
          ...props.style,
        }}>
        {props.icon}
        <Text
          style={{
            color: AppColor.SecondaryColor,
            fontSize: 18,
            fontFamily: AppFonts.Roboto_Regular,
            textTransform: 'uppercase',
            ...props.textStyle,
          }}>
          {props.title}
        </Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CustomButton;
