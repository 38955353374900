import axios from "axios";
import { MakeConfig } from "./MakeConfig";

export const getChatChannels = async ({affiliate: affiliate, userId: userId}) => {
    const body = {
        action: 'get_chat_channels',
        affiliate: affiliate,
        userId: userId
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getChatMessages = async ({params: params}) => {
    const body = {
        action: 'get_chat_messages',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const createChatMessage = async ({params: params}) => {
    const body = {
        action: 'create_chat_message',
        ...params
    }
    
    const Config = await MakeConfig(body);

    return axios(Config);

}

export const saveFile = async ({base64Data: base64Data, tenant: tenant}) => {
    const body = {
        action: 'save_file',
        base64Data: base64Data,
        tenant: tenant
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getChatUsers = async ({affiliate: affiliate}) => {
    const body = {
        action: 'get_chat_users',
        affiliate: affiliate
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const createFullChatChannel = async ({params: params}) => {
    const body = {
        action: 'create_full_chat_channel',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
    
}

export const updateFullChatChannel = async ({params: params}) => {
    const body = {
        action: 'update_full_chat_channel',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const getChannelMembers = async ({affiliate: affiliate, channel: channel}) => {
    const body = {
        action: 'get_channel_members',
        affiliate: affiliate,
        channel: channel
    }

    const Config = await MakeConfig(body);

    return axios(Config);
    
}

export const giphyTrending = async ({limit: limit, offset: offset}) => {
    const body = {
        action: 'giphy_trending',
        offset: offset,
        limit: limit,
        rating: 'g'
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const giphySearch = async ({limit: limit, offset: offset, query: query}) => {
    const body = {
        action: 'giphy_search',
        offset: offset,
        limit: limit,
        query: query,
        rating: 'g',
        lang: 'en'
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const updateChatStatus = async ({status: status}) => {
    const body = {
        action: 'update_chat_status',
        status: status
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const deleteChatChannel = async ({affiliate: affiliate, id: id}) => {
    const body = {
        action: 'delete_chat_channel',
        affiliate: affiliate,
        id: id
    }

    const Config = await MakeConfig(body);

    return axios(Config);
    
}

export const updateChatActivity = async ({activity: activity, channel: channel}) => {
    const body = {
        action: 'update_chat_activity',
        activity: activity,
        channel: channel
    }

    const Config = await MakeConfig(body);
    
    return axios(Config);

}