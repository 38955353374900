import { Icon } from 'react-native-elements';
import React from 'react';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector } from 'react-redux';

const SelectTemplates = ({ navigation, route }) => {
  const saved_messages = useSelector((state) => state.saved_messages).data;
  
  const renderListView = (item) => {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(route.params.screen, { template_message: item.message, memberData: route.params.memberData, deliveryDate: route.params.deliveryDate,time: route.params.time,frequency: route.params.frequency });
        }}
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 10,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            type={'antdesign'}
            name={'copy1'}
            color={AppColor.PrimaryColor}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 17,
              marginHorizontal: 10,
              color: 'black',
            }}
            numberOfLines={1}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              lineHeight: 20,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}
            numberOfLines={2}>
            {item.message}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        title={'Select Template'}
        back={true}
      />
      <View style={{ flex: 1 }}>
        <FlatList
          data={saved_messages}
          ListEmptyComponent={
            <Text style={{ textAlign: 'center' }}>Empty Templates.</Text>
          }
          key={(item) => item.name}
          style={{
            marginTop: 20,
            marginHorizontal: 10,
          }}
          renderItem={({ item }) => renderListView(item)}
        />
      </View>
    </SafeAreaView>
  );
};
export default SelectTemplates;
