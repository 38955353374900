import React from 'react';
import { useSelector } from 'react-redux';
import { ScrollView, TouchableOpacity, View, Image, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import EndPoints from '../../../Constants/Api';

import { providers, integrations_types } from '../../IntegrationProvider';

const Crm = ({ route, navigation }) => {
  const crms = useSelector((state) => state.integrations).integrations;
  const integration_providers = useSelector(
    (state) => state.integrations.providers
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Connect to CRM'}
      />
      <ScrollView>
      <Text style={{fontFamily: AppFonts.Roboto_Regular,
        color: AppColor.Dark,
        fontSize: 16,
        marginVertical: 5,
        }}
      >
            MyRepChat works with many popular CRMs. By connecting MyRepChat to
            your CRM, MyRepChat will be able to query your CRM for contacts when
            you send/receive messages, and may be able to send those messages
            back to the CRM as notes.
          </Text>

        <View
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            margin: 10,
          }}>
          {Object.entries(providers)
            .filter(
              (x) =>
                x[1].type.name == integrations_types.CRM.name &&
                integration_providers.includes(x[1].name)
            )
            .map((i, index) => {
              let b_color = AppColor.Dark;
              let b_w = 1;

              const isExisted = crms.find((x) => x.provider == i[1].name && x.enabled);
              if (isExisted) {
                b_color = AppColor.PrimaryColor;
                b_w = 3;
              }
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('Integrations', {
                      provider: i[1],
                      type: 'crm',
                      from: route?.params?.from,
                    });
                  }}
                  style={{
                    alignItems: 'center',
                    borderWidth: b_w,
                    margin: 5,
                    borderColor: b_color,
                    width: 200,
                    height: 150,
                    padding: 15,
                  }}
                  key={`crm${index}`}>
                  <img
                    style={{
                      height: 45,
                      width: 160,
                      margin: '0px 10px',
                      objectFit:'contain'
                    }}
                    src={EndPoints.Base_url + i[1].fullImageUrlPath }
                    alt={`${i[1].name} logo.`}
                  />
                  {isExisted && (
                    <View
                      style={{
                        marginTop: 30,
                      }}>
                      <MaterialIcons
                        name="check-circle"
                        size={30}
                        color={AppColor.SuccessColor}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              );
            })}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default Crm;
