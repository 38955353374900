import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Keyboard,
  Linking,
  Platform,
  ScrollView,
  Switch,
} from 'react-native';
import { createElement } from 'react-native-web';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, Input, Icon } from 'react-native-elements';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Modal from 'modal-react-native-web';
import { useToast } from 'native-base';
import Alert from 'Alert';
import moment from 'moment';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import {
  loadAllContactsThunk,
  loadAllBlockedContactsThunk,
} from '../../Redux/contacts';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import AppFonts from '../../Constants/Fonts';
// import DateTimePicker from '@react-native-community/datetimepicker';
import { phoneNumberTypes } from './AddPhoneNumber';
import { loadReconcileMember, reconcileMember } from './helpers';
import { ReconcileModal } from './modals';

const EditContact = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const Toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const integrations = useSelector((state) => state.integrations).integrations;
  const [showRisk, setShowRisk] = React.useState(false);
  const [client, setClient] = React.useState(null);
  const [exportVisible, setExportVisible] = React.useState(false);
  const inputFieldDataInitialState = {
    id:null,
    accountId:'',
    fname: '',
    lname: '',
    mname: '',
    email_address: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    phoneNumbers: [],
    distributionLists: [],
    stats: {},
    source: '',
    sourceId: '',
    spam: false,
  }
  const group = useSelector((state) => state.User)?.profile?.group;
  const [inputFieldData, setInputFieldData] = React.useState(inputFieldDataInitialState);
  const [reconcileVisible, setReconcileVisible] = React.useState(false);
  const [reconcileMembers, setReconcileMembers] = React.useState([]);


  React.useEffect(() => {
    // distribution add from dl screen selection
    if (route.params?.dl) {
      let arr = { ...inputFieldData }.distributionLists;
      console.log("arr:",arr);
      if (arr.findIndex((x) => x.id === route.params.dl.id) === -1) {
        console.log("route.params.dl:",route.params.dl);
        arr.push(route.params.dl);
        let data = { ...inputFieldData, distributionLists: arr };
        console.log("data:",data);
        setInputFieldData(data);
      }
    }
    // phone number from  add/edit phoneNumber screen
    if (route.params?.phoneData) {
      let { phoneData } = route.params;
      let arr = { ...inputFieldData }.phoneNumbers;
      if (phoneData.messaging) {
        arr = arr.map((x) => {
          x.messaging = false;
          return x;
        });
      }
      if (phoneData.id) {
        let index = arr.findIndex((x) => x.id === phoneData.id );
        if (index !== -1) {
          arr[index] = phoneData;
        } else {
          arr.push(phoneData);
        }
      } 
      if (arr.filter((x) => x.messaging).length === 0) {
        if(arr[0]?.messaging) arr[0].messaging = true;

      }
      let data = { ...inputFieldData, phoneNumbers: arr };
      setInputFieldData(data);
    }
  }, [route.params]);

  React.useEffect(() => {
    let riskalyzeIndex = integrations.findIndex(
      (x) => x.provider === 'riskalyze'
    );
    if (
      riskalyzeIndex != -1 &&
      integrations[riskalyzeIndex].enabled &&
      inputFieldData.id
    ) {
      setShowRisk(true);
      if (
        inputFieldData.email_address != null &&
        inputFieldData.email_address != ''
      ) {
        loadRisk();
      } else {
      }
    } else {
      setShowRisk(false);
    }
  }, [integrations, inputFieldData]);


  const loadRisk = async () => {
    try {
      const body = {
        action: 'get_riskalyze_client',
        email: inputFieldData.email_address,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success === 'false' || result.data.success === null) {
        Alert.alert(
          'Server Error',
          'The server returned an error:' + result.data.errorMessage
        );
        return;
      } else {
        let clientObj = result.data.client;
        if (clientObj != null) {
          setClient(clientObj);
        }
      }
    } catch (err) {
      // Alert.alert("Server Error", "The server returned an error " + err.code + ". Please contact support if this continues.");
    } finally {
    }
  };

  const SaveContact = async (data) => {
    Keyboard.dismiss();
    // if(inputFieldData.phoneNumbers.length===0){
    //     Toast.show({duration: 3000, type: "success",
    //         text:"Please add phone number."
    //     })
    //     return
    // }
    try {
      
      let result = await apiSaveContact();
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        dispatch(loadAllBlockedContactsThunk());
        dispatch(loadDuplicateNumbersThunk(tenant.id));
        setInputFieldData(inputFieldDataInitialState)
        Toast.show({
          duration: 3000,
          status: 'success',
          title: result?.data?.message || 'successfully saved contact',
        });
        navigation.navigate('AllContacts');
      } else {
        Toast.show({
          duration: 3000,
          title: result?.data?.errorMessage || 'Something went wrong, please try again later.',
          status: 'danger',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const apiSaveContact = async () => {
    setLoading(true);
      const body = {
        fname: inputFieldData.fname,
        mname: inputFieldData.mname,
        lname: inputFieldData.lname,
        address: inputFieldData.address,
        city: inputFieldData.city,
        state: inputFieldData.state,
        zip: inputFieldData.zip,
        email_address: inputFieldData.email_address,
        birth_date: null,
        spam: inputFieldData.spam,
        distributionLists: inputFieldData.distributionLists,
        phoneNumbers: inputFieldData.phoneNumbers,
        source: inputFieldData.source,
        sourceId: inputFieldData.sourceId,
        comments: '',
        email_newsletter: false,
        list_in_directory: false,
        notify: false,
      };
      if (
        !inputFieldData.id ||
        inputFieldData.id === null ||
        inputFieldData.id === 0 
      ) {
        body.action = 'create_member';
      } else {
        body.action = 'update_member';
        body.id = inputFieldData.id;
      }
      
      for(let i = 0; i < body.phoneNumbers.length; i++){
        if(typeof body.phoneNumbers[i].id === 'string'){
          if(body.phoneNumbers[i].id.includes('E')){
            delete body.phoneNumbers[i].id;
          }
        }
      }

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      console.log(body);
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      return RequestMaker(Config);
  }

  const removeDL = (id) => {
    let arr = { ...inputFieldData }.distributionLists;
    let index = arr.findIndex((x) => x.id === id);
    arr.splice(index, 1);
    let data = { ...inputFieldData, distributionLists: arr };
    setInputFieldData(data);
  };

  const removePhoneNumber = (id) => {
    let arr = { ...inputFieldData }.phoneNumbers;
    if (arr.length > 0) {
      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      if (arr.filter((x) => x.messaging).length === 0) {
        if(arr.length > 0) arr[0].messaging = true;
      }

      let data = { ...inputFieldData, phoneNumbers: arr };
      setInputFieldData(data);
    }
  };

  const loadMemberDetails = async (id) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_member',
        id: id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status === 200 && result.data.id) {
        setInputFieldData(result.data);
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    let isSubscribed = true;

    if (route.params?.item) {
      loadMemberDetails(route.params.item.id);
    }

    return () => (isSubscribed = false)
  }, [route.params]);

  const updateReconcile = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'reconcile_member',
        id: inputFieldData.id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        dispatch(loadDuplicateNumbersThunk(tenant.id));
        setInputFieldData(inputFieldDataInitialState)
        navigation.navigate('Contacts')
      } else {
        Toast.show({
          duration: 2000,
          title: result?.data?.errorMessage || 'Something went wrong. Please try again later.',
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const reconcileContact = () => {
    if (
      inputFieldData.id &&
      inputFieldData.id != null &&
      inputFieldData.id != 0
    ) {
      setLoading(true);
      setReconcileVisible(true);
      setReconcileMembers([]);
      loadReconcileMember(inputFieldData.id)
      .then(res => {
        if(res?.data?.success){
          setReconcileMembers(res.data.contacts);
        }
        setLoading(false);
      })
    }
  };

  const handleReconcile = async (contact) => {
    setReconcileVisible(false);
    setLoading(true);
    reconcileMember(contact)
    .then(res => {
      setLoading(false);
      if(res?.data?.success){ 
        loadMemberDetails(route.params.item.id);
        Toast.show({ duration: 2000, title: res?.data?.message, status: 'success' });
      }
      else {
        Toast.show({
          duration: 3000,
          type: 'danger',
          text: res?.data?.errorMessage || 'Something went wrong, please try again later.',
        });
      }
    }) 
  };

  const deleteContactAsync = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'delete_member',
        id: inputFieldData.id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        dispatch(loadDuplicateNumbersThunk(tenant.id));
        setInputFieldData(inputFieldDataInitialState);
        navigation.navigate('Contacts');
        Toast.show({
          duration: 2000,
          title: 'Contact deleted!',
          status: 'info',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: result?.data?.errorMessage || 'Something went wrong. Please try again later.',
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const deleteContact = () => {
    Alert.alert(
      'Delete Contact',
      'Are you sure you want to delete this contact?',
      [
        {
          text: 'Cancel',
        },
        {
          text: 'Ok',
          onPress: () => deleteContactAsync(),
        },
      ]
    );
    if (
      inputFieldData.id &&
      inputFieldData.id != null &&
      inputFieldData.id != 0
    ) {
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Add/Edit Contact'}
        back={true}
        onPress={() => {
          setInputFieldData(inputFieldDataInitialState);
          navigation.goBack();
          
        }}
        chatIcon={
          <Icon
            color="white"
            name="chatbox"
            type="ionicon"
            onPress={() => {
              if(!inputFieldData.phoneNumbers.length){
                Alert.alert('No messaging number','This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
              } 
              else if (inputFieldData.id && inputFieldData.id != 0) {
                // navigation.navigate('Chats', { memberData: inputFieldData });
                navigation.navigate('Chats', { memberData: inputFieldData });
              }
            }}
          />
        }
      />
      <ScrollView
        style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 30 }}>
        <Input
          value={inputFieldData.fname}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, fname: t }));
          }}
          labelStyle={{ color: AppColor.PrimaryColor }}
          label="First Name"
        />
        <View style={{ height: 10 }} />
        <Input
          value={inputFieldData.mname}
          labelStyle={{ color: AppColor.PrimaryColor }}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, mname: t }));
          }}
          label={'Middle Name'}
        />
        <View style={{ height: 10 }} />
        <Input
          value={inputFieldData.lname}
          labelStyle={{ color: AppColor.PrimaryColor }}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, lname: t }));
          }}
          label={'Last Name'}
        />
        { inputFieldData.accountId && 
        <>
          <View style={{ height: 10 }} />
          <Input
            value={inputFieldData.accountId}
            labelStyle={{ color: AppColor.PrimaryColor }}
            label={'Organization'}
          />
        </>
        }

        <View style={{ height: 10 }} />
        <Input
          value={inputFieldData.email_address}
          labelStyle={{ color: AppColor.PrimaryColor }}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, email_address: t }));
          }}
          label={'Email'}
        />
        <View style={{ height: 10 }} />
        <Input
          labelStyle={{ color: AppColor.PrimaryColor }}
          value={inputFieldData.address}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, address: t }));
          }}
          label={'Address'}
        />
        <View style={{ height: 10 }} />
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Input
            value={inputFieldData.state}
            label="State"
            labelStyle={{ color: AppColor.PrimaryColor }}
            containerStyle={{ flex: 1 }}
            onChangeText={(t) => {
              setInputFieldData((states) => ({ ...states, state: t }));
            }}
          />
          <Input
            value={inputFieldData.city}
            labelStyle={{ color: AppColor.PrimaryColor }}
            containerStyle={{ flex: 1 }}
            label="City"
            onChangeText={(t) => {
              setInputFieldData((states) => ({ ...states, city: t }));
            }}
          />
          <Input
            value={inputFieldData.zip}
            labelStyle={{ color: AppColor.PrimaryColor }}
            containerStyle={{ flex: 1 }}
            label="Zipcode"
            onChangeText={(t) => {
              setInputFieldData((states) => ({ ...states, zip: t }));
            }}
            keyboardType={'numeric'}
          />
        </View>
        <View style={{ marginHorizontal: 10, marginVertical: 10 }}>
          <Text style={{ fontSize: 15, color: AppColor.Dark, lineHeight: 22 }}>
            You must save the contact to save changes to the phone numbers.
          </Text>
          {/* phones added */}
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
            accessibilityRole='button'
            accessibilityLabel='Add Phone.'
              onPress={() => {
                navigation.navigate('AddPhoneNumber');
              }}
              style={{ paddingHorizontal: 5 }}>
              <Text
                style={{
                  fontSize: 18,
                  borderBottomColor: AppColor.PrimaryColor,
                  color: AppColor.PrimaryColor,
                  borderBottomWidth: 1,
                }}>
                Add Phone
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ marginVertical: 10 }}>
            {inputFieldData.phoneNumbers.length === 0 && (
              <Text style={{ marginBottom: 5, color: AppColor.Dark }}>
                No phone numbers
              </Text>
            )}
            {inputFieldData.phoneNumbers.map((x, index) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    borderRadius: 5,
                    padding: 5,
                    borderColor: AppColor.PrimaryColor,
                    marginBottom: 5,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={`phonenumber_${index}`}>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('AddPhoneNumber', { phoneNumber: x });
                    }}
                    style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {!!x.messaging && (
                      <AntDesign
                        name="message1"
                        color={AppColor.PrimaryColor}
                      />
                    )}
                    <Text
                      style={{ fontSize: 15, color: AppColor.PrimaryColor }}>
                      {x.countryCode}
                      {x.longCode}
                    </Text>
                    <Text
                      style={{ fontSize: 15, color: AppColor.PrimaryColor }}>
                      {' '}
                      ({phoneNumberTypes.find((z) => z.id === x.typeId).label})
                    </Text>
                  </TouchableOpacity>
                  <Icon
                    accessibilityRole='button'
                    accessibilityLabel='Trash.'
                    accessibilityHint='Delete phone number.'
                    color={AppColor.danger}
                    name="delete"
                    type="material-community"
                    onPress={() => removePhoneNumber(x.id)}
                  />
                  {/* <Text style={{ color: AppColor.danger }} >Remove</Text> */}
                </View>
              );
            })}
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
              <TouchableOpacity
                accessibilityRole='button'
                accessibilityLabel='Add to DL.'
              
                onPress={() => {
                  navigation.navigate('DLList');
                }}>
                <Text
                  style={{
                    fontSize: 18,
                    color: AppColor.PrimaryColor,
                    borderBottomColor: AppColor.PrimaryColor,
                    color: AppColor.PrimaryColor,
                    borderBottomWidth: 1,
                  }}>
                  Add to DL
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{ marginVertical: 10 }} />
            {inputFieldData.distributionLists.length === 0 && (
              <Text style={{ marginBottom: 5, color: AppColor.Dark }}>
                Not a member of any Distribution Lists
              </Text>
            )}
            {inputFieldData.distributionLists.map((x, index) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    borderRadius: 5,
                    padding: 5,
                    borderColor: AppColor.PrimaryColor,
                    marginBottom: 5,
                    justifyContent: 'space-between',
                  }}
                  key={`distributionLists_${index}`}>
                  <Text style={{ fontSize: 15, color: AppColor.PrimaryColor }}>
                    {x.countryCode}
                    {x.text}
                  </Text>
                  <Icon
                    accessibilityRole='button'
                    accessbilityLabel='Trash can.'
                    accessibilityHint='Remove user from distribution list.'
                    color={AppColor.danger}
                    name="delete"
                    type="material-community"
                    onPress={() => removeDL(x.id)}
                  />
                  {/* <Text style={{ color: AppColor.danger }}}>Remove</Text> */}
                </View>
              );
            })}
            {/* DL Added */}
          </View>
          <Text style={{ color: AppColor.Dark, marginTop: 10 }}>
            ACCOUNT ACTIVITY
          </Text>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
              marginTop: 10,
              borderWidth: 1,
              padding: 5,
              borderColor: AppColor.PrimaryColor,
            }}>
            <View style={{ flex: 1.2, alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Message Sent
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {inputFieldData.stats.outbound ?? 0}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: 'center', opacity: 0.8 }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Responses
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {inputFieldData.stats.inbound ?? 0}
              </Text>
            </View>
            <View style={{ flex: 1.2, alignItems: 'center', opacity: 0.5 }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Response Rate
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {Math.round(
                  (Number(inputFieldData.stats.inbound ?? 0) * 100) /
                    Number(inputFieldData.stats.outbound ?? 1)
                )}
                %
              </Text>
            </View>
          </View>
          {showRisk && (
            <RiskalyzeComponent client={client} member={inputFieldData} />
          )}
          <View style={{ marginVertical: 10 }}>
            <Text
              style={{ fontSize: 15, color: AppColor.Dark, lineHeight: 22 }}>
              Block this contact to have any future messages or calls rejected,
              and to hide them from your messages view.
            </Text>
            <View style={{ height: 10 }} />
            <Switch
            accessibilityRole='switch'
            accessibilityLabel='Blocked'
            accessibilityHint='Block Contact'
              style={{ alignSelf: 'flex-start', marginVertical: 10 }}
              value={inputFieldData.spam}
              onValueChange={(v) => {
                setInputFieldData((state) => ({ ...state, spam: v }));
              }}
              trackColor={{ false: 'red', true: 'green' }}
            />
          </View>
          <TouchableOpacity accessibilityRole='button' accessibilityLabel='Reconcile Contact.' onPress={reconcileContact}>
            <Text
              style={{
                fontSize: 18,
                color: AppColor.PrimaryColor,
                marginBottom: 10,
              }}>
              Reconcile Contact
            </Text>
          </TouchableOpacity>
          { group?.showTenantExport &&
          <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Export Conversation.'
            onPress={() => {
              setExportVisible(true);
            }}>
            <Text
              style={{
                fontSize: 18,
                color: AppColor.PrimaryColor,
                marginBottom: 10,
              }}>
              Export Conversation
            </Text>
          </TouchableOpacity>}
          <TouchableOpacity onPress={deleteContact}
          accessibilityRole='button'
          accessibilityLabel='Delete Contact.'
          >
            <Text
              style={{
                fontSize: 18,
                color: AppColor.danger,
                marginBottom: 10,
              }}>
              Delete Contact
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
          <CustomButton
            style={{ width: '40%' }}
            title={'Save'}
            onPress={SaveContact}
          />
          <CustomButton
            style={{ width: '40%' }}
            title={'Cancel'}
            onPress={() => {
              setInputFieldData(inputFieldDataInitialState);
              navigation.goBack();
            }}
          />
        </View>
      </ScrollView>
      <ExportConversationModal
        navigation={navigation}
        visible={exportVisible}
        setVisible={setExportVisible}
        contact={inputFieldData.id}
      />
      {
          reconcileVisible && 
        <Modal visible={reconcileVisible} transparent={true}>
          <ReconcileModal
            setReconcileVisible={setReconcileVisible}
            reconcileMembers={reconcileMembers}
            handleReconcile={handleReconcile}
            isLoading={loading}
          />
        </Modal>

        }
      {loading && <Loader />}
    </SafeAreaView>
  );
};

export default EditContact;

function ExportConversationModal(props) {
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const token = useSelector((state) => state.User)?.Token;

  const onChange = (event) => {
    // const currentDate = selectedDate ;
    setDateFrom(event.target.value);
  };

  const onChange1 = (event) => {
    // const currentDate = selectedDate;
    setDateTo(event.target.value);
  };

  const onPressSubmit = () => {
    
    const url = `${End_Points.Base_url}${End_Points.End}?action=generate_report&from=${new Date(dateFrom).getTime()}&to=${new Date(dateTo).getTime()}&contact=${props.contact}&format=text&tenantUUID=${tenant.uniqueId}&token=${token}`;
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        //Alert.alert('Error', "Don't know how to open URI");
        console.log("Don't know how to open URI: " + url);
      }
    });
    props.setVisible(false);
  };

  return (
    <Modal
      visible={props.visible}
      useNativeDriver={true}
      transparent={true}
      // onBackdropPress={() => props.setVisible(false)}
    >
      <View
        style={{
          padding: 20,
          flex: 1,
          backgroundColor: '#0005',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text style={{ fontSize: 18, textAlign: 'center' }}>
            Export Conversation
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}>
            <View>
              <Text>From*</Text>
              <TouchableOpacity
                onPress={() => {
                  setShow1(false);
                  setShow(true);
                }}
                style={{
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  padding: 10,
                  paddingHorizontal: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  backgroundColor: '#F6F6F6',
                }}>
                <Text>{moment(dateFrom).format('DD/MM/YYYY')}</Text>
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              </TouchableOpacity>
            </View>
            <View>
              <Text>To</Text>
              <TouchableOpacity
                onPress={() => {
                  setShow(false);
                  setShow1(true);
                }}
                style={{
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  padding: 10,
                  paddingHorizontal: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  backgroundColor: '#F6F6F6',
                }}>
                <Text>{moment(dateTo).format('DD/MM/YYYY')}</Text>
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              </TouchableOpacity>
            </View>
          </View>
          {show && (
            <View>
              <DateTimePicker value={dateFrom} onChange={onChange} />
              <TouchableOpacity
                style={{ width: 80, alignSelf: 'center' }}
                onPress={() => {
                  setShow(false);
                }}>
                <Text style={{ color: AppColor.Dark, textAlign: 'center' }}>
                  Hide
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {show1 && (
            <View>
              <DateTimePicker value={dateTo} onChange={onChange1} />
              <TouchableOpacity
                style={{ width: 80, alignSelf: 'center' }}
                onPress={() => {
                  setShow1(false);
                }}>
                <Text style={{ color: AppColor.Dark, textAlign: 'center' }}>
                  Hide
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 40,
            }}>
            <CustomButton
              style={{ width: '40%' }}
              title={'Cancel'}
              onPress={() => {
                props.setVisible(false);
              }}
            />
            <CustomButton
              style={{ width: '40%' }}
              title={'OK'}
              onPress={onPressSubmit}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}

function RiskalyzeComponent({ client, member }) {
  let questionnairesData = {
    SIMPLE: { name: 'simple', label: ' Simple Questionnaire ' },
    STANDARD: { name: 'standard', label: ' Standard Questionnaire ' },
    DETAILED: { name: 'detailed', label: ' Detailed Questionnaire ' },
    // ADVISOR: { name: "advisor", label: " Advisor Questionnaire " },
  };
  const [loading, setLoading] = React.useState(false);
  const [questionnaires, setQuesionaries] = React.useState([]);
  const [type, setType] = React.useState(questionnairesData.SIMPLE);
  const [questionnaireId, setQuestionnaireId] = React.useState(null);

  React.useEffect(() => {
    if (client && client != null) {
      if (client.questionnaires != null) {
        setQuesionaries(client.questionnaires);
      }
    }
  }, [client]);

  const handlePersmissionsforQues = () => {
    Alert.alert('Send', 'Do you really want to send?', [
      {
        text: 'NO',
      },
      {
        text: 'YES',
        onPress: () => {
          handleAskQuestionaire();
        },
      },
    ]);
  };

  const handleAskQuestionaire = async () => {
    if (member.email_address === null || member.email === '') {
      Alert.alert(
        'Riskalyze',
        'The contact must first be saved with an email address.'
      );
      return;
    }

    try {
      setLoading(true);
      const body = {
        action: 'send_riskalyze_questionnaire',
        type: type.name,
        memberId: member.id,
      };

      if (client?.clientId != null) {
        body['clientId'] = client.clientId;
      }
      if (questionnaireId && questionnaireId != null) {
        body['questionnaireId'] = questionnaireId;
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Sent Successfully',
          status: 'success',
        });
      } else {
        // Alert.alert("Server Error",result.data.errorMessage)
        Toast.show({
          duration: 2000,
          title: 'Unable to send. ' + result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <View
      style={{ marginTop: 10, borderTopWidth: 1, borderColor: AppColor.Dark }}>
      <Text style={{ color: AppColor.Dark, marginTop: 10 }}>RISKALYZE</Text>
      <View
        style={{
          borderRadius: 10,
          paddingHorizontal: 2,
          borderWidth: 1,
          alignItems: 'center',
          alignSelf: 'center',
        }}>
        <Text
          style={{
            letterSpacing: 2,
            color: AppColor.Dark,
            fontSize: 18,
            fontFamily: AppFonts.Roboto_Bold,
          }}>
          RISK
        </Text>
        <Text
          style={{
            letterSpacing: 3,
            color: AppColor.PrimaryColor,
            fontSize: 30,
            fontFamily: AppFonts.Roboto_Bold,
          }}>
          {client?.riskNumber ?? '--'}
        </Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          marginHorizontal: 10,
          marginTop: 10,
          borderColor: AppColor.Dark,
        }}>
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: AppColor.PrimaryColor,
            padding: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Text style={{ textTransform: 'uppercase' }}>Score</Text>
          <Text style={{ textTransform: 'uppercase' }}>Goals</Text>
          <Text style={{ textTransform: 'uppercase' }}>Completed</Text>
        </View>
        {questionnaires.slice(0, 3).map((x) => {
          return (
            <TouchableOpacity
              onPress={() => setQuestionnaireId(x.id)}
              style={{
                borderBottomWidth: 1,
                borderColor: AppColor.PrimaryColor,
                padding: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              key={x.id}>
              <Text>{x.riskNumber ?? '--'}</Text>
              <Text style={{ textTransform: 'uppercase' }}>{x.goals}</Text>
              <Text>
                {x.completedTimestamp === null
                  ? '---'
                  : moment(x.completedTimestamp).format('DD/MM/YY')}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <Text
        style={{
          textTransform: 'uppercase',
          color: AppColor.Dark,
          fontSize: 20,
          marginTop: 10,
        }}>
        Question type
      </Text>
      {Object.values(questionnairesData).map((x, index) => {
        return (
          <CheckBox
            title={x.label}
            checked={x.name === type.name}
            onPress={() => setType(x)}
            key={`check_${index}`}
          />
        );
      })}
      <CustomButton
        style={{ marginTop: 10 }}
        title="send selected questionnaire"
        onPress={handlePersmissionsforQues}
      />
    </View>
  );
}

function DateTimePicker({ value, onChange }) {
  return createElement('input', {
    type: 'date',
    value: value,
    onInput: onChange,
  });
}