import React from 'react';
import { ScrollView, SectionList, Text, View } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import BigList from 'react-native-big-list';

const NewAlphabetList = ({data, RenderItem, ListHeaderComponent, sortBy}) => {
        
    const result = [];
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    [...data].sort((a,b) => a[sortBy].localeCompare(b[sortBy])).forEach(e => {
        let firstLetterOfName = e[sortBy].charAt(0).toUpperCase();

        if(!letters.includes(firstLetterOfName)){
            firstLetterOfName = '*';
        }

        const indexOfTitle = result.findIndex(ee => ee[0].label === firstLetterOfName);

        if( indexOfTitle === -1 ){
            result.push([{label: firstLetterOfName, ...e}])
        } else {
            result[indexOfTitle].push({ ...e});
        }
    })    
    
    return(
        <Content ListHeaderComponent={ListHeaderComponent} RenderItem={RenderItem} data={result} />
    )
}


const Content = ({RenderItem, data, ListHeaderComponent}) => {

    let sectionRefs = React.useRef(null);
    

    if(data?.length){
        return(
            <View style={{flex:1,flexDirection:'row'}}>
            <BigList 
            ref={sectionRefs}
            style={{flex:20}}
            renderSectionHeader={(label) => <Text style={{color:'white', backgroundColor:AppColor.PrimaryColor, fontFamily: AppFonts.Roboto_Regular,maxWidth:50,maxHeight:20,textAlign:'center',justifyContent:'center',alignItems:'center',marginLeft:10}}>{data[label][0].label}</Text>}
            sectionHeaderHeight={20}
            renderHeader={ListHeaderComponent} headerHeight={5} renderItem={RenderItem} itemHeight={60} 
            sections={data}
            />
            <View style={{alignItems:'center',justifyContent:'center',height:'100%',marginRight:10,marginLeft:10}}>
                {
                    data.map((e, i) => {
                        return(
                            <Text onPress={()=>{sectionRefs.current.scrollToIndex({ index:0, section: i, animated: true })}} style={{fontSize:'.9em',fontWeight:'600',fontFamily:AppFonts.Roboto_Regular,margin:'0em 0em .2em 0em',padding:0,color:AppColor.PrimaryColor}}>{e[0].label}</Text>
                        )
                    })
                }
            </View>
            </View>
    )
    } else return null;
}


export default NewAlphabetList;

