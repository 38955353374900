import React from 'react';
import { View, Text, Switch, Linking, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox } from 'react-native-elements';
import AntDesign from 'react-native-vector-icons/AntDesign';

import AppFont from '../../../../Constants/Fonts';
import AppColor from '../../../../Constants/Color';
import {
  integrations_types,
  EnumFrequency,
  EnumLocation,
} from '../../../IntegrationProvider';
import {
  setEnabled,
  setFrequency,
  setLocation,
  setCreateContact,
  setUpdateContact,
  setAttributes,
} from '../../../../Redux/integrationData';

export default function IntegrationComponent({
  provider,
  integration,
  allowContactEdits,
  allowLocation,
  allowAdvancedOptions,
  config
}) {
  const dispatch = useDispatch();
  const [inte, setIntegration] = React.useState({
    enabled: false,
  });

  let locationDefault = EnumLocation.CALL;
  let noteDefault = EnumFrequency.DAILY;

  const [currentNote, setCurrentNote] = React.useState(noteDefault);
  const [currentLocation, setCurrentLocation] = React.useState(locationDefault);

  const [includeDueDate, setIncludeDueDate] = React.useState(false);
  const [includeAttachments, setIncludeAttachments] = React.useState(false);

  React.useEffect(()=>{
    if(config?.locationCall === 'true'){
      setCurrentLocation(EnumLocation.CALL)
    } else if (config?.locationEmail === 'true'){
      setCurrentLocation(EnumLocation.EMAIL)
    } else if (config?.locationNotes === 'true'){
      setCurrentLocation(EnumLocation.NOTE)
    } else if(config?.locationTask === 'true'){
      setCurrentLocation(EnumLocation.TASK)
    }
  
  
    if(config?.messagesDaily === 'true'){
        setCurrentNote(EnumFrequency.DAILY)
    } else if(config?.messagesRealtime === 'true') {
        setCurrentNote(EnumFrequency.REALTIME)
    } else if(config?.messagesNever === 'true'){
        setCurrentNote(EnumFrequency.NEVER)
    }
  },[config])
 

  const [createContact, setCurrentCreateContact] = React.useState(false);
  const [updateContact, setCurrentUpdateContact] = React.useState(false);

  const group = useSelector((state) => state.User)?.profile?.group;

  const groupAllowCreate = group?.allowCreateContactsInCRM;
  const groupAllowUpdate = group?.allowSyncContactsInCRM;

  console.log(config);


  React.useEffect(() => {
    dispatch(setEnabled(inte.enabled));
    dispatch(setCreateContact(createContact));
    dispatch(setUpdateContact(updateContact));
    dispatch(setFrequency(currentNote.key));
    dispatch(setLocation(currentLocation.key));
  }, [inte, currentNote, currentLocation, createContact, updateContact]);

  React.useEffect(() => {
    if(allowAdvancedOptions){
    dispatch(
      setAttributes({
        includeAttachments: includeAttachments,
        includeDueDate: includeDueDate,
      })
    );
    }
  }, [includeDueDate, includeAttachments]);
  


  React.useEffect(() => {
    if (integration != null && integration) {
      console.log("INT",integration);

      setIntegration(integration);
      setIncludeDueDate(integration.includeDueDate === "true");
      setIncludeAttachments(integration.includeAttachments === "true");
      setCurrentCreateContact(integration.createcontact === "true");
      setCurrentUpdateContact(integration.updatecontact === "true");

      if(!integration.active){
        setCurrentNote(EnumFrequency.NEVER)
      } else if(integration.realtimeNotes === "true" || integration.realtimeNotes === true) {
        setCurrentNote(EnumFrequency.REALTIME)
      } else {
        setCurrentNote(EnumFrequency.DAILY)
      }
      
      
      setCurrentLocation({key: integration.noteLocation});

    } else {
      setIntegration({enabled: false});
    }

  }, [integration]);

  return (
    <View style={{ marginBottom: 10 }}>
      <View
        style={{
          flexDirection: 'row',
          marginHorizontal: 20,
          alignItems: 'center',
          marginVertical: 10,
        }}>
        <Text style={{ marginRight: 5 }}>Enable</Text>
        <Switch
          value={inte?.enabled}
          style={{ width: 50 }}
          trackColor={{ false: 'red', true: 'green' }}
          onValueChange={(v) =>
            setIntegration((state) => ({ ...state, enabled: v }))
          }
        />

        <TouchableOpacity
          onPress={() => window.open(provider.helpUrl, '_blank')}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 15,
          }}>
          <AntDesign
            name="questioncircle"
            size={25}
            color={AppColor.PrimaryColor}
          />
        </TouchableOpacity>
      </View>
      {allowAdvancedOptions && 
      
      <View style={{ borderWidth: 1, padding: 10, marginTop: 10 }}>
      <CheckBox
        title={'Include Due Date in Activities'}
        checked={includeDueDate}
        onPress={() => setIncludeDueDate(!includeDueDate)}
      />
      <CheckBox
        title={'Include MMS as Attachments'}
        checked={includeAttachments}
        onPress={() => setIncludeAttachments(!includeAttachments)}
      />
    </View>

      }
      {integrations_types.CRM == provider.type && (
        <View style={{ borderWidth: 1, padding: 10, borderRadius: 4 }}>
          <Text
            style={{
              color: AppColor.Dark,
              fontSize: 16,
              fontFamily: AppFont.Roboto_Regular,
            }}>
            Send Messages to CRM
          </Text>
          {Object.values(EnumFrequency).map((x, index) => {

            if(provider?.name === 'salesforce' && x.key === 'daily' && config?.messagesDaily === "false") return;
            if(provider?.name === 'salesforce' && x.key === 'realtime' && config?.messagesRealtime === "false") return;
            if(provider?.name === 'salesforce' && x.key === 'never' && config?.messagesNever === "false") return;

            return (
              <CheckBox
                title={x.desc}
                checked={x.key === currentNote.key}
                onPress={() => setCurrentNote(x)}
                key={`crm_${index}`}
              />
            );
          })}
        </View>
      )}
      {allowLocation && (
        <View style={{ borderWidth: 1, padding: 10, marginTop: 10 }}>
          <Text
            style={{
              color: AppColor.Dark,
              fontSize: 16,
              fontFamily: AppFont.Roboto_Regular,
            }}>
            Note Location
          </Text>
          {Object.values(EnumLocation).map((x, index) => {
            if((x.desc === 'Email') && provider.name === 'zoho') return;
            if(provider?.name === 'salesforce' && x.key === 'task' && config?.locationTask === "false") return;
            if(provider?.name === 'salesforce' && x.key === 'call' && config?.locationCall === "false") return;
            if(provider?.name === 'salesforce' && x.key === 'email' && config?.locationEmail === "false") return;
            if(provider?.name === 'salesforce' && x.key === 'note' && config?.locationNotes === "false") return;
            return (
              <CheckBox
                title={x.desc}
                checked={x.key === currentLocation.key}
                onPress={() => setCurrentLocation(x)}
                key={`note_${index}`}
              />
            );
          })}

        </View>
      )}
      {allowContactEdits && (groupAllowCreate || groupAllowUpdate) && (
        <View style={{ borderWidth: 1, padding: 10, marginTop: 10 }}>
          <Text
            style={{
              color: AppColor.Dark,
              fontSize: 16,
              fontFamily: AppFont.Roboto_Regular,
            }}>
            Modify Contact in CRM
          </Text>
          {groupAllowUpdate && (
          <CheckBox
            title={'Update CRM Contacts'}
            checked={updateContact}
            onPress={() => setCurrentUpdateContact(!updateContact)}
          />
		 )}
          {groupAllowCreate && (
          <CheckBox
            title={'Create CRM Contacts'}
            checked={createContact}
            onPress={() => setCurrentCreateContact(!createContact)}
          />
		 )}
        </View>
      )}
    </View>
  );
}
