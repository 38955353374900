import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  ScrollView,
  useWindowDimensions,
  Platform,
  Linking,
  Pressable
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useToast, Menu } from 'native-base';
import {
  createDrawerNavigator,
  DrawerItemList,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import { Icon } from 'react-native-elements';
import IdleTimer from 'react-idle-timer';
import { DrawerActions } from '@react-navigation/routers';
import SelectBookContact from '../Screens/Bookmarks/SelectBookContact'
import AppColor from '../Constants/Color';
import AppFonts from '../Constants/Fonts';
import End_Points from '../Constants/Api';
import keyword from '../Constants/Keywords';
import Dashboard from '../Screens/Dashboard';
import Calculators from '../Screens/Calculators';
import Templates from '../Screens/Templates';
import Contacts from '../Screens/Contacts';
import DialPad from '../Screens/Dialpad';
import AutoResponse from '../Screens/AutoResponse';
import Bookmarks from '../Screens/Bookmarks';
import Messages from '../Screens/Messages';
import ScheduledMessage from '../Screens/ScheduledMessage';

import Settings from '../Screens/Settings';
import Vcard from '../Screens/Profile/Vcard';
import ChangePassword from '../Screens/Profile/ChangePassword';
import ForwardingNumber from '../Screens/Preference/ForwardingNumber';
import MessageHistory from '../Screens/Preference/MessageHistory';
import OutOfOffice from '../Screens/Preference/Out_Of_Office';
import Timezone from '../Screens/Preference/Timezone';
import BillingManagement from '../Screens/AddBillings/BillingManagement';
import ArchiveAddOn from '../Screens/AddBillings/Archive_Add_On';
import Accounts from '../Screens/AddBillings/Accounts';
import Crm from '../Screens/Integration/Crm';
import Archive from '../Screens/Integration/Archive';
import AddAutoResponse from '../Screens/AutoResponse/AddAutoResponse';
import AddTemplates from '../Screens/Templates/AddTemplates';
import AddBookmarks from '../Screens/Bookmarks/AddBookmarks';
import VoiceAddOn from '../Screens/AddBillings/VoiceAddOn';
import CallerId from '../Screens/AddBillings/VoiceAddOn/CallerId';
import CallerTimeout from '../Screens/AddBillings/VoiceAddOn/CallerTimeout';
import VoiceAvailability from '../Screens/AddBillings/VoiceAddOn/VoiceAvailability';
import VoiceMail from '../Screens/AddBillings/VoiceAddOn/VoiceMail';
import VoiceNumber from '../Screens/AddBillings/VoiceAddOn/VoiceNumber';
import RecordingAddOn from '../Screens/AddBillings/RecordingAddOn';
import TranscribeAddOn from '../Screens/AddBillings/TranscribeAddOn';
import AddContacts from '../Screens/Contacts/AddContacts';
import Chats from '../Screens/Chat';
import RecentMedia from '../Screens/Chat/RecentMedia';
import SelectTemplates from '../Screens/Chat/SelectTemplates';
import DistributionDetail from '../Screens/Distributions/DistributionDetail';
import AllContacts from '../Screens/Contacts/contacts';
import Distributions from '../Screens/Distributions';
import StartConversation from '../Screens/Messages/StartConversation';
import WebView from '../Screens/WebView';
import AddScheduledMessage from '../Screens/ScheduledMessage/AddScheduledMessage';
import SelectContactForSchedule from '../Screens/ScheduledMessage/SelectContactForSchedule';
import ScheduleContactMessage from '../Screens/Chat/ScheduleContactMessage';
import BlockedContacts from '../Screens/Contacts/BlockedContacts';
import DuplicateContacts from '../Screens/Contacts/DuplicateContacts';
import ContactPhonePolicy from '../Screens/Contacts/ContactPhonePolicy';
import ImportFromCrm from '../Screens/Contacts/ImportFromCrm';
import ContactEdit from '../Screens/Contacts/ContactEdit';
import AddDL from '../Screens/Contacts/AddDL';
import ContactFromPhone from '../Screens/Contacts/ContactFromPhone';
import DLList from '../Screens/Contacts/DLList';
import AddPhoneNumber from '../Screens/Contacts/AddPhoneNumber';
import DialpadSearch from '../Screens/Dialpad/DialpadSearch';
import Integrations from '../Screens/Settings/Integrations';
import Support from '../Screens/Support/index';
import TrainingVideos from '../Screens/Support/TrainingVideos';
import ContactUs from '../Screens/Support/ContactUs';
import TermOfUse from '../Screens/Support/TermOfUse';
import MobileNotifications from '../Screens/Support/MobileNotifications';
import NotificationStatus from '../Screens/Support/NotificationStatus';
import NotificationTest from '../Screens/Support/NotificationTest';
import SearchMessages from '../Screens/Messages/SearchMessages';
import OAuthView from '../Screens/Settings/Integrations/ui/OAuthView';
import ContentScreen from '../Screens/Chat/ContentScreen';
import AccountReport from '../Screens/ArchivesReports/AccountReport';
import ArchiveAudit from '../Screens/ArchivesReports/ArchiveAudit';
import ArchiveLayout from '../Screens/ArchivesReports/ArchiveLayout';
import ArchiveSchedule from '../Screens/ArchivesReports/ArchiveSchedule';
import ConnectArchiver from '../Screens/ArchivesReports/ConnectArchiver';
import AppOptions from '../Screens/Profile/AppOptions';
import PositiveConsent from '../Screens/Profile/PositiveConsent';

import RequestMaker from '../Middleware/ApiCaller';
import getData from '../AsyncUtils/GetData';
import storeData from '../AsyncUtils/StoreData';
import { setToken, setProfile, clearProfile } from '../Redux';
import { loadRecentMessagesThunk } from '../Redux/recent_message';
import {
  loadAllContactsThunk,
  loadAllBlockedContactsThunk,
} from '../Redux/contacts';
import { loadRecentMediaThunk } from '../Redux/recent_media';
import { loadSavedMessagesThunk } from '../Redux/saved_messages';
import { loadAccountStatThunk } from '../Redux/account_stat';
import { loadAllAutoResponseThunk } from '../Redux/auto_response';
import { loadAccountHealthThunk } from '../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk,
} from '../Redux/add_ons';
import { loadAllDistributionsThunk } from '../Redux/distributions';
import {
  setTimeOutThunk,
} from '../Redux/account_active';
import TenFour from '../Screens/Ten4';
import Loader from '../Components/Loader';
import Ten4Chat from '../Screens/Ten4/Ten4Chat';
import AddPublicChannel from '../Screens/Ten4/AddPublicChannel';
import EditPublicChannel from '../Screens/Ten4/EditPublicChannel';
import AddPrivateChannel from '../Screens/Ten4/AddPrivateChannel';
import EditPrivateChannel from '../Screens/Ten4/EditPrivateChannel';
import APIAccess from '../Screens/Profile/APIAccess';
import { useSocket } from '../Middleware/useSocket';
import { useRoute } from '@react-navigation/native';
import { getChatChannels } from '../Api/ten4';
import Accessibility from '../Screens/Support/Accessibility';

const Drawerscreen = createDrawerNavigator();
const features = {
  MYREPCHAT: {
    id: 8,
    label: 'MyRepChat',
  },
};

const SwitchAccountModal = ({
  setVisible,
  setLoading,
  user,
  tenant,
  dispatch,
}) => {
  const Toast = useToast();
  const [activeAccount, setActiveAccount] = React.useState(null);

  React.useEffect(() => {
    if (user) {
      let accounts = user?.accounts ?? [];
      for (let x of accounts) {
        if (x.id == tenant?.id) {
          setActiveAccount(x);
        }
      }
    }
  }, [user, tenant]);

  // dashboard thunk com over here
  const loadDashBoardData = () => {
    dispatch(loadAccountStatThunk());
  };
  const loadRecentMessages = async () => {
    dispatch(loadRecentMessagesThunk());
  };
  const LoadAgain = (tenant) => {
    dispatch(loadAccountHealthThunk(tenant));
    loadDashBoardData();
    loadRecentMessages();
    dispatch(loadAllContactsThunk());
    dispatch(loadRecentMediaThunk());
    dispatch(loadSavedMessagesThunk());
    dispatch(loadIntegrationProviderThuunk());
    dispatch(loadIntegrationsThuunk());
    dispatch(loadGroupAddOnsThuunk());
    dispatch(loadBillingDetailsThuunk());
    dispatch(loadAllDistributionsThunk());
    dispatch(loadAllAutoResponseThunk());
    dispatch(loadAllBlockedContactsThunk());
  };

  const handleSubmit = async (selectedAccount) => {
    
    
    try {
      setLoading(true);
      setVisible(false);
      const fcmToken = await getData('fcmToken');
      const body = {
        action: 'authorize',
        account: selectedAccount.id,
        newToken: true,
        pushKey: fcmToken,
        expiration: 1000 * 60 * 60 * 24 * 7,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        if (result.headers && result.headers['x-dart-token']) {
          storeData(keyword.Token, result.headers['x-dart-token']);
          dispatch(
            setToken({
              token: result.headers['x-dart-token'],
              authenticated: true,
            })
          );
        }
        if (result.data) {
          dispatch(setProfile(result.data));
        }
        LoadAgain(selectedAccount.id);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      if (selectedAccount.id == 0) Linking.openURL(End_Points.Base_url + '/dart/web/index.html'); //MUST BE CHANGED FOR PRODUCTION
    }



  };
  
  return (
    <Menu.Item>
      <View style={{maxHeight:400,overflow:'scroll'}}>
      {user?.accounts &&
        user.accounts.map((x, i) => {
     
          let selected = activeAccount?.id == x.id;
          return (
            <TouchableOpacity
              style={{
                padding: 10,
                backgroundColor: selected
                  ? AppColor.PrimaryColor
                  : AppColor.SecondaryColor,
              }}
              onPress={() => {
                handleSubmit(x);
                setActiveAccount(x);
              }}>
              <Text
                style={{
                  color: selected ? AppColor.SecondaryColor : AppColor.Dark,
                }}>
                {i + 1}. {x.contact}
              </Text>
            </TouchableOpacity>
          );
        })}
        </View>
    </Menu.Item>
  );
};

export default function Drawer({ navigation }) {
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;

  const dimensions = useWindowDimensions();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [inactiveTimeout, setInactiveTimeout] = React.useState(1000 * 60 * 10);

  const user = useSelector((state) => state.User.profile)?.user;
  const tenant = useSelector((state) => state.User?.profile)?.tenant;
  const number = useSelector((state) => state.account_health)?.mrcNumber;
  const [newMessage, setNewMessage] = React.useState(false);
  const { inactivity } = useSelector((state) => state.accountActivity);
  const { profile } = useSelector((state) => state.User);
  const recent_messages = useSelector((state) => state.recent_messages).data;

  const group = useSelector((state) => state.User?.profile?.group);
  const dispatch = useDispatch();
  const idleTimer = React.useRef();

  const [ten4Badge, setTen4Badge] = React.useState(false);
  const [isTen4Active,setIsTen4Active] = React.useState(false);
  const socket = useSocket();
  const route = useRoute();

  const onTen4Message = React.useCallback((message) => {
    const data = JSON.parse(message?.data);

    if(data.message.subject === 'New channel message' && !isTen4Active){
      setTen4Badge(true)
      triggerNotification(data)
    }
  })

  React.useEffect(()=>{
    let isSubscribed = true;

    if(affiliate?.chatEnabled){
      getChatChannels({affiliate: affiliate.id, userId: user.user_id })
      .then(result => {
          if(result?.data?.success){
              if((result.data.public.length > 0 && result.data.public.findIndex(e => e.unread > 0) > -1) || (result.data.private.length > 0 && result.data.private.findIndex(e => e.unread > 0) > -1) ){
                setTen4Badge(true);
              }
          }
      })
    }

    return () => (isSubscribed = false)

  },[])

  const triggerNotification = async data => {
    const sound = await getData('ten4Sound');
    const options = {
        body: `from ${data.message.senderName}`,
        icon: `${End_Points.Base_url}dart/css/dart/images/iolake_logo_icon.png`,
    }
    if(sound !== false) {
        const audio = new Audio(require('../Assets/sounds/ping.mp3'));
        audio.play();

    }
    
    const notification = new Notification('New Ten4 Message', options );
    
}

  React.useEffect(() => {
    if(socket){
      socket.addEventListener("message", onTen4Message);

      return () => {
        socket.removeEventListener("message", onTen4Message);
      };
    }
  }, [socket, onTen4Message]);


  React.useEffect(() => {
    const timeout =
      profile?.group?.inactivityTimeout ||
      profile?.affiliate?.inactivityTimeout;
    if (timeout) {
      setInactiveTimeout(timeout * 1000 * 60);
    }
  }, [profile]);

  React.useEffect(()=>{
    if(recent_messages){
      const isOnMessagesScreen = route?.state?.history[route.state.history.length - 1]?.key?.includes('Messages') || !route.state?.history;
      const index = recent_messages.findIndex(e => e.unreadmessages > 0)
      if(index > -1 && !isOnMessagesScreen) {
        setNewMessage(true);
      } else {
        setNewMessage(false);
      }
    }
  },[ recent_messages])

  const onAction = (e) => {
    dispatch(setTimeOutThunk(false));
  };

  const _onActive = (e) => {
    dispatch(setTimeOutThunk(false));
  };

  const _onIdle = (e) => {
    idleTimer.current?.reset();

    dispatch(setTimeOutThunk(true));
  };

  const handleLogout = async () => {
    const body = {
      action: 'logout',
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    let result = await RequestMaker(Config);
    if (result.status == 200) {
      dispatch(clearProfile());
      navigation.dispatch(DrawerActions.closeDrawer());
      navigation.navigate('Login');
    }
  }

  const renderItem = (focused, label) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: 50,
          paddingHorizontal: 18,
        }}>
        {focused && (
          <Icon
            name={'east'}
            type={'material'}
            color={AppColor.SecondaryColor}
            size={20}
          />
        )}
        <Text
          style={{
            fontSize: 16,
            color: AppColor.SecondaryColor,
            fontFamily: AppFonts.Roboto_Bold,
            marginLeft: 15,
          }}>
          {label}
        </Text>
      </View>
    );
  };

  const title = (text) =>
    Platform.select({ web: `MyRepChat | ${text}`, default: text });

  const CustomSidebarMenu = (props) => {


    let contact_name = ''; 
    let accounts = user?.accounts ?? [];
    for (let x of accounts) {
      if (x.id == tenant?.id) {
        contact_name = x.contact
      }
    }
    
    const focusMainContent = () => {
      const x = document.getElementsByClassName('css-1dbjc4n r-1p0dtai r-6koalj r-13awgt0 r-1d2f490 r-105ug2t r-u8s1d r-zchlnj r-ipm5af')[0]
      if(x){
        x.setAttribute('tabindex', 1);
        x.focus()
      }
    }

    return (
      <>
      <Pressable onPress={focusMainContent} accessible={true} accessibilityLabel='Skip Navigation Links.' style={(state) => state.focused ? {position:'absolute',top:0,left:0,zIndex:99999,backgroundColor:'white'} : {position:'absolute',top:-1000,left:-1000}}>
      <Text style={{fontWeight:'800',padding:10,color:AppColor.PrimaryColor,fontFamily:AppFonts.Roboto_Regular}}>Skip Navigation Links</Text>
      </Pressable>

        <View style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
          <SafeAreaView style={{ backgroundColor: AppColor.PrimaryColor }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: 0, 
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
              }}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('Login');
                  navigation.dispatch(DrawerActions.closeDrawer());
                }}
                activeOpacity={0.9}
                style={{ backgroundColor: AppColor.PrimaryColor, flex: 1 }}>
                <View
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    paddingVertical: 10,
                  }}>
                  <Icon
                    size={50}
                    color={AppColor.SecondaryColor}
                    name={'user-circle-o'}
                    type={'font-awesome'}
                  />
                  <Menu
                    placement="bottom"
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity
                          {...triggerProps}
                          onPress={e => user?.accounts.length > 1 ? triggerProps.onPress() : e.preventDefault()}
                          style={{
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}>
                          <View
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: 60,
                            }}>
                            <Text
                              style={{
                                color: AppColor.SecondaryColor,
                                fontSize: 20,
                                marginBottom: 2,
                                fontFamily: AppFonts.Roboto_Regular,
                              }}>
                              {contact_name}
                            </Text>
                            <Text
                              style={{
                                color: AppColor.SecondaryColor,
                                fontSize: 14,
                                fontFamily: AppFonts.Roboto_Regular,
                                letterSpacing: 1.5,
                              }}>
                              {number?.value}
                            </Text>
                          </View>
                          <View
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: 60,
                              marginLeft: 10,
                            }}>
                            {
                            user?.accounts.length > 1 &&
                            <Icon
                              size={15}
                              color={AppColor.SecondaryColor}
                              name={'arrow-down'}
                              type={'font-awesome-5'}
                            />
                            }
                          </View>
                        </TouchableOpacity>
                      );
                    }}>
                    <SwitchAccountModal
                      user={user}
                      tenant={tenant}
                      dispatch={dispatch}
                      visible={visible}
                      setVisible={setVisible}
                      setLoading={setLoading}
                    />
                  </Menu>
                </View>
              </TouchableOpacity>
            </View>
          </SafeAreaView>
          <View style={{ height: 10 }}></View>
          <DrawerContentScrollView {...props}>
            <DrawerItemList {...props} />
          </DrawerContentScrollView>
        </View>
        {/* {visible && */}
        {loading && <Loader backgroundColor="#fff6" />}
      </>
    );
  };

  let calcualtor_enabled = 'disabled'
  if(group?.appCalculators == true) calcualtor_enabled = 'Calculators'

  return (
    <Drawerscreen.Navigator
      initialRouteName="Messages"
      drawerPosition="left"
      drawerType="permanent"
      edgeWidth={10}
      overlayColor="transparent"
      drawerContent={(props) => {
        const filteredProps = {
          ...props,
          state: {
            ...props.state,
            routeNames: props.state.routeNames.filter((routeName) => {
              routeName === 'dashboard' ||
                routeName === 'Messages' ||
                routeName === 'Ten4' ||
                routeName === 'Dialpad' ||
                routeName === 'Contacts' ||
                routeName === 'ScheduledMessage' ||
                routeName === 'Templates' ||
                routeName === 'AutoResponse' ||
                routeName === 'Bookmarks' ||
                routeName === 'Calculators';
            }),
            routes: props.state.routes.filter(
              (route) =>
                route.name === 'dashboard' ||
                route.name === 'Messages' ||
                route.name === 'Ten4' ||
                route.name === 'Dialpad' ||
                route.name === 'Contacts' ||
                route.name === 'ScheduledMessage' ||
                route.name === 'Templates' ||
                route.name === 'AutoResponse' ||
                route.name === 'Bookmarks' ||
                route.name === 'Calculators'
            ),
          },
        };
        return (
          <>
            {inactivity && (
              <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={_onActive}
                onIdle={_onIdle}
                onAction={onAction}
                debounce={1000}
                timeout={inactiveTimeout}
              />
            )}

            <CustomSidebarMenu
              navigation={navigation}
              setDrawerOpen={(x) => setDrawerOpen(x)}
              isdrawerOpen={drawerOpen}
              {...filteredProps}
            />
            <View style={{ backgroundColor: AppColor.PrimaryColor }}>
              <TouchableOpacity
              accessible={true}
              accessibilityRole='button'
                onPress={() => navigation.navigate('Settings')}
                style={{
                  minHeight: 35,
                  flexDirection: 'row',
                  marginHorizontal: 20,
                }}>
                <View style={{ justifyContent: 'center' }}>
                  <Icon
                    style={{ color: AppColor.SecondaryColor }}
                    color={AppColor.SecondaryColor}
                    name={'gear'}
                    type={'evilicon'}
                  />
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flex: 1,
                    alignItems: 'flex-start',
                  }}>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 16,
                      color: AppColor.SecondaryColor,
                      marginLeft: 15,
                    }}>
                    Settings
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
              accessible={true}
              accessibilityRole='button'
                onPress={() => navigation.navigate('Support')}
                style={{
                  minHeight: 35,
                  flexDirection: 'row',
                  marginHorizontal: 20,
                }}>
                <View style={{ justifyContent: 'center' }}>
                  <Icon
                    style={{ color: AppColor.SecondaryColor }}
                    color={AppColor.SecondaryColor}
                    name={'help-outline'}
                    type={'MaterialIcons'}
                  />
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flex: 1,
                    alignItems: 'flex-start',
                  }}>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 16,
                      color: AppColor.SecondaryColor,
                      marginLeft: 15,
                    }}>
                    Support
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
              accessible={true}
              accessibilityRole="button"
                onPress={handleLogout}
                style={{
                  height: 35,
                  flexDirection: 'row',
                  marginHorizontal: 20,
                }}>
                <View style={{ justifyContent: 'center' }}>
                  <Icon
                    style={{ color: AppColor.SecondaryColor }}
                    color={AppColor.SecondaryColor}
                    name={'logout'}
                    type={'MaterialCommunityIcons'}
                  />
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    flex: 1,
                    alignItems: 'flex-start',
                  }}>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 16,
                      color: AppColor.SecondaryColor,
                      marginLeft: 15,
                    }}>
                    Logout
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </>
        );
      }}
      drawerStyle={{
        backgroundColor: 'red',
        minWidth: 200,
        maxWidth:260,
        maxHeight: window.innerHeight,
        elevation: drawerOpen ? 5 : 0,
        shadowColor: '#000',
        shadowOffset: {
          width: drawerOpen ? -5 : 0,
          height: drawerOpen ? 5 : 0,
        },
        shadowOpacity: 0.34,
        shadowRadius: drawerOpen ? 6.27 : 0,
      }}
      drawerContentOptions={{
        labelStyle: {
          color: 'white',
          textAlign: 'justify',
          fontSize: 16,
          fontFamily: AppFonts.Roboto_Regular,
        },
        activeTintColor: '#598527',
        itemStyle: {
          backgroundColor: AppColor.SecondaryColor,
          marginLeft: 0,
          width: '100%',
          minHeight: 50,
          borderColor: '#598527',
          marginVertical: 0,
          borderRadius: 0,
          paddingHorizontal: 18,
        },
        contentContainerStyle: {
        },
        style: {},
      }}>
      <Drawerscreen.Screen
        name="dashboard"
        component={Dashboard}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  marginLeft: -20,
                  textAlign: 'left',
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                Dashboard
              </Text>
            ) : (
              <Text
                style={{
                  marginLeft: -20,
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                Dashboard
              </Text>
            );
          },
          title: 'MyRepChat | Dashboard',
          drawerIcon: ({ focused, color, size }) => {
            return focused ? (
              <Icon
                name={'dashboard'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'dashboard'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />
      <Drawerscreen.Screen
        name="Messages"
        component={Messages}
        listeners={() => ({
          focus: () => {
            setNewMessage(false);
          },
        })}
        options={{
          drawerLabel: ({ focused }) => {
            return (
              <View style={{flexDirection:'row',alignItems:'center'}}>
                <Text
                  style={{
                    fontSize: 16,
                    color: 'black',
                    marginLeft: -20,
                    fontFamily: AppFonts.Roboto_Regular,
                  }}>
                  Messages
                </Text>
                {newMessage && (
                  <View
                    style={{
                      backgroundColor: '#de9208',
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      marginLeft:'17%'
                    }}
                  />
                )}
              </View>
            );
          },
          title: 'MyRepChat | Messages',
          drawerIcon: ({ focused }) => {
            return (
              <Icon
                name={'message1'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />

      {affiliate?.chatEnabled &&
            <Drawerscreen.Screen
              name="Ten4"
              component={TenFour}
              listeners={{
                blur: () => {
                  setIsTen4Active(false);
                },
                focus: () => {
                  setIsTen4Active(true)
                }
              }}
              options={{
                
                drawerLabel: ({ focused }) => {
                  return (
                    <View onClick={()=>setTen4Badge(false)} style={{flexDirection:'row',alignItems:'center'}}>
                    
                      <Text
                        style={{
                          fontSize: 16,
                          color: 'black',
                          fontFamily: AppFonts.Roboto_Regular,
                          marginLeft: -20,
                        }}>
                        Ten4
                      </Text>
                      { ten4Badge && (
                        <View
                          style={{
                            backgroundColor: '#de9208',
                            width: 12,
                            height: 12,
                            borderRadius: 6,
                            marginLeft:'17%'
                          }}
                        />
                        )}
                      </View>
                  );
                },
                title: 'MyRepChat | Ten4',
                drawerIcon: ({ focused }) => {
                  return (
                    <Icon
                      // onPress={({ focused, color, size }) => { }}
                      name={'message1'}
                      type={'antdesign'}
                      style={{ color: 'black', fontSize: 20 }}
                    />
                  );
                },
                
              }}
        />}
      
      <Drawerscreen.Screen
        name="Dialpad"
        component={DialPad}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Dialpad
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Dialpad
              </Text>
            );
          },
          title: 'MyRepChat | Dialpad',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'dialpad'}
                type={'MaterialCommunityIcons'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'dialpad'}
                type={'MaterialCommunityIcons'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />
      
      <Drawerscreen.Screen
        name="Contacts"
        component={Contacts}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Contacts
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Contacts
              </Text>
            );
          },
          title: 'MyRepChat | Contacts',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'contacts'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'contacts'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />

      <Drawerscreen.Screen
        name="ScheduledMessage"
        component={ScheduledMessage}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Scheduled
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Scheduled
              </Text>
            );
          },
          title: 'MyRepChat | Scheduled',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'calendar'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'calendar'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />
      <Drawerscreen.Screen
        name="Templates"
        component={Templates}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Templates
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Templates
              </Text>
            );
          },
          title: 'MyRepChat | Templates',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'copy1'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'copy1'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />
      <Drawerscreen.Screen
        name="AutoResponse"
        component={AutoResponse}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Auto Response
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Auto Response
              </Text>
            );
          },
          title: 'MyRepChat | Auto Response',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'reply-all'}
                type={'font-awesome-5'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'reply-all'}
                type={'font-awesome-5'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />
      <Drawerscreen.Screen
        name="Bookmarks"
        component={Bookmarks}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Bookmarks
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Bookmarks
              </Text>
            );
          },
          title: 'MyRepChat | Bookmarks',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'book'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'book'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}
      />




      
        <Drawerscreen.Screen

        
        
        name={calcualtor_enabled}
        component={Calculators}
        options={{
          drawerLabel: ({ focused }) => {
            return focused ? (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_RegularMontserrat_SemiBold,
                  marginLeft: -20,
                }}>
                Calculator
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginLeft: -20,
                }}>
                Calculator
              </Text>
            );
          },
          title: 'MyRepChat | Calculators',
          drawerIcon: ({ focused }) => {
            return focused ? (
              <Icon
                name={'calculator'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}></Icon>
            ) : (
              <Icon
                name={'calculator'}
                type={'antdesign'}
                style={{ color: 'black', fontSize: 20 }}
              />
            );
          },
        }}



        />
     




      <Drawerscreen.Screen
        name="BlockedContacts"
        component={BlockedContacts}
        options={{ drawerLabel: title('BlockedContacts') }}
      />
      <Drawerscreen.Screen
        name="DuplicateContacts"
        component={DuplicateContacts}
        options={{ drawerLabel: title('Contacts') }}
      />
      <Drawerscreen.Screen
        name="AllContacts"
        component={AllContacts}
        options={{ drawerLabel: title('All Contacts') }}
      />
      <Drawerscreen.Screen
        name="Distributions"
        component={Distributions}
        options={{ drawerLabel: title('Distributions') }}
      />
      <Drawerscreen.Screen
        name="AddContacts"
        component={AddContacts}
        options={{ drawerLabel: title('AddContacts') }}
      />
      <Drawerscreen.Screen
        name="ContactPhonePolicy"
        component={ContactPhonePolicy}
        options={{ drawerLabel: title('Contact Phone Policy') }}
      />
      <Drawerscreen.Screen
        name="ContactFromPhone"
        component={ContactFromPhone}
        options={{ drawerLabel: title('ContactFromPhone') }}
      />
      <Drawerscreen.Screen
        name="ImportFromCrm"
        component={ImportFromCrm}
        options={{ drawerLabel: title('Login'), unmountOnBlur: true }}
      />
      <Drawerscreen.Screen
        name="ContactEdit"
        component={ContactEdit}
        options={{ title: title('Contact Edit') }}
      />
      <Drawerscreen.Screen
        name="AddPhoneNumber"
        component={AddPhoneNumber}
        options={{ drawerLabel: title('Add PhoneNumber') }}
      />
      <Drawerscreen.Screen
        name="DLList"
        component={DLList}
        options={{ drawerLabel: title('DLList') }}
      />
      <Drawerscreen.Screen
        name="AddDL"
        component={AddDL}
        
        options={{ drawerLabel: title('AddDL'), unmountOnBlur:true }}
      />
      <Drawerscreen.Screen
        name="DistributionDetail"
        component={DistributionDetail}
        options={{ drawerLabel: title('Distribution Detail') }}
      />
      <Drawerscreen.Screen
        name="DialpadSearch"
        component={DialpadSearch}
        options={{ drawerLabel: title('Search') }}
      />
      <Drawerscreen.Screen
        name="AddScheduledMessage"
        component={AddScheduledMessage}
        options={{ drawerLabel: title('AddScheduledMessage') }}
      />
      <Drawerscreen.Screen name="SelectTemplates" component={SelectTemplates} />
      <Drawerscreen.Screen
        name="SelectContactForSchedule"
        component={SelectContactForSchedule}
      />
      <Drawerscreen.Screen name="AddTemplates" component={AddTemplates} />
      <Drawerscreen.Screen name="WebView" component={WebView} />
      <Drawerscreen.Screen name="AddBookmarks" component={AddBookmarks} />
      <Drawerscreen.Screen name="AddAutoResponse" component={AddAutoResponse} />
      <Drawerscreen.Screen
        name="StartConversation"
        component={StartConversation}
        options={{unmountOnBlur:true}}
      />
      <Drawerscreen.Screen
        name="Chats"
        component={Chats}
        options={{ title: title('Chats') }}
      />
      <Drawerscreen.Screen
        name="RecentMedia"
        component={RecentMedia}
        options={{ title: title('RecentMedia') }}
      />
      <Drawerscreen.Screen
        name="SearchMessages"
        component={SearchMessages}
        options={{ title: title('Search Messages') }}
      />
      <Drawerscreen.Screen
        name="Settings"
        component={Settings}
        options={{ title: title('Settings') }}
      />
      <Drawerscreen.Screen
        name="Vcard"
        component={Vcard}
        options={{ title: title('Card') }}
      />
      <Drawerscreen.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{ title: title('ChangePassword') }}
      />
      <Drawerscreen.Screen
        name="ForwardingNumber"
        component={ForwardingNumber}
        options={{ title: title('ForwardingNumber') }}
      />
      <Drawerscreen.Screen
        name="OutOfOffice"
        component={OutOfOffice}
        options={{ title: title('OutOfOffice') }}
      />
      <Drawerscreen.Screen
        name="Timezone"
        component={Timezone}
        options={{ title: title('Timezone') }}
      />
       <Drawerscreen.Screen
            name="SelectBookContact"
            component={SelectBookContact}
            options={{ title: title('SelectBookContact') }}

        />

      <Drawerscreen.Screen
        name="MessageHistory"
        component={MessageHistory}
        options={{ title: title('MessageHistory') }}
      />
      <Drawerscreen.Screen
        name="VoiceAddOn"
        component={VoiceAddOn}
        options={{ title: title('VoiceAddOn') }}
      />
      <Drawerscreen.Screen name="CallerId" component={CallerId} />
      <Drawerscreen.Screen name="CallerTimeout" component={CallerTimeout} />
      <Drawerscreen.Screen
        name="VoiceAvailability"
        component={VoiceAvailability}
      />
      <Drawerscreen.Screen name="VoiceMail" component={VoiceMail} />
      <Drawerscreen.Screen name="VoiceNumber" component={VoiceNumber} />
      <Drawerscreen.Screen name="AccountReport" component={AccountReport} />
      <Drawerscreen.Screen name="ArchiveAudit" component={ArchiveAudit} />
      <Drawerscreen.Screen name="ArchiveLayout" component={ArchiveLayout} />
      <Drawerscreen.Screen name="ArchiveSchedule" component={ArchiveSchedule} />
      <Drawerscreen.Screen name="ConnectArchiver" component={ConnectArchiver} />
      <Drawerscreen.Screen
        name="RecordingAddOn"
        component={RecordingAddOn}
        options={{ title: title('RecordingAddOn') }}
      />
      <Drawerscreen.Screen
        name="TranscribeAddOn"
        component={TranscribeAddOn}
        options={{ title: title('TranscribeAddOn') }}
      />
      <Drawerscreen.Screen
        name="Accounts"
        component={Accounts}
        options={{ title: title('Accounts') }}
      />
      <Drawerscreen.Screen
        name="ArchiveAddOn"
        component={ArchiveAddOn}
        options={{ title: title('ArchiveAddOn') }}
      />
      <Drawerscreen.Screen
        name="BillingManagement"
        component={BillingManagement}
        options={{ title: title('BillingManagement') }}
      />
      <Drawerscreen.Screen
        name="Support"
        component={Support}
        options={{ title: title('Support') }}
      />
      <Drawerscreen.Screen
        name="ContactUs"
        component={ContactUs}
        options={{ title: title('ContactUs') }}
      />
      <Drawerscreen.Screen
        name="TermOfUse"
        component={TermOfUse}
        options={{ title: title('TermOfUse') }}
      />
      <Drawerscreen.Screen
        name="MobileNotifications"
        component={MobileNotifications}
        options={{ title: title('Mobile Notifications') }}
      />
      <Drawerscreen.Screen
        name="NotificationStatus"
        component={NotificationStatus}
        options={{ title: title('Notification Status') }}
      />
      <Drawerscreen.Screen
        name="NotificationTest"
        component={NotificationTest}
        options={{ title: title('Notification Test') }}
      />
      <Drawerscreen.Screen
        name="TrainingVideos"
        component={TrainingVideos}
        options={{ title: title('Training Videos') }}
      />
      <Drawerscreen.Screen
        name="Integrations"
        component={Integrations}
        options={{ title: title('Integrations') }}
      />
      <Drawerscreen.Screen
        name="Crm"
        component={Crm}
        options={{ title: title('CRM') }}
      />
      <Drawerscreen.Screen
        name="Archive"
        component={Archive}
        options={{ title: title('Archive') }}
      />
      <Drawerscreen.Screen
        name="OAuthView"
        component={OAuthView}
        options={{ title: title('OAuthView') }}
      />
      <Drawerscreen.Screen
        name="ScheduleContactMessage"
        component={ScheduleContactMessage}
        options={{ title: title('ScheduleContactMessage') }}
      />
      <Drawerscreen.Screen
        name="ContentScreen"
        component={ContentScreen}
        options={{ title: title('Content') }}
      />

      <Drawerscreen.Screen name="AppOptions" component={AppOptions} />
      <Drawerscreen.Screen name="PositiveConsent" component={PositiveConsent} />
      <Drawerscreen.Screen name="Ten4Chat" component={Ten4Chat} />
      <Drawerscreen.Screen name="AddPublicChannel" component={AddPublicChannel} />
      <Drawerscreen.Screen name="EditPublicChannel" component={EditPublicChannel} />
      <Drawerscreen.Screen name="AddPrivateChannel" component={AddPrivateChannel} />
      <Drawerscreen.Screen name="EditPrivateChannel" component={EditPrivateChannel} />
      <Drawerscreen.Screen name="APIAccess" component={APIAccess} />
      <Drawerscreen.Screen name="Accessibility" component={Accessibility} />
    </Drawerscreen.Navigator>
  );
}
