import React, { useState, useEffect } from 'react';
import { Text, View, Switch, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import Alert from 'Alert';
import { Select, useToast } from 'native-base';

import RequestMaker from '../../Middleware/ApiCaller';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import Loader from '../../Components/Loader';
import getData from '../../AsyncUtils/GetData';
import CustomInput from '../../Components/CustomInput';

const ArchiveLayout = ({ navigation, route }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [header, setHeader] = useState('');
  const [message, setMessage] = useState('');
  const [footer, setFooter] = useState('');
  const [meta, setMeta] = useState('');
  const [transcript, setTranscript] = useState(false);
  const [recording, setRecording] = useState(false);
  const [media, setMedia] = useState(false);
  const [call, setCall] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('');
  const [selectedLayoutId, setSelectedLayoutId] = useState(-1);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [selectedRollup, setSelectedRollup] = useState('');
  const [selectedMms, setSelectedMms] = useState('');
  const [layoutList, setLayoutList] = useState([]);
  const [saveBtnDisable, setSaveBtnDisable] = useState(true);
  const [copyBtnDisable, setCopyBtnDisable] = useState(true);
  const [removeBtnDisable, setRemoveBtnDisable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const Toast = useToast();

  const tenant = useSelector((state) => state.User)?.profile?.tenant;

  useEffect(() => {
    _getLayouts();
  }, []);

  useEffect(() => {
    if (!selectedLayoutId) return;
    const temp = layoutList.find((v) => v.id == selectedLayoutId);
    setSelectedLayout(temp);
  }, [selectedLayoutId]);

  useEffect(() => {
    renderLayout();
  }, [selectedLayout]);

  const _getLayouts = async () => {
    try {
      let body = {
        action: 'get_archive_layout',
        tenantId: tenant.id
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLayoutList(data.layouts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
	
	if (selectedLayout.id != null && selectedLayout.tenantId == null) {
		Alert.alert('You must be the owner of the layout to save it.');
		return;
	}
	
    if (!name) {
      Alert.alert('Info', 'Please type name');
      return;
    }

    try {
      var params = {
        name: name,
        layoutFormat: selectedFormat,
        layoutRollup: selectedRollup,
        layoutMms: selectedMms,
        title,
        from,
        to,
        header,
        message,
        footer,
        metadata: meta,
        includeCall: call,
        includeMms: media,
        includeRecording: recording,
        includeTranscript: transcript,
      };

      if (tenant) {
        params.tenantId = tenant.id;
      }

      if (selectedLayout) {
        params.id = selectedLayout.id;
      }

      let body = {
        action: selectedLayout.id != null
          ? 'update_archive_layout'
          : 'create_archive_layout',
        ...params,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      _getLayouts();
      setLoading(false);
      setVisible(false);
      Toast.show({
        duration: 2000,
        title: 'Archive template saved',
        status: 'success',
      });
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
	if (selectedLayout.tenantId == null) {
		Alert.alert('You must be the owner of the layout to remove it.');
		return;
	}
	
    try {
      let body = {
        action: 'delete_archive_layout',
        id: selectedLayout.id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      Toast.show({
        duration: 2000,
        title: 'Archive template deleted',
        status: 'success',
      });
      setLoading(false);
      setVisible(false);
      _getLayouts();
    } catch (err) {
      Toast.show({
        duration: 2000,
        title: 'Layout removed',
        status: 'success',
      });
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
	selectedLayout.id = null;
	selectedLayout.tenantId = tenant.id;
	selectedLayout.name = 'Copy of ' + selectedLayout.name;
	renderLayout();	
  };
  const handleEmpty = () => {
	console.log("Calling empty");

    if (selectedLayout) {
		selectedLayout.id = null;
		selectedLayout.tenantId = tenant.id;
	}
	
    setName('');
    setSelectedFormat('');
    setSelectedRollup('');
    setSelectedMms('');
    setTitle('');
    setFrom('');
    setTo('');
    setHeader('');
    setMessage('');
    setFooter('');
    setMeta('');
    setCall('');
    setMedia('');
    setRecording('');
    setTranscript('');

    setIsDisabled(false);
    setVisible(true);
    setSaveBtnDisable(false);

  };

  const handleCancel = () => {
	setSelectedLayout(null);
	setSelectedLayoutId(-1);

    setVisible(false);
    setSaveBtnDisable(true);
  };

  const renderLayout = () => {
    if (!selectedLayout) return;
    
    setIsDisabled(selectedLayout.tenantId == null); //disable if layout isnt owned (is default layout)
    setSaveBtnDisable(selectedLayout.tenantId == null);

    setVisible(true);

    setName(selectedLayout.name);
    setSelectedFormat(selectedLayout.layoutFormat);
    setSelectedRollup(selectedLayout.layoutRollup);
    setSelectedMms(selectedLayout.layoutMms);
    setTitle(selectedLayout.title);
    setFrom(selectedLayout.from);
    setTo(selectedLayout.to);
    setHeader(selectedLayout.header);
    setMessage(selectedLayout.message);
    setFooter(selectedLayout.footer);
    setMeta(selectedLayout.metadata);
    setCall(selectedLayout.includeCall);
    setMedia(selectedLayout.includeMms);
    setRecording(selectedLayout.includeRecording);
    setTranscript(selectedLayout.includeTranscript);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Archive Layout'}
      />
      <View style={styles.container}>
        <View style={styles.item}>
          <Text>
            For most users, the default layout provides all the details that
            they need in their archive. However, you can create your own layout
            and when you do, you will have complete control of the layout of
            your archive.
          </Text>
        </View>
        <View style={styles.item}>
          <Text>
            A layout defines what the archive looks like, is html or text,
            defines how its rolled up (by contact or by period), and how MMS
            messages are handled.
          </Text>
        </View>
        <View style={styles.item}>
          <Text>
            Text attributes - all substitution variables are allowed. HTML is
            allowed for HTML format.
          </Text>
        </View>
        <View style={styles.item}>
          <Text>
            Title - included at the top or in a ‘subject’ field if one exists.
          </Text>
          <Text>
            Data to include - the type of data in addition to text to include.
          </Text>
          <Text>
            From/To - included in a from/to field if one exists (like email).
          </Text>
          <Text>
            Header - display at the top of each ‘rollup’ - message_per_contact
            will show a header at the top per contact.
          </Text>
          <Text>
            Footer - display at the bottom of each ‘rollup’ -
            message_per_contact will show a footer at the bottom per contact.
          </Text>
          <Text>
            Message - what to show for each message. MMS display is based on
            what is configured in layout MMS
          </Text>
          <Text>
            Metadata - Additional key/value pairs. Typically added as email
            headers.
          </Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.layout}>Layout</Text>
          <Select
            style={styles.picker}
            selectedValue={selectedLayoutId}
            onValueChange={(itemValue, itemIndex) => {
              setVisible(true);
              setSaveBtnDisable(true);
              setRemoveBtnDisable(false);
              setCopyBtnDisable(false);
              setSelectedLayoutId(Number(itemValue));
            }}>
            {layoutList.map((v) => (
              <Select.Item label={v.name} value={v.id} key={v.id} />
            ))}
          </Select>
        </View>
          <View>
        {visible && (
            <View>
              <View style={styles.item}>
                <CustomInput
                  onChangeText={(val) => setName(val)}
                  value={name}
                  title={'Name'}
                  placeholder={''}
                  disabled={isDisabled}
                />
              </View>
              <View style={styles.item}>
                <Text style={styles.label}>DATA TO INCLUDE</Text>
                <View style={styles.switch}>
                  <Text style={styles.title}>Call</Text>
                  <Switch
                    value={call}
                    onValueChange={() => setCall(!call)}
                    trackColor={{ false: 'red', true: 'green' }}
                    disabled={isDisabled}
                  />
                </View>
                <View style={styles.switch}>
                  <Text style={styles.title}>MEDIA</Text>
                  <Switch
                    value={media}
                    onValueChange={() => setMedia(!media)}
                    trackColor={{ false: 'red', true: 'green' }}
                    disabled={isDisabled}
                  />
                </View>
                <View style={styles.switch}>
                  <Text style={styles.title}>RECORDING</Text>
                  <Switch
                    value={recording}
                    onValueChange={() => setRecording(!recording)}
                    trackColor={{ false: 'red', true: 'green' }}
                    disabled={isDisabled}
                  />
                </View>
                <View style={styles.switch}>
                  <Text style={styles.title}>TRANSCRIPT</Text>
                  <Switch
                    value={transcript}
                    onValueChange={() => setTranscript(!transcript)}
                    trackColor={{ false: 'red', true: 'green' }}
                    disabled={isDisabled}
                  />
                </View>
                <View style={styles.item}>
                  <Text style={styles.title}>Format</Text>
                  <Select
                    style={styles.picker}
                    selectedValue={selectedFormat}
                    onValueChange={(itemValue, itemIndex) =>
                      setSelectedFormat(itemValue)
                    }
                    isDisabled={isDisabled}>
                    <Select.Item label="HTML" value="html" />
                    <Select.Item label="Text" value="text" />
                  </Select>
                </View>
                <View style={styles.item}>
                  <Text style={styles.title}>ROLLUP</Text>
                  <Select
                    style={styles.picker}
                    selectedValue={selectedRollup}
                    onValueChange={(itemValue, itemIndex) =>
                      setSelectedRollup(itemValue)
                    }
                    isDisabled={isDisabled}>
                    <Select.Item
                      label="Per Contact"
                      value="message_per_contact"
                    />
                    <Select.Item
                      label="Per Period"
                      value="message_per_period"
                    />
                  </Select>
                </View>
                <View style={styles.item}>
                  <Text style={styles.title}>MMS</Text>
                  <Select
                    style={styles.picker}
                    selectedValue={selectedMms}
                    onValueChange={(itemValue, itemIndex) =>
                      setSelectedMms(itemValue)
                    }
                    isDisabled={isDisabled}>
                    <Select.Item label="Embedded" value="mms_embedded" />
                    <Select.Item label="Attached" value="mms_attached" />
                    <Select.Item label="Linked" value="mms_linked" />
                    <Select.Item label="Metadata" value="mms_metadata" />
                  </Select>
                </View>
                <CustomInput
                  onChangeText={(val) => setTitle(val)}
                  value={title}
                  title={'Title'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setFrom(val)}
                  value={from}
                  title={'From'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setTo(val)}
                  value={to}
                  title={'To'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setHeader(val)}
                  value={header}
                  title={'Header'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setMessage(val)}
                  value={message}
                  title={'message'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setFooter(val)}
                  value={footer}
                  title={'Footer'}
                  placeholder={''}
                  disabled={isDisabled}
                />
                <CustomInput
                  onChangeText={(val) => setMeta(val)}
                  value={meta}
                  title={'Meta'}
                  placeholder={''}
                  disabled={isDisabled}
                />
              </View>
            </View>
        )}

            <View style={styles.buttons}>
              <CustomButton
                onPress={() => {
                  handleSave();
                }}
                title="Save"
                width={150}
                disabled={saveBtnDisable}
              />
              <CustomButton
                onPress={() => {
                  handleRemove();
                }}
                title="Remove"
                width={150}
                disabled={removeBtnDisable}
              />
         	  <CustomButton
                title="Create Empty"
                onPress={() => {
				  handleEmpty();
                }}
                width={300}
             />
             <CustomButton
                onPress={() => {
				  handleCopy();
                }}
                title="Copy"
                width={150}
                disabled={copyBtnDisable}
              />
              <CustomButton
                onPress={() => {
                  handleCancel();
                }}
                title="Cancel"
                width={150}
                style={{
                  marginVertical: 10,
                }}
              />
            </View>
          </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default ArchiveLayout;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    padding: 30,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 15,
    flexWrap: 'wrap',
  },
  picker: {
    fontSize: 15,
    color: AppColor.PrimaryColor,
    fontFamily: AppFonts.Roboto_Bold,
  },
  switch: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  title: {
    fontSize: 15,
    color: AppColor.PrimaryColor,
    fontFamily: AppFonts.Roboto_Bold,
    marginRight: 15,
  },
  label: {
    fontSize: 18,
    color: AppColor.PrimaryColor,
    fontFamily: AppFonts.Roboto_Bold,
  },
  item: {
    marginVertical: 10,
  },
  layout: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Bold,
    textAlign: 'center',
  },
});
