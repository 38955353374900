import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const initialState = {
  messagingPhone: {},
  email: {},
  source: {},
  name: {},
  phone: {},
};

const duplicatesNumbersSlice = createSlice({
  name: 'duplicate_members',
  initialState: initialState,
  reducers: {
    setMessagingPhones: (state, action) => {
      return {
        ...state,
        messagingPhone: action.payload.data,
      };
    },
  },
});

export const { setMessagingPhones } = duplicatesNumbersSlice.actions;

export const loadDuplicateNumbersThunk = (tenant) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_duplicate_members',
        tenant: tenant,
        checkEmail: false,
        checkMessagingPhone: true,
        checkName: false,
        checkPhone: false,
        checkSource: false,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        dispatch(setMessagingPhones({ data: result.data.messagingPhone }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default duplicatesNumbersSlice.reducer;
