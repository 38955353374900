import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AntDesign from 'react-native-vector-icons/AntDesign';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const TrainingVideo = ({ navigation }) => {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Training Videos'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View style={{ flex: 1, padding: 30 }}>
        <View style={styles.row}>
          <Text>
            <Text style={styles.text}>
              Available 24 hours - You can view training videos on how to use
              MyRepChat to communicate effectively with your clients. Click on
              the
            </Text>
            <AntDesign name="videocamera" color={AppColor.Black200} size={20} />{' '}
            <Text style={styles.text}>
              icon to open the video in Vimeo in a different browser tab.
            </Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>Getting Started</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/742789095/f7168f538c', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>

        <View style={styles.row}>
          <Text style={styles.text}>How to use the Dashboard</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416275/d07869b98d', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Messages</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416321/2512755cf3', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Scheduled Messages</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416365/84622a6dda', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Templates</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416400/527a967b91', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Auto Responses</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416179/3b84c6e385', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Dialpad</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416824/8cd70f4ca5', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>How to use Contacts</Text>
          <TouchableOpacity
            onPress={() =>
              window.open('https://vimeo.com/719416230/7f2899a5ac', '_blank').focus()
            }>
            <AntDesign
              name="videocamera"
              color={AppColor.PrimaryColor}
              size={20}
            />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};
export default TrainingVideo;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    color: AppColor.Black200,
    marginRight: 5,
  },
});
