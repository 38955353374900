import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  Linking,
  FlatList,
  Platform,
  Dimensions,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import {
  loadAllAutoResponseThunk,
  deleteAutoResponseThunk,
} from '../../Redux/auto_response';
import { useDispatch, useSelector } from 'react-redux';
import { HStack } from 'native-base';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';

const AutoResponse = ({ navigation }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auto_responses).data;
  React.useEffect(() => {
    dispatch(loadAllAutoResponseThunk());
  }, []);

  const handleDeleteAutoResponse = (id) => {
    dispatch(deleteAutoResponseThunk(id));
  };


  const handleReorderUp = async item => {
    let previousId;
    const index = data.findIndex(e => e.id === item.id);
    if(index === 1){
      previousId = null;
    } else if(index === 0){
      return;
    } else if(index > 1){
      previousId = data[index - 2].id
    }
    const body = {
      action: 'update_workflow',
      ...item,
      previousId: previousId
    };
    
    let token = await getData('token');
    let device = await getData('@device_id');
    const headers = { 'x-dart-token': token, 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    if (result.data?.success) {
      dispatch(loadAllAutoResponseThunk());
    } else {
      alert("Something went wrong. Please try again later.")
    }
  }

  const handleReorderDown = async item => {
    let previousId;
    const index = data.findIndex(e => e.id === item.id);
    if(item.id === data[data.length - 1].id ){
      return;
    } else {
      previousId = data[index + 1].id
    }
    const body = {
      action: 'update_workflow',
      ...item,
      previousId: previousId
    };    
    let token = await getData('token');
    let device = await getData('@device_id');
    const headers = { 'x-dart-token': token, 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    if (result.data?.success) {
      dispatch(loadAllAutoResponseThunk());
    } else {
      alert("Something went wrong. Please try again later.")
    }
  }

  const renderListView = (item) => {
    let request = 'message1';
    let response = 'message1';
    let type = 'AntDesign';
    if (item.request) {
      switch (item.request) {
        case 'incomingSms':
          request = 'message1';
          break;
        case 'incomingSmsNumber':
          request = 'message1';
          break;
        case 'incomingVoice':
          request = 'phone';
          break;
        case 'incomingVoiceAny':
          request = 'phone';
          break;
        default:
          request = 'message1';
      }
    }
    if (item.response) {
      switch (item.response) {
        case 'replyWith':
          response = 'reply';
          type = 'font-awesome';
          break;
        case 'email':
          response = 'email';
          type = 'material-community';
          break;
        case 'forwardTo':
          response = 'mail-forward';
          type = 'font-awesome';
          break;
        case 'sayMessage':
          response = 'volume-2';
          type = 'feather';
          break;
        default:
          response = 'message1';
      }
    }
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate('AddAutoResponse', { item: item })}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          minHeight: 60,
          marginBottom: 5,
        }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Icon
            color={AppColor.PrimaryColor}
            name={'reply-all'}
            type={'font-awesome-5'}
          />
          <View style={{ minWidth: 30, marginLeft: 10 }}>
            <Icon
              color={AppColor.Dark}
              name={'arrowright'}
              type={'antdesign'}
            />
            <View style={{ height: 5 }}></View>
            <Icon color={AppColor.Dark} name={'arrowleft'} type={'antdesign'} />
          </View>
          <View style={{ minWidth: 30 }}>
            <Icon color={AppColor.Dark} name={request} type={'antdesign'} />
            <View style={{ height: 5 }}></View>
            <Icon color={AppColor.Dark} name={response} type={type} />
          </View>
          <View style={{ flex: 1 }}>
            <Text
              numberOfLines={1}
              style={{
                color: 'black',
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 15,
              }}>
              {item.requestValue}
            </Text>
            <View style={{ height: 5 }}></View>
            <Text numberOfLines={1} style={{ fontSize: 12 }}>
              {item.responseValue}
            </Text>
          </View>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => handleReorderUp(item)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              style={{ fontSize: 20, color: AppColor.Dark }}
              name={'arrowup'}
              type={'antdesign'}
              color='black'
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => handleReorderDown(item)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              style={{ fontSize: 20, color: AppColor.Dark }}
              name={'arrowdown'}
              type={'antdesign'}
              
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => handleDeleteAutoResponse(item.id)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon color={AppColor.danger} name={'delete'} type={'antdesign'} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor, overflow:'hidden' }}>
      <Header
        title={'Auto Responses'}
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        
      />
      <View style={{flexDirection:'row',marginTop:0}}>
        <View style={{alignItems:'center',flex:1}}>
        <ScrollView style={{width:'100%', flex: 1, padding: 15,maxHeight:'80%' }}>
          <FlatList
            data={data}
            ListEmptyComponent={
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                Empty List.
              </Text>
            }
            scrollEnabled={false}
            renderItem={(item) => {
              return renderListView(item.item);
            }}
          />
          
        </ScrollView>

        <View style={{ alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => navigation.reset({index:0, routes:[{name: 'AddAutoResponse'}]}) }
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop:20,
                marginBottom: 20,
              }}>
              <Icon
                color={AppColor.PrimaryColor}
                name={'add-circle-outline'}
                type={'ionicon'}
              />
              <Text
                style={{
                  fontSize: 24,
                  marginHorizontal: 5,
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.PrimaryColor,
                }}>
                Create Auto Response
              </Text>
            </TouchableOpacity>
          </View>

          </View>
        <View
          style={{
            maxHeight:'100%',
            flex: 1,
            borderLeftWidth: 1,
            borderColor: AppColor.PrimaryColor,
            paddingHorizontal: 10,
            paddingTop:10,
          }}>
          <Text style={styles.notesStyles}>
            An auto response is a way to return an automatic reply to an
            incoming text message. An example would be to have MyRepChat send
            back a link to your calendar when someone sends the word "calendar"
            to your MyRepChat number.
          </Text>
          <Text style={[styles.notesStyles, { marginVertical: 20 }]}>
            You cannot create auto responses for built in keywords, including
            keywords that are used for adherance to the Telephone Consumer
            Protection Act (TCPA). The keywords you cannot use are:
          </Text>
          <Text style={styles.notesStyles}>vcard (MyRepChat Keyword)</Text>
          <Text style={styles.notesStyles}>vcardlink (MyRepChat Keyword)</Text>
          <Text style={styles.notesStyles}>
            contactinfo (MyRepChat Keyword)
          </Text>
          <Text style={styles.notesStyles}>accept (MyRepChat Keyword) </Text>
          <Text style={styles.notesStyles}>start (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>stop (TCPA Keyword) </Text>
          <Text style={styles.notesStyles}>stopall (TCPA Keyword) </Text>
          <Text style={styles.notesStyles}>subscribe (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>unsubscribe (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>cancel (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>end (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>unstop (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>help (TCPA Keyword)</Text>
          <Text style={styles.notesStyles}>info (TCPA Keyword)</Text>
          <Text style={[styles.notesStyles, { marginTop: 20 }]}>
            Only the first auto response that meets the criteria of the incoming
            text will be used, make sure to order your auto responses to meet
            your requirements. An example would be if an unknown contact sends a
            keyword and you have an auto response for unknown contacts AND for
            the keyword, you need to specify which one should be used first by
            setting its order higher using the arrow buttons.{' '}
          </Text>
        </View>
        </View>
    </SafeAreaView>
  );
};
export default AutoResponse;

const styles = StyleSheet.create({
  notesStyles: {
    fontSize: 17,
    textAlign: 'center',
  },
});
