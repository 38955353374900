import React, { useState } from 'react';
import {
  Day,
  GiftedChat,
  LoadEarlier,
  Composer,
} from 'react-native-gifted-chat';

import {
  StyleSheet,
  Text,
  View,
  Linking,
  Platform,
} from 'react-native';

import { useToast } from 'native-base';
import Alert from 'Alert';
import Modal from 'modal-react-native-web';
import { useFocusEffect } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
const moment = require('moment');
import { useDispatch, useSelector } from 'react-redux';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';

import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import Loader from '../../Components/Loader';
import AllCollections from './AlltachmentsModalContainer';
import { loadRecentMessagesThunk } from '../../Redux/recent_message';
import { RenderPhoneModal } from './RenderPhoneModal';
import { RenderBubble } from './RenderBubble';
import { initiateCall } from '../../Api/chat';
import MessageHeader from './MessageHeader';
import MessageInput from './MessageInput';
import { MultiPreviews } from './MultiPreviews';
import getData from '../../AsyncUtils/GetData';
import storeData from '../../AsyncUtils/StoreData';
import EmojisModal from '../../Components/Modals/EmojisModal';
import RequestMaker from '../../Middleware/ApiCaller';
import Entypo from 'react-native-vector-icons/Entypo';

const Chats = ({ navigation, memberData, messages, template_message, message, loadPreviewsAndCurrentChatMessages, loadContactMessages }) => {
  const tenant = useSelector((state) => state.User?.profile?.tenant);
  const group = useSelector((state) => state.User)?.profile?.group;
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const Toast = useToast();
  const [typedmessage, setTypedmessage] = useState('');
  const [isVisibleSubmit, setIsVisibleSubmit] = React.useState(false);
  const [consent, setConsent] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [multiVisible, setMultiVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [selectedMultiData, setSelectedMultiData] = React.useState({
    type: '',
    url: '',
  });
  const chatRef = React.useRef();
  const textInputRef = React.useRef();
  const [emojiModalVisible, setEmojiModalVisible] = React.useState(false);

  
  React.useEffect(()=> {
      checkIfEnterToSendEnabled();

      loadChatDetails();

      if (template_message) {
        setTypedmessage(template_message);
      } else if (message === 'clear') {
        setTypedmessage('');
      }

  }, [memberData])

  const loadChatDetails = () => {
    setTypedmessage('');
    setConsent(true);

    const hasInboundMessage = messages.findIndex(e => e.direction === 'inbound') > -1
    const hasReceievedAcceptMessage = messages.some(communication => 
      communication?.messages?.findIndex(e => e?.sms?.toLowerCase() === "accept") > -1
    );

    if(
      !consent && 
      hasInboundMessage && 
      group?.consentInboundAccepts
    ) {
      setTypedmessage('');
      setConsent(true);
    }

    if(
      !consent &&
      hasReceievedAcceptMessage &&
      group?.consentResponseRequired
    ) {
      setConsent(true);
      setTypedmessage('');
    }

    if (!memberData.consented && group?.consentEnabled) {
      get_consent();
    }
  }

  const processMessages = (communications) => {
    const filteredMessages = communications.filter((msg, i, arr) => {
      const prevMsg = arr[i - 1];
      const isUniqueSmsSid = !(prevMsg?.messages?.some(e => e?.sms_sid === msg?.messages[0]?.sms_sid))
        || !msg?.messages[0]?.sms_sid
        || msg?.messages[0]?.sms_sid === "none";
      const isSmsWithMessages = msg.type === 'sms' && msg.messages.length !== 0;
      const isNotSms = msg.type !== 'sms';

      return isUniqueSmsSid && (isSmsWithMessages || isNotSms);
    });

    const messages = filteredMessages.map(x => ({
      _id: x.id,
      createdAt: x.date,
      type: x.type,
      direction: x.direction,
      user: {
        _id: x.direction === 'outbound' ? 1 : 2,
      },
      sms_status: x.sms_status,
      call_status: x.call_status,
      mms: x.mms,
      sms: x.messages,  // This line ensures the sms property is retained
      records: x.recordings,
      transcripts: x.transcripts,
      senderName: x.senders.length === 0 ? '' : x.senders[0].name,
    }));

    setTimeout(()=>{
      if(chatRef.current){
        chatRef.current.scrollToBottom()
      }
    },500)

    return messages;

  };

  const processedMessages = processMessages(messages);

  const handleSendMessageError = (result, typedMessageCopy) => {
    const { failures, errorMessage } = result.data;
    if (failures && failures.length > 0) {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: failures.join(','),
      });
      if (failures.includes('Message rejected by your home office')) {
        loadContactMessages();
      }
      if (failures.includes("Message exceeds 1600 characters.")) {
        setTypedmessage(typedMessageCopy);
      }
    } else if (errorMessage) {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: errorMessage,
      });
    } else {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: 'We were unable to send your message',
      });
    }
  };

  const sendMessage = async (text) => {
    setLoading(true);
    const body = {
      action: 'send_text_message',
      type: 'sms',
      text,
      members: [memberData.id],
    };

    try {
      const result = await RequestMaker(body);
      if (result.status === 400) {
        Keyboard.dismiss();
        const alertMessage = tenant.status === 3
          ? 'You are not able to send messages while your account is in review state.'
          : 'You are not able to send messages at this time.';
        Alert.alert('Server Error', alertMessage);
      } else if (result.data.success) {
          loadPreviewsAndCurrentChatMessages();
      } else {
        handleSendMessageError(result, text);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onSend =
    (messages = []) => {
      sendMessage(messages.text);
    };

  const renderDay = (props) => {
    return <Day {...props} textStyle={CommonStyle.dayText} />;
  };

  const checkIfEnterToSendEnabled = async () => {
    const result = await getData('@entertosend');
    setIsVisibleSubmit(result);
  }

  const handleCheckEnterToSend = async () => {
    await storeData('@entertosend', !isVisibleSubmit);
    setIsVisibleSubmit(!isVisibleSubmit);
  }

  const renderInputToolbar = (props) => {
    return (
      <MessageInput
        checked={isVisibleSubmit}
        onCheck={handleCheckEnterToSend}
        consent={consent}
        toolBarProps={{ ...props }}
        onPressSend={() => {
          if (consent) {
            const mes = {
              _id: Date.now() + Math.random(0, 2000),
              text: typedmessage,
              sms_status: 'sending',
              createdAt: new Date(),
              user: {
                _id: 1,
              },
              type: '',
              transactionAmount: '402',
              transactionstatus: 'Received',
              records: [],
              transcripts: [],
            };
            typedmessage.trim() !== ''
              ? onSend(mes)
              : setTypedmessage('');
            setTypedmessage('');
          } else {
            handleConsentAction();
          }
        }}
        onPressAttachment={() => setVisible(!visible)}
        onPressEmoji={() => setEmojiModalVisible(!emojiModalVisible)}
      />
    );
  };

  const renderComposer = (props) => {
    return (

      <Composer
        {...props}
        keyboardAppearance='default'

        textInputProps={{
          placeholder: 'Type here.',
          value: typedmessage,
          onKeyPress: (event) => {
            if (Platform.OS === "web" && event.keyCode === 13 && isVisibleSubmit && !event.shiftKey) {

              if (typedmessage && typedmessage.trim() != '') {
                const mes = {
                  _id: Date.now() + Math.random(0, 2000),
                  text: typedmessage.trim(),
                  sms_status: 'sending',
                  createdAt: new Date(),
                  user: {
                    _id: 1,
                  },
                  type: '',
                  transactionAmount: '402',
                  transactionstatus: 'Recieved',
                  records: [],
                  transcripts: [],
                };
                onSend(mes);
                setTypedmessage('');
              }
            }
          },
          onChangeText: (message) => { if (consent || !group?.consentResponseRequired) { setTypedmessage(message) } },
        }}
        textInputStyle={{
          textAlignVertical: 'center',
          marginTop: 0,
          paddingTop: 0,
          marginHorizontal: 0,
          minHeight: 50,
          maxHeight: 100,
          lineHeight: '1.5em',
          paddingHorizontal: 10,
          fontSize: 18,
          fontFamily: AppFonts.Roboto_Regular,
          backgroundColor: '#F2F4FB',
        }}
      />
    );
  };

  const renderLoadEarlier = (props) => {
    return <LoadEarlier {...props} textStyle={CommonStyle.loadEarlierText} />;
  };

  const handleCall = async (phoneNumber) => {
    initiateCall({ toNumber: phoneNumber })
      .then(result => {
        if (result.data.success) {
          Toast.show({
            duration: 2000,
            title: 'Call initiated, Use your phone to compelete the call',
            status: 'success',
          });
          setTimeout(() => {
            Linking.openURL(`tel:${phoneNumber}`);
          }, 2000);
        } else if (result.data?.errorMessage) {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.failures.join(','),
            status: 'error',
          });
        }
        setTimeout(() => {
          loadContactMessages();
        }, 2000);
      })
  };

  // consecent
  const showConsentDialog = (consent) => {
    let msg = '';

    if (consent.state === 'pending') {
      const formattedDate = moment(consent.lastchanged ?? new Date()).format('MM/DD/YYYY hh:mm:ss');
      msg = `This contact was previously sent the required consent message at ${formattedDate}. Click send button to re-send the required consent message.`;
    } else {
      msg = 'This contact has not accepted the required consent message. This message cannot be changed. Click send button to send the required consent message.';
    }

    if (consent.state !== 'pending' && consent.state !== 'accepted' && affiliate?.blockoutboundconsent && affiliate?.blockoutboundconsentmessage) {
      Alert.alert('Consent Blocked', affiliate.blockoutboundconsentmessage);
      return;
    }

    Alert.alert('Consent Required', msg);
  };

  const get_consent = async () => {
    if (!memberData?.id) return;

    try {
      const body = {
        action: 'get_consent',
        member: memberData.id,
      };

      const result = await RequestMaker(body);

      if (!result.data.success) {
        Alert.alert('Error', result?.data?.errorMessage);
        return;
      }

      const { state, consent, consentMsg } = result.data;

      switch (state) {
        case 'accepted':
          if (template_message) {
            setTypedmessage(template_message);
          }
          return true;

        case 'pending':
          if (group?.consentResponseRequired) {
            showConsentDialog(consent);
            setConsent(false);
            if (consentMsg) setTypedmessage(consentMsg);
          }
          break;

        default:
          setTypedmessage(consentMsg);
          showConsentDialog(consent);
          setConsent(false);
          break;
      }

      return false;

    } catch (err) {
      console.log(err);
    }
  };

  const handleConsentSend = async (id) => {
    try {
      let body = {
        action: 'send_consent',
        id: id
      };

      const result = await RequestMaker(body);
      if (result.data.success) {
        if (!group?.consentResponseRequired) {
          setConsent(true);
          setTypedmessage('');
        }
      } else if(result.data.failures){
        const failures = result.data.failures.join(',');
        Toast.show({status:'error',title:failures});
      }
      loadPreviewsAndCurrentChatMessages();
    } catch (err) {
      console.log(err);
    }
  };

  const handleConsentAction = () => {
    if (affiliate?.blockoutboundconsent && affiliate?.blockoutboundconsentmessage) {
      Alert.alert('Consent Blocked', affiliate.blockoutboundconsentmessage);
      return;
    }

    const showConsentAlert = () => {
      if (group?.consentResponseRequired) {
        Alert.alert(
          'Consent Sent',
          'Consent request sent. You will not be able to text this contact until the keyword accept is sent back.',
          [{ text: 'OK' }],
        );
      }
    };

    if (memberData?.id) {
      handleConsentSend(memberData.id, false);
      showConsentAlert();
    } else {
      Alert.alert('Error', 'No contact selected to send consent');
    }

  };

  const addEmoji = e => {
    setTypedmessage(typedmessage + e)
    setEmojiModalVisible(false);
    if (chatRef) {
      chatRef.current.focusTextInput();
    }
  }

  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: 'white' }}
        edges={['bottom', 'top']}>
        <View style={{ flex: 1 }}>

          <MessageHeader
            member={memberData}
            onPress={() => {
                navigation.navigate('ContactEdit', { item: memberData });
              
            }}
            onPhonePress={() => {
                setPhoneModalVisible(true);
            }
            }

          />


          <GiftedChat
            maxInputLength={1600}
            ref={chatRef}
            inverted={false}
            loadEarlier={true}
            // loadEarlier={loadMoreMessages}
            // onLoadEarlier={handleLoadMoreMessages}
            messages={processedMessages}
            scrollToBottom={true}
            onSend={(messages) => onSend(messages)}
            user={{
              _id: 1,
            }}
            renderDay={renderDay}
            listViewProps={{
              initialNumToRender: 10,
            }}
            scrollToBottomComponent={() => <Entypo size={32} name='chevron-down'/>}
            renderChatEmpty={() => (
              <View
                style={{
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                <Text
                  style={{
                    color: AppColor.Dark,
                    transform: [{ rotateX: '180deg' }],
                  }}>
                  Empty messages
                </Text>
              </View>
            )}
            textInputProps={{
              accessible: true,
              accessibilityRole: 'text',
              ref: textInputRef,
              autoCapitalize: 'none',
              autoCorrect: true,
              spellCheck: true,
              blurOnSubmit: isVisibleSubmit && Platform.OS === 'web',

            }}
            renderLoading={renderLoadEarlier}
            renderInputToolbar={renderInputToolbar}
            renderComposer={renderComposer}
            renderTime={renderTime}
            renderLoadEarlier={renderLoadEarlier}
            renderBubble={(props) => (
              <RenderBubble
                {...props}
                openDocument={openDocument}
                setMultiVisible={setMultiVisible}
                setSelectedMultiData={setSelectedMultiData}
                setLoading={setLoading}
              />
            )}
            showUserAvatar={false}
            isKeyboardInternallyHandled={true}
            isCustomViewBottom
            messagesContainerStyle={{
              paddingBottom: 50,
            }}
          />
        </View>
      </SafeAreaView>

      {/* attachment types*/}
      <Modal
        visible={visible}
        transparent={true}
        style={{ justifyContent: 'flex-end' }}>
        <AllCollections
          typedmessage={typedmessage}
          navigation={navigation}
          setVisible={setVisible}
          member={memberData}
          loadMessage={loadContactMessages}
          setLoading={setLoading}
          memberData={memberData}
        />
      </Modal>
      {/* multimedia preview */}
      <Modal
        visible={multiVisible}
        onBackdropPress={() => {
          setMultiVisible(false);
          setSelectedMultiData({
            type: '',
            url: '',
          });
        }}
        transparent={true}
        backdropOpacity={0.7}
        style={{ justifyContent: 'center' }}>
        <MultiPreviews data={selectedMultiData} />
      </Modal>
      <Modal
        visible={phoneModalVisible}
        onBackdropPress={() => {
          setPhoneModalVisible(false);
        }}
        transparent={true}
        backdropOpacity={0.7}
        useNativeDriver={true}
        style={{ justifyContent: 'center' }}>
        <RenderPhoneModal
          handleCall={handleCall}
          phoneNumbers={memberData.phoneNumbers}
          setPhoneModalVisible={setPhoneModalVisible}
        />
      </Modal>
      {
        emojiModalVisible &&
        <EmojisModal
          visible={emojiModalVisible}
          setVisible={setEmojiModalVisible}
          onPressEmoji={addEmoji}
        />
      }
      {loading && <Loader />}
    </>
  );
};

export const openDocument = (url) => {
  window.open(url, '_blank').focus();
};

const renderTime = (props) => {
  return (
    <View style={{ width: 140, marginHorizontal: 2 }}>
      <Text
        style={{
          textAlign: props.position == 'right' ? 'right' : 'right',
          fontSize: 12,
          fontFamily: AppFonts.Roboto_Regular,
          color: props.position == 'right' ? AppColor.SecondaryColor : 'black',
        }}>
        {props.currentMessage.sms_status}{' '}
        {moment(props.currentMessage.createdAt).format('h:mm a')}
      </Text>
    </View>
  );
};

export const CommonStyle = StyleSheet.create({
  AuthContainer: {
    flex: 1,
    backgroundColor: 'black',
  },
  headerTextStyle: {
    padding: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'black',
    fontSize: 2,
    fontWeight: '500',
  },
  buttonGroupContainerStyle: {
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: 5,
  },
  disableTextStyle: {
    color: 'black',
  },
  selectedTextStyle: {
    color: 'black',
  },
  seperatorStyle: {
    height: 1,
    width: '86%',
    backgroundColor: 'black',
    alignSelf: 'center',
  },

  dayText: {
    color: 'grey',
    fontSize: 13,
  },
  left: {
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
    maxWidth: wp(93),
  },
  right: {
    backgroundColor: AppColor.PrimaryColor + 'CA',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
  },
  leftImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
  },

  rightImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
    padding: 5,
  },

  bubbleLeftText: {
    color: 'black',
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },
  bubbleRightText: {
    color: AppColor.SecondaryColor,
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },

  inputContainer: {
    minHeight: 6,
    borderTopWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
  },
  inputPrimary: {
    backgroundColor: 'black',
    marginVertical: 4,
    marginHorizontal: 16,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  send: {
    width: 20,
    height: 20,
  },
  composerText: {
    color: AppColor.SecondaryColor,
    fontSize: 14,
  },
  timeTextLeft: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'right',
  },
  timeTextRight: {
    color: AppColor.SecondaryColor,
    fontSize: 12,
  },
  loadEarlierText: {
    color: 'white',
    fontSize: 12,
  },
  modalitemsStyle: { alignItems: 'center', width: '33%' },
  modaltextStyle: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
});

export default React.memo(Chats);
