import React, { useState } from 'react';
import { Text, View, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import Alert from 'Alert';
import { SafeAreaView } from 'react-native-safe-area-context';
import Modal from 'modal-react-native-web';
import { Icon } from 'react-native-elements';
import { Checkbox } from 'native-base';
import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';

const Accounts = ({ navigation, route }) => {
  const [modalvisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    id: null,
    name: '',
    email: '',
  });
  const [amount_detail, setAmountDetail] = useState({
    addon: 'Record',
    amount: 0,
  });
  const [users, setUsers] = useState([]);
  const [externalAccounts, setExternalAccounts] = React.useState([]);
  const [externalAccountsModalVisible, setExternalAccountsModalVisible] = React.useState(false);
  const add_ons = useSelector((state) => state.add_ons);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const integrations = useSelector((state) => state.integrations)?.integrations;

  React.useEffect(() => {
    if (!modalvisible) {
      setModalData({
        id: null,
        name: '',
        email: '',
      });
    }
  }, [modalvisible]);

  React.useEffect(() => {
    let hasClientworks = false;

    integrations.forEach(e => {
      if(e.provider === 'clientworks'){
        hasClientworks = true;
      }
    })

    if(hasClientworks) getExternalAccounts();
    getAccounts();

  }, []);

  React.useEffect(() => {
    if (!modalvisible) {
      setModalData({
        id: null,
        name: '',
        email: '',
      });
    }
  }, [modalvisible]);

  React.useEffect(() => {
    let hasClientworks = false;

    integrations.forEach(e => {
      if(e.provider === 'clientworks'){
        hasClientworks = true;
      }
    })

    if(hasClientworks) getExternalAccounts();
    getAccounts();

  }, [tenant.name]);

  React.useEffect(() => {
    if (add_ons?.biling_details) {
      let voice = add_ons?.biling_details.find((x) => x.addon == 'Logins');
      if (voice) {
        setAmountDetail(voice);
      }
    }
  }, [add_ons]);

  const getAccounts = async (value) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_account_logins',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        console.log(data.logins);
        setUsers(data.logins);
      } else {
        Alert.alert(
          'Error',
          'We are currently unable to display results. You can submit a manual request to add a user while we resolve the issue.'
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      let body = {};
      if (modalData.id == null) {
        body = {
          action: 'create_login',
          name: modalData.name,
          email: modalData.email,
          type: 'account'
        };
      } else {
        body = {
          action: 'update_login',
          name: modalData.name,
          id: modalData.id,
          email: modalData.email,
          type:'account'
        };
      }
      setVisible(false);
      setLoading(true);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('logins', result);
      if (result.data.success) {
        getAccounts();
      } else {
        Alert.alert(
          'errorMessage',
          result.data.errorMessage ??
            "We're sorry, there was a problem createing an account login."
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setVisible(false);
      setLoading(false);
    }
  };

  const getExternalAccounts = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'get_external_user_list',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        setExternalAccounts(data.users)
        console.log(data)
      } else {
        Alert.alert(
          'Error',
          'We are currently unable to display results. You can submit a manual request to add a user while we resolve the issue.'
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteAccount = async (id) => {
    try {
      setLoading(true);
      const body = {
        action: 'delete_login',
        id: id,
        type: 'account',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      console.log('logins', result);
      if (result.data.success) {
        getAccounts();
      } else {
        Alert.alert(
          'Error',
          result.data.errorMessage ??
            "We're sorry, there was a problem removing the account login."
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const checkIfChecked = item => {
    console.log(item);
    const index = users.findIndex(e =>{ return(e.name.toLowerCase() === item.name.toLowerCase() && e.email.toLowerCase() === item.email.toLowerCase()) })
    console.log(index);
    if(index > -1) {
      return true
    } else {
      return false
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Account Logins'}
      />
      <ScrollView
        style={{
          flex: 1,
          padding: 30,
          width: '100%',
          alignSelf: 'center',
        }}>
        <View style={{ width: '100%', alignSelf: 'center' }}>
          <Text
            style={{
              fontSize: 18,
              lineHeight: 20,
              fontFamily: AppFonts.Roboto_Regular,
              textAlign: 'center',
            }}>
            Grant access to your account to additional co-workers. They can use and manage the account the same way you do.
          </Text>
          <Text
            style={{
              fontSize: 18,
              lineHeight: 20,
              fontFamily: AppFonts.Roboto_Regular,
              textAlign: 'center',
              marginVertical: 15,
            }}>
          Each account includes 2 logins at no additional cost - account owner and one more. Each additional login after the second is $5/month/login. New logins will receive two emails from noreply@ionlake.com with their account access information and password.

        </Text>
          <Text
            style={{
              fontSize: 18,
              lineHeight: 20,
              fontFamily: AppFonts.Roboto_Regular,
              textAlign: 'center',
            }}>
            Note: This setting is not available in the mobile app.
          </Text>
        </View>
        <FlatList
          data={users}
          renderItem={({ item, index }) => (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                marginVertical: 5,
                alignItems: 'center',
              }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                }}
                onPress={() => {
                  setModalData({
                    id: item.user_id,
                    name: item.name,
                    email: item.email,
                  });
                  setVisible(true);
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.name}
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.email}
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.mobile}
                </Text>
              </TouchableOpacity>
              <Icon
                onPress={() => handleDeleteAccount(item.user_id)}
                name={'circle-with-cross'}
                type={'entypo'}
                color={AppColor.PrimaryColor}
              />
            </View>
          )}
          keyExtractor={(item, index) => item.user_id}
          style={{
            flex: 1,
            marginVertical: 20,
            borderRadius: 5,
            borderWidth: 1,
            padding: 10,
          }}
          ListHeaderComponent={
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  width: '30%',
                }}>
                Name
              </Text>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  width: '30%',
                }}>
                Email
              </Text>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  width: '30%',
                }}>
                Multi-Factor Authentication
              </Text>
            </View>
          }
        />
        <View style={{ marginVertical: 30 }}>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              marginTop: 15,
            }}>
            Adding a third or more logins costs ${amount_detail.amount}
            .00/month/login.
          </Text>
        </View>
        <CustomButton
          onPress={() => setVisible(true)}
          title={'+ Create Login'}
          width="100%"
          style={{
            maxWidth: 300,
          }}
        />


{
        externalAccounts.length > 0 &&
        <>
      <Text
      style={{
        fontSize: 18,
        lineHeight: 20,
        fontFamily: AppFonts.Roboto_Regular,
        textAlign: 'center',
      }}>
        You may add or remove additional logins to access this account. 
        These users will be able to manage the account on your behalf. 
        Select any additional users you would like to add or remove from your account.
      </Text>

      <FlatList
          data={externalAccounts}
          renderItem={({ item, index }) => (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                marginVertical: 5,
                alignItems: 'center',
              }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                }}
                onPress={() => {
                  setModalData({id: null, name: item.name, email: item.email})
                  setExternalAccountsModalVisible(true);
                }}>
                <Checkbox isChecked={checkIfChecked(item)} value="enabled" accessibilityLabel='Enable account' style={{marginRight:'.5rem'}} />

                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.name}
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.email}
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: AppFonts.Roboto_Regular,
                    color: AppColor.PrimaryColor,
                    textDecorationLine: 'underline',
                    width: '30%',
                  }}
                  numberOfLines={1}>
                  {item.mobile}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          keyExtractor={(item, index) => item.user_id}
          style={{
            flex: 1,
            marginVertical: 20,
            borderRadius: 5,
            borderWidth: 1,
            padding: 10,
          }}
          ListHeaderComponent={
            <View style={{ flexDirection: 'row',marginLeft:'1.5rem' }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  width: '30%',
                }}>
                Name
              </Text>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  width: '30%',
                }}>
                Email
              </Text>
            </View>
          }
        />


      </>
        }


      </ScrollView>
      <View style={{ height: 10 }} />
      <RenderAddLogin
        modalData={modalData}
        setVisible={setVisible}
        modalvisible={modalvisible}
        setModalData={setModalData}
        setLoading={setLoading}
        getAccounts={getAccounts}
        handleSave={handleSave}
      />

      <RenderToggleExternalAccount 
        setVisible={setExternalAccountsModalVisible}
        modalvisible={externalAccountsModalVisible}
        modalData={modalData}
        setLoading={setLoading}
        users={users}
        externalAccounts={externalAccounts}
        getAccounts={getAccounts}
        handleSave={handleSave}
        handleDeleteAccount={handleDeleteAccount}
      />


      {loading && <Loader />}
    </SafeAreaView>
  );
};

const RenderAddLogin = ({
  modalvisible,
  modalData,
  setModalData,
  setVisible,
  handleSave
}) => {

  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={modalvisible}
      transparent={true}
      onDismiss={() => {
        setVisible(false);
      }}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => {
        setVisible(false);
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '100%',
            maxWidth: 767,
            borderRadius: 8,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: 30,
          }}>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <Icon
              name={'circle-with-cross'}
              type={'entypo'}
              onPress={() => setVisible(false)}
              color={AppColor.PrimaryColor}
            />
          </View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 20,
              color: AppColor.PrimaryColor,
              fontFamily: AppFonts.Roboto_Bold,
              marginTop: 5,
            }}>
            Account Logins
          </Text>
          <View
            style={{
              alignSelf: 'center',
              width: '100%',
            }}>
            <View style={{ height: 10 }} />
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
              }}>
              Name
            </Text>
            <CustomInput
              value={modalData.name}
              onChangeText={(t) =>
                setModalData((state) => ({ ...state, name: t }))
              }
              placeholder={'Enter Name'}
            />
            <View style={{ height: 20 }} />
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
              }}>
              Email
            </Text>
            <CustomInput
              value={modalData.email}
              onChangeText={(t) =>
                setModalData((state) => ({ ...state, email: t }))
              }
              placeholder={'Enter Email'}
            />
          </View>
          <View style={{ height: 20 }} />
          <CustomButton onPress={handleSave} title={'Save'} />
        </View>
      </View>
    </Modal>
  );
};


const RenderToggleExternalAccount = ({
  modalvisible,
  setVisible,
  modalData,
  setLoading,
  users,
  getAccounts,
  handleDeleteAccount,
  externalAccounts,
  handleSave
}) => {

  const [isEnabled, setIsEnabled] = React.useState();
  const [userId, setUserId] = React.useState();

  React.useEffect(()=>{
    const index = users.findIndex(e =>{ return(e.name.toLowerCase() === modalData.name.toLowerCase() && e.email.toLowerCase() === modalData.email.toLowerCase())}  )
    if(index > -1) {
      setIsEnabled(true)
      setUserId(users[index]['user_id'])
      console.log(users[index]['user_id']);
    } else {
      setIsEnabled(false)
    }
  },[modalData])

  const handleToggleExternalAccount = async () => {
    try {
      let body = {
          action: 'update_external_user',
          flagset: isEnabled ? false : true,
          name: modalData.name,
          email: modalData.email,
        };
      setVisible(false);
      setLoading(true);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('logins', result);
      if (result.data.success) {
        if(isEnabled){
           handleDeleteAccount(userId);
        } else {
          handleSave();
        }
        getAccounts();
      } else {
        Alert.alert(
          'errorMessage',
          result.data.errorMessage ??
            "We're sorry, there was a problem createing an account login."
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setVisible(false);
      setLoading(false);
    }
  }
  
  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={modalvisible}
      transparent={true}
      onDismiss={() => {
        setVisible(false);
      }}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => {
        setVisible(false);
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '100%',
            maxWidth: 767,
            borderRadius: 8,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: 30,
          }}>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <Icon
              name={'circle-with-cross'}
              type={'entypo'}
              onPress={() => setVisible(false)}
              color={AppColor.PrimaryColor}
            />
          </View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 20,
              color: AppColor.PrimaryColor,
              fontFamily: AppFonts.Roboto_Bold,
              marginTop: 5,
            }}>
              {isEnabled ? 'Deactivate External Account' : 'Activate External Account'}
          </Text>
          <View
            style={{
              alignSelf: 'center',
              width: '100%',
              marginBottom:10
            }}>
            <Text>
            {isEnabled ? 'Are you sure you want to disable this user? This change will be immediate and this user will be removed from this account.' : 'Are you sure you want to enable this user? This change will be immediate and this user will receive an email informing them that they have been added to this account.'}
            </Text>
          </View>
          <View style={{ height: 20 }} />
          <View style={{flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'space-evenly'}}>
          <CustomButton title={'Cancel'} onPress={() => setVisible(false)} width='40%' />
          <CustomButton title={'Confirm'} onPress={handleToggleExternalAccount} width='40%' />
          </View>

        </View>
      </View>
    </Modal>
  );
};





export default Accounts;
