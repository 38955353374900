import {
  Box,
  Header as HeaderN,
  Item,
  Button,
  Input,
  useToast,
} from 'native-base';
import React from 'react';
import { Icon } from 'react-native-elements';
import { Text, View, FlatList } from 'react-native';
import Alert from 'Alert';

import { Avatar } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import Loader from '../../Components/Loader';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';

// import
const SelectTemplates = ({ navigation, route }) => {
  const [contents, setContents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const { member } = route?.params;
  const Toast = useToast();

  const searchContext = async () => {
    if (search.trim().length < 3) {
      Toast.show({
        duration: 2000,
        title: 'Keyword must contain at least 3 characters',
        status: 'warning',
      });
      return;
    }
    try {
      setLoading(true);
      const data = {
        action: 'get_content_list',
        search: search,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = {
        'x-dart-token': token,
        'x-device': device,
        'Content-Type': 'application/json',
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: data,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        setContents(result.data.content);
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({ duration: 2000, title: ' Server Error', status: 'error' });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const askForSend = (item) => {
    Alert.alert('Confirm', 'Do you want to send this article?', [
      { text: 'No' },
      { text: 'Yes', onPress: () => sendContentItem(item) },
    ]);
  };

  const handlePreview = async (item) => {
    try {
      setLoading(true);
      const data = {
        action: 'get_content_item',
        provider: item.source,
        refId: item.refid,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: data,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        window.open(result.data.content.link, '_blank').focus();
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({ duration: 2000, title: ' Server Error', status: 'error' });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const sendContentItem = async (item) => {
    try {
      setLoading(true);
      const data = {
        action: 'send_content_item',
        provider: item.source,
        refId: item.refid,
        title: item.subject,
        memberId: member.id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: data,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Successfully sent!!',
          status: 'success',
        });
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else if (result.data.message) {
        Toast.show({
          duration: 2000,
          title: result.data.message,
          status: 'info',
        });
      } else {
        Toast.show({ duration: 2000, title: ' Server Error', status: 'error' });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const renderListView = (item) => {
    return (
      <View
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 10,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Avatar source={{ uri: item.thumbnail }} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              marginHorizontal: 10,
              color: 'black',
            }}
            numberOfLines={1}>
            {item.refid}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Icon
            name="eye"
            type={'antdesign'}
            style={{ color: AppColor.PrimaryColor, fontSize: 20 }}
            onPress={() => handlePreview(item)}
          />
          <Icon
            name="send"
            style={{
              color: AppColor.PrimaryColor,
              fontSize: 20,
              marginLeft: 5,
            }}
            onPress={() => {
              askForSend(item);
            }}
          />
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        title={'Send Content'}
        back={true}
      />
      <Box
        py={15}
        alignItems="center"
        flexDirection="row"
        style={{
          backgroundColor: AppColor.SecondaryColor,
          paddingHorizontal: 10,
        }}
        width="100%">
        <Box flex={1}>
          <Input
            style={{ flex: 1 }}
            value={search}
            onChangeText={(t) => setSearch(t)}
            placeholder="Search here"
          />
        </Box>
        <Box px={20}>
          <Icon name="ios-search" type="ionicon" onPress={searchContext} />
        </Box>
      </Box>
      <FlatList
        data={contents}
        scrollEnabled={false}
        key={(item, index) => item.refid + index}
        style={{
          marginTop: 20,
          marginHorizontal: 10,
        }}
        renderItem={({ item }) => {
          return renderListView(item);
        }}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default SelectTemplates;
