import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const addOnSlice = createSlice({
  name: 'add_ons',
  initialState: {
    addons: [],
    biling_details: [],
    hasPaymentMethod: false,
  },
  reducers: {
    setAddOns: (state, action) => {
      return {
        ...state,
        addons: action.payload.addons,
      };
    },
    setBillingDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setAddOns, setBillingDetails } = addOnSlice.actions;

export const loadGroupAddOnsThuunk = () => {
  return async (dispatch, state) => {
    if (state().User?.profile?.tenant?.affiliate) {
      try {
        const body = {
          action: 'get_group_addons',
          affiliate: state().User?.profile?.tenant?.affiliate,
        };
        let token = await getData('token');
        let device = await getData('@device_id');
        const headers = { 'x-dart-token': token, 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('addons', result);
        if (result.data.success) {
          dispatch(setAddOns({ addons: result.data.addons }));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
};
export const loadBillingDetailsThuunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_billing_detail',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(
          setBillingDetails({
            biling_details: result.data.detail,
            hasPaymentMethod: result.data.hasPaymentMethod,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default addOnSlice.reducer;
