import React from 'react';
import { Text, TouchableOpacity, FlatList, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import AntDesign from 'react-native-vector-icons/AntDesign';

const DistributionLists = ({ navigation }) => {
  const contactData = useSelector((state) => state.distributions).data;
  const [search, setSearch] = React.useState('');
  const [allContactData, setAllContactData] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter((x) =>
        x.text.toLowerCase().includes(search.trim().toLowerCase())
      );
      setAllContactData(searchedData);
    } else {
      setAllContactData(contactData);
    }
  }, [search]);

  React.useEffect(() => {
    setAllContactData(contactData);
  }, [contactData]);

  const renderListView = ({ item }) => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity
        accessibilityRole='button'
          onPress={() => {
            navigation.navigate('ContactEdit', { dl: item, item: null });
          }}
          style={{
            width: '80%',
            flexDirection: 'row',
            height: 60,
            marginBottom: 10,
            alignItems: 'center',
            marginLeft: 20,
          }}>
          <AntDesign name="tags" size={40} color={AppColor.PrimaryColor} />
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
              lineHeight: 60,
            }}>
            {item.text}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Distribution List'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <Search onChangeText={(t) => setSearch(t)} />
      <FlatList
        data={allContactData}
        keyExtractor={(item, index) => item.id + '' + index}
        renderItem={renderListView}
      />
    </SafeAreaView>
  );
};
export default DistributionLists;
