import React, { useState } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Image,
  StyleSheet,
} from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Alert from 'Alert';
import { useSelector } from 'react-redux';
import Modal from 'modal-react-native-web';

import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import EndPoints from '../../../Constants/Api';
import CustomButton from '../../../Components/Button';

import { providers, integrations_types } from '../../IntegrationProvider';

const Archive = ({ navigation }) => {
  const [isGlobalModalVisible, setGlobalModalVisible] = useState(false);
  const [isMicrofocusVisible, setMicrofocusVisible] = useState(false);

  const archives = useSelector((state) => state.integrations).integrations;
  const integration_providers = useSelector(
    (state) => state.integrations.providers
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Connect to ARCHIVE'}
      />
      <ScrollView>
        <View
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: 10,
          }}>
          {Object.entries(providers)
            .filter(
              (x) =>
                x[1].type.name == integrations_types.ARCHIVE.name &&
                integration_providers.includes(x[1].name)
            )
            .map((i, index) => {
              let b_color = AppColor.Dark;
              let b_w = 1;
              if (archives.find((x) => x.provider == i[1].name)) {
                b_color = AppColor.PrimaryColor;
                b_w = 3;
              }

              if (i[1].name === 'globalrelay') {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setGlobalModalVisible(true);
                    }}
                    style={{
                      alignItems: 'center',
                      borderWidth: b_w,
                      marginVertical: 5,
                      borderColor: b_color,
                      width: 200,
                      height: 150,
                      padding: 15,
                    }}
                    key={`archive${index}`}>
                    <img
                      style={{
                        height: 45,
                        width: 160,
                        margin: '0px 10px',
                        objectFit:'contain'
                      }}
                      alt={`${i[1].name} logo`}
                      src={EndPoints.Base_url + i[1].fullImageUrlPath}
                    />
                  </TouchableOpacity>
                );
              }
              if (i[1].name === 'microfocus') {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setMicrofocusVisible(true);
                    }}
                    style={{
                      alignItems: 'center',
                      borderWidth: b_w,
                      marginVertical: 5,
                      borderColor: b_color,
                      width: 200,
                      height: 150,
                      padding: 15,
                    }}
                    key={`archive${index}`}>
                    <img
                      style={{
                        height: 45,
                        width: 160,
                        margin: '0px 10px',
                        objectFit:'contain'
                      }}
                      src={ EndPoints.Base_url + i[1].fullImageUrlPath}
                      alt={`${i[1].name} logo.`}
                    />
                  </TouchableOpacity>
                );
              }

              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('Integrations', {
                      provider: i[1],
                      type: 'archive',
                    });
                  }}
                  style={{
                    alignItems: 'center',
                    borderWidth: b_w,
                    marginVertical: 5,
                    borderColor: b_color,
                    width: 200,
                    height: 150,
                    padding: 15,
                  }}
                  key={`archive${index}`}>
                  <img
                    style={{
                      height: 45,
                      width: 160,
                      margin: '0px 10px',
                      objectFit:'contain'
                    }}
                    alt={i[1].name}
                    src={ EndPoints.Base_url + i[1].fullImageUrlPath }
                  />
                </TouchableOpacity>
              );
            })}
        </View>
      </ScrollView>
      <ShowConfirmModal
        visible={isGlobalModalVisible || isMicrofocusVisible}
        isGlobalModalVisible={isGlobalModalVisible}
        isMicrofocusVisible={isMicrofocusVisible}
        setVisible={() => {
          setGlobalModalVisible(false);
          setMicrofocusVisible(false);
        }}
      />
    </SafeAreaView>
  );
};

const ShowConfirmModal = ({
  visible,
  setVisible,
  isGlobalModalVisible,
  isMicrofocusVisible,
}) => {
  let label = 'MicroFocus';
  if (isGlobalModalVisible) {
    label = 'Global Relay.';
  }
  console.log(12312, visible);
  return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      visible={visible}
      transparent={true}
      onDismiss={() => {
        setVisible(false);
      }}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <Text style={styles.description}>
            MyRepChat can send your data to {label} for archival. In order to
            set this up you need to contact your group administrator and ask
            them to configure your group for archiving to {label}.
          </Text>
          <CustomButton title="Ok" onPress={() => setVisible(false)} />
        </View>
      </View>
    </Modal>
  );
};
export default Archive;

const styles = StyleSheet.create({
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: '1.5em',
    marginVertical: 5,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    maxWidth: 767,
    width: '100%',
  },
});
