import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Linking,
  Platform,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useFocusEffect } from '@react-navigation/native';
import {
  loadAllBookMarksThunk,
  deleteBookmarkThunk,
} from '../../Redux/bookmarks';
import { useDispatch, useSelector } from 'react-redux';

const Bookmarks = ({ navigation }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bookmarks).data;
  const tenant = useSelector((state) => state.User)?.profile?.tenant;

  useFocusEffect(
    React.useCallback(() => {
      dispatch(loadAllBookMarksThunk());
    }, [])
  );

  React.useEffect(()=>{
    dispatch(loadAllBookMarksThunk())
  },[tenant])



  const handleDelete = (id) => {
    dispatch(deleteBookmarkThunk(id));
  };

  const renderListView = (item) => {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate('AddBookmarks', { item: item })}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            type={'antdesign'}
            name={'book'}
            color={AppColor.PrimaryColor}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 17,
              marginHorizontal: 10,
              color: 'black',
            }}>
            {item.label}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              lineHeight: 20,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.url}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => handleDelete(item.id)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
          <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              name={'arrowright'}
              type={'antdesign'}
              color={AppColor.Dark}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Add/Edit Bookmark'}
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        
      />
      <View style={{ flex: 1, padding: 30 }}>
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 14,
              lineHeight: 18,
              fontFamily: AppFonts.Roboto_Regular,
              width: '80%',
              color: AppColor.Dark,
            }}>
            Bookmarks are links that you can use for your own quick reference or
            to send to your contacts.
          </Text>
        </View>
        <FlatList
          data={data}
          scrollEnabled={false}
          key={(item) => item.name}
          style={{}}
          renderItem={(item) => {
            return renderListView(item.item);
          }}
        />
      </View>
      <View style={{ alignItems: 'center' }}>
        <TouchableOpacity
          onPress={() => navigation.navigate('AddBookmarks', {item:null})}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <Icon
            color={AppColor.PrimaryColor}
            name={'add-circle-outline'}
            type={'ionicon'}
          />
          <Text
            style={{
              fontSize: 24,
              marginHorizontal: 5,
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.PrimaryColor,
            }}>
            Create Bookmark
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};
export default Bookmarks;
