import { createSlice, configureStore } from '@reduxjs/toolkit';
// import Reactotron from '../../ReactotronConfig'

import clearAll from '../AsyncUtils/ClearAll';

const User = createSlice({
  name: 'User',
  initialState: {
    Token: null,
    profile: null,
    authenticated: false,
  },
  reducers: {
    setToken(state, action) {
      const Token = action.payload.token;
      const authenticated = action.payload.authenticated;
      return { ...state, Token, authenticated };
    },
    setProfile(state, action) {
      const profile = { ...state.profile, ...action.payload };
      return { ...state, profile };
    },
    clearProfile(state, action) {
      return { ...state, Token: null, authenticated: false, profile: null };
    },
  },
});
export const { setToken, clearProfile, setProfile } = User.actions;
export const logOutThunk = () => {
  return (dispatch) => {
    clearAll();
    dispatch(setToken({ token: null, authenticated: false }));
  };
};

// reducers
import RecentMessageReducer from './recent_message';
import ContactReducer from './contacts';
import RecentMediaReducer from './recent_media';
import SavedMessageReducer from './saved_messages';
import AccountStatReducer from './account_stat';
import AccountHelathReducer from './account_health';
import DistributionsReducer from './distributions';
import VCardReducer from './vcard';
import BookMarksReducer from './bookmarks';
import AutoResponseReducer from './auto_response';
import ScheduledMessageReducer from './scheduled_messages';
import SelectedContactReducer from './selected_contact';
import DuplicateMemberReducer from './duplicate_members';
import IntegrationReducer from './integrations';
import AddOnReducer from './add_ons';
import IntegrationDataReducer from './integrationData';
import SignUpdataReducer from './signupdata';
import ReceivedNewMessageReducer from './recevied_message';
import AccountActivityReducer from './account_active';
import SignupWizardReducer from './singupwizard';

const store = configureStore({
  reducer: {
    User: User.reducer,
    recent_messages: RecentMessageReducer,
    contacts: ContactReducer,
    recent_media: RecentMediaReducer,
    saved_messages: SavedMessageReducer,
    account_stat: AccountStatReducer,
    account_health: AccountHelathReducer,
    distributions: DistributionsReducer,
    vcard: VCardReducer,
    bookmarks: BookMarksReducer,
    auto_responses: AutoResponseReducer,
    scheduled_messages: ScheduledMessageReducer,
    selected_contact: SelectedContactReducer,
    duplicate_members: DuplicateMemberReducer,
    integrations: IntegrationReducer,
    add_ons: AddOnReducer,
    integrationData: IntegrationDataReducer,
    signupdata: SignUpdataReducer,
    receivedNewMessage: ReceivedNewMessageReducer,
    accountActivity: AccountActivityReducer,
    signupWizard: SignupWizardReducer,
  },
  // enhancers:[Reactotron.createEnhancer()]
});

export default store;
