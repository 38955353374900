import React, { useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  TouchableOpacity,
  FlatList,
  Linking,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'modal-react-native-web';
import { useToast, Select } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import Alert from 'Alert';

import CustomButton from '../../Components/Button';
import CustomInput from '../../Components/CustomInput';
import Loader from '../../Components/Loader';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api/index';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import { loadAccountHealthThunk } from '../../Redux/account_health';
import AppColor from '../../Constants/Color';

const fields = [
  {
    id: 'id',
    label: 'Id',
    defaults: [
      'complete',
      'new',
      'expiring',
      'newsletter_mail',
      'newsletter_email',
    ],
    hint: 'number',
  },
  { id: 'fname', label: 'First Name', defaults: ['all'], hint: '64 max' },
  { id: 'lname', label: 'Last Name', defaults: ['all'], hint: '64 max' },
  {
    id: 'address',
    label: 'Address',
    defaults: ['complete', 'new', 'expiring', 'newsletter_mail'],
    hint: '128 max',
  },
  {
    id: 'city',
    label: 'City',
    defaults: ['complete', 'new', 'expiring', 'newsletter_mail'],
    hint: '64 max',
  },
  {
    id: 'state',
    label: 'State',
    defaults: ['complete', 'new', 'expiring', 'newsletter_mail'],
    hint: '32 max',
  },
  {
    id: 'zip',
    label: 'Zip Code',
    defaults: ['complete', 'new', 'expiring', 'newsletter_mail'],
    hint: '16 max',
  },
  { id: 'blocked', label: 'Blocked', defaults: [], hint: '24 max' },
  { id: 'messaging', label: 'Messaging Number', defaults: [], hint: '24 max' },
  { id: 'consented', label: 'Consented', defaults: [], hint: '24 max' },
  { id: 'mobile', label: 'Mobile', defaults: [], hint: '24 max' },
  { id: 'home', label: 'Home', defaults: [], hint: '24 max' },
  { id: 'office', label: 'Office', defaults: [], hint: '24 max' },
  { id: 'fax', label: 'Fax', defaults: [], hint: '24 max' },
  { id: 'other', label: 'Other', defaults: [], hint: '24 max' },
  {
    id: 'email_address',
    label: 'Email',
    defaults: ['newsletter_email'],
    hint: '128 max',
  },
  { id: 'join_date', label: 'Join Date', defaults: ['new'], hint: 'date' },
  {
    id: 'exp_date',
    label: 'Expiration Date',
    defaults: ['expiring', 'newsletter_mail', 'newsletter_email'],
    hint: 'date',
  },
  { id: 'email_newsletter', label: 'Email Newsletter', hint: 't/f' },
  { id: 'mail_newsletter', label: 'Mail Newsletter', hint: 't/f' },
  { id: 'list_in_directory', label: 'List in Newsletter', hint: 't/f' },
  { id: 'tags', label: 'Tags', defaults: ['complete'], hint: 'tag|tag' },
  { id: 'source', label: 'Source', hint: 'redtail' },
  { id: 'sourceId', label: 'Source Id', hint: 'ABC123' },
];

const ContactsModal = ({ visible, setVisible }) => {
  const [selectedType, setSelectedType] = useState('complete');
  const [selectedFields, setSelectedFields] = useState([]);
  const tenant = useSelector((state) => state.User.profile)?.tenant;

  React.useEffect(()=>{
    if(selectedType){
      switch(selectedType){
        case "complete":
          setSelectedFields(['id','fname','lname','address','city','state','zip','tags']);
          break;
        case "new":
          setSelectedFields(['id','fname','lname','address','city','state','zip','join_date']);
          break;
        case "expiring":
          setSelectedFields(['id','fname','lname','address','city','state','zip','exp_date']);
          break;
        case "newsletter":
          setSelectedFields(['fname','lname']);
          break;
        case "newsletter_mail":
          setSelectedFields(['id','fname','lname','address','city','state','zip','exp_date']);
          break;
        case "newsletter_email":
          setSelectedFields(['id','fname','email_address','exp_date']);
          break;
      }
    }
  },[selectedType])

  const toggleItem = (item) => {
    const _selectedFields = [...selectedFields];
    const index = selectedFields.indexOf(item.id);
    if (index > -1) {
      _selectedFields.splice(index, 1);
    } else {
      _selectedFields.push(item.id);
    }
    setSelectedFields(_selectedFields);
  };

  const handleExport = async () => {
    let token = await getData('token');
    if (!selectedType) {
      Alert.alert('Error', 'Please select type');
      return;
    }
    setVisible(false);
    const url = `${End_Points.Base_url}${
      End_Points.End
    }?action=get_all_members&type=${selectedType}&format=csv&columns=${selectedFields.join()}&tenantUUID=${
      tenant.uuid
    }&token=${token}`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        Alert.alert('Error', "Don't know how to open URI");
        console.log("Don't know how to open URI: " + url);
      }
    });
  };

  const renderItem = ({ item }) => {
    const index = selectedFields.indexOf(item.id);
    return (
      <TouchableOpacity onPress={() => toggleItem(item)} key={item.id}>
        <View style={index > -1 ? styles.selectedField : styles.field}>
          <Text style={index > -1 ? styles.selectedText : styles.text}>{item.label}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      onDismiss={() => {
        setVisible(false);
      }}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => {
        setVisible(false);
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <TouchableOpacity onPress={() => setVisible(false)}>
              <EvilIcons name="close" size={30} />
            </TouchableOpacity>
          </View>
          <Text style={styles.description}>
            Select the type of contacts you want in the list.
          </Text>
          <Select
            style={styles.picker}
            selectedValue={selectedType}
            onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
            placeholder="Active">
            <Select.Item value="complete" label="All Active" />
            <Select.Item value="new" label="New Since Last Month" />
            <Select.Item
              value="expiring"
              label="Expiring After the First of Last Month"
            />
            <Select.Item value="newsletter" label="List for Newsletter" />
            <Select.Item value="newsletter_mail" label="Newsletter Mailing" />
            <Select.Item
              value="newsletter_email"
              label="Newsletter E-Mailing"
            />
          </Select>
          <FlatList
            data={fields}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedFields}
            style={styles.flatlist}
          />
          <Text style={styles.description}>
            Select the fields that you want to to appear in the export.
          </Text>
          <View style={styles.button}>
            <CustomButton title="Ok" onPress={handleExport} />
          </View>
          <View style={styles.button}>
            <CustomButton title="Cancel" onPress={() => setVisible(false)} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ContactsModal;

const styles = StyleSheet.create({
  button: {
    marginTop: 15,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
  },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 5,
  },
  flatlist: {
    height: 300,
    marginVertical: 30,
  },
  field: {
    borderRadius: 4,
    borderWidth: 1,
    marginVertical: 3,
    paddingVertical: 5,
    paddingLeft: 10,
  },
  selectedField: {
    backgroundColor: AppColor.SuccessColor,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: AppColor.SuccessColor,
    marginVertical: 3,
    paddingVertical: 5,
    paddingLeft: 10,
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
    color: AppColor.BlackColor,
  },
  selectedText: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
    color: AppColor.WHITE400,
  }
});
