import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { useToast, Select } from 'native-base';
import Alert from 'Alert';

import Header from '../../../Components/Header';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import { setProfile } from '../../../Redux';

const Timezone = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [timezones, setTimezones] = useState([]);
  const [timezone, setTimezone] = useState();
  const tenant = useSelector((state) => state.User?.profile)?.tenant;
  const Toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetTimezones();
    handleGetCountries();
  }, []);

  useEffect(() => {
    if (!tenant) return;
    setTimezone(tenant.timezone);
    setCountry(tenant.isoCountry);
  }, [tenant]);

  const handleSave = async () => {
    if (!country || !timezone) {
      Alert.alert('Error', 'Please select country and timezone');
      return;
    }
    try {
      let body = {
        action: 'update_tenant_locale',
        isoCountry: country,
        timezone: timezone,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Timezone information updated',
          status: 'success',
        });
        console.log(data)
        dispatch(
          setProfile({
            tenant: {
              ...tenant,
              timezone: timezone,
              isoCountry: country,
            },
          })
        );
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCountries = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_countries',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        setCountries(data.countries);
      } else {
        Alert.alert('Error', data.errorMessage);
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTimezones = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_timezones',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        setTimezones(data.timezones);
      } else {
        Alert.alert('Error', data.errorMessage);
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          onPress={() => navigation.goBack()}
          back={true}
          title={'Timezone'}
        />
        <View style={styles.content}>
          <View style={styles.body}>
            <View style={styles.forms}>
              <Text style={styles.comment}>
                You can update your timezone below
              </Text>
              <View style={styles.item}>
                <Text style={styles.formLabel}>Country</Text>
                <Select
                  variant="outline"
                  selectedValue={country}
                  maxWidth="100%"
                  minWidth="200"
                  placeholder="Choose Country"
                  mt={1}
                  flex={1}
                  borderColor="#000000"
                  onValueChange={(itemValue) => setCountry(itemValue)}>
                  {countries.map((country) => (
                    <Select.Item
                      label={country.label}
                      value={country.isoCode}
                      key={country.isoCode}
                    />
                  ))}
                </Select>
              </View>
              <View style={styles.item}>
                <Text style={styles.formLabel}>Timezone</Text>
                <Select
                  variant="outline"
                  selectedValue={timezone}
                  minWidth="200"
                  maxWidth="100%"
                  placeholder="Choose Timezone"
                  mt={1}
                  flex={1}
                  borderColor="#000000"
                  onValueChange={(itemValue) => setTimezone(itemValue)}>
                  {timezones.map((timezone) => (
                    <Select.Item
                      label={timezone.label}
                      value={timezone.id}
                      key={timezone.id}
                    />
                  ))}
                </Select>
              </View>
              <View style={styles.button}>
                <CustomButton title="save" onPress={handleSave} />
              </View>
            </View>
          </View>
        </View>

        {loading && <Loader />}
      </SafeAreaView>
    </>
  );
};

export default Timezone;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Regular,
  },
  header: {
    alignSelf: 'center',
  },
  body: {
    width: '100%',
    maxWidth: 768,
    marginTop: 20,
    padding: 20,
  },
  comment: {
    fontSize: 20,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
  forms: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#d5e2f5',
    padding: 20,
    marginTop: 20,
  },
  item: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginVertical: 15,
  },
  formLabel: {
    color: '#2666b4',
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Regular,
    marginRight: 50,
    width: 100,
  },
  button: {
    marginTop: 30,
  },
});
