import React from 'react';
import { TouchableOpacity, Image, StyleSheet, Text, View  } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import AppColor from '../../Constants/Color';

export const ChannelButton = props => {
    const [hover, setHover] = React.useState(false);

    const getStatusColor = () => {
        switch(props.status){
            case "available":
                return AppColor.PrimaryColor;
            case "inameeting":
                return 'orange';
            case "away":
                return 'orange';
            case "donotdisturb":
                return 'red';
        }
    }

    return(
        <TouchableOpacity
            onPress={props.onPress}
            style={[styles.container, {backgroundColor: (hover || props.channel.id === props.id) ? '#f5f5f5' : 'transparent'}]}
            onMouseEnter={()=>{setHover(true)}}
            onMouseLeave={()=>{setHover(false)}}
        >
            <View style={{flexDirection:'row',alignItems:'center'}}>
            {
            props.avatar ?
            <img
            style={{height:40,
                width:40, 
                borderRadius:100,
                margin:5,
                objectFit:'cover'}}
            src={props.avatar}
            alt="Channel Avatar."
            />
            :
            props.status ?
            <View style={{height:15,width:15,margin:17, borderRadius:100, backgroundColor: getStatusColor() }}></View>
            :
            <Text style={{height:15,width:15,margin:17,textAlign:'center'}}>{props.memberCount}</Text>
            }
            {
            (props.unread > 0 && props.id !== props.channel.id) &&
            <View style={{left:-20,top:-20,backgroundColor:'red',height:20,width:20,borderRadius:100,alignItems:'center',justifyContent:'center'}}>
                <Text style={{color:'white'}}>{props.unread}</Text>
            </View>
            }
            <Text style={styles.text}> {props.name} </Text>
            </View>
            {
            hover &&
            <Entypo 
            name='pencil'
            color='rgba(0,0,0,.5)'
            size={24}
            onPress={props.onEditPress}
            />
            }

        </TouchableOpacity>

    )
}

const styles = StyleSheet.create({
    container: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        margin:5,
    },
    image: {
        height:40,
        width:40, 
        borderRadius:100,
        margin:5,
    },
    text: {
        fontSize:16
    }
})