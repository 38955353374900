import React from 'react';
import Alert from 'Alert';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import TimeZone from 'react-native-timezone';
import { useToast } from 'native-base';
import { useSelector } from 'react-redux';
let ICustomTimezone = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana, Pacific Time',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};

const Calculators = ({ navigation }) => {
  const [timezone, setTimeZone] = React.useState(null);
  const getTimeZone = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  };
  const Toast = useToast();
  React.useEffect(() => {
    getTimeZone();
  }, []);
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;

  const data = [
    {
      name: 'Support Articles',
      onPress: () =>
        window.open('https://ionlake.zendesk.com/hc/en-us', '_blank').focus(),
    },
    {
      name: 'Training Videos',
      onPress: () => {
        navigation.navigate('TrainingVideos');
      },
    },
    {
      name: 'MyRepChat Minute',
      onPress: () =>
        window
          .open('https://www.youtube.com/c/MyRepChatMinute/videos', '_blank')
          .focus(),
    },
    {
      name: 'System Status',
      onPress: () =>
        window
          .open('https://dart.ionlake.com/dart/web/status.html', '_blank')
          .focus(),
    },
    {
      name: 'Notification',
      sub: [
        {
          name: 'Browser Notifications Status',
          onPress: () => {
            navigation.navigate('NotificationStatus');
          },
        },
        {
          name: 'Browser Notifications Test',
          onPress: () => {
            navigation.navigate('NotificationTest');
          },
        },
        {
          name: 'Mobile Notifications',
          onPress: () => {
            navigation.navigate('MobileNotifications');
          },
        },
      ],
    },
    {
      name: 'Terms of use',
      onPress: () => {
        navigation.navigate('TermOfUse');
      },
    },
    {
      name: 'Contact Us',
      onPress: () => {
        navigation.navigate('ContactUs');
      },
    },
    {
      name: 'Accessibility',
      onPress: () => {
        navigation.navigate('Accessibility')
      }
    }
  ];
  const renderListView = ( item ) => {
    return (
      <TouchableOpacity
        style={{
          marginVertical: 5,
        }}
        onPress={item.onPress && item.onPress}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Bold,
            fontSize: 16,
          }}>
          {item.name}
        </Text>
        {item.sub &&
          item.sub.map((v, index) => (
            <TouchableOpacity
              style={{
                marginVertical: 5,
              }}
              onPress={v.onPress}
              key={`sub_${index}`}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 14,
                  paddingLeft: 15,
                }}>
                {v.name}
              </Text>
            </TouchableOpacity>
          ))}
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Support'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View style={{ flex: 1, padding: 30 }}>

        <View style={{ width: '100%', alignSelf: 'center' }}>
          {
            data.map(e => {
              if(e.name === 'Support Articles' && affiliate?.show_articals === false) return null;
              if(e.name === 'Training Videos' && affiliate?.show_videos === false) return null;
              if(e.name === 'MyRepChat Minute' && affiliate?.show_myrepchatMinute === false) return null;
              if(e.name === 'System Status' && affiliate?.show_systemstatus === false) return null;

              if(e.name === 'Notification' && affiliate?.show_notificationTests === false) return null;
              if(e.name === 'Terms of use' && affiliate?.show_tos === false) return null;
              if(e.name === 'Contact Us' && affiliate?.show_contactUs === false) return null;
              if(e.name === 'Accessibility' && affiliate?.show_accessibility === false) return null;

              return(
                renderListView(e)
              )
            })
          }
        </View>
      </View>
    </SafeAreaView>
  );
};
export default Calculators;
