import React, { useState } from 'react';
import {
  FlatList,
  ScrollView,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Alert from 'Alert';
import * as DocumentPicker from 'expo-document-picker';

import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector } from 'react-redux';
import { loadRecentMediaThunk } from '../../Redux/recent_media';
import { useDispatch } from 'react-redux';
import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api/index';
import Loader from '../../Components/Loader';
import uuid from 'react-native-uuid';

// import RNFS from 'react-native-fs';
import FileViewer from 'react-native-file-viewer';
import { useToast } from 'native-base';
import { Icon } from 'react-native-elements';

const moment = require('moment');

const RecentMedia = ({ navigation, route }) => {
  const Toast = useToast();
  const [Message, setMessages] = useState('');
  const dispatch = useDispatch();
  const recent_media = useSelector((state) => state.recent_media).data;
  const token = useSelector((state) => state.User.Token);
  const { member, dl } = route.params ?? {};
  const [loading, setLoading] = React.useState(false);
  const tenant = useSelector((state) => state.User?.profile?.tenant);

  React.useEffect(() => {
    dispatch(loadRecentMediaThunk());
  }, []);

  const handleSendMedia = async (mediaId) => {
    setLoading(true);
    try {
      const data = {
        action: 'send_text_message',
        type: 'mms',
        text: '',
        mediaId: mediaId,
      };
      if (dl == null) {
        data['members'] = [member.id];
      } else {
        data['distributionLists'] = [{ text: dl.text }];
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: data,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status == 400) {
        if (tenant.status == 3) {
          Alert.alert(
            'Server Error',
            'You are not able to send messages while your account is in review state.',
            'Ok',
            null
          );
        } else {
          Alert.alert(
            'Server Error',
            'You are not able to send messages at this time.'
          );
        }
      }
      if (result.data.success) {

        Alert.alert('Successfully sent', 'MMS message has been sent', [
          {
            text: 'OK',
            onPress: () => navigation.navigate('Messages', {memberData: member}),
          },
        ]);
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: ' We were unable to send your message',
          status: 'info',
        });
      }
      // setTimeout(() => {

      // }, 2000)
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const openDocument = (url, type) => {
    //   // Feel free to change main path according to your requirements.
    //   // IMPORTANT: A file extension is always required on iOS.
    //   // You might encounter issues if the file extension isn't included
    //   // or if the extension doesn't match the mime type of the file.
    // setLoading(true)
    window.open(url, '_blank').focus();
    // const localFile = `${RNFS.DocumentDirectoryPath}/temp.${type}`;
    // const options = {
    //     fromUrl: url,
    //     toFile: localFile
    // };
    // RNFS.downloadFile(options).promise
    //     .then(() => {
    //         setLoading(false)
    //         FileViewer.open(localFile)
    //     })
    //     .then(() => {
    //         setLoading(false)
    //     })
    //     .catch(error => {
    //         setLoading(false)
    //     });
  };

  const AlertAskPermission = async (mediaId, url = '', type = '') => {
    Alert.alert('Confirm', 'Are you sure want to send this media?', [
      {
        text: 'OK',
        onPress: () => handleSendMedia(mediaId),
      },
      {
        text: 'Preview',
        onPress: () => openDocument(url, type),
      },

      {
        text: 'Cancel',
        onPress: () => null,
      },
    ]);
  };

  const handleDocumentPicker = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: '*/*',
      });
      if (res.type == 'success') {
        await handleUpladMedia(res);
      }
    } catch (err) {}
  };

  const handleUpladMedia = async (data) => {
    console.log('data', data);
    setLoading(true);
    // handleSendMedia(mediaId)

    try {
      let b = await fetch(data.uri);
      let file = await b.blob();
      const data1 = new FormData();
      data1.append('upfile', file, data.file.name);
      console.log(data1);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = {
        'x-dart-token': token,
        'x-device': device,
        'content-type': 'multipart/form-data',
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + 'dart/upload',
        data: data1,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Alert.alert('Upload', 'Successfully Uploaded!!!');
        dispatch(loadRecentMediaThunk());
      } else {
        Alert.alert('Fail to Send Message', result.data.failures.join(','));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderItem = ({ item }) => {
    const type = item.contentType.split('/')[0];
    switch (type) {
      case 'image':
        return (
          <TouchableOpacity
            onPress={() => {
              AlertAskPermission(
                item.id,
                item.url + `?token=${token}`,
                item.contentType.split('/')[1]
              );
            }}
            style={{
              height: 150,
              width: 150,
              borderWidth: 1,
              marginLeft: 10,
              marginTop: 10,
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: AppColor.PrimaryColor,
            }}>
            <Image
              style={{ width: 140, height: 140 }}
              resizeMode="contain"
              source={{ uri: item.url + `?token=${token}` }}
            />
          </TouchableOpacity>
        );
      case 'audio':
        return (
          <TouchableOpacity
            onPress={() => {
              AlertAskPermission(
                item.id,
                item.url + `?token=${token}`,
                item.contentType.split('/')[1]
              );
            }}
            style={{
              height: 150,
              width: 150,
              borderWidth: 1,
              marginLeft: 10,
              marginTop: 10,
              borderColor: AppColor.PrimaryColor,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Entypo
              name="controller-play"
              size={60}
              color={AppColor.PrimaryColor}
            />
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 10,
                textAlign: 'center',
                alignSelf: 'center',
              }}>
              {item.name}
            </Text>
          </TouchableOpacity>
        );
      case 'video':
        return (
          <TouchableOpacity
            onPress={() => {
              AlertAskPermission(
                item.id,
                item.url + `?token=${token}`,
                item.contentType.split('/')[1]
              );
            }}
            style={{
              height: 150,
              width: 150,
              borderWidth: 1,
              marginLeft: 10,
              marginTop: 10,
              borderColor: AppColor.PrimaryColor,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Entypo
              name="controller-play"
              size={60}
              color={AppColor.PrimaryColor}
            />
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 10,
                textAlign: 'center',
              }}>
              {item.name}
            </Text>
          </TouchableOpacity>
        );
      default:
        return (
          <TouchableOpacity
            style={{
              height: 150,
              width: 150,
              borderWidth: 1,
              marginLeft: 10,
              marginTop: 10,
              borderColor: AppColor.PrimaryColor,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              AlertAskPermission(
                item.id,
                item.url + `?token=${token}`,
                item.contentType.split('/')[1]
              );
            }}>
            <AntDesign name="file1" color={AppColor.PrimaryColor} size={30} />
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 10,
                textAlign: 'center',
              }}>
              {item.name}
            </Text>
          </TouchableOpacity>
        );
    }
  };

  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
        <Header
          back={true}
          title={'Recent Media'}
          onPress={() => {
            navigation.goBack();
          }}
          chatIcon={
            <Icon
              onPress={handleDocumentPicker}
              color={'white'}
              name="pluscircle"
              type="antdesign"
            />
          }
        />
        <ScrollView>
          <View
            style={{
              backgroundColor: AppColor.SecondaryColor,
              paddingVertical: 20,
            }}>
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
                marginLeft: 10,
              }}>
              Photos
            </Text>
            <FlatList
              data={recent_media.filter(
                (item) => item.contentType.split('/')[0] == 'image'
              )}
              horizontal
              renderItem={renderItem}
            />
          </View>
          <View
            style={{
              backgroundColor: AppColor.SecondaryColor,
              paddingVertical: 20,
            }}>
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
                marginLeft: 10,
              }}>
              Audios
            </Text>
            <FlatList
              data={recent_media.filter(
                (item) => item.contentType.split('/')[0] == 'audio'
              )}
              horizontal
              renderItem={renderItem}
            />
          </View>
          <View
            style={{
              backgroundColor: AppColor.SecondaryColor,
              paddingVertical: 20,
            }}>
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
                marginLeft: 10,
              }}>
              Videos
            </Text>
            <FlatList
              data={recent_media.filter(
                (item) => item.contentType.split('/')[0] == 'video'
              )}
              horizontal
              renderItem={renderItem}
            />
          </View>
          <View
            style={{
              backgroundColor: AppColor.SecondaryColor,
              paddingVertical: 20,
            }}>
            <Text
              style={{
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 16,
                marginLeft: 10,
              }}>
              Files
            </Text>
            <FlatList
              data={recent_media.filter(
                (item) =>
                  item.contentType.split('/')[0] != 'image' &&
                  item.contentType.split('/')[0] != 'audio' &&
                  item.contentType.split('/')[0] != 'video'
              )}
              horizontal
              renderItem={renderItem}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};
export default RecentMedia;
