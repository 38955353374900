import { useState, useEffect } from "react";
import store from "../Redux";


function useStoreState(selector) {
    const [selectedState, setSelectedState] = useState(selector(store.getState()));
  
    useEffect(() => {
      const unsubscribe = store.subscribe(() => {
        setSelectedState(selector(store.getState()));
      });
  
      // Unsubscribe when the component unmounts
      return unsubscribe;
    }, [selector]);
  
    return selectedState;
}

export default useStoreState;
  