import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import Alert from 'Alert';
import { Select, useToast } from 'native-base';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import SelectMobileModal from './SelectMobile';
import SucessSelectMobile from './SuccessSelectMobile';
import AppFonts from '../../../Constants/Fonts';
import { setCompletedStep } from '../../../Redux/singupwizard';

const SetupWizardChooseNum = ({ goBack, navigation }) => {
  const [areaCode, setAreaCode] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const tenant = useSelector((state) => state.User?.profile)?.tenant;
  const dispatch = useDispatch();
  const Toast = useToast();

  const handleSearch = async () => {
    if (areaCode.length !== 3) {
      Alert.alert('Invalid area code', 'The area code must be 3 digits.');
      return;
    }
    setLoading(true);
    try {
      let body = {
        action: 'get_numbers',
        areaCode: areaCode,
        isoCountry: tenant.isoCountry,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if (result.data.numbers.length > 0) {
          setNumbers(result.data.numbers);
          setSelectedNumber(result.data.numbers[0]?.endpoint);
        } else {
          Toast.show({
            duration: 2000,
            title: "There's currently no inventory available in that area. Please check another area code or contact the Support team for further assistance.",
            status: 'info',
          });
        }
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const handleSetNumber = async () => {
    setLoading(true);
    setVisible(false);
    try {
      let body = {
        action: 'reserve_number',
        endpoint: selectedNumber,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        setSuccessVisible(true);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderLabel = (number) => {
    if (number.locality) {
      return number.number + '(' + number.locality + ')';
    }
    return number.number;
  };

  const handleContinue = () => {
    handleSetNumber();
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.comment}>
            To choose a new number to use for MyRepChat you will need to search
            for numbers that are in your area code, or an area code near you.
          </Text>
          <CustomInput
            title="Type in an area code to begin"
            placeholder="Area Code*"
            keyboardType="phone-pad"
            value={areaCode}
            onChangeText={(val) => setAreaCode(val)}
            maxLength={3}
          />
          {numbers.length > 0 && (
            <Select
              style={styles.picker}
              selectedValue={selectedNumber}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedNumber(itemValue);
                setVisible(true);
              }}>
              {numbers.map((number) => (
                <Select.Item
                  label={renderLabel(number)}
                  value={number.endpoint}
                />
              ))}
            </Select>
          )}
          {isLoaded ? (
            <View style={styles.buttonWrapper}>
              <CustomButton
                title="Refresh"
                width={150}
                onPress={handleSearch}
              />
            { selectedNumber && <CustomButton
                style={{marginTop:20}}
                title="Continue"
                width={150}
                onPress={handleContinue}
              />
            }              
            <TouchableOpacity
              accessible={true}
              accessibilityRole='button'
                onPress={() => {
                  setNumbers([]);
                  setLoaded(false);
                }}>
                <Text style={styles.text}>Change Area code</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.buttonWrapper}>
              <CustomButton title="search" width={150} onPress={handleSearch} />
            </View>
          )}
        </View>
        <SelectMobileModal
          visible={visible}
          setVisible={(val) => setVisible(val)}
          selectedNumber={selectedNumber}
          handleSetNumber={handleSetNumber}
        />
        <SucessSelectMobile
          selectedNumber={selectedNumber}
          visible={successVisible}
          setVisible={(val) => {
            setSuccessVisible(val);
          }}
          handleNextPage={() => {
            setSuccessVisible(false);
            dispatch(setCompletedStep({ phone: true }));
            navigation.navigate('BusinessCard', {number: selectedNumber});
          }}
        />
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};

export default SetupWizardChooseNum;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: widthPercentageToDP(90),
    maxWidth: 768,
    alignItems: 'center',
    padding: 15,
  },
  comment: {
    fontSize: 16,
    color: AppColor.Black200,
    marginBottom: 10,
  },
  buttonWrapper: {
    marginTop: 15,
    width: '100%',
    alignItems: 'center',
  },
  picker: {
    width: '100%',
    marginVertical: 10,
  },
  text: {
    color: AppColor.PrimaryColor,
    textDecorationLine: 'underline',
    textDecorationColor: AppColor.PrimaryColor,
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 10,
  },
});
