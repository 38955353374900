import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import { Icon } from 'react-native-elements';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import End_Points from '../../Constants/Api';
import { providers } from '../IntegrationProvider';
import splashlogo from '../../Assets/Images/splashlogo.png';
import { useSelector } from 'react-redux';

const MessageHeader = props => {
    const member = props.member;
    const dl = props.dl;
    const tenant = useSelector((state) => state.User?.profile?.tenant);
    let countryCode = member.phoneNumbers.find((x) => x.messaging)?.countryCode;
    let longCode = member.phoneNumbers.find((x) => x.messaging)?.longCode;
    let number = `${countryCode}${longCode}`;
    if(number.length > 12){
      number = String(number).slice(0,12);
    }
    return(
        <View
            style={{
              width: '100%',
              backgroundColor: AppColor.PrimaryColor,
              paddingVertical: 5,
              paddingHorizontal: 10,
            }}>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}>
              <View>
                <HeaderLogo member={member} />
              </View>
              <TouchableOpacity
                onPress={props.onPress}
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  paddingHorizontal: 15,
                }}>
                {dl == null ? (
                  <>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 20,
                        color: 'white',
                      }}
                      numberOfLines={1}>
                      {member.fname} {member.mname} {member.lname}
                    </Text>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 14,
                        color: 'white',
                      }}>
                      {number}
                    </Text>
                  </>
                ) : (
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 20,
                      color: 'white',
                    }}>
                    {dl.text}
                  </Text>
                )}
              </TouchableOpacity>

              {(tenant?.voice) &&
              <TouchableOpacity
                onPress={props.onPhonePress}>
                <Icon color={'white'} type={'material'} name={'phone'} />
              </TouchableOpacity>
              }

            </View>
          </View>
    )
}


const HeaderLogo = ({member}) => {
    
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == member.source);
    if(member.source){
      if (provider) {
        image = End_Points.Base_url + provider.smallImageUrlPath;
        imageAlt = `${provider.name} logo.`
      }
    }


    return (
      <img
        alt={imageAlt}
        style={{ width: 40, height: 40, borderRadius: 35, marginLeft: 20 }}
        src={image}
      />
    );
  };



export default MessageHeader;