import React from 'react';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';
import { useSelector } from 'react-redux';
import { getChatChannels } from '../../Api/ten4';

export const SocketContext = React.createContext();

export const SocketProvider = props => {

    const [ws, setWs] = React.useState();
    const [countdown, setCountdown] = React.useState(1000);
    const [endpoint, setEndpoint] = React.useState('');
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    React.useEffect(()=>{
        async function connect(){
            const token = await getData('token');
            const suffix = End_Points.Base_url.replace('https', 'wss');
            const url = suffix + `dart/websocket?X-Dart-Token=${token}`;
            setEndpoint(url)
            setWs(new WebSocket(url));     
        }
        
        if(affiliate?.chatEnabled){
            connect();
        }
    
    },[affiliate])


    React.useEffect(()=>{

        if(ws && endpoint){

            const onClose = () => {
                setTimeout(async ()=> {
                    const token = await getData('token');
                    const suffix = End_Points.Base_url.replace('https', 'wss');
                    const url = suffix + `dart/websocket?X-Dart-Token=${token}`;
                    setCountdown(time => time * 2)
                    setWs(new WebSocket(url));
                }, countdown);
            }

            ws.addEventListener('close', onClose)

            return () => {
                ws.removeEventListener('close', onClose);
            }
        }
    },[ws, setWs])

    return(
        <SocketContext.Provider value={ws}>
        {props.children}
        </SocketContext.Provider>
    )
}



/*
export const Ten4Socket = React.createContext();

const Ten4SocketProvider = props => {
    const [socket, setSocket] = React.useState();
    const user = useSelector((state) => state.User.profile)?.user;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [message, setMessage] = React.useState('');
    const [badgeCountNumber, setBadgeCountNumber] = React.useState(0);
    const [socketInitialized, setSocketInitialized] = React.useState(false);
    
    React.useEffect(()=>{
        if(affiliate?.chatEnabled){
            if(!socketInitialized){
            initializeSocketConnection();
            getChatChannels({affiliate: affiliate.id, userId: user.user_id })
            .then(result => {
                if(result?.data?.success){
                    let badgeCount = 0;
                    if(result.data.public.length > 0 && result.data.public.findIndex(e => e.unread > 0) > -1 ){
                        result.data.public.forEach(e => {
                            badgeCount += e.unread;
                        })
                    }

                    if(result.data.private.length > 0 && result.data.private.findIndex(e => e.unread > 0) > -1 ){
                        result.data.private.forEach(e => {
                            badgeCount += e.unread;
                        })
                    }

                    setBadgeCountNumber(badgeCount);
                }
            })
            setSocketInitialized(true);    
            }
        } else if(!affiliate?.chatEnabled && socket) {
            socket.close();
            setSocketInitialized(false);    
        }


    },[affiliate?.chatEnabled])

    const initializeSocketConnection = async () => {
        const token = await getData('token');
        const suffix = End_Points.Base_url.replace('https', 'wss');
        const endPoint = suffix + `dart/websocket?X-Dart-Token=${token}`;
        const socketConnection = new WebSocket(endPoint); 

        socketConnection.onopen = () => {
            setSocket(socketConnection);
        }

        socketConnection.onmessage = ({data}) => {
            const parsed = JSON.parse(data);

            setMessage(parsed.message);

            if(parsed.message.subject === 'New channel message'){
                triggerNotification(parsed);
                setBadgeCountNumber(badgeCountNumber + 1)
                
            }
        }

    }

    const triggerNotification = async data => {
        console.log(data);
        const sound = await getData('ten4Sound');
        const options = {
            body: `from ${data.message.senderName}`,
            icon: `${End_Points.Base_url}dart/css/dart/images/iolake_logo_icon.png`,
        }
        if(sound !== false) {
            const audio = new Audio(require('../../Assets/sounds/ping.mp3'));
            audio.play();
  
        }
        
        const notification = new Notification('New Ten4 Message', options );
        
    }

    return(
        <Ten4Socket.Provider value={{socketMessage: message, setBadgeCountNumber: setBadgeCountNumber, badgeCountNumber: badgeCountNumber}}>
            {props.children}
        </Ten4Socket.Provider>
    )

}

export default Ten4SocketProvider;*/