import { useToast } from 'native-base';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View, Keyboard } from 'react-native';
import Alert from 'Alert';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import getData from '../../../AsyncUtils/GetData';
import storeData from '../../../AsyncUtils/StoreData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import Loader from '../../../Components/Loader';
import { setProfile } from '../../../Redux';

const MessageHistory = ({ navigation }) => {
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const [loading, setLoading] = React.useState(false);
  const [mh, setMH] = React.useState('');
  const Toast = useToast();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tenant?.messageHistory) {
      setMH(tenant.messageHistory + '');
      console.log(tenant);
    }
  }, [tenant]);

  const getAsyncData = () => {
    getData('mh').then((x) => {
      if (x) {
        setMH(x);
      } else {
      }
    });
  };
  React.useEffect(() => {
    getAsyncData();
  }, []);
  const handleSave = async () => {
    Keyboard.dismiss();
    setLoading(true);
    if (mh < 10 || mh > 120) {
      Alert.alert('Error', 'Message History must be between 10 and 120 days.');
      setLoading(false);
      return;
    }
    try {
      let body = { action: 'update_message_history', messageHistory: mh };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setProfile({
          tenant: {
            ...tenant,
            messageHistory: mh
          }
        }))
        storeData('mh', mh);
        Toast.show({
          duration: 2000,
          title: 'Updated Successfully',
          status: 'success',
        });
        if (tenant?.id) {
          // dispatch(loadAccountHealthThunk(tenant.id))
        }
        navigation.goBack();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Message History'}
      />
      <View
        style={{
          width: '100%',
          maxWidth: 768,
          marginTop: 20,
          padding: 20,
          alignSelf: 'center',
        }}>
        <View style={{ marginBottom: 20, marginTop: 5 }}>
          <Text style={{ fontSize: 18, color: AppColor.Dark }}>
            By default, the message view will show 90 days worth of message
            history. The maximum number of days to display is 120 days.
            Note: Increasing the number of days displayed will also increase the
            amount of the time required to load messages.
          </Text>
        </View>
        <CustomInput
          value={mh}
          keyboardType={'numeric'}
          onChangeText={(t) => setMH(t)}
          placeholder={'Enter desired number of days of message history. Maximum is 120'}
          title={'Number of days of message history'}
        />
        <View
          style={{
            width: '70%',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 40
        }}>
          <CustomButton title={'Save'} onPress={() => handleSave()} />
          <View style={{ height: 20 }}/>

          <CustomButton onPress={() => navigation.goBack()} title={'Cancel'} />
        </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default MessageHistory;
