import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const recentMessageSlice = createSlice({
  name: 'recent_message',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    setRecentMessage: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
  },
});

export const { setRecentMessage } = recentMessageSlice.actions;

export const loadRecentMessagesThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_recent_member_message',
        days: 90,
        offset: 0,
        limit: 100,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);

        const noDuplicateData = [];

        if(result.data.members.length){
          result.data.members.forEach(e => {
            const index = noDuplicateData.findIndex(ee => ee.mobile === e.mobile && ee.fname === e.fname && ee.lname === e.lname && ee.message === e.message );
            if(index === -1){
              noDuplicateData.push(e);
            }
          })
        }

        dispatch(setRecentMessage({ data: noDuplicateData }));
      
    } catch (err) {
      console.log(err);
    }
  };
};

export default recentMessageSlice.reducer;
