import React, { useState } from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon } from 'react-native-elements';
import { useToast } from 'native-base';

import CustomButton from '../../Components/Button';
import Loader from '../../Components/Loader';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadAllDistributionsThunk } from '../../Redux/distributions';

import CSVImportModal from './CSVImport';

const AddContacts = ({ navigation }) => {
  const [isVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Toast = useToast();

  const handleImportMembers = async (payload) => {
    setVisible(false);
    try {
      setLoading(true);
      const body = {
        action: 'import_members',
        ...payload,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.status == 200) {
        dispatch(loadAllContactsThunk());
        Toast.show({
          duration: 2000,
          title: 'Imported contacts successfully!',
          status: 'success',
        });
        dispatch(loadAllDistributionsThunk());
        navigation.navigate('AllContacts');
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Add Contacts'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View
        style={{
          width: widthPercentageToDP(90),
          alignItems: 'center',
          alignSelf: 'center',
          marginVertical: 10,
        }}>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular, lineHeight: 20 }}>
          You can import contacts from your phone, from your CRM, or manually,
          using this phone app, You can also import contacts from CSV by using
          our web app.
        </Text>
      </View>
      <TouchableOpacity
      accessibilityRole='button'
      accessibilityLabel='Import Contacts from CRM'
        activeOpacity={1}
        onPress={() => navigation.navigate('ImportFromCrm')}
        style={styles.btnstyle}>
        <Icon
          color={AppColor.SecondaryColor}
          name={'database-import'}
          type={'material-community'}
        />
        <Text style={styles.txtstyle}>Import from CRM</Text>
      </TouchableOpacity>
      <TouchableOpacity
        accessibilityRole='button'
        accessibilityLabel='Import Contacts from CSV'
      
        activeOpacity={1}
        onPress={() => setVisible(true)}
        style={styles.btnstyle}>
        <Icon
          color={AppColor.SecondaryColor}
          name={'database-import'}
          type={'material-community'}
        />
        <Text style={styles.txtstyle}>Import Contacts from CSV</Text>
      </TouchableOpacity>
      <TouchableOpacity
        accessibilityRole='button'
        accessibilityLabel='Add contacts manually.'      
        activeOpacity={1}
        onPress={() => navigation.navigate('ContactEdit', {phoneData: null, dl: null, item: null})}
        style={styles.btnstyle}>
        <Icon
          color={AppColor.SecondaryColor}
          name={'adduser'}
          type={'antdesign'}
        />
        <Text style={styles.txtstyle}>Add contacts manually</Text>
      </TouchableOpacity>
      <TouchableOpacity
        accessibilityRole='button'
        accessibilityLabel='Add Distribution List manually'
        activeOpacity={1}
        onPress={() => navigation.navigate('AddDL')}
        style={styles.btnstyle}>
        <Icon
          color={AppColor.SecondaryColor}
          name={'addfile'}
          type={'antdesign'}
        />
        <Text style={styles.txtstyle}>Add DL manually</Text>
      </TouchableOpacity>
      <CustomButton
        onPress={() => navigation.goBack()}
        title={'Cancel'}
        style={{ width: '70%' }}
      />
      <View style={{ height: 20 }}></View>
      <CSVImportModal
        visible={isVisible}
        setVisible={setVisible}
        handleImportMembers={handleImportMembers}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  txtstyle: {
    fontSize: 18,
    color: AppColor.SecondaryColor,
    fontFamily: AppFonts.Roboto_Regular,
    padding: 10,
    textAlign: 'center',
  },
  btnstyle: {
    backgroundColor: AppColor.PrimaryColor,
    marginVertical: 10,
    width: '70%',
    alignSelf: 'center',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default AddContacts;
