import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View, Platform, TouchableOpacity, ScrollView } from 'react-native';
import Alert from 'Alert';
import { createElement } from 'react-native-web';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';
import { Icon } from 'react-native-elements';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'rc-time-picker';

import CustomModal from '../../Components/DropDown';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { setSelectedContact } from '../../Redux/selected_contact';
import './index.css';
import 'rc-time-picker/assets/index.css';
import { deleteScheduledMessageThunk } from '../../Redux/scheduled_messages';
import EmojisModal from '../../Components/Modals/EmojisModal';

const moment = require('moment');

const AddAutoResponse = ({ navigation, route }) => {
  const Toast = useToast();
  const [message, setMessage] = React.useState('');
  const selected_contact = useSelector((state) => state.selected_contact);
  const [id, setId] = React.useState(null);
  const [type, setType] = React.useState('create');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [frequency, setFrequency] = React.useState(0);
  const [time_zone, setTimeZone] = useState(null);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(moment());
  const [consented, setConsented] = useState(true);
  const group = useSelector((state) => state.User)?.profile?.group;
  const [emojiModalVisible, setEmojiModalVisible] = React.useState(false);
  const inputMessageRef = React.useRef(null);
  const [status, setStatus] = React.useState('');

  const frequencylist = [
    {
      label: 'Just once',
      value: 0,
    },
    {
      label: 'Daily',
      value: 1,
    },
    {
      label: 'Weekly',
      value: 2,
    },
    {
      label: 'Monthly',
      value: 3,
    },
    {
      label: 'Annually',
      value: 4,
    },
    {
      label: 'Quarterly',
      value: 5,
    },
    {
      label: 'Semi-Annually',
      value: 6,
    },
  ];

  React.useEffect(() => {
    if (route.params?.item) {
      let { item } = route.params;
      setType('update');
      if (item.contact) {
        dispatch(
          setSelectedContact({
            data: { id: item.contact, fname: item.fname, lname: item.lname },
            dl: false,
          })
        );
      } else {
        dispatch(
          setSelectedContact({
            data: { id: item.distributionList, text: item.lname },
            dl: true,
          })
        );
      }

      setDate(new Date(item.deliveryDate));
      setTime(moment(new Date(item.deliveryDate)));
      setFrequency(item.frequency);
      setMessage(item.message);
      setId(item.id);
      setConsented(true);
      setStatus(item.status)
      if(item?.consented === false && group?.consentEnabled === true) setConsented(false)
    } else {
      setType('create');
      if(route.params.date){
        setDate(new Date(route.params.date));
      } else {
      setDate(new Date());
      }
      setConsented(true);
      setTime(moment());
      setFrequency(0);
      setMessage('');
      setId(null);
      setStatus('')
    }
    if (route.params?.template_message) {
      setMessage(route.params.template_message);
      setDate(new Date(route.params.deliveryDate));
      setTime(moment(route.params.time));
      setFrequency(route.params.frequency);
    }
  }, [route]);

  const handleSave = async () => {
    const mergedDate = moment(
      moment(date).format('MM/DD/YYYY') + ' ' + moment(time).format('hh:mm a')
    ).format();

    if (!selected_contact.data?.id) {
      Toast.show({
        duration: 2000,
        status: 'info',
        title: 'Please select user',
      });
      return;
    }
    const now = new Date().getTime();
    if (now > new Date(mergedDate).getTime()) {
      Toast.show({
        duration: 2000,
        status: 'info',
        title: 'The message delivery date must be in the future.',
      });
      return;
    }

    try {
      setLoading(true);
      let body = {
        deliveryDate: new Date(mergedDate).getTime(),
        message: message,
        frequency: frequency,
        timezone: time_zone,
      };
      if (selected_contact.dl) {
        body['distributionList'] = selected_contact.data?.id;
      } else {
        body['contact'] = selected_contact.data?.id;
      }
      if (type == 'update') {
        body['action'] = 'update_scheduled_message';
        body['id'] = id;
      } else {
        body['action'] = 'create_scheduled_message';
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Your new message is scheduled.',
          status: 'success',
        });
        navigation.goBack();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      route.params?.loadMonthData();
    }
  };

  const getTimeZone = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const timeZone= await TimeZone.getTimeZone().then(zone => zone);
    setTimeZone(timeZone);
  };
  React.useEffect(() => {
    getTimeZone();
  }, []);

  const handleDelete = () => {
    if(type === 'create'){
      navigation.goBack();
    } else {
      if(id){
      dispatch(deleteScheduledMessageThunk(id)).then((x) => {
        navigation.goBack();
      });
      } else {
        alert('Something went wrong. Please try again later.')
      }

    }
  }

  const handleConsentSend = async () => {
    setLoading(true)
    try {
      let body = {};

      if (selected_contact.dl) {
        body = {
          action: 'send_consent',
          distributionList: route.params.item.contact,
        };
      } else {
        body = {
          action: 'send_consent',
          id: route.params.item.contact,
        };
      }

      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      setLoading(false);
      if(result?.data?.success){
        Toast.show({
          duration: 2000,
          title: 'Consent successfully sent.',
          status: 'success',
        });
        navigation.goBack();
      } else {
        alert(result?.data?.errorMessage || 'Something went wrong, please try again later')
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    } 
  };


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Scheduled Message'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <ScrollView style={{ flex: 1, padding: 30 }}>
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
              padding: 10,
            }}>
            To
          </Text>
          <TouchableOpacity
            accessibilityRole='button'
            onPress={() => navigation.navigate('SelectContactForSchedule')}
            style={{
              width: '100%',
              alignSelf: 'center',
              flexDirection: 'row',
              padding: 10,
              paddingHorizontal: 20,
              fontFamily: AppFonts.Roboto_Bold,
              backgroundColor: '#F6F6F6',
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'user'}
              type={'font-awesome'}
            />
            {!selected_contact.dl && (
              <Text style={{ marginLeft: 10 }}>
                {selected_contact.data?.fname} {selected_contact.data?.lname}
              </Text>
            )}
            {selected_contact.dl && (
              <Text style={{ marginLeft: 10 }}>
                {selected_contact.data.text}
              </Text>
            )}
          </TouchableOpacity>
{ consented === false && <View style={{flexDirection:'row',alignItems:'flex-end'}}>
              <Icon name='warning' type={'material'} color={AppColor.danger}/>
              <Text style={{color:AppColor.danger,marginLeft:5}}>This contact has not consented to receive texts. <Text onPress={handleConsentSend} style={{color:AppColor.BLUE100,textDecorationLine:'underline',cursor:'pointer'}}>Send Consent Now</Text></Text>
          </View>
}        </View>
        <View style={{ height: 20 }} />
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
              paddingVertical: 10,
            }}>
            Date
          </Text>
        </View>
        <View style={{ alignItems: 'center', zIndex: 9 }}>
          <View
            style={{
              width: '100%',
              zIndex: 9,
            }}>
            <DateTimePicker
              calendarType="US"
              onChange={setDate}
              value={date}
              calendarIcon={
                <Icon
                
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
        </View>
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
              paddingVertical: 10,
            }}>
            Time
          </Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View
            style={{
              width: '100%',
            }}>
            <TimePicker
              value={time}
              onChange={(val) => setTime(val)}
              showSecond={false}
              minuteStep={15}
              use12Hours={true}
              inputIcon={
                <Icon
                  accessible={true}
                  accessibilityRole='button'
                  color={AppColor.PrimaryColor}
                  name={'back-in-time'}
                  type={'entypo'}
                />
              }
              clearIcon={null}
            />
          </View>
        </View>
        <Text
          style={{
            width: '100%',
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Bold,
            color: AppColor.PrimaryColor,
            paddingVertical: 10,
          }}>
          Frequency
        </Text>
        <View 
        style={{ width: '100%', alignSelf: 'center' }}>
          <CustomModal
            place={'Select Here'}
            value={frequency}
            fun={(v) => setFrequency(v)}
            data={frequencylist}
          />
        </View>
        <View style={{ height: 20 }}></View>
        <View style={{ width: '100%', alignSelf: 'center' }}>
          <CustomInput
            ref={inputMessageRef}
            maxLength={1600}
            multiline={true}
            value={message}
            onChangeText={(t) => setMessage(t)}
            title={'Message'}
            placeholder={'Type Something'}
          />
          <View style={{flexDirection:'row',alignItems:'center'}}>
          <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Select Template.'
            onPress={() => {
              navigation.navigate('SelectTemplates', {
                screen: 'AddScheduledMessage',
                deliveryDate: date,
                time: time,
                frequency: frequency          
              });
            }}
            style={{
              borderBottomColor: AppColor.PrimaryColor,
              borderBottomWidth: 1,
              width: 120,
              marginVertical: 10,
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                color: AppColor.PrimaryColor,
              }}>
              Select Template
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Smile'
              accessibilityHint='Select emoji.'
              style={{ marginHorizontal: 10 }}
              onPress={() => setEmojiModalVisible(true)}>
              <Icon
                name="smile"
                type='feather'
                size={25}
                color={AppColor.PrimaryColor}
              />
            </TouchableOpacity>
          </View>
        </View>
        { status &&
        <>
        <Text
          style={{
            width: '100%',
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Bold,
            color: AppColor.PrimaryColor,
            marginVertical: 10,
            maxHeight:300,
            
          }}>
          Status
        </Text>

        <Text
        style={{backgroundColor: '#F6F6F6',padding:10}}
        >
          {status}
        </Text>
        </>
        }

        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignSelf: 'center',
          }}>
          <CustomButton
            onPress={() => {
              handleSave();
            }}
            style={{ width: '40%' }}
            title={type == 'create' ? 'Save' : 'Update'}
          />
          <CustomButton
            onPress={handleDelete}
            style={{ width: '40%' }}
            title={'Delete'}
          />
        </View>
      </ScrollView>
      {loading && <Loader />}
      {
      emojiModalVisible &&
      <EmojisModal 
        visible={emojiModalVisible}
        setVisible={setEmojiModalVisible}
        onPressEmoji={e => {
          setMessage(message + e)
          setEmojiModalVisible(false);
        }}
      />
      }
    </SafeAreaView>
  );
};

// function DateTimePicker({ value, onChange }) {
//   return createElement('input', {
//     type: 'datetime-local',
//     value: value,
//     onInput: onChange,
//   });
// }
export default AddAutoResponse;
