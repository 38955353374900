/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { useSelector } from 'react-redux';
import { Text, TouchableOpacity, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Card } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import Header from '../../Components/Header';
import AppFonts from '../../Constants/Fonts';
import { providers } from '../IntegrationProvider';
import { useDispatch } from 'react-redux';
import { loadGroupAddOnsThuunk } from '../../Redux/add_ons';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';

const AddONType = {
  ACCOUNT: 1,
  ARCHIVE: 2,
  LOGIN: 3,
  VOICE: 4,
  RECORD: 5,
  TRANSCRIBE: 6,
};

const Settings = ({ navigation }) => {
  const dispatch = useDispatch();
  const integrations_providers = useSelector(
    (state) => state.integrations
  ).providers;
  const [ads, setAds] = React.useState([]);
  const add_ons = useSelector((state) => state.add_ons);
  const group = useSelector((state) => state.User)?.profile?.group;
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const affiliate = useSelector((state) => state.User)?.profile?.affiliate;

  const [integrationsData, setIntegrationsData] = React.useState({
    arr1: [],
    arr2: [],
  });

  const [addOnsData, setAddOnData] = React.useState({
    arr1: [],
    arr2: [],
  });

  const [archivesData, setArchivesData] = React.useState({
    arr1: [],
    arr2: [],
  });

  React.useEffect(()=>{
    const getAddons = navigation.addListener('focus', () => {
      loadAddons()
    })

    return getAddons;

  },[navigation])

  const loadAddons = async () => {
    const body = {
      action: 'get_group_addons',
      affiliate: affiliate.id,
    };
    let token = await getData('token');
    let device = await getData('@device_id');
    const headers = { 'x-dart-token': token, 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    console.log('addons', result);
    if (result.data.success) {
      setAds(result.data.addons)
    }
  }

  React.useEffect(() => {
    if (integrations_providers.length > 0) {

      let arr1 = [];
      let arr2 = [];

      if (integrations_providers.includes(providers.RISKALYZE.name)) {
        arr1.push('Riskalyze');
        arr2.push(() =>
          navigation.navigate('Integrations', {
            provider: providers.RISKALYZE,
            type: 'crm',
            from: 'settings',
          })
        );
      }

      if (integrations_providers.includes(providers.MARKETINGPRO.name)) {
        arr1.push('Marketing Pro');
        arr2.push(() =>
          navigation.navigate('Integrations', {
            provider: providers.MARKETINGPRO,
            type: 'crm',
            from: 'settings',
          })
        );
      }

      if (integrations_providers.includes(providers.FMGSUITE.name)) {
        arr1.push('FMG Suite');
        arr2.push(() =>
          navigation.navigate('Integrations', {
            provider: providers.FMGSUITE,
            type: 'crm',
            from: 'settings',
          })
        );
      }

      setIntegrationsData({
        arr1: arr1,
        arr2: arr2,
      });
    }
  }, [integrations_providers]);

  React.useEffect(() => {
    if (ads?.length) {
      let arr1 = [];
      let arr2 = [];
      let voice = ads.find((x) => x.type === AddONType.VOICE && x.available)
      let records = ads.find((x) => x.type === AddONType.RECORD && x.available);
      let transcribe = ads.find((x) => x.type === AddONType.TRANSCRIBE && x.available);
      let login = ads.find((x) => x.type === AddONType.LOGIN && x.available);
      let archieve = ads.find((x) => x.type === AddONType.ARCHIVE && x.available);

   
      if ( voice ) {
        arr1.push('Voice Add-on');
        arr2.push(() => navigation.navigate('VoiceAddOn', { item: voice, mutable: voice?.mutable }));
        arr1.push('Voice Number');
        arr2.push(() => navigation.navigate('VoiceNumber', { item: voice }));
        arr1.push('Availability');
        arr2.push(() =>
          navigation.navigate('VoiceAvailability', { item: voice })
        );
        arr1.push('Voice Mail');
        arr2.push(() => navigation.navigate('VoiceMail', { item: voice }));
        arr1.push('Caller ID');
        arr2.push(() => navigation.navigate('CallerId', { item: voice }));
        arr1.push('Caller Timeout');
        arr2.push(() => navigation.navigate('CallerTimeout', { item: voice }));
      }
      if (records) {
        arr1.push('Recording Add-on');
        arr2.push(() =>
          navigation.navigate('RecordingAddOn', { item: records, mutable: records?.mutable })
        );
      }
      if ( transcribe) {
        arr1.push('Transcribe Add-on');
        arr2.push(() =>
          navigation.navigate('TranscribeAddOn', { item: transcribe, mutable: transcribe?.mutable })
        );
      }
      if (login) {
        arr1.push('Additional Account Logins');
        arr2.push(() => navigation.navigate('Accounts', { item: login, mutable: login?.mutable }));
      }
      if ( archieve) {
        arr1.unshift('Archive Add-on');
        arr2.unshift(() =>
          navigation.navigate('ArchiveAddOn', { item: archieve, mutable: archieve?.mutable })
        );
      }
      setAddOnData({
        arr1: arr1,
        arr2: arr2,
      });
    }
  }, [ads]);



  React.useEffect(() => {
    let arr1 = [];
    let arr2 = [];

    if (group.showTenantArchive) {
          arr1.push('Connect to Archiver');
          arr2.push(() => navigation.navigate('ConnectArchiver', { from: 'settings' }));
          arr1.push('Archive Layout');
          arr2.push(() => navigation.navigate('ArchiveLayout'));
          arr1.push('Archive Schedule');
          arr2.push(() => navigation.navigate('ArchiveSchedule'));
          arr1.push('Archive Audit');
          arr2.push(() => navigation.navigate('ArchiveAudit'));
    }

    setArchivesData({
      arr1: arr1,
      arr2: arr2,
    });
  }, [group]);










  const data = [
    {
      _id: '1',
      heading: 'Profile',
      arr: [
        'Personal Details(VCard)',
        'Change Password',
        'App Options',
        'Positive Consent',
        'API Access'
      ],
      arr2: [
        () => navigation.navigate('Vcard'),
        () => navigation.navigate('ChangePassword'),
        () => navigation.navigate('AppOptions'),
        () => navigation.navigate('PositiveConsent'),
        () => navigation.navigate('APIAccess')
      ],
    },
    {
      _id: '2',
      heading: 'Preference',
      arr: [
        'Forwarding Number',
        'Message History',
        'Out of office response',
        'Timezone',
      ],
      arr2: [
        () => navigation.navigate('ForwardingNumber'),
        () => navigation.navigate('MessageHistory'),
        () => navigation.navigate('OutOfOffice'),
        () => navigation.navigate('Timezone'),
      ],
    },
    {
      _id: '3',
      heading: 'Integration',
      arr: ['Connect to CRM', ...integrationsData.arr1],
      arr2: [
        () => navigation.navigate('Crm', { from: 'settings' }),
        ...integrationsData.arr2,
      ],
    },
    {
      _id: '4',
      heading: 'Add-Ons and Billing',
      arr: [...addOnsData.arr1, 'Billing Management'],
      arr2: [
        ...addOnsData.arr2,
        () => navigation.navigate('BillingManagement'),
      ],
    },
    {
      _id: '5',
      heading: 'Archives and Reports',
      arr: [...archivesData.arr1,'Account Report'],
	  arr2: [
		...archivesData.arr2,
		() => navigation.navigate('AccountReport')
      ],
    },
  ];

  const renderSettings = (item) => {
    return (
      <Card style={{ width: '95%', padding: 10, alignSelf: 'center' }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Bold,
            fontSize: 17,
            letterSpacing: 1,
            marginLeft: 10,
          }}>
          {item.heading}
        </Text>
        {item.arr.map((values, key) => {
          if(values === 'API Access' && !group.allowApiAccess) return
          return (
            <TouchableOpacity
            accessibilityRole='button'
            accessibilityLabel={values}
            key={key} onPress={() => {
              if((values === 'Voice Number' ||
              values === 'Availability' ||
              values === 'Voice Mail' ||
              values === 'Caller ID' ||
              values === 'Caller Timeout') && !tenant?.voice) return;
              
              item.arr2[key]()
              
              }}>
              {values === 'Voice Number' ||
              values === 'Availability' ||
              values === 'Voice Mail' ||
              values === 'Caller ID' ||
              values === 'Caller Timeout' ? (
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: AppFonts.Roboto_Regular,
                    marginLeft: 40,
                    marginVertical: 10,
                    color: tenant?.voice ? 'black' : 'rgba(0,0,0,.6)'
                  }}>
                  {values}
                </Text>
              ) : (
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: AppFonts.Roboto_Regular,
                    marginLeft: 20,
                    marginVertical: 10,
                  }}>
                  {values}
                </Text>
              )}
            </TouchableOpacity>
          );
        })}
      </Card>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header title={'Settings'} />
      <FlatList
        data={data}
        keyExtractor={(item) => item._id}
        renderItem={(item) => {
          return renderSettings(item.item);
        }}
      />
    </SafeAreaView>
  );
};

export default Settings;
