/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { StyleSheet, View, Text, Image, Linking, Keyboard, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, Content, useToast } from 'native-base';

import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import storeData from '../../../AsyncUtils/StoreData';
import keyword from '../../../Constants/Keywords';
import { setSignUpData } from '../../../Redux/signupdata';

const GroupSetup = ({ navigation }) => {
  const [groupCode, setGroupCode] = useState('');
  const [loader, setLoading] = useState(false);
  const Toast = useToast();
  const dispatch = useDispatch();
  const handleNext = () => {
	dispatch(setSignUpData({ data: { groupCode: groupCode}}));
	navigation.navigate('CompanyDetail');
  };

  return (
    <SafeAreaView style={styles.container}>
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt='MyRepChat logo.'
              src={require('../../../Assets/Images/logo.png')}
            />
          </View>
          <View style={{ width: '100%' }}>
            <View
              style={{
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text style={{ fontSize: 20 }}>Organization Code</Text>
            </View>
            <View
              style={{
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text
                style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 16 }}>
				<Text>
                Are you a part of an Organization that has adopted MyRepChat? 
				If so, please enter the unique CODE associated with that Organization to ensure the proper routing of your account. 
				If you are not sure, you can{' '}
				</Text>
				<TouchableOpacity onPress={() => Linking.openURL('mailto:support@ionlake.com?Subject=Organization%20Code')}>
   					<Text style={{ textDecorationLine: 'underline'}}>ASK US</Text>
				</TouchableOpacity>
				.
              </Text>
            </View>
            <CustomInput
              onChangeText={(s) => setGroupCode(s)}
              value={groupCode}
              title={'Organization Code'}
              placeholder={'Enter organization code'}
            />
          </View>
          <View style={{ height: 15 }} />
          <CustomButton
            onPress={() => {
				handleNext();
            }}
            title={'Next'}
          />
          <View style={{ height: 25 }} />
          <TouchableOpacity accessible={true} accessibilityRole='button' onPress={handleNext} style={{alignSelf:'center',marginTop:20, textDecorationLine: 'underline'}}>
              <Text style={{fontSize:18}}>Skip for now</Text>
          </TouchableOpacity>

        </View>
      </Container>
      {loader ? <Loader /> : null}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});

export default GroupSetup;
