import React from "react";
import { Modal, Text, View } from "react-native";
import CustomButton from "../Button";

const ContactAdminModal = ({ visible, handleSubmit }) => {
    return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      onDismiss={() => {
        handleSubmit();
      }}
      onBackdropPress={() => handleSubmit()}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#0007',
        }}>
        <View
          style={{
            backgroundColor: 'white',
            padding: 10,
            width: '90%',
            maxWidth: 400,
          }}>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>Message</Text>
          <View
            style={{
              height: 1,
              backgroundColor: '#ffffff',
              marginVertical: 10,
            }}
          />
          <Text style={{ color: '#000000', textAlign: 'center' }}>
            Your account will be logged out successfully
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 10,
            }}>
            <CustomButton
              title={'OK'}
              style={{ width: '40%' }}
              onPress={() => handleSubmit()}
            />
          </View>
        </View>
      </View>
    </Modal>
  );

};

export default ContactAdminModal;