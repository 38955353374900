import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Switch, TouchableOpacity } from 'react-native';
import { useToast } from 'native-base';
import { useSelector, useDispatch } from 'react-redux';

import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import Loader from '../../../../Components/Loader';
import AppColor from '../../../../Constants/Color';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';
import AppFonts from '../../../../Constants/Fonts';
import { setAttributes } from '../../../../Redux/integrationData';
import AntDesign from 'react-native-vector-icons/AntDesign';

export default function SmartOfficeComponent({
  provider,
  integration,
  updateIntegration,
  createIntegration,
  loadProviderData,
}) {
  const [loading, setLoading] = useState(false);
  const [office, setOffice] = useState('');
  const [username, setUsername] = useState();
  const [isVisible, setVisible] = useState(true);
  const [smartToken, setSmartToken] = useState('');
  const [active, setActive] = useState(false);
  const [createcontact, setCreatecontact] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [updatecontact, setUpdatecontact] = useState(false);
  const [realtimeNotes, setRealtimeNotes] = useState(false);
  const dispatch = useDispatch();
  const { attributes } = useSelector((state) => state.integrationData);

  const Toast = useToast();

  useEffect(() => {
    if (integration) {
      setActive(integration.active);
      setEnabled(integration.enabled);
      setSmartToken(integration.token);
      setCreatecontact(
        integration.createcontact === true ||
          integration.createcontact === 'true'
      );
      setUpdatecontact(
        integration.updatecontact === true ||
          integration.updatecontact === 'true'
      );
      setRealtimeNotes(
        integration.realtimeNotes === true ||
          integration.realtimeNotes === 'true'
      );
      setAttributes({
        ...attributes,
        active: integration.active,
        enabled: integration.enabled,
        createcontact:
          integration.createcontact === true ||
          integration.createcontact === 'true',
        updatecontact:
          integration.updatecontact === true ||
          integration.updatecontact === 'true',
        realtimeNotes:
          integration.realtimeNotes === true ||
          integration.realtimeNotes === 'true',
      });
    } else {
      setActive(false);
      setEnabled(false);
      setSmartToken('');
      setCreatecontact(false);
      setUpdatecontact(false);
      setRealtimeNotes(false);
    }
  }, [integration]);

  const handleConnect = async () => {
    const body = {
      action: 'get_smartoffice_key',
      office: office,
      username: username,
    };

    try {
      setLoading(true);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result.data);
      if (result.data.success) {
        if(!integration){
          createIntegration(result.data.token)
        } else {
          updateIntegration(result.data.token)
        }
        loadProviderData(null, provider, null);
        setSmartToken(result.data.token);
        setVisible(false);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (integration) {
      updateIntegration(smartToken);
    } else {
      createIntegration(smartToken);
    }
  };

  return (
    <>
        <TouchableOpacity
          onPress={() => window.open(provider.helpUrl, '_blank')}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf:'flex-start',
            paddingLeft:30
          }}>
          <AntDesign
            name="questioncircle"
            size={25}
            color={AppColor.PrimaryColor}
          />
        </TouchableOpacity>

      {integration ? (
        <View style={styles.container}>
          <Text style={styles.text}>
            MyRepChat can send messages to contacts from your SmartOffice
            account. Imported SmartOffice contacts will have notes added to them
            for all the messages you send/receive to these contacts through
            MyRepChat. Contacts will be imported by incoming phone calls (via
            phone number) or searching for name/phone in Messaging.
          </Text>
          <View style={{ paddingLeft: 15 }}>
            <CustomInput
              title="Token*"
              value={smartToken}
              disabled={true}
              titleStyle={styles.text}
            />
            <View style={styles.rowCenter}>
              <Text style={[styles.text, { marginRight: 15 }]}>Enable</Text>
              <Switch
                value={enabled}
                onValueChange={() => {
                  dispatch(
                    setAttributes({
                      ...attributes,
                      enabled: !enabled,
                    })
                  );
                  setEnabled(!enabled);
                }}
                trackColor={{ false: 'red', true: 'green' }}
              />
            </View>
            <View style={styles.rowCenter}>
              <Text style={[styles.text, { marginRight: 15 }]}>
                Activate Notes
              </Text>
              <Switch
                value={active}
                onValueChange={() => {
                  dispatch(
                    setAttributes({
                      ...attributes,
                      active: !active,
                    })
                  );
                  setActive(!active);
                }}
                trackColor={{ false: 'red', true: 'green' }}
              />
            </View>
            <View style={styles.rowCenter}>
              <Text style={[styles.text, { marginRight: 15 }]}>
                Real Time Notes
              </Text>
              <Switch
                value={realtimeNotes}
                onValueChange={() => {
                  dispatch(
                    setAttributes({
                      ...attributes,
                      realtimeNotes: !realtimeNotes,
                    })
                  );
                  setRealtimeNotes(!realtimeNotes);
                }}
                trackColor={{ false: 'red', true: 'green' }}
              />
            </View>
            <View style={styles.rowCenter}>
              <Text style={[styles.text, { marginRight: 15 }]}>
                Allow MyRepChat to Create Contacts in CRM
              </Text>
              <Switch
                value={createcontact}
                onValueChange={() => {
                  dispatch(
                    setAttributes({
                      ...attributes,
                      createcontact: !createcontact,
                    })
                  );
                  setCreatecontact(!createcontact);
                }}
                trackColor={{ false: 'red', true: 'green' }}
              />
            </View>
            <View style={styles.rowCenter}>
              <Text style={[styles.text, { marginRight: 15 }]}>
                Allow edits to contact in MyRepChat to edit contact in CRM
              </Text>
              <Switch
                value={updatecontact}
                onValueChange={() => {
                  dispatch(
                    setAttributes({
                      ...attributes,
                      updatecontact: !updatecontact,
                    })
                  );
                  setUpdatecontact(!updatecontact);
                }}
                trackColor={{ false: 'red', true: 'green' }}
              />
            </View>
          </View>
          <Text style={styles.text}>
            Once successfully connected, we will include this CRM when searching
            for contacts for new outgoing and incoming messages. We do not auto
            import all your contacts.
          </Text>
          <Text style={styles.text}>Enable - Turns on/off the integration</Text>
          <Text style={styles.text}>
            Active Notes - Turns on/off the creation of notes in the CRM for
            messages
          </Text>
          <Text style={styles.text}>
            Real Time Notes - If on, puts notes in the CRM as they happen, if
            off messages are combined into a daily note and added overnight.
          </Text>
          <View style={styles.row}>
            <CustomButton
              title="Save Settings"
              onPress={handleSave}
              style={{ width: '40%' }}
            />
          </View>
        </View>
      ) : (
        <View style={styles.container}>
          <View>
            <Text style={styles.text}>
              MyRepChat can import contacts from your SmartOffice account.
              Imported SmartOffice contacts will have notes added to them for
              all the messages you send/receive to these contacts through
              MyRepChat. To get started, you will need to:
            </Text>
            <Text style={[styles.text, { paddingLeft: 15 }]}>
              1. Enter your SmartOffice Office and Username below.
            </Text>
            <Text style={[styles.text, { paddingLeft: 15 }]}>
              2. Click the Connect button.
            </Text>
            <Text style={styles.text}>
              We will use your SmartOffice credentials to receive a unique
              token. Once we have this token, we will no longer need your
              credentials and won't ask for them again.
            </Text>
          </View>
          <View style={styles.input}>
            <CustomInput
              title="SMARTOFFICE OFFICE NAME*"
              value={office}
              onChangeText={(val) => setOffice(val)}
            />
          </View>
          <CustomInput
            title="SMARTOFFICE USERNAME*"
            value={username}
            onChangeText={(val) => setUsername(val)}
          />
          <CustomButton
            title="connect"
            onPress={handleConnect}
            style={{ width: '40%', marginTop: 30 }}
          />
        </View>
      )}
      {loading && <Loader backgroundColor="transparent" />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 30,
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    color: AppColor.Dark,
    fontSize: 16,
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  input: {
    marginVertical: 15,
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
