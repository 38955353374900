import React from 'react';
import { Text, View } from 'react-native';
import { Switch } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';

import Alert from 'Alert';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomModal from '../../Components/DropDown';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import { widthPercentageToDP } from 'react-native-responsive-screen';

class PhoneNumberType {
  constructor(id, label) {
    this.id = id;
    this.label = label;
  }
  getId() {
    return this.id;
  }
  getLabel() {
    return this.label;
  }
}

class Country {
  constructor(label, isoCode, iddPrefix, countryCode, voice, sms, mms) {
    this.label = label;
    this.isoCode = isoCode;
    this.iddPrefix = iddPrefix;
    this.countryCode = countryCode;
    this.voice = voice;
    this.sms = sms;
    this.mms = mms;
  }
  getLabel() {
    return this.label + '(+' + this.countryCode + ')';
  }
}

const countries = [
  new Country('United States', 'US', '011', 1, true, true, true),
  new Country('Australia', 'AU', '011', 61, true, true, false),
  new Country('Austria', 'AT', '011', 43, false, true, false),
  new Country('Belgium', 'BE', '011', 32, false, true, false),
  new Country('Canada', 'CA', '011', 1, true, true, true),
  new Country('Chile', 'CL', '011', 56, false, true, false),
  new Country('Czech Republic', 'CZ', '011', 420, false, true, false),
  new Country('Estonia', 'EE', '011', 372, false, true, false),
  new Country('France', 'FR', '011', 33, true, true, false),
  new Country('Germany', 'DE', '011', 49, true, true, false),
  new Country('Hong Kong', 'HK', '011', 852, false, true, false),
  new Country('Hungary', 'HU', '011', 36, false, true, false),
  new Country('Ireland', 'IE', '011', 353, false, true, false),
  new Country('Israel', 'IL', '011', 972, true, true, false),
  new Country('Latvia', 'LV', '011', 371, false, true, false),
  new Country('Lithuania', 'LT', '011', 370, false, true, false),
  new Country('Milaysia', 'MY', '011', 60, false, true, false),
  new Country('Mexico', 'MX', '011', 52, false, true, true),
  new Country('Netherlands', 'NL', '011', 31, false, true, false),
  new Country('Norway', 'NO', '011', 47, false, true, false),
  new Country('Poland', 'PL', '011', 48, false, true, false),
  new Country('Portugal', 'PT', '011', 351, false, true, false),
  new Country('Puerto Rico', 'PR', '', 1, true, true, false),
  new Country('Spain', 'ES', '011', 34, false, true, false),
  new Country('Sweden', 'SE', '011', 46, false, true, false),
  new Country('Switzerland', 'CH', '011', 41, false, true, false),
  new Country('United Kingdom', 'UK', '011', 44, true, true, false),
];

export const phoneNumberTypes = [
  new PhoneNumberType(1, 'Mobile'),
  new PhoneNumberType(2, 'Home'),
  new PhoneNumberType(3, 'Office'),
  new PhoneNumberType(4, 'Fax'),
  new PhoneNumberType(99, 'Other'),
];

const DistributionLists = ({ navigation, route }) => {
  const [frequency, setFrequency] = React.useState(0);
  const [countrySelected, SetCountrySelected] = React.useState(0);
  const initialDataState = {
    countryCode: '+1',
    isoCode: 'US',
    longCode: '',
    messaging: true,
    type: 1,
    typeId: 1,
  }
  const [data, setData] = React.useState(initialDataState);
  const [originPhone, setOriginPhone] = React.useState({
    countryCode: '+1',
    isoCode: 'US',
    longCode: '',
    messaging: true,
    type: 1,
    typeId: 1,
  });
  const [isChanges, setIsChanges] = React.useState(false);

  React.useEffect(() => {
    if (route.params?.phoneNumber) {
      let { phoneNumber } = route.params;
      setData(phoneNumber);
      setOriginPhone(phoneNumber);
      let countryIndex = countries.findIndex(
        (x) => x.isoCode.toLowerCase() == phoneNumber.isoCode.toLowerCase()
      );
      SetCountrySelected(countryIndex);
      let phoneTypeIndex = phoneNumberTypes.findIndex(
        (x) => x.id == phoneNumber.type
      );
      setFrequency(phoneTypeIndex);
      setIsChanges(true);
    }
  }, [route]);

  const saveContact = () => {
    console.log(isNaN(data.longCode))
    if (JSON.stringify(originPhone) === JSON.stringify(data)) {
      Alert.alert('Error', 'This number already exists');
      return;
    }
    if (isNaN(data.longCode)) {
      Alert.alert('Error', 'Please insert correct number');
      return;
    }
    if(data.longCode.length !== 10){
      Alert.alert('Error', 'Please enter a valid 10 digit phone number.');
      return;
    }
    const randomID = `${Math.floor(Math.random() * 100)}EE`
    const body = {
      ...data,
      id: data.id ? data.id : randomID
    }
    console.log("BODY",body);
    navigation.navigate('ContactEdit', { phoneData: body, item: null  });
  };

  React.useEffect(()=>{
    const unsubscribe = navigation.addListener('blur', () => {
      setData(initialDataState)
    })

    return unsubscribe;
  },[navigation])

  const toggleMessaging = () => {
    setData({ ...data, messaging: !data.messaging });

    console.log(data.messaging);
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Add/Edit Phone Number'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View style={{ flex: 1, width: '100%', padding: 10 }}>
        <Text
          style={{
            fontSize: 15,
            fontFamily: AppFonts.Roboto_Bold,
            color: AppColor.PrimaryColor,
          }}>
          Country*
        </Text>
        <View
          style={{
            marginBottom: 10,
          }}>
          <CustomModal
            place={'Select Here'}
            value={countrySelected}
            fun={(v) => {
              SetCountrySelected(v);
              let country = countries[v];
              setData((state) => ({
                ...data,
                countryCode: '+' + country.countryCode,
                isoCode: country.isoCode,
              }));
            }}
            data={countries.map((x, i) => ({
              label: x.getLabel(),
              value: i,
            }))}
          />
        </View>
        <View
          style={{
            marginBottom: 10,
          }}>
          <CustomInput
            keyboardType={'number-pad'}
            value={data.longCode}
            onChangeText={(t) => {
              setData((state) => ({ ...data, longCode: t }));
            }}
            title="Phone Number*"
            maxLength={15}
          />
        </View>
        <Text
          style={{
            fontSize: 15,
            fontFamily: AppFonts.Roboto_Bold,
            color: AppColor.PrimaryColor,
            paddingVertical: 10,
          }}>
          Phone Type*
        </Text>
        <View>
          <CustomModal
            place={'Select Here'}
            value={frequency}
            fun={(v) => {
              setFrequency(v);
              let ph = phoneNumberTypes[v];
              setData((state) => ({ ...data, typeId: ph.id, type: ph.id }));
            }}
            data={[
              ...phoneNumberTypes.map((x, i) => ({
                label: x.getLabel(),
                value: i,
              })),
            ]}
          />
        </View>
        <View
          style={{
            marginTop: 20,
          }}>
          <Text style={{ fontSize: 15, color: AppColor.Dark }}>
            Turn on messaging for this number to use this number for messaging,
            this will override any other messaging numbers for this contact.
          </Text>
        </View>
        <View
          style={{
            marginVertical: 20,
          }}>
          <Switch
            style={{ alignSelf: 'flex-start' }}
            value={data.messaging}
            
            onValueChange={toggleMessaging}
            trackColor={{ false: 'red', true: 'green' }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <CustomButton
            style={{ width: '40%' }}
            onPress={saveContact}
            title="OK"
          />
          <CustomButton
            style={{ width: '40%' }}
            title="CANCEL"
            onPress={() => {
              navigation.goBack();
              setData(initialDataState);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};
export default DistributionLists;
