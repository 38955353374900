import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const distributionsSlice = createSlice({
  name: 'distributions',
  initialState: {
    data: [],
  },
  reducers: {
    setDistributions: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
  },
});

export const { setDistributions } = distributionsSlice.actions;

export const loadAllDistributionsThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_distributionlists',
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setDistributions({ data: result.data.distributionLists }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteDistribution = (id) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'delete_distributionlist',
        id: id,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadAllDistributionsThunk());
        return { error: false };
      }
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };
};

export default distributionsSlice.reducer;
