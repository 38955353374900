import React from 'react';
import { FlatList } from 'react-native';
import { Box } from 'native-base';
import {
  SectionList,
  View,
  Text,
  StyleSheet,
  StatusBar,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import AppColor from '../../Constants/Color';

const { width, height } = Dimensions.get('screen');

const AlbhabetList = ({ data, RenderItem, ListHeaderComponent, sortBy }) => {
  const [items, setItems] = React.useState([]);
  const [albhabets, setAlphabets] = React.useState([]);
  const scrollRef = React.useRef(null);
  // const [selectedAlbhbet]
  React.useEffect(() => {
    if (data && Array.isArray(data)) {
      let x = Object.assign([], data);
      x.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
      let d = x.reduce((p, c) => {
        let char = c[sortBy].charAt(0).toUpperCase();
        if (/[a-z]/i.test(char)) {
          p[char] = [].concat(p[char] || [], c);
        } else {
          p['*'] = [].concat(p['*'] || [], c);
        }
        return p;
      }, {});
      setAlphabets(Object.keys(d));
      let z = Object.entries(d).map((value) => ({
        title: `${value[0]}`,
        data: value[1],
      }));
      setItems(z);
    }
  }, [data, sortBy]);

  return (
    <Box flex={1}>
      <Box overflowY={'auto'} flex={1}>
        <FlatList
          ref={scrollRef}
          stickySectionHeadersEnabled={true}
          data={items}
          keyExtractor={(item, index) => item.title + index}
          renderItem={({ item }) => <RenderItem item={item} />}
          onScrollToIndexFailed={(info) => {
            const wait = new Promise((resolve) => setTimeout(resolve, 500));
            wait.then(() => {
              scrollRef.current?.scrollToIndex({
                index: info.index,
                animated: true,
              });
            });
          }}
          ListHeaderComponent={ListHeaderComponent}
          style={{
            paddingHorizontal: 30,
          }}
        />
      </Box>
      <Box
        width={30}
        overflowY={'auto'}
        right={30}
        position="absolute"
        height={'100%'}>
        <ScrollView
          contentContainerStyle={{ flex: 1, justifyContent: 'center' }}>
          {albhabets.map((x, i) => {
            return (
              <TouchableOpacity
                onPress={() => scrollRef.current.scrollToIndex({ index: i })}
                key={x}
                style={{ width: '100%', alignItems: 'center', paddingTop: 5 }}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 15,
                    color: AppColor.PrimaryColor,
                  }}>
                  {x}
                </Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </Box>
    </Box>
  );
};

export default AlbhabetList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    marginHorizontal: 16,
  },
  item: {
    backgroundColor: '#f9c2ff',
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 20,
    paddingLeft: 10,
    width: 30,
    color: 'white',
    backgroundColor: '#2569B4',
  },
  title: {
    fontSize: 24,
  },
});
