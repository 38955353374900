import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { NativeBaseProvider,  } from 'native-base';
import { AlertRoot } from 'Alert';
import * as Font from 'expo-font';
import { useExternalScript } from './Source/Middleware/useExternalScript';
import firebase from './firebase_config';
import storeData from './Source/AsyncUtils/StoreData';
import 'file-loader?name=firebase-messaging-sw.js!./firebase-message-sw.js';
import 'firebase/messaging';
import Router from './Source/Router';
import store from './Source/Redux';
import getData from './Source/AsyncUtils/GetData';
import { setToken } from './Source/Redux/index';
import { loadRecentMessagesThunk } from './Source/Redux/recent_message';
import { setReceivedMessageThunk } from './Source/Redux/recevied_message';
import {
  setAccountInactivityThunk,
  setCountTimerThunk,
  setTimeOutThunk,
} from './Source/Redux/account_active';
import useStoreState from './Source/Middleware/useStoreState';
import ContactAdminModal from './Source/Components/Modals/ContactAdminModal';
import { useGTM } from './Source/Middleware/useGTM';



const custom_fonts = {
  'Roboto-Regular': require('./Source/Assets/fonts/Roboto-Regular.ttf'),
  'Roboto-Bold': require('./Source/Assets/fonts/Roboto-Bold.ttf'),
};



export default function App() {
  // Used useSelector instead of store.subscribe
  const authenticated = useStoreState((state) => state.User.authenticated);
  const inactivity = useStoreState((state) => state.accountActivity.inactivity);
  const isTimedOut = useStoreState((state) => state.accountActivity.timeOut);

  const [visible, setVisible] = useState(false);
  const externalScript = "https://wsmcdn.audioeye.com/aem.js";
  const state = useExternalScript(externalScript);
  const GTMLink = 'https://www.googletagmanager.com/gtag/js?id=G-ZFZTQC1E61';
  const GTM = useGTM(GTMLink);

  const loadData = async () => {
    try {
      let token = await getData('token');
      if (token) {
        store.dispatch(setToken({ token, authenticated: false }));
      }
    } catch (err) {
      console.error('An error occurred while loading data: ', err);
    }
  };

  useEffect(() => {
    loadData();
    loadFonts();
  }, []);

  useEffect(() => {
    if (inactivity && isTimedOut) {
      store.dispatch(setCountTimerThunk(false));
      return setVisible(true);
    }
    setVisible(false);
  }, [inactivity, isTimedOut]);

  const loadFonts = async () => {
    try {
      await Font.loadAsync(custom_fonts);
    } catch (err) {
      console.error('An error occurred while loading fonts: ', err);
    }
  };

  const handleSubmit = () => {
    setVisible(false);
    store.dispatch(setCountTimerThunk(true));
    store.dispatch(setAccountInactivityThunk(false));
    store.dispatch(setTimeOutThunk(false));
    store.dispatch(setToken({ token: null, authenticated: false }));
  };

  return (
    <>
      <NativeBaseProvider>
        <Provider store={store}>
          <Router />
        </Provider>
      </NativeBaseProvider>
      <AlertRoot />
      {visible && <ContactAdminModal visible={visible} handleSubmit={handleSubmit} />}
    </>
  );
}

// Moved Firebase code outside of App
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();

  // Register service worker once when the page loads
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.error('Service worker registration failed, error:', err);
    });

  // Request notification permission once when the page loads
  Notification.requestPermission()
    .then((result) => {
      if (result !== 'granted') {
        console.error('Notification permission not granted.');
      }
    })
    .catch((err) => {
      console.error('Notification permission request failed, error:', err);
    });

  messaging
    .requestPermission()
    .then(async () => {
      const token = await messaging.getToken();
      if (token) {
        await storeData('fcmToken', token);
        console.log('FCM token> ', token);
      } else {
        console.log('No Token available');
      }
    })
    .catch((err) => {
      console.error('An error occurred while requesting permission or retrieving token. ', err);
    });

  messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
    showNotification(payload);
    store.dispatch(loadRecentMessagesThunk());
    store.dispatch(setReceivedMessageThunk(payload));
  });

}

async function showNotification(payload) {
  if (Notification.permission === 'granted') {
    const audio = new Audio(require('./Source/Assets/sounds/ping.mp3'));
    audio.play();

    const registration = await navigator.serviceWorker.ready;
    registration.showNotification(payload.notification.title, {
      body: payload.data.contactName + ' for ' + payload.data.accountContact,
      tag: payload.notification.tag,
      icon: require('./Source/Assets/Images/splashlogo.png'),
      silent: false,
    });
  }
}