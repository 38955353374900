import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TouchableOpacity, View, SafeAreaView, ScrollView } from 'react-native';
import { DrawerActions } from '@react-navigation/routers';
import { useFocusEffect } from '@react-navigation/core';
import { Icon, Card } from 'react-native-elements';
import { PieChart } from 'react-native-chart-kit';
import { Box, HStack, VStack } from 'native-base';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import Alert from 'Alert';

import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { loadRecentMessagesThunk } from '../../Redux/recent_message';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadRecentMediaThunk } from '../../Redux/recent_media';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { loadAccountStatThunk } from '../../Redux/account_stat';
import { loadAccountHealthThunk } from '../../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk,
} from '../../Redux/add_ons';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { formatPhoneNumber } from '../../Middleware/helpers';
//import OrphanConversationModal from './OrphanConversationModal';

const moment = require('moment');

const Dashboard = ({ navigation }) => {
  const dispatch = useDispatch();
  const todayDate = Date.now();
  const sevenDayBefore = todayDate - 7 * 24 * 60 * 60 * 1000;
  const account_stat = useSelector((state) => state.account_stat);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const account_health = useSelector((state) => state.account_health);
  const contactData = useSelector((state) => state.contacts).data;
  const [isOrphanModalVisible, setOrphanModalVisible] = React.useState(false);
  const [orphanData, setOrphanData] = React.useState(null);

  const dup_contacts = useSelector(
    (state) => state.duplicate_members
  ).messagingPhone;

  const [msgData, setMsgData] = useState({
    sent: 0,
    receive: 0,
  });

  // loading recent messages
  useFocusEffect(
    useCallback(() => {
      dispatch(loadAccountStatThunk());
      dispatch(loadRecentMessagesThunk());
      dispatch(loadAllContactsThunk());
      dispatch(loadRecentMediaThunk());
      dispatch(loadSavedMessagesThunk());
      dispatch(loadIntegrationProviderThuunk());
      dispatch(loadIntegrationsThuunk());
      dispatch(loadGroupAddOnsThuunk());
      dispatch(loadBillingDetailsThuunk());
      dispatch(loadAllDistributionsThunk());
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (tenant?.id) {
        dispatch(loadAccountHealthThunk(tenant?.id));
      }
    }, [tenant])
  );

  useEffect(() => {
    if (account_stat.stats) {
      let x = 0;
      let y = 0;
      for (let i of account_stat.stats) {
        switch(i.type) {
          case "comm.sms.inbound": 
            x += i.val;
            break;
          case "comm.sms.outbound": 
            y += i.val;
            break;
          }
      }
      setMsgData({ sent: y, receive: x });
    }
  }, [account_stat]);

  const countNoMessagingNumbers = () => {
    let count = 0;
     
    contactData.forEach(e => {
      let hasNoMessagingNumber = false;

      e.phoneNumbers.forEach(ee => {
        if(ee.messaging) hasNoMessagingNumber = true;
      })

      if(!hasNoMessagingNumber) count += 1
    })

    return count;

  }

  const data = [
    {
      _id: '3',
      name: 'Unread Messages',
      iconname: 'mail-unread',
      icontype: 'ionicon',
      Count: account_stat.unreadMessages,
    },
    {
      _id: '4',
      name: 'Templates',
      iconname: 'clipboard',
      icontype: 'feather',
      Count: account_stat.templateCount,
    },
    {
      _id: '1',
      name: 'Auto Response',
      iconname: 'reply-all',
      icontype: 'font-awesome-5',
      Count: account_stat.workflowCount,
    },
    {
      _id: '7',
      name: 'Orphan Conversations',
      iconname: 'chat',
      icontype: 'entypo',
      Count: account_health.orphans.length,
      data: account_health.orphans,
    },

    {
      _id: '9',
      name: 'CRM Connections',
      iconname: 'disconnect',
      icontype: 'antdesign',
      Count: account_health.crms.filter((x) => x.enabled).length,
    },

    {
      _id: '6',
      name: 'Archive connections',
      iconname: 'archive',
      icontype: 'font-awesome',
      Count: account_health.archive.length,
    },

    {
      _id: '8',
      name: 'Contacts',
      iconname: 'contacts',
      icontype: 'antdesign',
      Count: account_stat.memberCount,
    },
    {
      _id: '2',
      name: 'Duplicate Contacts',
      iconname: 'contacts',
      icontype: 'antdesign',
      Count: Object.entries(dup_contacts).length,
    },
    {
      _id: '10',
      name: 'No Messaging Number',
      iconname: 'notifications-off',
      icontype: 'Entypo',
      Count: countNoMessagingNumbers(),
    },
    {
      _id: '5',
      name: 'No Phone Number',
      iconname: 'phone-slash',
      icontype: 'font-awesome-5',
      Count: contactData.filter((x) => x.phoneNumbers.length == 0).length,
    },
  ];

  const chartConfig = {
    backgroundGradientFrom: '#fff',
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: AppColor.PrimaryColor,
    backgroundGradientToOpacity: 0.0,
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    barPercentage: 0.5,
    useShadowColorFromDataset: false,
  };

  const datas = [
    {
      name: 'Sent',
      population: msgData.sent == 0 ? 1 : msgData.sent,
      color: '#ED7014',
      legendFontColor: '#7F7F7F',
      legendFontSize: 15,
    },
    {
      name: 'Received',
      population: msgData.receive == 0 ? 1 : msgData.receive,
      color: '#FCAE1E',
      legendFontColor: '#7F7F7F',
      legendFontSize: 15,
    },
  ];

  const getTenantStatus = () => {
    switch(tenant?.status){
      case 1:
        return 'Active'
      case 90:
        return 'Active'
      case 3:
        return "Review"
      case 4:
        return "Trial"
      case 5:
        return "Past Due"
    }
  }

  const renderDashboardItems = (item) => {
    return (
      <TouchableOpacity
      accessibilityRole='button'
        activeOpacity={0.6}
        onPress={() => {
          // if(item.Count>0){
          switch (item._id) {
            case '1':
              navigation.navigate('AutoResponse');
              break;
            case '2':
              navigation.navigate('DuplicateContacts');
              break;
            case '3':
              navigation.navigate('Messages', { unread: true });
              break;
            case '4':
              navigation.navigate('Templates');
              break;
            case '5':
              navigation.navigate('AllContacts', { no_phone: true });
              break;
            case '6':
              navigation.navigate('ConnectArchiver');
              break;
            case '10':
              navigation.navigate('AllContacts', {filter: true});
              break;
            case '8':
              navigation.navigate('Contacts');
              break;
            case '7':
              // setOrphanData(item);
              // setOrphanModalVisible(true);
              item.Count > 0 ? 
              Alert.alert(
                'Message',
                `You have messages that are not associated with ${
                  item.Count
                } phone numbers.\nThe phone numbers are \n${item.data.join(
                  '\n'
                )} \nAdd these numbers to existing contacts or create new contacts to see these messages.`,
                [
                  {
                    text: 'ok',
                    onPress: () => {return;},
                  },
                ]
              )
              :
              Alert.alert('Message', 'You have no orphan messages');
              break;
            case '9':
              navigation.navigate('Crm', {from: 'settings'});
              break;
            default:
              navigation.navigate('dashboard');
          }
          // }
        }}
        key={item._id}
        style={{
          height: widthPercentageToDP(8.5),
          backgroundColor: AppColor.SecondaryColor,
          width: widthPercentageToDP(8.5),
          margin: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 0,
          }}>
          <Icon
            // style={{ color: AppColor.PrimaryColor }}
            color={AppColor.PrimaryColor}
            name={item.iconname}
            type={item.icontype}
          />
          <Text
            style={{
              textAlign: 'center',
              marginTop: 10,
              fontFamily: AppFonts.Roboto_Regular,
              marginHorizontal: 20,
              fontSize: heightPercentageToDP(1.6),
            }}>
            {item.name}
          </Text>
        </View>
        <View
          style={{
            borderRadius: 30,
            backgroundColor: AppColor.PrimaryColor,
            position: 'absolute',
            top: 0,
            right: 0,
          }}>
          <Text
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: 30,
              color: AppColor.SecondaryColor,
              textAlign: 'center',
            }}>
            {item.Count}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        title={'Dashboard'}
        onPress={() => {
          navigation.dispatch(DrawerActions.openDrawer());
        }}
      />
      <ScrollView style={{ flex: 1 }}>
        <Text
          style={{
            textAlign: 'center',
            marginVertical: 15,
            fontFamily: AppFonts.Roboto_Bold,
            fontSize: 20,
          }}>
          Account Status :{' '}
          <Text
            style={{
              textAlign: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 25,
              color: AppColor.PrimaryColor,
            }}>
            {getTenantStatus()}
          </Text>
        </Text>
        <HStack>
          <View style={{ marginTop: 20, flex: 1 }}>
            <Text
              style={{
                marginVertical: 10,
                color: AppColor.PrimaryColor,
                fontSize: 20,
                fontFamily: AppFonts.Roboto_Regular,
                textAlign: 'center',
              }}>
              Message Activity ({moment(sevenDayBefore).format('MMM D YYYY')} -{' '}
              {moment(todayDate).format('MMM D YYYY')})
            </Text>
            <HStack justifyContent="space-between" marginX={20}>
              <View>
                <Text
                  style={{
                    color: 'black',
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  Sent
                </Text>
                <Text
                  style={{
                    color: AppColor.PrimaryColor,
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  {msgData.sent}
                </Text>
              </View>
              <View style={{ marginHorizontal: 10 }}>
                <Text
                  style={{
                    color: 'black',
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  Received
                </Text>
                <Text
                  style={{
                    color: AppColor.PrimaryColor,
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  {msgData.receive}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    color: 'black',
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  Avg Sent
                </Text>
                <Text
                  style={{
                    color: AppColor.PrimaryColor,
                    textAlign: 'center',
                    fontFamily: AppFonts.Roboto_Bold,
                    fontSize: 20,
                  }}>
                  {msgData.sent > 0 ? Math.floor(msgData.sent / 7) : 0}
                </Text>
              </View>
            </HStack>
            <View style={{ height: 220 }}>
              <PieChart
                data={datas}
                width={widthPercentageToDP(40)}
                height={220}
                chartConfig={chartConfig}
                accessor={'population'}
                backgroundColor={'transparent'}
                paddingLeft={'15'}
                center={[20, 0]}
              />
            </View>
          </View>
          <Box flex={1}>
            <Card style={{ alignSelf: 'center' }}>
              <Text style={{padding:0,margin:0,alignSelf:'flex-end',color:AppColor.PrimaryColor,fontWeight:'800'}}>{tenant?.provider && tenant.provider.charAt(0).toUpperCase()}</Text>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: 'black',
                }}>
                MyRepChat Number
              </Text>

              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                }}>
                {formatPhoneNumber(
                  account_health.mrcNumber.value.replace('+1', '')
                )}
              </Text>
            </Card>
            <Card style={{ alignSelf: 'center' }}>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: 'black',
                }}>
                Voice/Forwarding Number
              </Text>

              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  color: AppColor.PrimaryColor,
                }}>
                {formatPhoneNumber(account_health.voice.value)}
              </Text>
            </Card>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 20,
                marginVertical: 15,
                fontFamily: AppFonts.Roboto_Bold,
                color: 'black',
              }}>
              Contacts
            </Text>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'center',
                borderRadius: 8,
              }}>
              {data.slice(6).map((i) => {
                return renderDashboardItems(i);
              })}
            </View>
          </Box>
        </HStack>
        <View style={{ marginHorizontal: 20 }}>
          <Text
            style={{
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
              color: 'black',
              marginVertical:15
            }}>
            Others
          </Text>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderRadius: 8,
            }}>
            {data.slice(0, 6).map((i) => {
              return renderDashboardItems(i);
            })}
          </View>
        </View>
      </ScrollView>
      {account_health.loading && <Loader />}
      {
        isOrphanModalVisible &&
      <OrphanConversationModal data={orphanData} visible={isOrphanModalVisible} setVisible={setOrphanModalVisible} />
      }
    </SafeAreaView>
  );
};
export default Dashboard;
