import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FlatList,
  Text,
  View,
  TouchableOpacity,
  Keyboard,
  StyleSheet,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { useFocusEffect } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';

import CustomModal from '../../Components/DropDown';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import { loadRecentMessagesThunk } from '../../Redux/recent_message';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadRecentMediaThunk } from '../../Redux/recent_media';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { loadAccountStatThunk } from '../../Redux/account_stat';
import { loadAccountHealthThunk } from '../../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk,
} from '../../Redux/add_ons';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import { setProfile } from '../../Redux';
import Chat from '../Chat/chat_side';

import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import firebase from '../../../firebase_config';
import TabFocusChecker from './TabFocusChecker';
import { TermsOfUseModal } from './TermsOfUseModal';
import MessagePreview from './MessagePreview';
import Alert from '../../Components/Alert';

const Messages = ({ navigation, route }) => {
  const recent_messages = useSelector((state) => state.recent_messages).data;
  const { newMessage } = useSelector((state) => state.receivedNewMessage);
  const { tenant, tous } = useSelector((state) => state.User.profile);
  const [filterValue, setFilter] = useState('all');
  const [allData, setAllData] = React.useState([]);
  const [unread, setUnread] = React.useState(false);
  const [unreadMessages, setUnreadMessages] = React.useState([]);
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = React.useState(null);
  const [selectedChatMessages, setSelectedChatMessages] = React.useState([]);
  const [template_message, setTemplateMessage] = React.useState('');
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const loadRecentMessages = () => dispatch(loadRecentMessagesThunk());

  React.useEffect(() => {
    if (newMessage) {
      setTimeout(()=>{
        loadPreviewsAndCurrentChatMessages();
      },500)
    }
  }, [newMessage]);


  React.useEffect(() => {

    dispatch(loadAccountStatThunk());
    dispatch(loadAllContactsThunk());
    dispatch(loadRecentMediaThunk());
    dispatch(loadSavedMessagesThunk());
    dispatch(loadIntegrationProviderThuunk());
    dispatch(loadIntegrationsThuunk());
    dispatch(loadGroupAddOnsThuunk());
    dispatch(loadBillingDetailsThuunk());
    dispatch(loadAllDistributionsThunk());
    dispatch(loadRecentMessagesThunk());

    if (typeof tous === 'undefined') {
      authorize();
    } else if (tous?.length > 0) {
      setVisible(true);
    }

    return () => {
      setTemplateMessage('');
      navigation.setParams({ template_message: null, memberData: null, unread: false });
    };

  }, [])

  useFocusEffect(
    React.useCallback(() => {
      if (tenant?.id) {
        dispatch(loadAccountHealthThunk(tenant.id));
        dispatch(loadDuplicateNumbersThunk(tenant.id));
      }
    }, [tenant])
  )


  React.useEffect(() => {
    //close open message panel on account switch
    if (tenant?.name) {
      loadRecentMessages();
      setSelectedChat(null);
    }
  }, [tenant?.name])


  React.useEffect(() => {
    if (route.params?.unread == true) {
      setUnread(true);
      setUnreadMessages(recent_messages.filter((x) => x.unreadmessages > 0));
    } else {
      setUnread(false);
      setUnreadMessages([]);
    }
    if (route.params?.template_message) {
      setTemplateMessage(route.params.template_message);
    } else {
      setTemplateMessage('');
    }

    if (route.params?.memberData) {
      loadSelectedMessageDetails(route.params.memberData.id);
    }

  }, [route.params]);

  React.useEffect(() => {
    setAllData(recent_messages);
  }, [recent_messages]);

  React.useEffect(() => {
    let timer;

    const focus = navigation.addListener('focus', () => {
      timer = null;
      if (Notification.permission !== 'granted' || !firebase.messaging.isSupported()) {
        timer = setInterval(() => {
          loadRecentMessages()
        }, 120000)
      }
    })

    const blur = navigation.addListener('blur', () => {
      if (timer) {
        clearInterval(timer);
      }
    })
  }, [navigation])


  const handleSearch = (value) => {
    const trimmedValue = value.trim().toLowerCase();

    if (trimmedValue === '') {
      setAllData(recent_messages);
      return;
    }

    const matchesSearchTerm = (message) => {
      const fullName = `${message.fname.toLowerCase()} ${message.lname.toLowerCase()}`;
      return fullName.includes(trimmedValue);
    };

    const searchedData = recent_messages.filter(matchesSearchTerm);
    setAllData(searchedData);
  };


  const RenderList = (i) => {
    let item = i;

    if(selectedChat?.id === i.id){
      item = {...i, unreadmessages: 0};
    }

    return (
      <MessagePreview
      i={item} 
      onPress={() => {
        setTemplateMessage('');
        if (selectedChat?.id !== i.id) {
          loadSelectedMessageDetails(i.id);
        }
      }}
      />
    );
  };

  const authorize = async () => {
    try {
      setLoader(true);
      const fcmToken = await getData('fcmToken');
      const body = {
        action: 'authorize',
        pushKey: fcmToken,
      };
      let { data } = await RequestMaker(body);
      setLoader(false);
      dispatch(setProfile(data));
      if (data?.tous?.length > 0) {
        setVisible(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const loadMemberDetails = async (id) => {
    try {
      const body = {
        action: 'get_member',
        id: id,
      };
      let result = await RequestMaker(body);
      if (result.status === 200 && result.data.id) {
        if (result.data.stopped) {
          Alert.alert(
            'Opted Out',
            'This contact has opted out of receiving sms messages from you...',
          );
        }
        return result.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const loadContactMessages = async (id, offset = 0, limit = 25) => {
    try {
      const body = {
        action: 'get_messages',
        member: id,
        offset: offset,
        limit: limit,
      };
      const result = await RequestMaker(body);
      if (result.data.success) {
        return result.data.communications;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const loadSelectedMessageDetails = async (id) => {
    setLoader(true)
    const [member, messages] = await Promise.all([
      loadMemberDetails(id),
      loadContactMessages(id),
    ]);
    setLoader(false)
    console.log('\r\ndata', member, messages, '\r\n')
    if (member) {
      setSelectedChat(member);
    }
    if (messages) {
      setSelectedChatMessages(messages);
    }
  }

  const loadPreviewsAndCurrentChatMessages = async () => {
    loadRecentMessages();
    if(selectedChat?.id) {
      const messages = await loadContactMessages(selectedChat.id);
      setSelectedChatMessages(messages);

    }
  }

  const filterByMessageType = (x) => {
    if (filterValue == 'all') {
      return true;
    }
    return filterValue == x.messageType;
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Messages'}
        onPress={() => {
          Keyboard.dismiss();
          navigation.dispatch(DrawerActions.openDrawer());
        }}
        chatIcon={
          <Icon
            onPress={() => {
              navigation.navigate('SearchMessages');
            }}
            name="search"
            type="font-awesome"
            color={'white'}
          />
        }
      />
      {loader && <Loader />}
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <View
          style={{
            flex: selectedChat != null ? 0.6 : 1, borderRightWidth: 1,
            borderColor: AppColor.PrimaryColor
          }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingHorizontal: 5 }}>
            <Search
              onChangeText={t => handleSearch(t)}
              placeholder="Filter recent"
              icon={
                <View accessible={false}>
                  <Icon
                    name="filter"
                    type="AntDesign"
                    style={{ color: AppColor.PrimaryColor }}
                  />
                </View>
              }
            />
            <Icon
              name='refresh'
              type='MaterialIcons'
              size={28}
              color='black'
              onPress={loadPreviewsAndCurrentChatMessages}
              style={{ marginHorizontal: 7 }}
            />
          </View>
          <View style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
            <View style={{ marginHorizontal: 20 }}>
              <CustomModal
                place={'Select Here'}
                value={filterValue}
                fun={(v) => setFilter(v)}
                data={[
                  { value: 'all', label: 'Calls & Messages' },
                  { value: 'sms', label: 'Messages' },
                  { value: 'call', label: 'Calls' },
                ]}
              />
            </View>

            {!unread && (
              <FlatList
                ItemSeparatorComponent={() => {
                  return (
                    <View
                      style={{
                        height: 1,
                        backgroundColor: 'white',
                        width: '95%',
                        alignSelf: 'center',
                      }}></View>
                  );
                }}
                ListEmptyComponent={
                  <View style={{ alignItems: 'center', marginTop: 20 }}>
                    <Text>No Messages</Text>
                  </View>
                }
                keyExtractor={(item, index) => item.id + '' + index}
                data={!unread ? allData.filter(filterByMessageType) : unreadMessages.filter(filterByMessageType)}
                renderItem={({ item }) => {
                  return <RenderList {...item} />;
                }}
              />
            )}
          </View>

          <TouchableOpacity
            accessible={true}
            accessibilityLabel={'Open dialpad.'}
            accessibilityRole='button'
            onPress={() => navigation.navigate('Dialpad')}
            activeOpacity={0.7}
            style={[styles.fab, {
              bottom: 90,
            }]}>
            <MaterialIcons name="call" color="white" size={30} />
          </TouchableOpacity>

          <TouchableOpacity
            accessible={true}
            accessibilityLabel={'Start a Conversation'}
            accessibilityRole='button'
            onPress={() => navigation.navigate('StartConversation')}
            activeOpacity={0.7}
            style={[styles.fab, {
              bottom: 20,
            }]}>
            <MaterialIcons name="edit" color="white" size={30} />
          </TouchableOpacity>

        </View>

        {selectedChat != null && (
          <Chat
            navigation={navigation}
            template_message={template_message}
            memberData={selectedChat}
            messages={selectedChatMessages}
            message={route.params?.message}
            loadPreviewsAndCurrentChatMessages={loadPreviewsAndCurrentChatMessages}
            loadContactMessages={loadContactMessages}
          />
        )}
      </View>

      <TabFocusChecker onTabRefocused={loadRecentMessages} />
      {visible &&
        <TermsOfUseModal setVisible={setVisible} visible={visible} setLoader={setLoader} tous={tous} handleAgree={handleAgree} handleDisagree={handleDisagree} />
      }
    </SafeAreaView>
  );
};

export default Messages;


const styles = StyleSheet.create(
  {
    fab: {
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 60,
      minWidth: 60,
      borderRadius: 30,
      backgroundColor: AppColor.PrimaryColor,
      position: 'absolute',
      right: 20,
    }
  }
)