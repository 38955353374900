import { DrawerActions } from '@react-navigation/routers';
import { Container, Content, Icon, Switch } from 'native-base';
import React from 'react';
import { useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Keyboard,
  Dimensions,
} from 'react-native';
import Alert from 'Alert';

import { set } from 'react-native-reanimated';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch } from 'react-redux';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';

const AddTemplates = ({ navigation, route }) => {
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [id, setId] = React.useState(null);
  const [type, setType] = React.useState('create');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (route.params?.item) {
      const { item } = route.params;
      setName(item.name);
      setMessage(item.message);
      setId(item.id);
      setType('update');
    }
  }, [route]);

  React.useEffect(()=>{
    const listen = navigation.addListener('blur', () => {
      setName('');
      setMessage('');
      setId(null);
      setType('create');
    })

    return listen;

  },[navigation])


  const handleSave = async () => {
    Keyboard.dismiss();
    setLoading(true);
    try {
      let body = {
        name: name,
        message: message,
      };
      if (type == 'create') {
        body['action'] = 'create_saved_message';
      } else {
        body['action'] = 'update_saved_message';
        body['id'] = id;
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        dispatch(loadSavedMessagesThunk());
        if (id == null) {
          alert('Created!\n' + 'Your new template has been saved.');
        } else {
          alert('Updated\n' + 'Your Template has been updated.');
        }
        navigation.goBack();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const _handleChange = (e) => {
    e.target.style.height = 0
    e.target.style.height = `${e.target.scrollHeight}px`
  };
  

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Templates'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <ScrollView>
      <View style={{ width: '80%', alignSelf: 'center' }}>
        <View style={{ height: 20 }}></View>
        <CustomInput
          value={name}
          onChangeText={(t) => setName(t)}
          title={'Name Template*'}
          placeholder={'Enter Name'}
        />
        <View style={{ height: 20 }}></View>
        <CustomInput
          textInputStyle={{maxHeight:500}}
          value={message}
          onChange={_handleChange}
          onChangeText={(t) => setMessage(t)}
          title={'Message*'}
          placeholder={'Type Message here'}
          multiline={true}
        />
        <View style={{ height: 40 }}></View>
        <CustomButton
          onPress={handleSave}
          title={'Save'}
          style={{ width: '50%' }}
        />
        <View style={{ height: 20 }}></View>
        <CustomButton
          onPress={() => navigation.goBack()}
          style={{ width: '50%' }}
          title={'Cancel'}
        />
      </View>
      <View
        style={{
          flex: 1,
          height: Dimensions.get('window').height * 0.8,
          borderLeftWidth: 1,
          borderColor: AppColor.PrimaryColor,
          paddingVertical: 20,
          paddingHorizontal: 10,
        }}>
        <Text style={[styles.notesStyles, { marginVertical: 20 }]}>
          You can use the following place holders in your message
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.name}'} - combines first and last name of the contact
          (formerly {'${name}'})
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.firstname}'} - first name of contact (formerly{' '}
          {'${fname}'})
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.lastname}'} - last name of contact (formerly {'${lname}'})
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.phone}'} - the messaging number of the contact
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.address}'} - the address line of the contact
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.city}'} - the city of the contact
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.state}'} - the state of the contact (two letter)
        </Text>
        <Text style={styles.notesStyles}>
          {'${contact.zip}'} - the zip code of the contact
        </Text>
        <Text style={[styles.notesStyles, { marginTop: 20 }]}>
          When the message is sent, these place holders will be replaced with
          the values from the contact record.
        </Text>
      </View>
      </ScrollView>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  notesStyles: {
    fontSize: 17,
    textAlign: 'center',
  },
});
export default AddTemplates;
