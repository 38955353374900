import { useSelector } from 'react-redux';
import defaultImage from '../../Assets/Images/splashlogo.png';
import End_Points from '../../Constants/Api';
import { providers } from '../IntegrationProvider';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { Icon } from 'react-native-elements';
import moment from 'moment';
import React from 'react';

const MessagePreview = ({ i, onPress }) => {
    let image = defaultImage;
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == i.source);
    if (provider) {
        image = End_Points.Base_url + provider.smallImageUrlPath;
        imageAlt = `${provider.name} Logo.`
    }
    const [isUnread, setIsUnread] = React.useState(i.unreadmessages > 0);

    const { tenant } = useSelector((state) => state.User.profile);

    const accountId = i?.accountId || null;

    const messageHistoryLimitUnix = tenant?.messageHistory * 86400000;
    if ((Date.now() - messageHistoryLimitUnix) > i?.when) return null

    return (
        <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => {
                setIsUnread(false);
                onPress()
            }}
            style={styles.container}
        >
            <View style={{ width: 40, justifyContent: 'center' }}>
                <img
                    src={image}
                    alt={imageAlt}
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                />
                {isUnread && (
                    <View
                        style={styles.badge}>
                        <Text
                            style={{
                                fontFamily: AppFonts.Roboto_Regular,
                                fontSize: 12,
                                color: AppColor.SecondaryColor,
                            }}>
                            {i.unreadmessages}
                        </Text>
                    </View>
                )}
            </View>

            <View style={styles.previewContainer}>
                <Text
                    style={styles.contactName}>
                    {i.fname + ' ' + i.lname}
                </Text>
                {i.messageType === 'sms' && (
                    <Text
                        style={[ styles.messagePreview, {
                            fontWeight:isUnread? 'bold' : 'normal',
                        }]}
                        numberOfLines={1}>
                        {i.message}
                    </Text>
                )}
                {(i.messageType === 'mms' || (i.source === 'bandwidth' && i.message === '')) && (
                    <MaterialIcons
                        name="perm-media"
                        size={22}
                        color={AppColor.PrimaryColor}
                    />
                )}

                {
                    i.messageType === 'call' &&
                    <View style={{alignSelf:'flex-start',alignItems:'center',flexDirection:'row'}}>
                    <Icon
                        style={{ fontSize: 20, color: 'green' }}
                        name={i.direction == 'inbound' ? 'call-received' : 'call-missed'}
                        type={'MaterialIcons'}
                    />
                    <Text style={styles.messagePreview}>{i.direction === 'inbound' ? 'Call Received' : 'Call Placed'}</Text>
                    </View>
                }

                <Text
                    style={{
                        fontSize: 10,
                        color:
                            i.status == 'failed'
                                ? AppColor.danger
                                : AppColor.PrimaryColor,
                    }}>
                    {i.status}
                </Text>
            </View>

            <View style={styles.rightHandDetails}>

                {accountId &&
                    <View style={{ flexDirection: 'row' }}>
                        <Icon size={12} name='building-o' type='font-awesome' />
                        <Text style={{ marginLeft: 2 }}>{accountId}</Text>
                    </View>
                }

                <Text style={styles.timestamp}>
                    {moment(i.when).format('h:mm a')}
                </Text>
                <Text style={styles.timestamp}>
                    {moment(i.when).format('MMM DD YYYY')}
                </Text>

            </View>

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '95%',
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        minHeight: 60,
    },
    timestamp: {
        color: AppColor.Dark,
        fontFamily: AppFonts.Roboto_Regular,
        fontSize: 14,
    },
    rightHandDetails: {
        flex: 1,
        justifyContent: 'center',
        height: 60,
        alignItems: 'flex-end',
    },
    badge: {
        backgroundColor: 'red',
        paddingHorizontal: 3,
        borderRadius: 5,
        position: 'absolute',
        right: 0,
        top: -5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    messagePreview: {
        color: AppColor.Dark,
        fontFamily: AppFonts.Roboto_Regular,
        fontSize: 14,
    },
    contactName: {
        color: AppColor.PrimaryColor,
        fontFamily: AppFonts.Roboto_Bold,
        fontSize: 16,
    },
    previewContainer: {
        justifyContent: 'center',
        marginHorizontal: 10,
        width: '60%',
    }
})


export default MessagePreview;