import { DrawerActions } from '@react-navigation/routers';
import React from 'react';
import { set } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import { WebView } from 'react-native-webview';
import End_Points from '../../Constants/Api';
import Loader from '../../Components/Loader';
import { useSelector } from 'react-redux';

const WebViewScreen = ({ navigation, route }) => {
  const [loading, setLoading] = React.useState(false);
  const { title, url } = route.params ?? { title: '', url: '' };
  const token = useSelector((state) => state.User)?.Token;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header title={title} back={true} onPress={() => navigation.goBack()} />
      <WebView
        onLoadStart={(e) => {
          setLoading(true);
        }}
        onLoadEnd={(e) => {
          setLoading(false);
        }}
        source={{
          uri: End_Points.Base_url + url,
          headers: {
            'x-dart-token': token,
          },
        }}
        style={{ flex: 1 }}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default WebViewScreen;
