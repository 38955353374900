import { useToast } from 'native-base';
import React from 'react';
import { Text, View, Keyboard, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { Input } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import MemeberModal from '../Distributions/MemberModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loadAllDistributionsThunk } from '../../Redux/distributions';

const EditContact = ({ navigation }) => {
  const contactData = useSelector((state) => state.contacts).data;
  const dispatch = useDispatch();
  const Toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const handleCreate = async () => {
    if (name.trim() == '') {
      Toast.show({
        duration: 2000,
        title: 'Empty Distribution List Name',
        status: 'info',
      });
      return;
    }
    Keyboard.dismiss();
    try {
      setLoading(true);
      const body = {
        action: 'create_distributionlist',
        text: name,
        members: members.map((x) => x.id),
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'New DL created!',
          status: 'success',
        });
        dispatch(loadAllDistributionsThunk());
        navigation.goBack();
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = (item) => {
    setMembers(members => [...members, ...item]);
    setVisible(false);
  };
  const handleDelete = (item) => {
    setMembers((state) => state.filter((x) => x.id != item.id));
  };
  const renderListView = ({ item }) => {
    const contact = contactData.find(e => e.id === item.id);
    let status = '';
    if(contact){
      contact.consented ? status = 'Consented' : contact.stopped ? status = 'Opted-Out' : ''
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          height: 40,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <AntDesign name="user" size={30} color={AppColor.PrimaryColor} />
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
              lineHeight: 40,
            }}>
            {item.fname} {item.mname} {item.lname}
          </Text>
          <Text
          style={{fontFamily:AppFonts.Roboto_Regular,
          color: status === 'Consented' ? 'green' : status === 'Opted-Out' ? 'red' : ''
          }}
          >
            {status}
          </Text>

        </View>
        <AntDesign
          name="delete"
          size={25}
          onPress={() => handleDelete(item)}
          color={AppColor.danger}
        />
      </View>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Create Distribution List'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 30 }}>
        <Input
          value={name}
          onChangeText={(t) => {
            setName(t);
          }}
          placeholder={'Distribution List Name'}
        />
        <View style={{ height: 10 }} />
        <CustomButton
          onPress={() => setVisible(true)}
          style={{ maxWidth: 300, width: '90%' }}
          title={'Add DL Members'}
        />
        <FlatList
          data={members.sort((a,b) => a.fname.localeCompare(b.fname))}
          keyExtractor={(item) => item.id}
          renderItem={renderListView}
          style={{
            marginVertical: 10,
            flex: 1,
          }}
        />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            alignSelf: 'center',
          }}>
          <CustomButton
            onPress={handleCreate}
            style={{ maxWidth: 300, width: '90%' }}
            title={'Save'}
          />
          <CustomButton
            onPress={() => navigation.goBack()}
            style={{ maxWidth: 300, width: '90%' }}
            title={'Cancel'}
          />
        </View>
      </View>
      {loading && <Loader />}
      { visible &&
      <MemeberModal
        members={members}
        visible={visible}
        setVisible={setVisible}
        handleAddMember={handleAddMember}
      />}
    </SafeAreaView>
  );
};

export default EditContact;
