import React, { useEffect, useMemo } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';

const NotificationTest = ({ navigation }) => {
  const { Token } = useSelector((state) => state.User);
  const websocket = new WebSocket(
    'wss://' + location.host + '/dart/websocket' + '?X-Dart-Token=' + Token
  );
  // const websocket = new WebSocket(
  //   'wss://javascript.info/article/websocket/demo/hello'
  // );

  websocket.onopen = function (e) {
    websocket.send('My name is John');
  };
  websocket.onclose = function (event) {
    if (event.wasClean) {
      console.log(
        `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
      );
    } else {
      console.log('[close] Connection died');
    }
  };

  websocket.onerror = function (error) {
    console.log(`[error] ${error.message}`);
  };

  const handleTest = () => {
    websocket.onmessage = function (event) {
      console.log(`[message] Data received from server: ${event.data}`);
    };
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Browser Notifications Test'}
        back={true}
        onPress={() => navigation.goBack()}
      />

      <View style={{ width: '100%', padding: 30 }}>
        <Text style={styles.text}>
          Check to see if your browser is connected to receive push
          notifications from the application server. Test receiving a
          notification from the server. If you do not see a browser alert popup
          with the message "Push Test Successful" then the connection between
          your browser and our application server is not working.
        </Text>
        <View
          style={{
            marginVertical: 15,
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Text
            style={[
              styles.text,
              { color: AppColor.PrimaryColor, marginRight: 15 },
            ]}>
            CONNECTION STATUS:
          </Text>
          <Text style={[styles.text, { fontFamily: AppFonts.Roboto_Bold }]}>
            {websocket ? 'Connected' : 'Not Connected'}
          </Text>
        </View>
        <CustomButton
          title="push notification test"
          style={{ width: '100%', maxWidth: 250, marginTop: 30 }}
          onPress={handleTest}
        />
      </View>
    </SafeAreaView>
  );
};
export default NotificationTest;

const styles = StyleSheet.create({
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 24,
    color: AppColor.Black200,
  },
});
