import React from 'react';
import {
  Text,
  TouchableOpacity,
  View, ScrollView
} from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import { useSelector } from 'react-redux';
import CustomButton from '../../Components/Button';

export const RenderPhoneModal = (props) => {
  const [dnc, setDNC] = React.useState(false);
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;

  return (
    <View
      style={{ flex: 1, justifyContent: 'center', backgroundColor: '#0005' }}>
      <View
        style={{
          height: 270,
          paddingHorizontal: 30,
          width: '40%',
          alignSelf: 'center',
          backgroundColor: 'white',
          marginBottom: 48,
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
        }}>
          {
        dnc ? 
        <DNCWarning 
        onCancel={() => setDNC(false)}
        onContinue={() =>{ 
          props.setPhoneModalVisible(false);
          props.handleCall(dnc.countryCode + dnc.longCode);
        
        }}
        />
        :
        <>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
          }}>
          Call Contact
        </Text>
        <View
          style={{
            height: 1,
            backgroundColor: AppColor.PrimaryColor,
            marginVertical: 10,
          }}></View>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 15 }}>
          Choose the number you would like to call
        </Text>
        <ScrollView style={{ marginVertical: 10 }}>
          {props.phoneNumbers.map((x, index) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                key={`pp${index}`}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 20,
                    color: AppColor.PrimaryColor,
                  }}>
                  {x.countryCode}
                  {x.longCode}
                </Text>
                <MaterialIcons
                  onPress={() => {
                    if(affiliate?.checkdnc && x?.isdnc){
                      setDNC(x);
                    } else {
                      props.setPhoneModalVisible(false);
                      props.handleCall(x.countryCode + x.longCode);
                    }
                  }}
                  name="phone"
                  size={30}
                  color={AppColor.PrimaryColor} />
              </View>
            );
          })}
        </ScrollView>
        <TouchableOpacity
          onPress={() => props.setPhoneModalVisible(false)}
          style={{
            padding: 10,
            backgroundColor: AppColor.PrimaryColor,
            borderRadius: 5,
            marginBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.SecondaryColor,
              fontSize: 20,
              textAlign: 'center',
            }}>
            Cancel
          </Text>
        </TouchableOpacity>
        </>
        }
      </View>
    </View>
  );
};


const DNCWarning = props => {
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;

  const message = affiliate?.checkdncmessage ?? 'This number is listed on the FCC\'s national Do Not Call list, are you sure you\'d like to continue?';
  return(
    <View style={{flex:1,alignSelf:'center',justifyContent:'center',alignItems:'center',gap:20}}>
    <Text>
      {message}
    </Text>
    <CustomButton onPress={props.onCancel} title={'Go Back'}/>
    <CustomButton onPress={props.onContinue} title={'Continue'}/>

    </View>
  )
}