
import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, Platform } from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const CustomInput = (props) => {
  const [charCount, setCharCount] = useState(0);

  const handleTextChange = (text) => {
    setCharCount(text.length);
    if (props.onChangeText) props.onChangeText(text);
  }

  return (
    <>
      <Text
        style={{
          fontSize: 15,
          color: AppColor.PrimaryColor,
          fontFamily: AppFonts.Roboto_Bold,
          ...props.titleStyle,
        }}>
        {props.title}
      </Text>
      <View
        style={{
          minHeight: 45,
          width: props.width ? props.width : '100%',
          alignSelf: 'center',
          marginTop: 10,
          borderRadius: 4,
          paddingBottom: props.maxLength ? 20 : 0,
          ...props.containerStyle,
        }}>
        <TextInput
          spellCheck
          autoCorrect
          value={props.value}
          accessibilityRole='text'
          accessible={true}
          secureTextEntry={props.secure}
          placeholder={props.placeholder}
          onChangeText={handleTextChange}
          keyboardType={props.keyboardType}
          multiline={props.multiline}
          onChange={e => props.onChange ? props.onChange(e) : null}
          onKeyPress={(e) => {
            if (e.nativeEvent.code == 'Enter' && props.onEnterPress) {
              props.onEnterPress();
            }
          }}
          style={{
            width: '100%',
            paddingVertical: 10,
            paddingHorizontal: 5,
            borderBottomWidth: 1,
            borderBottomColor: AppColor.PrimaryColor,
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 16,
            backgroundColor: props.disabled ? AppColor.WHITE400 : 'transparent',
            ...props.textInputStyle,
          }}
          editable={!props.disabled}
          maxLength={props.maxLength}
        />
        {props.maxLength && (
          <Text style={styles.counter}>
            {charCount}/{props.maxLength}
          </Text>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: 12,
    color: 'black',
  },
});

export default CustomInput;
