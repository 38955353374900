/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from 'react';
import { StyleSheet, Image, Text, View, StatusBar } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { Icon } from 'react-native-elements';
const Header = (props) => {
  return (
    <>
      {/* <StatusBar barStyle="dark-content" backgroundColor={AppColor.PrimaryColor} /> */}
      <View
        style={{
          height: 50,
          backgroundColor: AppColor.PrimaryColor,
          flexDirection: 'row',
          paddingHorizontal: 10,
        }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: 50,
          }}>
          {props.back && (
            <Icon
              accessibilityRole='button'
              accessibilityLabel='Back button'
              onPress={props.onPress}
              color={AppColor.SecondaryColor}
              name={props.back ? 'arrow-back' : 'menu'}
              type={'ionicon'}
            />
          )}
        </View>
        <View
          style={{
            height: 50,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              color: AppColor.SecondaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            {props.title}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            height: 50,
            alignItems: 'flex-end',
          }}>
          {props.search ? (
            <Icon
              onPress={props.onPressRight}
              color={AppColor.SecondaryColor}
              name={'search'}
              type={'ionicon'}
            />
          ) : null}
          {props.chatIcon}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Header;
