import firebase from 'firebase/app';
import '@firebase/messaging';

// const firebaseConfig = {
//   apiKey: "AIzaSyActAK5gO1TNiR7ztZc4HFVFpgJ8sb-NAQ",
//   authDomain: "test-repchat.firebaseapp.com",
//   projectId: "test-repchat",
//   storageBucket: "test-repchat.appspot.com",
//   messagingSenderId: "649310833033",
//   appId: "1:649310833033:web:843c0246b52d2a8503b958",
//   measurementId: "G-CLYSSDKVCL"
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAa24wXHKbAb1Pja_H8kc_DNZtHiSnPIs4',
  authDomain: 'ionlake-myrepchat-4f23b.firebaseapp.com',
  projectId: 'ionlake-myrepchat-4f23b',
  storageBucket: 'ionlake-myrepchat-4f23b.appspot.com',
  messagingSenderId: '677978055606',
  appId: '1:677978055606:web:decfc0bf9a85a41126d717',
  measurementId: 'G-TGJ8N0RQH9',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
