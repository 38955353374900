import React from 'react';
import { useToast } from 'native-base';
import { View, Linking, Keyboard, Platform } from 'react-native';
import Alert from 'Alert';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { widthPercentageToDP } from 'react-native-responsive-screen';

const AddBookmarks = ({ navigation, route }) => {
  const Toast = useToast();
  const [id, setId] = React.useState(null);
  const [label, setLabel] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [type, setType] = React.useState('create');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(()=>{
    const clearData = navigation.addListener('blur', () => {
      navigation.setParams({item: null})
    })
    return clearData
  },[navigation])

  React.useEffect(() => {
    if (route.params?.item) {
      const { item } = route.params;
      setId(item.id);
      setUrl(item.url);
      setLabel(item.label);
      setType('update');
    } else {
      setId(null);
      setUrl('');
      setLabel('');
      setType('create')
    }
  }, [route]);

  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  const handleSave = async () => {
    if (label.trim().length == 0) {
      Toast.show({
        duration: 2000,
        title: 'Bookmark Label is empty!',
        status: 'warning',
      });
      return;
    }
    if (url.trim().length == 0) {
      Toast.show({
        duration: 2000,
        title: 'Bookmark url is empty!',
        status: 'warning',
      });
      return;
    }
    if (!validURL(url)) {
      Toast.show({
        duration: 2000,
        title: 'Bookmark url must be a valid url!',
        status: 'warning',
      });
      return;
    }
    Keyboard.dismiss();
    setLoading(true);
    try {
      let body = {
        label: label,
        url: url,
        useembedded: false,
      };
      if (type == 'create') {
        body['action'] = 'create_bookmark';
      } else {
        body['action'] = 'update_bookmark';
        body['id'] = id;
      }
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        if (id == null) {
          Toast.show({
            duration: 2000,
            title: 'Your new Bookmark has been saved.',
            status: 'success',
          });
        } else {
          Toast.show({
            duration: 2000,
            title: 'Your Bookmark has been updated.',
            status: 'success',
          });
        }

        navigation.goBack();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        title={'Bookmarks'}
        back={true}
      />
      <View style={{ width: '100%', alignSelf: 'center', padding: 30 }}>
        <View style={{ height: 20 }}></View>
        <CustomInput
          onChangeText={(t) => setLabel(t)}
          value={label}
          title={'BOOKMARK LABEL*'}
          placeholder={'Enter Name'}
        />
        <View style={{ height: 20 }}></View>
        <CustomInput
          onChangeText={(t) => setUrl(t)}
          value={url}
          title={'BOOKMARK URL'}
          placeholder={'Enter Url'}
        />
        <View style={{ height: 40 }}></View>
        <CustomButton
          onPress={() => {
            if (validURL(url)) {
              if (Platform.OS == 'web') {
                window.open(url, '_blank').focus();
              } else {
                Linking.openURL(url);
              }
            } else {
              Toast.show({
                duration: 2000,
                title: 'Please enter valid url to open link.',
              });
            }
          }}
          title={'OPEN LINK'}
          style={{ width: '50%' }}
        />
        <View style={{ height: 20 }}></View>
        {/* <CustomButton onPress={() => navigation.goBack()} title={'OPEN IN PREVIEW'} />
        <View style={{ height: 20 }}></View> */}
        <CustomButton
          onPress={() => navigation.navigate('SelectBookContact', { url: url })}
          title={'SEND TO CONTACT'}
          style={{ width: '50%' }}
        />
        <View style={{ height: 20 }}></View>
        <CustomButton
          onPress={handleSave}
          title={'Save'}
          style={{ width: '50%' }}
        />
        <View style={{ height: 20 }}></View>
        <CustomButton
          onPress={() => navigation.goBack()}
          title={'Cancel'}
          style={{ width: '50%' }}
        />
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default AddBookmarks;
