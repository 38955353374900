import axios from 'axios';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';

const RequestMaker = async (body) => {
  if(body.action){
    let token = await getData('token');
    let device = await getData('@device_id');
    const headers = { 'x-dart-token': token, 'x-device': device };

    try {
      let payload = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      }
      
      const result = await axios(payload);
      return result;
    } catch (error) {
      return {
        data: { success: false, errorMessage: error?.message || 'Server Error' },
      };
    }
  } else {
    try {
      const result = await axios(body);
      return result;
    } catch (error) {
      return {
        data: { success: false, errorMessage: error?.message || 'Server Error' },
      };
    }
  }
};
export default RequestMaker;
