import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import { useState } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from 'react-native';
import Alert from 'Alert';

import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import getData from '../../AsyncUtils/GetData';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import { useToast } from 'native-base';
import { useFocusEffect } from '@react-navigation/native';
import { alignSelf } from 'styled-system';
const DialPad = ({ navigation }) => {
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  const Toast = useToast();
  const [pad, setPad] = useState([]);
  const call = () => {
    if (pad.join('').length == 10) {
      handleCall('+1' + pad).then((x) => {
        setTimeout(() => {
          Linking.openURL(`tel:${pad.join('')}`);
        }, 3000);
      });
    } else {
      Alert.alert(
        'Error Message',
        'The format of your number is incorrect. Please input again and retry.'
      );
    }
  };
  useFocusEffect(
    React.useCallback(() => {
      setPad([]);
    }, [])
  );
  const handleCall = async (phoneNumber) => {
    try {
      const body = {
        action: 'initiate_call',
        toNumber: phoneNumber,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to compelete the call',
          status: 'success',
        });
      } else {
        Alert.alert('Eror Message', result.data.failures.join(','));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Dialpad'}
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        //  chatIcon={<Icon
        //     onPress={()=>{
        //         Linking.openURL("https://ionlake.zendesk.com/hc/en-us/sections/360005996891-Contacts-and-Groups-Mobile")
        //     }}
        //     style={{ color: AppColor.SecondaryColor,fontSize:25}}
        //     name={'help-outline'}
        //     type={'MaterialIcons'}
        // />}
      />
      {/* <View style={{ width: widthPercentageToDP(100), alignItems: 'center' }}><Search /></View> */}
      <View style={{ flex: 1, width: '50%', alignSelf: 'center' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <TouchableOpacity
            onPress={() => navigation.navigate('DialpadSearch')}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '30%',
              alignSelf: 'center',
              margin: 20,
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'search1'}
              type={'antdesign'}
            />
            <Text style={{ color: AppColor.PrimaryColor }}>Search</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('Messages')}
            style={{
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              width: '30%',
              alignSelf: 'center',
              margin: 20,
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'account-details'}
              type={'material-community'}
            />
            <Text style={{ color: AppColor.PrimaryColor }}>Recents</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('AllContacts')}
            style={{
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
              width: '30%',
              alignSelf: 'center',
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'adduser'}
              type={'antdesign'}
            />
            <Text style={{ color: AppColor.PrimaryColor }}>Contacts</Text>
          </TouchableOpacity>
        </View>
      </View>

      <View
        style={{
          alignItems: 'center',
          width: '50%',
          alignSelf: 'center',
        }}>
        <View style={{ height: 100, justifyContent: 'center' }}>
          <ScrollView style={{ flex: 1 }} horizontal>
            <View
              style={{
                height: 100,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}>
              <Text
                style={{
                  width: '100%',
                  fontSize: 50,
                  textAlign: 'center',
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                {pad.join('')}
              </Text>
            </View>
          </ScrollView>
        </View>
        <View
          style={{
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
            alignSelf: 'center',
          }}>
          {numbers.map((i, index) => {
            return (
              <TouchableOpacity
                key={`number_${index}`}
                onPress={() => {
                  setPad((o) => [...o, i]);
                  console.log(pad);
                }}
                activeOpacity={0.8}
                style={{
                  width: '33%',
                  borderWidth: 1,
                  borderColor: AppColor.SecondaryColor,
                  height: 90,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: AppColor.PrimaryColor,
                }}>
                <Text
                  style={{
                    alignItems: 'center',
                    fontSize: 30,
                    color: AppColor.SecondaryColor,
                    fontFamily: AppFonts.Roboto_Bold,
                  }}>
                  {i}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            alignSelf: 'center',
          }}>
          <TouchableOpacity
            onPress={() => setPad((o) => ['+', ...o])}
            activeOpacity={0.5}
            style={{
              height: 100,
              borderWidth: 1,
              borderColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              width: '33%',
              borderRadius: 0,
              backgroundColor: AppColor.PrimaryColor,
            }}>
            <Text
              style={{
                fontSize: 30,
                textAlign: 'center',
                color: AppColor.SecondaryColor,
                fontFamily: AppFonts.Roboto_Regular,
              }}>
              +
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() => call()}
            style={{
              borderWidth: 1,
              borderColor: AppColor.SecondaryColor,
              height: 100,
              width: '33%',
              backgroundColor: AppColor.PrimaryColor,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon color="white" name={'call'} type={'material'} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              let p = [...pad];
              p.pop();
              setPad([...p]);
            }}
            activeOpacity={0.5}
            style={{
              height: 100,
              borderWidth: 1,
              borderColor: AppColor.SecondaryColor,
              justifyContent: 'center',
              alignItems: 'center',
              width: '33%',
              backgroundColor: AppColor.PrimaryColor,
            }}>
            <Icon
              color={AppColor.SecondaryColor}
              name={'backspace'}
              type={'font-awesome-5'}
            />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};
export default DialPad;
