/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from 'react';
import { StyleSheet, Image, Text, View, TextInput } from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { Icon } from 'react-native-elements';
const Search = (props) => {
  return (
    <>
      <View
        style={{
          height: 45,
          width: props.width ? props.width : '95%',
          alignSelf: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomColor: AppColor.PrimaryColor,
          borderBottomWidth: 1,
          marginTop: 5,
          marginBottom: 10,
        }}>
        <TextInput
        onSubmitEditing={props.onSubmitEditing}
          onKeyPress={props.onKeyPress}
          onChangeText={props.onChangeText}
          secureTextEntry={props.secure}
          placeholder={props.placeholder}
          style={{
            height: 45,
            width: widthPercentageToDP(90),
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 16,
            paddingHorizontal: 20,
          }}
        />
        <View
          style={{
            position: 'absolute',
            right: 5,
            top: 10,
          }}>
          {props.icon}
        </View>
      </View>
    </>
  );
};

Search.defaultProps = {
  secure: false,
  onChangeText: (t) => {
    console.log(t);
  },
  placeholder: 'Search Here',
  icon: (
    <Icon
      color={AppColor.PrimaryColor}
      name={'search'}
      type={'font-awesome-5'}
    />
  ),
};
const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Search;
