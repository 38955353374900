import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';

import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import Loader from '../../../../Components/Loader';
import AppColor from '../../../../Constants/Color';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';
import AppFonts from '../../../../Constants/Fonts';

const MarketingPro = ({ provider, integration, loadProviderData }) => {
  const [loading, setLoading] = useState(false);
  const [userCode, setUserCode] = useState('');

  const Toast = useToast();

  useEffect(() => {
    if (integration) {
      setUserCode(integration.usercode);
    } else {
      setUserCode('');
    }
  }, [integration]);

  const handleSave = async () => {
    let body = {
      action: 'create_integration',
      provider: provider.name,
      active: true,
      type: provider.type.name,
      usercode: userCode,
    };
    if (integration && integration != null) {
      body = {
        action: 'update_integration',
        provider: provider.name,
        active: true,
        type: provider.type.name,
        usercode: userCode,
        id: integration.id,
      };
    }

    try {
      setLoading(true);
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result.data);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Marketing Pro settings saved',
          status: 'success',
        });
        loadProviderData(null, provider, null);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <View style={styles.topComment}>
            <Text style={styles.text}>
              Connect MyRepChat to MarketingPro by providing your User Code and
              clicking the Save button below. Once connected you can access
              links to saved articles in MarketingPro and easily send them to
              clients.
            </Text>
            <Text style={styles.text}>
              Note: You will need to log out and log back into mobile app to see
              this setting change.
            </Text>
          </View>
          <CustomInput
            title="User Code"
            value={userCode}
            onChangeText={(val) => setUserCode(val)}
          />
          <View style={styles.buttonWrapper}>
            <CustomButton
              title="SAVE"
              onPress={handleSave}
              style={{ width: '40%' }}
            />
          </View>
        </View>
      </SafeAreaView>
      {loading && <Loader backgroundColor="transparent" />}
    </>
  );
};

export default MarketingPro;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 30,
  },
  topComment: {
    marginBottom: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
});
