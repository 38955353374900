import getData from "../AsyncUtils/GetData";
import End_Points from "../Constants/Api";

export const MakeConfig = async body => {
    let token = await getData('token');
    let device = await getData('@device_id');
    const headers = { 'x-dart-token': token, 'x-device': device };

    return {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };
}