import React, { useEffect, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
} from 'react-native';
import { useSelector } from 'react-redux';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Calendar from 'react-calendar';
import Alert from 'Alert';
import { Icon } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment-timezone';

import RequestMaker from '../../Middleware/ApiCaller';
import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import Loader from '../../Components/Loader';
import getData from '../../AsyncUtils/GetData';
import 'react-calendar/dist/Calendar.css';
import './index.css';

const ArchiveAudit = ({ navigation, route }) => {
  const [date, setDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [auditList, setAuditList] = useState();
  const tenant = useSelector((state) => state.User)?.profile?.tenant;

  useEffect(() => {
    handleGetAudit();
  }, []);

  useEffect(() => {
    handleGetAudit();
  }, [activeStartDate]);

  const handleGetAudit = async () => {
    try {
      let body = {
        action: 'get_archiveaudit_by_day',
        tenantId: tenant.id,
        month: moment(date).format('YYYY-MM'),
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      console.log(111, data);
      if (data.success) {
        setAuditList(data.audits);
      } else {
        Alert.alert('Error', data.errorMessage);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderItem = ({ item, index }) => {
    const date = item.rangeStart ? moment(new Date(item.rangeStart)) : moment();

    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedDetail(item);
          setTimeout(() => {
            setIsVisibleDetail(true);
          }, 300);
        }}>
        <View style={styles.row}>
          <Text>
            {item?.timezone
              ? date.tz(item.timezone).format('YYYY-MM-DD hh:mm z')
              : date.format('YYYY-MM-DD hh:mm z')}
          </Text>
          <Text>{item.tenant}</Text>
          <Text>{item.state}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Archive Audit'}
      />
      <View style={styles.container}>
        <Text style={styles.description}>
          Select the date you would like to view an archive audit for. The
          details for the audit will be displayed below the calendar.
        </Text>
        <Calendar
          onChange={(val) => setDate(val)}
          onViewChange={({ action, activeStartDate, value, view }) => {
            setDate(activeStartDate);
            setActiveStartDate(activeStartDate);
          }}
          onActiveStartDateChange={({
            action,
            activeStartDate,
            value,
            view,
          }) => {
            setDate(activeStartDate);
            setActiveStartDate(activeStartDate);
          }}
          value={date}
          calendarType="US"
          defaultView="month"
          next2Label={null}
          prev2Label={null}
          tileClassName="dateItem"
          tileContent={({ date, view }) => {
            if (
              auditList?.[moment(date).format('YYYY-MM-DD')]?.details?.length >
                0 &&
              view === 'month'
            ) {
              return <View style={styles.tile} />;
            }
            return null;
          }}
        />
        <View style={styles.content}>
          <Text style={styles.date}>{moment(date).format('YYYY-MM-DD')}</Text>
          <View style={styles.row}>
            <View style={styles.item}>
              <Text style={styles.value}>
                {
                  auditList?.[moment(date).format('YYYY-MM-DD')]?.summary
                    .messageCount
                }
              </Text>
              <Text style={styles.label}>messages</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.value}>
                {
                  auditList?.[moment(date).format('YYYY-MM-DD')]?.summary
                    .archiveDetailCount
                }
              </Text>
              <Text style={styles.label}>archives</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.value}>
                {
                  auditList?.[moment(date).format('YYYY-MM-DD')]?.summary
                    .archiveCount
                }
              </Text>
              <Text style={styles.label}>audits</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.value}>
                {
                  auditList?.[moment(date).format('YYYY-MM-DD')]?.summary
                    .failureArchiveCount
                }
              </Text>
              <Text style={styles.label}>failures</Text>
            </View>
          </View>
          <View style={styles.row}>
            <Text style={styles.text}>Time Status</Text>
            <Text style={styles.text} />
            <Text style={styles.text}>Account</Text>
          </View>
          <FlatList
            data={auditList?.[moment(date).format('YYYY-MM-DD')]?.details || []}
            keyExtractor={(item, index) => `audit_${index}`}
            renderItem={renderItem}
            style={styles.flatList}
          />
        </View>
      </View>
      {loading && <Loader />}
      {selectedDetail && (
        <Modal
          animationIn={'fadeIn'}
          useNativeDriver={true}
          animationOut={'fadeOut'}
          backdropOpacity={0.9}
          visible={isVisibleDetail}
          transparent={true}
          onDismiss={() => {
            setIsVisibleDetail(false);
          }}
          ariaHideApp={false}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            border: 'none',
          }}
          onBackdropPress={() => {
            setIsVisibleDetail(false);
          }}>
          <View style={styles.modalContainer}>
            <View style={styles.modal}>
              <View style={{ position: 'absolute', right: 10, top: 10 }}>
                <TouchableOpacity onPress={() => setIsVisibleDetail(false)}>
                  <EvilIcons name="close" size={30} />
                </TouchableOpacity>
              </View>
              <View>
                <Text style={styles.detailTitle}>Provider</Text>
                <Text style={styles.detailDes}>{selectedDetail?.provider}</Text>
                <Text style={styles.detailTitle}>State</Text>
                <Text style={styles.detailDes}>{selectedDetail?.state}</Text>
                <Text style={styles.detailTitle}>Date/Time</Text>
                <Text style={styles.detailDes}>
                  {moment(new Date(selectedDetail?.timestamp))
                    .tz(selectedDetail?.timezone)
                    .format('YYYY-MM-DD hh:mm z')}
                </Text>
                <Text style={styles.detailTitle}>Range Start</Text>
                <Text style={styles.detailDes}>
                  {moment(new Date(selectedDetail?.rangeStart))
                    .tz(selectedDetail?.timezone)
                    .format('YYYY-MM-DD hh:mm z')}
                </Text>
                <Text style={styles.detailTitle}>Range End</Text>
                <Text style={styles.detailDes}>
                  {moment(new Date(selectedDetail?.rangeEnd))
                    .tz(selectedDetail?.timezone)
                    .format('YYYY-MM-DD hh:mm z')}
                </Text>
                <Text style={styles.detailTitle}>Message Total</Text>
                <Text style={styles.detailDes}>
                  {selectedDetail?.totalMessages}
                </Text>
                <Text style={styles.detailTitle}>Retry Count</Text>
                <Text style={styles.detailDes}>
                  {selectedDetail?.retryCount}
                </Text>
              </View>
              <View style={styles.button}>
                <CustomButton
                  title="Ok"
                  onPress={() => setIsVisibleDetail(false)}
                />
              </View>
            </View>
          </View>
        </Modal>
      )}
    </SafeAreaView>
  );
};
export default ArchiveAudit;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 30,
  },
  label: {
    fontSize: 30,
    fontFamily: AppFonts.Roboto_Regular,
    textTransform: 'uppercase',
    color: AppColor.PrimaryColor,
  },
  value: {
    fontSize: 30,
    fontFamily: AppFonts.Roboto_Bold,
    color: AppColor.PrimaryColor,
  },
  row: {
    marginVertical: 15,
    justifyContent: 'space-around',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  content: {
    width: '100%',
    alignItems: 'center',
    marginTop: 30,
  },
  text: {
    fontSize: 20,
    fontFamily: AppFonts.Roboto_Regular,
  },
  item: {
    alignItems: 'center',
  },
  flatList: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: AppColor.GREY100,
    width: '100%',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#0007',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    width: '90%',
    maxWidth: 500,
  },
  detailTitle: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Bold,
    color: AppColor.PrimaryColor,
    marginTop: 10,
  },
  detailDes: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    color: AppColor.BlackColor,
  },
  tile: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: AppColor.PrimaryColor,
    marginTop: 10,
  },
});
