import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import getData from '../AsyncUtils/GetData';
import { setToken } from '../Redux';
import Login from '../Screens/Authentication/Login';
import Splash from '../Screens/Splash';
import VerifyEmail from '../Screens/Authentication/VerifyEmail';
import Drawer from '../Drawer';
import ForgertPassword from '../Screens/Authentication/forget_password';
import Verification from '../Screens/Authentication/Signup/verification';
import CompanyDetail from '../Screens/Authentication/Signup/CompanyDetail';
import GroupSetup from '../Screens/Authentication/Signup/GroupSetup';
import EmailPassword from '../Screens/Authentication/Signup/EmailPassword';
import SearchMessages from '../Screens/Messages/SearchMessages';
import OAuthView from '../Screens/Settings/Integrations/ui/OAuthView';
import SSOScreen from '../Screens/Authentication/SSOScreen';
import MobileAuth from '../Screens/Authentication/Mobile';
import ContentScreen from '../Screens/Chat/ContentScreen';
import SignupWizard from '../Screens/SignupWizard';
import AppOptions from '../Screens/Profile/AppOptions';
import PositiveConsent from '../Screens/Profile/PositiveConsent';
import Integrations from '../Screens/Settings/Integrations';
import Support from '../Screens/Support/index';
import { SocketProvider } from '../Components/Ten4/Ten4SocketProvider';
import LOAForm from '../Screens/SignupWizard/MyRepChatNum/LOAForm';

const Stack = createStackNavigator();

const title = (text) => Platform.select({ web: `MyRepChat | ${text}`, default: text });

function StackScreens() {
  const { authenticated, profile } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  const loadData = async () => {
    let token = await getData('token');
    if (token) {
      dispatch(setToken({ token, authenticated: false }));
    }
  };


  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {!authenticated ? (
        <>
          <Stack.Screen
            name="Login"
            
            component={Login}
            options={{ title: title('Login') }}
          />
          <Stack.Screen
            name="SSOScreen"
            component={SSOScreen}
            options={{ title: title('SSO') }}
          />
          <Stack.Screen
            name="GroupSetup"
            component={GroupSetup}
            options={{ title: title('Organization Setup') }}
          />
          <Stack.Screen
            name="VerifyEmail"
            component={VerifyEmail}
            options={{ title: title('VerifyEmail') }}
          />
          <Stack.Screen
            name="CompanyDetail"
            component={CompanyDetail}
            options={{ title: title('CompanyDetail') }}
          />
          <Stack.Screen
            name="Verification"
            component={Verification}
            options={{ title: title('Verification') }}
          />
          <Stack.Screen
            name="EmailPassword"
            component={EmailPassword}
            options={{ title: title('Email Password') }}
          />
          <Stack.Screen
            name="ForgetPassword"
            component={ForgertPassword}
            options={{ title: title('Forget Password') }}
          />
          <Stack.Screen
            name="MobileAuth"
            component={MobileAuth}
            options={{ title: title('Login') }}
          />
        </>
      ) : (
        <>
          {profile?.tenant?.tw_number ? (
            <Stack.Screen name="Drawer" component={DrawerWrapper} />
          ) : (
            <>
            <Stack.Screen name="SignupWizard" component={SignupWizard} />
            <Stack.Screen
            name="Integrations"
            component={Integrations}
            options={{ title: title('Integrations') }}
          />
          <Stack.Screen
            name="Support"
            component={Support}
            options={{ title: title('Support') }}
          />
          </>
    
          )}
        </>
      )}

    </Stack.Navigator>
  );
}

const DrawerWrapper = ({navigation}) => {
  return(
    <SocketProvider>
      <Drawer
      navigation={navigation}
      />
    </SocketProvider>
  )
}

function SplashScreen() {
  const [splash, setSplash] = useState(true);

  useEffect(() => {
    let isSubscribed = true
    setTimeout(() => {
      setSplash(false);
    }, 1500);
    return () => (isSubscribed = false)
  });

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {splash && <Stack.Screen name="Splash" component={Splash} />}
      <Stack.Screen name="StackScreens" component={StackScreens} />
    </Stack.Navigator>
  );
}

function Router() {

  const linking = {
    config: {
      NotFound: "404",
      StackScreens: {
        screens: {
          Login: "Login",
          Drawer: "Home",
          GroupSetup: "Register/GroupCode",
          CompanyDetail: "Register/CompanyDetail",
          EmailPassword: "Register/EmailPassword",
          SignupWizard: "Register/SignupWizard",
          
        },
      },
    },
  }
  
  const navigationRef = React.useRef(null);

  return (
      <NavigationContainer ref={navigationRef}>
          <SplashScreen />
      </NavigationContainer>
  );
}

export default Router;
