import { useToast } from 'native-base';
import React from 'react';
import {
  Text,
  View,
  Image,
  Keyboard,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import { Icon, Input } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import { TouchableOpacity } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { CheckBox } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { providers } from '../IntegrationProvider';
import EndPoints from '../../Constants/Api';
import Alert from 'Alert'

const Contacts = ({ navigation }) => {
  const Toast = useToast();
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state.distributions).data;
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [members, setMembers] = React.useState([]);
  const [dls, setDLs] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [data, setData] = React.useState({
    action: 'search',
    includeDistributionList: true,
    includeContacts: true,
    type: 'crm_and_local',
    import: false,
    filters: {
      scope: 'full',
    },
  });

  const handleDone = (d) => {
    setData(d);
  };

  // search begin
  const beginsearch = async () => {
    if (search.trim().length < 2) {
      Toast.show({
        duration: 2000,
        title: 'Search text must be atleast 2 characters',
        status: 'warning',
      });
      return;
    }
    // Keyboard.dismiss()
    try {
      setSearchLoading(true);
      const body = { ...data };
      body.filters['google'] = search;
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        setMembers(result.data.contacts);
        setDLs(result.data.distributionLists);
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSearchLoading(false);
    }
  };

  // create quick contact and goto chat window on create
  const handleQuickContact = async (data) => {
    Keyboard.dismiss();
    try {
      setLoading(true);
      const body = {
        action: 'create_member',
        disallowDuplicate: true, //return an existing contact with same messaging phone instead of creating new
        accountId: data.accountId ?? '',
        fname: data.firstName,
        mname: '',
        lname: data.lastName,
        address: '',
        city: '',
        state: '',
        zip: '',
        email_address: '',
        birth_date: null,
        consented: false,
        spam: false,
        distributionLists: [],
        phoneNumbers: [
          {
            isoCode: 'US',
            countryCode: '+1',
            longCode: data.phoneNo,
            type: 1,
            messaging: true,
          },
        ],
        source: '',
        sourceId: '',
        comments: '',
        email_newsletter: false,
        list_in_directory: false,
        notify: false,
        phone1: data.phoneNo,
      };
      let token = await getData('token');
      let device = await getData('@device_id');
      const headers = { 'x-dart-token': token, 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        body['id'] = result.data.memberId;
        // navigation.navigate('Chats', { memberData: body });
        navigation.navigate('Messages', { memberData: body });
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // after click on searched contact check weather it in already coantact or not else add and goto chat
  const handleCrmSearchClick = async (item) => {
    try {
      setLoading(true);
      if (!item.id || item.id == 0 || item.id == null) {
        const body = {
          action: 'create_member',
          accountId: item.accountId ?? '',
          fname: item.fname ?? '',
          mname: item.mname ?? '',
          lname: item.lname ?? '',
          address: item.address ?? '',
          city: item.city ?? '',
          state: item.state ?? '',
          zip: item.zip ?? '',
          email_address: item.email_address ?? '',
          birth_date: item.birth_date ?? null,
          consented: item.consented ?? false,
          spam: item.spam ?? false,
          distributionLists: [],
          phoneNumbers: item.phoneNumbers,
          source: item.source ?? '',
          sourceId: item.sourceId ?? '',
          comments: item.comments ?? '',
          email_newsletter: false,
          list_in_directory: false,
          notify: false,
        };
        let token = await getData('token');
        let device = await getData('@device_id');
        const headers = { 'x-dart-token': token, 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.data.success) {
          dispatch(loadAllContactsThunk());
          body['id'] = result.data.memberId;
          // navigation.navigate('Chats', { memberData: body });
          navigation.navigate('Messages', { memberData: body });
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } else {
        // navigation.navigate('Chats', { memberData: item });
        navigation.navigate('Messages', { memberData: item });
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const phoneFormat = (phoneNumber) => {
    let resp = phoneNumber;
    if (!resp) return;
    if (phoneNumber.length === 10) {
      var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        resp = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    } else if (phoneNumber.length > 10) {
      if (phoneNumber.startsWith('+1')) {
        resp =
          '(' +
          phoneNumber.substring(2, 5) +
          ') ' +
          phoneNumber.substring(5, 8) +
          '-' +
          phoneNumber.substring(8);
      }
    }

    return resp;
  };

  const renderContactView = ({ item }) => {
   
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == item.source);
      if (provider) {
        image = End_Points.Base_url + provider.smallImageUrlPath;
        imageAlt = `${provider.name} logo.`
      }
    

    const checkForMessagingNumber = () => {
      if(!item.phoneNumbers.length){
        Alert.alert('No messaging number','This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
        return;
      }
      handleCrmSearchClick(item)
    }
    
    let status = item.consented ? 'Consented' : item.stopped ? 'Opted-Out' : '';

    const accountId = item?.accountId || null;
    
    return (
      <TouchableOpacity
        onPress={checkForMessagingNumber}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            width: '25%',
          }}>
          <img alt={imageAlt} src={image} style={{ height: 40, width: 40 }} />
          <View>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
          {accountId &&
            <View style={{flexDirection:'row',marginHorizontal: 10}}>
            <Icon size={17} name='building-o' type='font-awesome'/>
            <Text style={{fontFamily: AppFonts.Roboto_Regular,fontSize: 17,marginLeft:2,color: AppColor.Dark,}}>{accountId}</Text>
            </View>
          }
          </View>
        </View>
        <View style={{ flex: 1, justifyContent: 'center', width: '25%' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.email_address}
          </Text>
        </View>
        <View style={{ flex: 1, justifyContent: 'center', width: '25%' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {phoneFormat(item.phone1)}
          </Text>
        </View>
        <View style={{ flex: 1, justifyContent: 'center', width: '25%' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              width:100,
              justifyContent:'center',
              alignItems:'center',
              color: status === 'Opted-Out' ? AppColor.danger : 'green',
            }}>
            {status}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const handleDistributionNavigation = async (item) => {

    if (item.id == 0 || item.id == null) {
      try {
        setLoading(true);
        const body = {
          action: 'import_crm_dls',
          distributionLists: [{provider: item.source, sourceid: item.sourceid, text: item.text}]
        };
        let token = await getData('token');
        let device = await getData('@device_id');
        const headers = { 'x-dart-token': token, 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.data.success) {
          console.log("Data:",result.data);
          dispatch(loadAllDistributionsThunk());
          const data = { id: result.data.id, text: item.text };
          navigation.navigate('Chats', { dl: data });
          //navigation.navigate('Messages', { dl: data });
        } else {
          Toast.show({
            duration: 3000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      navigation.navigate('Chats', { dl: item });
      //navigation.navigate('Messages', { dl: data });
    }
  };

  const renderDLView = ({ item }) => {

    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == item.source);
    if (provider) {
      image = End_Points.Base_url + provider.smallImageUrlPath;
      imageAlt = `${provider.name} logo.`
    }
    
    return (
      <TouchableOpacity
        onPress={() => {
          Alert.alert(
            'Message',
            'Do you want to send message to this distribution?',
            [
              {
                text: 'Cancel',
              },
              {
                text: 'Distribution Message',
                onPress: () => {
                  handleDistributionNavigation(item);
                },
              },
            ]
          );
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
          <img alt={imageAlt} src={image} style={{ height: 40, width: 40 }} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {item.text && (
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Start a Conversation'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Search onKeyPress={e => {if(e.key === 'Enter') beginsearch()}} value={search} onChangeText={(t) => setSearch(t)} />

        <TouchableOpacity onPress={() => setVisible(true)}>
          <AntDesign name="filter" size={30} color={AppColor.PrimaryColor} />
        </TouchableOpacity>
      </View>
      <CustomButton title="Search" onPress={beginsearch} />
      {!searchLoading && dls.length == 0 && members.length == 0 && (
        <Text style={{ textAlign: 'center' }}>No contact matches</Text>
      )}

<View style={{ paddingBottom: 10, flex: 1, marginBottom: 30,flexDirection:'row',justifyContent:'center' }}>
        {dls.length > 0 && (
          <FlatList
            style={{flex:1}}
            data={dls}
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={renderDLView}
            ListHeaderComponent={
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  marginVertical: 15,
                }}>
                Distribution Lists
              </Text>
            }
          />
        )}
      {(!!dls.length && !!members.length ) &&
          <View style={{height:'100%',width:2,backgroundColor:AppColor.PrimaryColor,opacity:.6}}/>
        }


        {members.length > 0 && (
          <FlatList
            style={{flex:2}}
            data={members}
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={renderContactView}
            ListHeaderComponent={
              <>
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 20,
                      fontFamily: AppFonts.Roboto_Bold,
                      marginVertical: 15,
                    }}>
                    Contacts
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                    width: '90%',
                  }}>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Bold,
                      fontSize: 17,
                      marginHorizontal: 10,
                      color: AppColor.Dark,
                      width: '25%',
                    }}>
                    Name
                  </Text>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Bold,
                      fontSize: 17,
                      marginHorizontal: 10,
                      color: AppColor.Dark,
                      width: '25%',
                    }}>
                    Email
                  </Text>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Bold,
                      fontSize: 17,
                      marginHorizontal: 10,
                      color: AppColor.Dark,
                      width: '25%',
                    }}>
                    Phone Number
                  </Text>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Bold,
                      fontSize: 17,
                      marginHorizontal: 10,
                      color: AppColor.Dark,
                      width: '25%',
                    }}>
                    Status
                  </Text>
                </View>
              </>
            }
          />
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 30,
          width: '100%',
        }}>
        <CustomButton
          title="Quick Create"
          style={{ width: '40%' }}
          onPress={() => setVisible1(true)}
        />
      </View>
      <FilterModal
        visible={visible}
        setVisible={setVisible}
        handleDone={handleDone}
      />
      <QuickCreateModal
        visible={visible1}
        setVisible={setVisible1}
        handleQuickContact={handleQuickContact}
      />
      {loading && <Loader />}
      {searchLoading && <Loader />}
    </SafeAreaView>
  );
};

//filter modal for searching contacts
const FilterModal = ({ visible, setVisible, handleDone }) => {
  const [includeDistributionList, setIncludeDistributionList] =
    React.useState(true);
  const [includeContacts, setIncludeContacts] = React.useState(true);
  const [searchCRM, setSeacrchCRM] = React.useState(true);
  const [searchMRC, setSeacrchMRC] = React.useState(true);
  const [allContacts, setAllContacts] = React.useState(true);
  const [myContacts, setMyContacts] = React.useState(false);

  const handleSave = () => {
    let type = 'crm_and_local';
    let scope = 'full';
    if (searchCRM && searchMRC) {
      type = 'crm_and_local';
    } else {
      if (searchCRM) {
        type = 'crm_only';
      }
      if (searchMRC) {
        type = 'local_only';
      }
    }
    if (myContacts) {
      scope = 'user';
    }
    if (allContacts) {
      scope = 'full';
    }

    const data = {
      action: 'search',
      includeDistributionList: includeDistributionList,
      includeContacts: includeContacts,
      type: type,
      import: false,
      filters: {
        scope: scope,
      },
    };
    handleDone(data);
    setVisible(false);
  };
  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Filter
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>

          <View style={{ marginVertical: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={includeContacts}
                onPress={() => setIncludeContacts(!includeContacts)}
                title="Search Contact"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={includeDistributionList}
                onPress={() =>
                  setIncludeDistributionList(!includeDistributionList)
                }
                title="Search DLs"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={searchCRM}
                onPress={() => setSeacrchCRM(!searchCRM)}
                title="Search CRM"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={searchMRC}
                onPress={() => setSeacrchMRC(!searchMRC)}
                title="Search MRC"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: AppColor.PrimaryColor,
                marginVertical: 10,
              }}></View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={allContacts}
                onPress={() => setAllContacts(!allContacts)}
                title="All Contacts"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={myContacts}
                onPress={() => setMyContacts(!myContacts)}
                title="My Contacts"
                containerStyle={{ flex: 1 }}
              />
            </View>
          </View>
          <CustomButton title="Done" onPress={() => handleSave()} />
        </View>
      </View>
    </Modal>
  );
};

// quick create  modal
const QuickCreateModal = ({ visible, setVisible, handleQuickContact }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNo, setPhone] = React.useState('');
  const handleSave = () => {
    if (
      firstName.trim() != '' &&
      (lastName.trim() != '') & (phoneNo.trim() != '')
    ) {
      if(phoneNo.length !== 10){
        Alert.alert('Error', 'Please enter a valid 10 digit phone number.');
        return;
      }
      setVisible(false);
      handleQuickContact({ firstName, lastName, phoneNo });
    } else {
      Alert.alert('Message', 'All field with * are required.');
    }
  };
  React.useEffect(() => {
    if (visible) {
      setFirstName('');
      setLastName('');
      setPhone('');
    }
  }, [visible]);

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '90%',
            maxWidth: 575,
            backgroundColor: 'white',
            padding: 20,
          }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Create Contact
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
            }}>
            <Input
              value={firstName}
              onChangeText={(t) => setFirstName(t)}
              placeholder="First Name*"
              style={{ fontSize: 15 }}
            />
            <Input
              value={lastName}
              onChangeText={(t) => setLastName(t)}
              placeholder="Last Name*"
              style={{ fontSize: 15 }}
            />
            <Input
              value={phoneNo}
              onChangeText={(t) => setPhone(t)}
              placeholder="Phone Number*"
              style={{ fontSize: 15 }}
              keyboardType={'phone-pad'}
            />
          </View>
          <View style={{ marginVertical: 10 }}></View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            <CustomButton
              style={{ width: '40%' }}
              title="Ok"
              onPress={() => handleSave()}
            />
            <CustomButton
              style={{ width: '40%' }}
              title="Cancel"
              onPress={() => setVisible(false)}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default Contacts;
